<template>
  <div class="full-content-box">
    <Button
      type="primary"
      class="addRules"
      style="margin-right: 14px; margin-bottom: 14px; width: 150px"
      @click="addRules"
      >添加考勤规则</Button
    >
    <Table :loading="mainLoading" :columns="mainCloums" :data="mainList">
      <template slot-scope="{ row, index }" slot="attendanceRestriction">
        {{
          row.attendanceRestriction == "GREEN_CODE"
            ? "绿码打卡"
            : row.attendanceRestriction == "GREEN_OR_YELLOW_CODE"
            ? "绿码+黄码打卡"
            : "无"
        }}
      </template>
      <template slot-scope="{ row, index }" slot="clockType">
        {{ row.clockType == "REGULAR" ? "常规打卡" : "人脸识别打卡" }}
      </template>
      <template slot-scope="{ row, index }" slot="ruleType">
        {{ row.ruleType == "PROJECT" ? "项目级" : row.ruleType == "TEAM" ? "班组级" :"" }}
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <div style="display: flex">
          <div
            style="
              margin-right: 4px;
              width: 160px;
              color: rgb(45, 183, 245);
              cursor: pointer;
            "
            @click="editRules(row)"
          >
            编辑规则
          </div>
          <div
            style="width: 100px; color: red; cursor: pointer"
            @click="deleteRules(row)"
          >
            删除
          </div>
          <div
            v-if="row.editorName"
            style="width: 100px; color: rgb(45, 183, 245); cursor: pointer"
          >
            <span v-if="row.showOrHide == 'hide'" @click="showMainConceal(row)"
              >显示</span
            >
            <span v-else @click="hideMainConceal(row)">隐藏</span>
          </div>
        </div>
      </template>
    </Table>
    <Modal
      width="60%"
      v-model="copySafeDiscloseFlag"
      :title="title"
      :mask-closable="false"
    >
      <template slot="footer">
        <Button
          v-if="title == '填写考勤规则信息'"
          type="primary"
          @click="copySafeDiscloseFlag = false"
          >取消</Button
        >
        <Button v-else type="primary" @click="step">上一步</Button>
        <Button v-if="title == '填写考勤规则信息'" @click="next" type="primary"
          >下一步</Button
        >
        <Button v-else type="primary" @click="complete">完成</Button>
      </template>
      <div v-show="title == '填写考勤规则信息'">
        <Form ref="payForm" :model="payForm" :rules="rules1">
          <FormItem label="考勤规则名称：" prop="ruleName">
            <Row>
              <Col span="20">
                <Input
                  placeholder="请输入考勤规则名称"
                  v-model.trim="payForm.ruleName"
                  :maxLength="20"
                />
              </Col>
            </Row>
          </FormItem>
          <FormItem label="适用范围：" prop="ruleType">
            <Row>
              <Col span="3">
                <RadioGroup v-model="payForm.ruleType">
                  <Radio label="PROJECT">项目</Radio>
                  <Radio label="TEAM">班组</Radio>
                </RadioGroup>
              </Col>
               <Col span="12" v-if="payForm.ruleType=='TEAM'">
                <Select
                  style="width: 200px; margin-right: 14px"
                  v-model="payForm.teamId"
                >
                  <Option
                    v-for="item in teamList"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.teamName }}</Option
                  >
                </Select>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="考勤方式：" prop="attendanceType">
            <Row>
              <Col span="20">
                <RadioGroup v-model="payForm.attendanceType">
                  <Radio label="GPS">GPS定位</Radio>
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="考勤限制：" prop="attendanceRestriction">
            <Row>
              <Col span="20">
                <RadioGroup v-model="payForm.attendanceRestriction">
                  <Radio label="GREEN_CODE">绿码打卡</Radio>
                  <Radio label="GREEN_OR_YELLOW_CODE">绿码+黄码打卡</Radio>
                  <Radio label="NONE">无</Radio>
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="打卡方式：" prop="clockType">
            <Row>
              <Col span="20">
                <RadioGroup v-model="payForm.clockType">
                  <Radio label="FACE_RECOGNITION">人脸识别打卡</Radio>
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <!-- <FormItem label="详细地址：" prop="attendanceLocationDetailAddress">
            <Row>
              <Col span="20">
                <Input
                  placeholder="请输入考勤详细地址"
                  v-model.trim="payForm.attendanceLocationDetailAddress"
                  :maxLength="20"
                />
              </Col>
            </Row>
          </FormItem> -->

          <FormItem label="考勤状态监控服务：" prop="attendanceStatusService">
            <Row>
              <Col span="20">
                <RadioGroup v-model="payForm.attendanceStatusService">
                  <Radio label="true">是</Radio>
                  <Radio label="false">否</Radio>
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>

          <FormItem label="项目地址：" prop="attendanceLocationDetailAddress">
            <Row>
              <Col span="20">
                <Input
                  disabled
                  placeholder="请输入项目地址"
                  v-model.trim="payForm.attendanceLocationDetailAddress"
                  :maxLength="20"
                />

                <!-- <Input v-model="region.join()" :maxLength="20" disabled /> -->
                <!-- <span v-model="regione"></span> -->
                <div style="min-width: 100px">
                  经纬度
                  <span
                    v-if="
                      payForm.attendanceLocationLongitude &&
                      payForm.attendanceLocationLatitude
                    "
                  >
                    {{ payForm.attendanceLocationLongitude }},{{
                      payForm.attendanceLocationLatitude
                    }}
                  </span>
                </div>
                <ModalMap
                  v-show="isOpen"
                  :lnglat="laXY"
                  @onSure="sureAddress"
                  @onSelect="onSelectAddress"
                  style="margin-top: 20px"
                />
              </Col>
              <Col span="3" style="margin-left: 30px">
                <Button @click="openOrClose" type="primary">
                  <span v-if="!isOpen">展开地图</span>
                  <span v-else>收起地图</span>
                </Button>
              </Col>
            </Row>
          </FormItem>
          <!-- 考勤电子围栏 -->
          <!-- prop="dianziweilan" -->
          <FormItem
            label="考勤范围："
            style="margin-top: 10px; margin-left: 12px"
          >
            <Row>
              <Col span="20">
                <Button type="primary" @click="isOpen1 = !isOpen1">
                  <span v-if="!isOpen1">展开地图</span>
                  <span v-else>收起地图</span>
                </Button>
                <Button
                  v-if="isOpen1"
                  style="margin-left: 20px"
                  type="primary"
                  @click="clear"
                  >清除</Button
                >
                <div v-show="isOpen1" style="position: relative">
                  <div class="inputBox">
                    <input
                      type="text"
                      placeholder="请输入关键字"
                      id="search1"
                    />
                  </div>
                  <div id="map" style="margin-top: 10px"></div>
                </div>
              </Col>
              <!-- 
                  <Col span="3" style="margin-left:30px">
                  <Button  type="primary" @click="isOpen1=!isOpen1"
                    >
                    <span v-if="!isOpen1">展开地图</span>
                    <span v-else>收起地图</span> 
                    </Button
                  > 
                  <Button style="margin-top:20px"  type="primary" @click="clear"
                    >清除</Button
                  >  
                </Col> 
                -->
            </Row>
          </FormItem>


          <FormItem label="班次设置：">
            <Row style="margin-bottom:20px">
              快捷班次设置：
              默认班次：
              <TimePicker
                v-model="payForm.defaultWorkStartTime"
                 format="HH:mm"
                placeholder="选择时间"
                style="width: 100px"
              />~
              <TimePicker
                v-model="payForm.defaultWorkEndTime"
                format="HH:mm"
                placeholder="选择时间"
                style="width: 100px"
              />
            </Row>
          <Table
          ref="selection"
            style="margin-left:100px"
            :columns="shiftCloums" 
            :data="shiftList"
            @on-select="onSelect"
            @on-select-cancel="onSelectCancel"
            @on-select-all="onSelectAll"
            @on-select-all-cancel="onSelectAllCancel"
 
          >
          <!-- <template slot-scope="{ row, index }" slot="isSelected">
            <Checkbox v-model="row.isSelected"></Checkbox>
          </template> -->
          <template slot-scope="{ row, index }" slot="workDay">
            {{
                  row.workDay == "MONDAY"
                    ? "周一"
                    : row.workDay == "TUESDAY"
                    ? "周二"
                    : row.workDay == "WEDNESDAY"
                    ? "周三"
                    : row.workDay == "THURSDAY"
                    ? "周四"
                    : row.workDay == "FRIDAY"
                    ? "周五"
                    : row.workDay == "SATURDAY"
                    ? "周六"
                    : row.workDay == "SUNDAY"
                    ? "周日"
                    : ""
                }}
            
          </template>
           <template slot-scope="{ row, index }" slot="shift">
    
             <span v-if="!row.workStartTime&&!row.workEndTime">休息</span>
             <span v-else>
              {{row.workStartTime}}~{{row.workEndTime}}
             </span>
          </template>
          <template slot-scope="{ row, index }" slot="action">
  
            <div v-if="row.isSelected||row._checked" style="color:#2d8def; cursor: pointer;
            " @click="timeChangeModal(row)">更改</div>

          </template>
          </Table>

            <!-- <Row
              style="margin: 20px 0"
              v-for="(item, index) in payForm.workDateList"
              :key="index"
            >
     
              <Col span="1" style="margin-left: 110px">
                {{
                  item.workDay == "MONDAY"
                    ? "周一"
                    : item.workDay == "TUESDAY"
                    ? "周二"
                    : item.workDay == "WEDNESDAY"
                    ? "周三"
                    : item.workDay == "THURSDAY"
                    ? "周四"
                    : item.workDay == "FRIDAY"
                    ? "周五"
                    : item.workDay == "SATURDAY"
                    ? "周六"
                    : item.workDay == "SUNDAY"
                    ? "周日"
                    : ""
                }}：
              </Col>
              <Col span="10">
                <Row>
                  <TimePicker
                    v-model="item.workStartTime"
                    format="HH:mm:ss"
                    placeholder="选择时间"
                    style="width: 100px"
                  />~
                  <TimePicker
                    v-model="item.workEndTime"
                    format="HH:mm:ss"
                    placeholder="选择时间"
                    style="width: 100px"
                  />
                </Row>
              </Col>
            </Row> -->
          </FormItem>

          <!-- <FormItem label="有效范围：">
            <Row>
              <Col span="20">
                <InputNumber
                  v-model="payForm.effectiveDistance"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="请输入你的反馈"
                />
                米
              </Col>
            </Row>
          </FormItem> -->
        </Form>
      </div>
      <div v-show="title !== '填写考勤规则信息'">
        <div class="titlebox">
          <div
            @click="changeTab(0)"
            :class="active == 0 ? 'actworkers' : ''"
            class="workers"
          >
            工人补卡申请审批流程
          </div>
          <div
            @click="changeTab(1)"
            :class="active == 1 ? 'actworkers' : ''"
            class="workers"
          >
            班组长补卡申请审批流程
          </div>
        </div>
        <div style="text-align: center; font-weight: Bold; margin: 10px 0">
          {{ active == 0 ? "工人审批流程设置" : "班组长审批流程设置" }}
        </div>
        <Form ref="payForm2" :model="payForm" :rules="rules2">
          <FormItem
            label="选择审核流程："
            prop="workerApprovalProcessType"
            v-if="active == 0"
          >
            <Row>
              <Col span="24">
                <RadioGroup v-model="payForm.workerApprovalProcessType">
                  <Radio label="TEAM_LEADER">工人->班组长</Radio>
                  <Radio label="PROJECT_LEADER">工人->项目管理员</Radio>
                  <Radio label="TEAM_AND_PROJECT_LEADER"
                    >工人->班组长->项目管理员</Radio
                  >
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem
            v-if="
              payForm.workerApprovalProcessType != 'TEAM_LEADER' && active == 0
            "
            label="选择审批人："
            porp="workerApproverIdentityId"
          >
            <Row>
              <Col span="20">
                <Select
                  v-model="payForm.workerApproverIdentityId"
                  @on-change="syncDict(payForm.workerApproverIdentityId)"
                >
                  <Option
                    v-for="item in approverList"
                    :value="item.approverIdentityId"
                    :key="item.approverIdentityId"
                    >{{ item.approverRoleName }}:
                    {{ item.approverUsername }}</Option
                  >
                </Select>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="选择审核流程：" v-if="active == 1">
            <Row>
              <Col span="20">
                <RadioGroup v-model="workerApprovalProcessType">
                  <Radio label="TEAM_AND_PROJECT_LEADER"
                    >班组长->项目管理员</Radio
                  >
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem
            label="选择审批人："
            v-if="active == 1"
            prop="teamLeaderApproverIdentityId"
          >
            <Row>
              <Col span="20">
                <Select
                  v-model="payForm.teamLeaderApproverIdentityId"
                  @on-change="syncDict(payForm.teamLeaderApproverIdentityId)"
                >
                  <Option
                    v-for="item in approverList"
                    :value="item.approverIdentityId"
                    :key="item.approverIdentityId"
                    >{{ item.approverRoleName }}:
                    {{ item.approverUsername }}</Option
                  >
                </Select>
              </Col>
            </Row>
          </FormItem>
        </Form>
        <!-- <Form v-else ref="payForm3" :model="payForm" :rules="rules3">
      
          <FormItem label="选择审核流程：">
            <Row>
              <Col span="20">
                <RadioGroup v-model="workerApprovalProcessType">
                  <Radio label="TEAM_AND_PROJECT_LEADER"
                    >班组长->项目管理员</Radio
                  >
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="选择审批人：" prop="teamLeaderApproverIdentityId">
            <Row>
              <Col span="20">
                <Select
                  v-model="payForm.teamLeaderApproverIdentityId"
                  @on-change="syncDict(payForm.teamLeaderApproverIdentityId)"
                >
                  <Option
                    v-for="item in approverList"
                    :value="item.approverIdentityId"
                    :key="item.approverIdentityId"
                    >{{ item.approverRoleName }}:
                    {{ item.approverUsername }}</Option
                  >
                </Select>
              </Col>
            </Row>
          </FormItem>
        </Form>  -->
      </div>
    </Modal>
    <Modal
     width="40%"
      v-model="timeRangeModal"
      title="班次设置"
      :mask-closable="false"
      @on-ok="toOk"
    >
    <div style="text-align:center">
     <TimePicker
        v-model="workStartTime"
        format="HH:mm"
        placeholder="选择时间"
        style="width: 100px"
      />~
      <TimePicker
        v-model="workEndTime"
        format="HH:mm"
        placeholder="选择时间"
        style="width: 100px"
      />
      </div>

    </Modal>

  </div>
</template>

<script>
import { options } from "../../../utils/region";
import ModalMap from "../../../components/ModalMap.vue";
import AMap from "AMap";
import { nameConceal } from "@/utils/desensitization";
var placeSearch;
export default {
  name: "AttendanceRules",
  data() {
    return {
      copySafeDiscloseFlag: false,
      options,
      rules1: {
        ruleName: [{ required: true, message: "请输入", trigger: "blur" }],
        ruleType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        attendanceType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        attendanceRestriction: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        clockType: [{ required: true, message: "请选择", trigger: "change" }],
        attendanceStatusService: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        attendanceLocationDetailAddress: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        dianziweilan: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      rules2: {
        workerApprovalProcessType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        workerApproverIdentityId: [
          { required: true, message: "请选择", trigger: ["blur"] },
        ],
        workerApprovalProcessType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        teamLeaderApproverIdentityId: [
          { required: true, message: "请选择", trigger: ["blur"] },
        ],
        workerApprovalProcessType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        teamLeaderApproverIdentityId: [
          { required: true, message: "请选择", trigger: ["blur"] },
        ],
      },
      rules3: {
        workerApprovalProcessType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        teamLeaderApproverIdentityId: [
          { required: true, message: "请选择", trigger: ["blur"] },
        ],
      },
      detailAddress: "",
      ruleId: 0,

      teamList: [],

      timeRangeModal: false, //时间范围 弹窗
      workStartTime:undefined,
      workEndTime:undefined,

      shiftCloums: [
        // {
        //   title: "#",
        //   key: "isSelected",
        //   slot: "isSelected",
        //   fixed: "left",
        // },
        {
            type: 'selection',
            width: 60,
            align: 'center',
          fixed: "left",

        },
        {
          title: "工作日",
          slot: "workDay",
          key: "workDay",
          fixed: "left",
        },
        {
          title: "班次",
          key: "shift",
          slot: "shift",
          fixed: "left",
        },
        
        
        {
          title: "操作",
          slot: "action",
          fixed: "left",
        },
      ],
      shiftList:[],
      selectRow:null,
      payForm: {
        ruleName: "",
        
        ruleType:'PROJECT',
        teamId: 0, //默认 不选的话  传0

        defaultWorkStartTime: "09:00",
        defaultWorkEndTime: "18:00",

        workDateList: [
        ],

        attendanceType: "",
        attendanceRestriction: "",
        clockType: "",
        attendanceStatusService: "",
        effectiveDistance: 100,
        attendanceLocationProvince: "",
        attendanceLocationCity: "",
        attendanceLocationDistrict: "",
        attendanceLocationLongitude: "",
        attendanceLocationLatitude: "",
        attendanceLocationDetailAddress: "",
        workerApprovalProcessType: "",
        workerApproverIdentityId: "",
        teamLeaderApproverIdentityId: "",

        attendanceAreas: [], //多个   电子围栏数据
      },
      attendanceArea: [], //单个电子围栏的数组

      workerApprovalProcessType: "TEAM_AND_PROJECT_LEADER",
      addressCloums: [
        {
          title: "考勤地点",
          key: "name",
          fixed: "left",
        },
        {
          title: "详细地址",
          key: "name",
          fixed: "left",
        },
        {
          title: "经纬度",
          key: "name",
          fixed: "left",
        },
        {
          title: "操作",
          key: "name",
          // slot: "action",
          fixed: "left",
        },
      ],
      active: 0,
      laXY: null,
      title: "填写考勤规则信息",
      approverList: [],
      region: [],
      lng: null,
      lat: null,
      searchMainListData: {
        name: "",
        workType: "",
        teamIds: [],
        startDate: "",
        endDate: "",
      },
      mainList: [],
      mainList1: [],

      cloumnsTime: [],
      mainCloums: [
        {
          title: "规则名称",
          key: "ruleName",
          fixed: "left",
        },
        {
          title: "打卡规则",
          slot: "attendanceRestriction",
          key: "attendanceRestriction",
          fixed: "left",
        },
        {
          title: "打卡类型",
          key: "attendanceType",
          fixed: "left",
        },
        {
          title: "打卡方式",
          slot: "clockType",
          key: "clockType",
          fixed: "left",
        },
         {
          title: "规则类型",
          slot: "ruleType",
          key: "ruleType",
          fixed: "left",
        },
        {
          title: "操作人",
          key: "editorName",
          fixed: "left",
        },
        {
          title: "操作时间",
          key: "updateTime",
          fixed: "left",
        },
        {
          title: "操作",
          slot: "action",
          fixed: "left",
        },
      ],

      mainLoading: false,
      mainPage: 1,
      mainSize: 15,
      mainTotal: 0,
      workTypeList: [
        {
          value: 1,
          label: "普工",
        },
        {
          value: 2,
          label: "高级工",
        },
      ],
      teamList: [
        {
          value: 1,
          label: "班组A",
        },
        {
          value: 2,
          label: "班组B",
        },
      ],
      rangeDate: "",
      exportData: {
        date: "",
      },

      // polygons: [],
      // polygonList: [],
      map: null,
      flag: true,
      marker: [],
      MouseTool: null,
      polygonsH: [],
      isCoincidence: false,
      isOpen: false,
      isOpen1: true, //默认展开

      AMap: {},
    };
  },
  props: ["ProjectInfo", "ScreenWidth"],
  created() {},
  mounted() {
    this.getMainList();
    this.getApproverList();
    this.AMapInit();
    this.getLongitudeLatitude();
  },
  components: {
    ModalMap,
  },
  filters: {},
  watch: {},
  methods: {
    openOrClose() {
      this.isOpen = !this.isOpen;
    },
    clear() {
      this.map.remove(this.polygonsH);
      this.MouseTool.close(true);
      this.payForm.attendanceAreas = [];
      this.markerInit();
    },
    // 获取范围
    getRange() {
      this.polygonsH = [];
      let locationRange = this.payForm.attendanceAreas;

      locationRange.forEach((item, index) => {
        [item.attendanceArea].map((res, index) => {
          // let polygon = new AMap.Polygon({
          //   map: this.map,
          //   path: res.map(
          //     (obj) => new AMap.LngLat(obj.longitude, obj.latitude)
          //   ),
          //   strokeWeight: 6, // 线条宽度，默认为 1
          //   strokeColor: "#ff2510", // 线条颜色
          //   fillColor: "#ff2510",
          //   fillOpacity: 0.4,
          // });
          // this.polygonsH.push(polygon);

          let polyline = new AMap.Polyline({
            map: this.map,
            path: res.map(
              (obj) => new AMap.LngLat(obj.longitude, obj.latitude)
            ),
            strokeColor: "#3366FF",
            strokeOpacity: 1,
            strokeWeight: 1,
            // 线样式还支持 'dashed'
            strokeStyle: "solid",
            // strokeStyle是dashed时有效
            // strokeDasharray: [10, 5],
          });
          this.polygonsH.push(polyline);
        });
        this.map.setFitView();
      });
    },

    //设置电子围栏的中心位置
    setAttendanceRangeCenter() {
      this.marker = this.laXY;
      this.map.setCenter(this.marker);
    },
    mapInit() {
      console.log("mapInit");
      const AMap = window.AMap;

      this.map = new AMap.Map("map");

      let that = this;

      AMap.plugin(
        [
          "AMap.ToolBar",
          "AMap.Autocomplete",
          "AMap.PlaceSearch",
          "AMap.Geocoder",
          "AMap.Marker",
        ],
        function () {
          that.map.addControl(
            new AMap.ToolBar({
              locate: false,
            })
          );
          const autoOptions = {
            city: "",
            input: "search1",
          };
          let autocomplete = new AMap.Autocomplete(autoOptions);
          console.log(autocomplete);
          // map.addControl(autocomplete);
          //搜索事件
          const placeSearch = new AMap.PlaceSearch({
            city: "",
            map: that.map,
          });
          // console.log(AMap.event);
          AMap.event.addListener(autocomplete, "select", function (e) {
            console.log(e);
            // that.$emit("onSelect", e.poi);

            // that.onSelectAddress(e.poi)

            console.log(e.poi);
            that.marker = [e.poi.location.lng, e.poi.location.lat];
            that.map.setCenter(that.marker);

            that.marker.setMap(null);
            that.marker = null;
            that.map.setZoom(20);

            // placeSearch.search(e.poi.name);
          });
        }
      );

      if (this.ruleId == 0) {
        this.setAttendanceRangeCenter();
        this.map.setZoom(20);
      }
      this.markerInit();
    },
    //范围
    markerInit() {
      let that = this;
      that.MouseTool = new AMap.MouseTool(this.map);

      // 绘制多边形
      // that.MouseTool.polygon({
      //   strokeColor: "#ff2510",
      //   strokeOpacity: 1,
      //   strokeWeight: 6,
      //   strokeOpacity: 1,
      //   fillColor: "#ff2510",
      //   fillOpacity: 0.4,
      //   strokeStyle: "solid",
      // });

      // 绘制多边形  中间颜色设置为白色
      // that.MouseTool.polygon({
      //   strokeColor: "#3366FF",
      //   strokeOpacity: 1,
      //   strokeWeight: 6,
      //   strokeOpacity: 1,
      //   fillColor: "#ffffff",
      //   fillOpacity: 0.1,
      //   strokeStyle: "solid",
      // });

      // 绘制线段
      that.MouseTool.polyline({
        strokeColor: "#3366FF",
        strokeOpacity: 1,
        strokeWeight: 1,
        // 线样式还支持 'dashed'
        strokeStyle: "solid",
        // strokeStyle是dashed时有效
        // strokeDasharray: [10, 5],
      });

      that.MouseTool.on("draw", function (event) {
        // event.obj 为绘制出来的覆盖物对象
        let a = {
          attendanceArea: [],
        };
        that.flag = true;
        for (let item in event.obj.w.path) {
          a.attendanceArea.push({
            latitude: event.obj.w.path[item].lat,
            longitude: event.obj.w.path[item].lng,
          });
        }
        that.payForm.attendanceAreas.push(a);
      });
    },
    getLongitudeLatitude() {
      console.log("=======================");
      AMap.plugin("AMap.Geolocation", () => {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });

        geolocation.getCityInfo((status, result) => {
          //只能获取当前用户所在城市和城市的经纬度
          if (status == "complete") {
            console.log("result", result);
            this.laXY = result.center;
          }
        });
        geolocation.getCurrentPosition((status, result) => {
          //获取用户当前的精确位置
          if (status == "complete") {
            console.log("位置信息", result);
            this.laXY = [];
            this.laXY[0] = result.position.lng;
            this.laXY[1] = result.position.lat;
          }
        });
      });
    },
    // 审批流程切换时
    changeTab(index) {
      this.active = index;
      if (index == 1) {
        this.workerApprovalProcessType = "TEAM_AND_PROJECT_LEADER";
      }
    },
    //添加考勤规则
    addRules() {
      // if (this.mainList.length > 0) {
      //   this.$Message.info("当前已有考勤规则");
      //   return;
      // }

      // if (this.ruleId != 0) {

      this.payForm = {
        ruleName: "",

        ruleType:'PROJECT',
        teamId: 0,
        defaultWorkStartTime: "09:00",
        defaultWorkEndTime: "18:00",
        workDateList: [],

        attendanceType: "",
        attendanceRestriction: "",
        clockType: "",
        attendanceStatusService: "",
        effectiveDistance: 100,
        attendanceLocationProvince: "",
        attendanceLocationCity: "",
        attendanceLocationDistrict: "",
        attendanceLocationLongitude: "",
        attendanceLocationLatitude: "",
        attendanceLocationDetailAddress: "",
        workerApprovalProcessType: "",
        workerApproverIdentityId: "",
        teamLeaderApproverIdentityId: "",
      };
      // }
      this.shiftList=[
        {
            workDay: "MONDAY",
            workStartTime: undefined,
            workEndTime: undefined,
            isSelected:false,
          },
          {
            workDay: "TUESDAY",
            workStartTime:undefined,
            workEndTime: undefined,
            isSelected:false,

          },
          {
            workDay: "WEDNESDAY",
            workStartTime: undefined,
            workEndTime: undefined,
            isSelected:false,

          },
          {
            workDay: "THURSDAY",
            workStartTime: undefined,
            workEndTime: undefined,
            isSelected:false,

          },
          {
            workDay: "FRIDAY",
            workStartTime: undefined,
            workEndTime: undefined,
            isSelected:false,

          },
          {
            workDay: "SATURDAY",
            workStartTime: undefined,
            workEndTime:undefined,
            isSelected:false,

          },
          {
            workDay: "SUNDAY",
            workStartTime: undefined,
            workEndTime: undefined,
            isSelected:false,

          },

      ]

      this.getFilesTeamList(); //班组

      this.ruleId = 0;
      this.title = "填写考勤规则信息";
      this.copySafeDiscloseFlag = true;
      this.region = [];
      this.AMapInit();

      //新增的时候 的 电子围栏初始化
      (this.payForm.attendanceAreas = []),
        this.$nextTick(() => {
          this.mapInit();
        });
    },
    //下一步
    next() {
      this.$refs["payForm"].validate((valid) => {
        if (valid) {
          if (this.payForm.attendanceLocationLongitude == "") {
            this.$Message.info("请在地图上点击后再试");
          } else if (this.payForm.attendanceAreas.length == 0) {
            this.$Message.info("请设置考勤范围");
          } else {
            this.title = "设置审核流程";
          }
        }
      });
    },
    //上一步
    step() {
      console.log(123);
      this.title = "填写考勤规则信息";
    },
    //删除规则
    deleteRules(row) {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否删除该考勤规则</p>",
        onOk: () => {
          let token = this.common.getAuth().accessToken;
          this.$axios({
            // method: "delete",
            method: "post",
            url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/attendance/rules/${row.ruleId}/delete`,
            headers: { accessToken: token },
          }).then((res) => {
            if (res.data.resultStatus) {
              this.$Message.success("删除成功");
              this.getMainList();
            } else {
              this.$Message.info(res.data.errorMessage);
            }
          });
        },
        onCancel: () => {},
      });
    },
    //考勤详情
    editRules(row) {
      this.getFilesTeamList(); //班组
      this.title = "填写考勤规则信息";
      let token = this.common.getAuth().accessToken;
      this.ruleId = row.ruleId;
      this.$axios({
        method: "get",
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/attendance/rules/${row.ruleId}`,
        headers: { accessToken: token },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.payForm = res.data.resultData;
          this.payForm.attendanceStatusService =
            res.data.resultData.attendanceStatusService == true
              ? "true"
              : "false";

          console.log(this.payForm, "payForm");
          this.laXY = [];
          this.laXY[0] = this.payForm.attendanceLocationLongitude;
          this.laXY[1] = this.payForm.attendanceLocationLatitude;
          this.copySafeDiscloseFlag = true;
          // this.payForm.attendanceLocationDetailAddress =
          //   this.payForm.attendanceLocationProvince +
          //   this.payForm.attendanceLocationCity +
          //   this.payForm.attendanceLocationDistrict +
          //   this.payForm.attendanceLocationDetailAddress;
          // 电子围栏数据
          if (!this.payForm.attendanceAreas) {
            this.payForm.attendanceAreas = [];
          }

          this.mapInit();
          // //根据选中的项目地址中心  设置电子围栏的中心
          this.setAttendanceRangeCenter();
          this.map.setZoom(20);

          this.getRange();
          this.payForm.defaultWorkStartTime=this.payForm.defaultWorkStartTime.slice(0,this.payForm.defaultWorkStartTime.length - 3)
          this.payForm.defaultWorkEndTime=this.payForm.defaultWorkEndTime.slice(0, this.payForm.defaultWorkEndTime.length - 3)

          if (this.payForm.workDateList.length == 0) {
            this.shiftList= [
                {
                workDay: "MONDAY",
                workStartTime: undefined,
                workEndTime: undefined,
                isSelected:false,
              },
              {
                workDay: "TUESDAY",
                workStartTime: undefined,
                workEndTime: undefined,
                isSelected:false,

              },
              {
                workDay: "WEDNESDAY",
                workStartTime: undefined,
                workEndTime: undefined,
                isSelected:false,

              },
              {
                workDay: "THURSDAY",
                workStartTime: undefined,
                workEndTime: undefined,
                isSelected:false,

              },
              {
                workDay: "FRIDAY",
                workStartTime: undefined,
                workEndTime: undefined,
                isSelected:false,

              },
              {
                workDay: "SATURDAY",
                workStartTime: undefined,
                workEndTime:undefined,
                isSelected:false,

              },
              {
                workDay: "SUNDAY",
                workStartTime: undefined,
                workEndTime: undefined,
                isSelected:false,

              },
            ];
          }else{
            this.payForm.workDateList.map((item,index)=>{
             item.workStartTime=item.workStartTime.slice(0, item.workStartTime.length - 3)
              item.workEndTime=item.workEndTime.slice(0, item.workEndTime.length - 3)

            })
            
          const result1 = this.payForm.workDateList.find(item => item.workDay =='MONDAY');
          const result2 = this.payForm.workDateList.find(item => item.workDay =='TUESDAY');
          const result3 = this.payForm.workDateList.find(item => item.workDay =='WEDNESDAY');
          const result4 = this.payForm.workDateList.find(item => item.workDay =='THURSDAY');
          const result5 = this.payForm.workDateList.find(item => item.workDay =='FRIDAY');
          const result6 = this.payForm.workDateList.find(item => item.workDay =='SATURDAY');
          const result7 = this.payForm.workDateList.find(item => item.workDay =='SUNDAY');

          console.log(result1,'result1');
          console.log(result2,'result2');
          console.log(result3,'result3');
          console.log(result4,'result4');
          console.log(result5,'result5');
          console.log(result6,'result6');
          console.log(result7,'result7');




          this.shiftList= [
              {
              workDay: "MONDAY",
              workStartTime: result1?result1.workStartTime:undefined,
              workEndTime: result1?result1.workEndTime:undefined,
              isSelected:result1?true:false,
              _checked:result1?true:false,

            },
            {
              workDay: "TUESDAY",
              workStartTime: result2?result2.workStartTime:undefined,
              workEndTime:result2?result2.workEndTime:undefined,
              isSelected:result2?true:false,
              _checked:result2?true:false,


            },
            {
              workDay: "WEDNESDAY",
              workStartTime:result3?result3.workStartTime:undefined,
              workEndTime:result3?result3.workEndTime:undefined,
              isSelected:result3?true:false,
              _checked:result3?true:false,


            },
            {
              workDay: "THURSDAY",
              workStartTime: result4?result4.workStartTime:undefined,
              workEndTime: result4?result4.workEndTime:undefined,
              isSelected:result4?true:false,
              _checked:result4?true:false,


            },
            {
              workDay: "FRIDAY",
              workStartTime:result5?result5.workStartTime:undefined,
              workEndTime:result5?result5.workEndTime:undefined,
              isSelected:result5?true:false,
              _checked:result5?true:false,


            },
            {
              workDay: "SATURDAY",
              workStartTime:result6?result6.workStartTime:undefined,
              workEndTime:result6?result6.workEndTime:undefined,
              isSelected:result6?true:false,
              _checked:result6?true:false,


            },
            {
              workDay: "SUNDAY",
              workStartTime: result7?result7.workStartTime:undefined,
              workEndTime:result7?result7.workEndTime:undefined,
              isSelected:result7?true:false,
              _checked:result7?true:false,


            },
          ];

          console.log(this.shiftList,"去掉:00");



          


          }
        } else {
          this.$Message.info(res.data.errorMessage);
        }
      });
    },
    
    //完成
    complete() {
    console.log("0");

  
      var fromstr = "payForm2";
      // if (this.active == 1) {
      //   fromstr = "payForm3";
      // }
      
      let obj = { ...this.payForm };

      console.log(1);
  
      this.$refs[fromstr].validate((valid) => {
        if (valid) {

          let token = this.common.getAuth().accessToken;
          if (
            this.payForm.workerApprovalProcessType != "TEAM_LEADER" &&
            this.payForm.workerApproverIdentityId == ""
          ) {
            this.$Message.info("请选择工人补卡审批人");
            return;
          } else if (this.payForm.teamLeaderApproverIdentityId == "") {
            this.$Message.info("请选择班组长补卡审批人");
            return;
          }
          if (this.payForm.workerApprovalProcessType == "TEAM_LEADER") {
            delete obj.workerApproverIdentityId;
          }
      
      obj.attendanceStatusService =
        obj.attendanceStatusService == "true" ? true : false;
            console.log(2);

          obj.workDateList=this.shiftList.filter((item,index)=>{
            return item.isSelected

          })  
          console.log(12);

          console.log(obj.workDateList);
          if( obj.defaultWorkStartTime){
          obj.defaultWorkStartTime=obj.defaultWorkStartTime+':00'

          }
          if(obj.defaultWorkEndTime){
          obj.defaultWorkEndTime=obj.defaultWorkEndTime+':00'

          }

          if(obj.workDateList.length!==0){
          obj.workDateList.map((item,index)=>{
              item.workStartTime=item.workStartTime+':00'
              item.workEndTime=item.workEndTime+':00'

            })
          }
           

           
     
          (obj.projectId = this.ProjectInfo.id),
            this.$axios({
              method: "post",
              url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/attendance/rules/${this.ruleId}`,
              headers: { accessToken: token },
              data: obj,
            }).then((res) => {
              console.log("++++++++++", res);
              if (res.data.resultStatus) {
                this.copySafeDiscloseFlag = false;
                this.getMainList();
                this.$Message.success("提交成功");
              } else {
                this.$Message.info(res.data.errorMessage);
              }
            });
        }
      });
    },
    //选择审批人
    syncDict(event) {
      console.log(event);
      if (this.active == 0) {
        this.payForm.workerApproverIdentityId = event;
      } else {
        this.payForm.teamLeaderApproverIdentityId = event;
      }
    },
    //地图
    AMapInit() {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 10,
      });
      AMap.service(["AMap.PlaceSearch"], function () {
        //构造地点查询类
        placeSearch = new AMap.PlaceSearch({
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          citylimit: false, //是否强制限制在设置的城市内搜索
          map: map, // 展现结果的地图实例
          panel: "panel", // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          renderStyle: "default",
        });
      });
      console.log("第三步", this.selectAddress);
      // 添加列表点选监听事件
      AMap.event.addListener(placeSearch, "selectChanged", this.selectAddress);
    },
    selectAddress(e) {
      console.log("第二步");
      //这里获得点选地点的经纬度
      let location = e.selected.data.location;
      this.payForm.attendanceLocationLongitude = location.lng;
      this.payForm.attendanceLocationLatitude = location.lat;

      //根据选中的项目地址中心  设置电子围栏的中心
      this.setAttendanceRangeCenter();

      this.laXY[0] = location.lng;
      this.laXY[1] = location.lat;
    },
    //搜索结果选中
    onSelectAddress(data) {
      var arr = data.district.split("省");
      console.log("++++++++++++", data, arr);
      this.payForm.attendanceLocationLongitude = "";
      this.payForm.attendanceLocationLatitude = "";
      if (arr.length == 2) {
        this.payForm.attendanceLocationProvince = arr[0] + "省";
        var city = arr[1] ? arr[1].split("市") : [];
        console.log("city", city);
        if (city[1] != "") {
          this.payForm.attendanceLocationCity = city[0] + "市";
          this.payForm.attendanceLocationDistrict = city[1];
        } else {
          this.payForm.attendanceLocationCity = city[0] + "市";
          this.payForm.attendanceLocationDistrict = data.address;
        }
      } else {
        var city = arr[0] ? arr[0].split("市") : [];
        console.log("city", arr[0], city);
        if (city[1] != "") {
          this.payForm.attendanceLocationProvince = city[0] + "市";
          this.payForm.attendanceLocationCity = city[0] + "市";
          this.payForm.attendanceLocationDistrict = city[1];
        } else {
          this.payForm.attendanceLocationProvince = city[0] + "市";
          this.payForm.attendanceLocationCity = city[0] + "市";
          this.payForm.attendanceLocationDistrict = data.address;
        }
      }

      this.payForm.attendanceLocationDetailAddress = data.district + data.name;
      console.log(
        "=================",
        this.payForm.attendanceLocationProvince,
        this.payForm.attendanceLocationCity,
        this.payForm.attendanceLocationDistrict
      );
      if (data.location != "") {
        this.laXY = [];
        this.laXY[0] = data.location.lng ? data.location.lng : "";
        this.laXY[1] = data.location.lat ? data.location.lat : "";
        this.payForm.attendanceLocationLongitude = data.location.lng
          ? data.location.lng
          : "";
        this.payForm.attendanceLocationLatitude = data.location.lat
          ? data.location.lat
          : "";
        console.log("这是经纬度", this.laXY);
      }

      //根据选中的项目地址中心  设置电子围栏的中心
      this.setAttendanceRangeCenter();
    },

    // 地图选中
    sureAddress(data) {
      console.log("第一步", data);
      this.laXY = data.lnglat;
      this.payForm.attendanceLocationLongitude = this.laXY[0];
      this.payForm.attendanceLocationLatitude = this.laXY[1];

      //根据选中的项目地址中心  设置电子围栏的中心
      this.setAttendanceRangeCenter();

      if (data.province == data.city) {
        this.region = [data.province, data.district];
        this.detailAddress = data.town + data.detailAddress;
        this.payForm.attendanceLocationProvince = data.province;
        this.payForm.attendanceLocationCity = data.city;
        this.payForm.attendanceLocationDistrict = data.district;
        this.payForm.attendanceLocationDetailAddress =
          data.province + data.city + data.district + data.detailAddress;
      } else {
        this.region = [data.province, data.city, data.district];
        this.detailAddress = data.town + data.detailAddress;
        this.payForm.attendanceLocationProvince = data.province;
        this.payForm.attendanceLocationCity = data.city;
        this.payForm.attendanceLocationDistrict = data.district;
        this.payForm.attendanceLocationDetailAddress =
          data.province + data.city + data.district + data.detailAddress;
      }
    },
    //获取审批人列表
    getApproverList() {
      let token = this.common.getAuth().accessToken;
      let params = {
        projectId: this.ProjectInfo.id,
      };
      this.$axios({
        method: "get",
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/attendance/approvers`,
        headers: { accessToken: token },
        params,
      }).then((res) => {
        console.log("++++++++++", res);
        if (res.data.resultStatus) {
          this.approverList = res.data.resultData;
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    handleMainPageChange() {},
    //获取考勤规则列表
    getMainList() {
      this.mainLoading = true;
      let params = {
        pageSize: 10,
        pageNo: 1,
        projectId: this.ProjectInfo.id,
      };
      let token = this.common.getAuth().accessToken;
      this.$axios({
        method: "get",
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/attendance/rules`,
        headers: { accessToken: token },
        params,
      }).then((res) => {
        this.mainLoading = false;
        if (res.data.resultStatus) {
          // this.mainList = res.data.resultData.dgAttendanceRule;
          this.mainList1 = res.data.resultData.dgAttendanceRule;

          this.mainList = JSON.parse(JSON.stringify(this.mainList1)); //处理深拷贝  浅拷贝
          // 默认先脱敏
          this.mainList.forEach((e) => {
            e.editorName = e.editorName
              ? nameConceal(e.editorName.toString())
              : ""; //姓名
            e.showOrHide = "hide"; //显示/隐藏
          });
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 展示
    showMainConceal(data) {
      this.mainList1.forEach((item, index) => {
        if (data.ruleId == item.ruleId) {
          data.editorName = item.editorName; //姓名
          data.showOrHide = "show"; //显示/隐藏
        }
      });
    },
    //隐藏
    hideMainConceal(data) {
      this.mainList1.forEach((item, index) => {
        if (data.ruleId == item.ruleId) {
          data.editorName = item.editorName
            ? nameConceal(item.editorName.toString())
            : ""; //姓名
          data.showOrHide = "hide"; //显示/隐藏
        }
      });
    },

    // 获取班组列表
    getFilesTeamList() {
      let data = {
        pageSize: "1000",
        projectId: this.ProjectInfo.id,
      };
      let res = this.$fetch(`/sapi/team/getTeamsByProjectIdOrName`, {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          this.teamList = res.data;
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    timeChangeModal(row,index) {
      // 打开弹窗
      this.selectRow=row;
      this.timeRangeModal = true;
      this.workStartTime=row.workStartTime;
      this.workEndTime=row.workEndTime;

    },
    toOk(){
      this.shiftList.map((item,index)=>{
        if(item.workDay==this.selectRow.workDay){
          item.workStartTime= this.workStartTime
          item.workEndTime= this.workEndTime
        }

      })
    },
    onSelect(selection,row){
      console.log(this.defaultWorkStartTime);
      this.shiftList.map((item,index)=>{
        if(row.workDay==item.workDay){
          item.isSelected=true;
          item.workStartTime=this.payForm.defaultWorkStartTime
          item.workEndTime=this.payForm.defaultWorkEndTime

        }
      })
      console.log(this.shiftList);
    },
    onSelectCancel(selection,row){
      this.shiftList.map((item,index)=>{
        if(row.workDay==item.workDay){
          item.isSelected=false;
          item.workStartTime=undefined
          item.workEndTime=undefined
        }
      })
      console.log(this.shiftList);


    },
    onSelectAll(){
      this.shiftList.map((item,index)=>{
       
          item.isSelected=true;
          item.workStartTime=this.payForm.defaultWorkStartTime
          item.workEndTime=this.payForm.defaultWorkEndTime

        
      })
      console.log(this.shiftList);

    },
    onSelectAllCancel(){
      this.shiftList.map((item,index)=>{
       
          item.isSelected=false;
          item.workStartTime=undefined
          item.workEndTime=undefined
        
      })
      console.log(this.shiftList);


    }
  },
};
</script>

<style lang="less" scoped>
@import "../public/style.less";

.full-content-box {
}

.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;

  .mb-item {
    margin-bottom: 14px;
  }
}

.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tcb-item {
  display: flex;
  flex-wrap: wrap;

  .tcbi-item {
    margin-bottom: 14px;
  }
}

.addRules {
  position: relative;
  left: 90%;
  margin-bottom: 40px;
}

.titlebox {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  .workers {
    width: 50%;
    padding: 10px 0;
    text-align: center;
    position: relative;
  }

  .actworkers {
    width: 50%;
    text-align: center;
    position: relative;
    color: rgba(33, 155, 255, 1);
  }

  .actworkers::after {
    content: "";
    width: 60px;
    height: 3px;
    background: rgba(33, 155, 255, 1);
    position: absolute;
    bottom: 0px;

    left: 50%;
    transform: translateX(-50%);
  }
}
.amap-sug-result {
  width: 150px;
  overflow: hidden;
  margin-top: 5px;
}
.mapBox {
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.2);
  // overflow: hidden;
  // z-index: 1000;
  .showBox {
    width: 100%;
    height: 400px;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: #fff;
    // position: absolute;
    // left: 0;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // margin: auto;
    .upBox {
      width: 100%;
      height: 100%;
      padding: 15px;
      box-sizing: border-box;
      position: relative;
    }
    .downBox {
      width: 100%;
      height: 90px;
      padding: 10px 20px;
      box-sizing: border-box;
      display: flex;
      p {
        padding: 0;
        margin: 0;
      }
    }
  }
  .inputBox {
    display: flex;
    position: absolute;
    overflow: hidden;
    right: 20px;
    top: 20px;
    z-index: 10000;
    width: 50%;
    height: 60px;
    border-radius: 5px;
    background-color: #fff;
    // border: 1px solid rgb(216, 216, 216);
    padding: 15px;
    box-sizing: border-box;
    .title {
      width: 6rem;
      text-align: justify;
      padding: 0.4rem 0.7rem;
      display: inline-block;
      text-justify: distribute-all-lines;
      text-align-last: justify;
      -moz-text-align-last: justify;
      -webkit-text-align-last: justify;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      font-size: 12px;
    }
    input {
      flex: 1;
      width: 100%;
      background: #fff;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
      height: 40px;
      box-sizing: border-box;
      &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba(128, 189, 255, 0.1);
      }
    }
  }
  .imgBox {
    position: absolute;
    right: 20px;
    bottom: 17px;
    z-index: 10000;
    width: 150px;
    height: 155px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    img {
      display: block;
      width: 110px;
      height: 110px;
      margin: 10px auto 0;
    }
    > div {
      text-align: center;
      line-height: 30px;
    }
  }
  .addressBox {
    flex-grow: 1;
    .name {
      height: 40px;
      padding: 5px 0;
      box-sizing: border-box;
      span {
        font-size: 14px;
        padding-right: 20px;
        line-height: 30px;
        color: #4d4d4d;
      }
      input {
        width: 175px;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #d3d3d3;
        border-radius: 4px;
        padding: 0 15px;
        color: #b5b5b5;
      }
    }
    .address {
      font-size: 14px;
      color: #4d4d4d;
      span {
        padding: 0 10px;
      }
    }
  }
  .phoneBox {
    min-width: 215px;
    line-height: 70px;
    text-align: center;
    a {
      color: #00ba0e;
      font-size: 16px;
      text-decoration: underline;
    }
  }
  .btnBox {
    min-width: 200px;
    button {
      width: 80px;
      height: 30px;
      margin-top: 20px;
      border: 0;
      border-radius: 4px;
      cursor: pointer;
      &.close {
        float: left;
        background: #fff;
        border: 1px solid #e5e5e5;
        color: #a5a5a5;
      }
      &.submit {
        float: right;
        background: #00ba0e;
        color: #fff;
      }
    }
  }
  #gaoMap {
    width: 100%;
    height: 100%;
  }
  #search {
    height: 30px;
  }
}
#map {
  width: 96%;
  height: 400px;
  margin-left: 18px;
}
.inputBox {
  display: flex;
  position: absolute;
  overflow: hidden;
  right: 20px;
  top: 20px;
  z-index: 10000;
  width: 50%;
  height: 60px;
  border-radius: 5px;
  background-color: #fff;
  // border: 1px solid rgb(216, 216, 216);
  padding: 15px;
  box-sizing: border-box;
  .title {
    width: 6rem;
    text-align: justify;
    padding: 0.4rem 0.7rem;
    display: inline-block;
    text-justify: distribute-all-lines;
    text-align-last: justify;
    -moz-text-align-last: justify;
    -webkit-text-align-last: justify;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    font-size: 12px;
  }
  input {
    flex: 1;
    width: 100%;
    background: #fff;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    height: 40px;
    box-sizing: border-box;
    &:focus {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(128, 189, 255, 0.1);
    }
  }
}
</style>