<template>
  <div class="full-content-box">
    <div class="second-title">
      <div class="shu" />
      整改事项
    </div>
    <div class="c-btnbox">
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">标题：</div>
          <Input
            placeholder="请输入标题"
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.searchValue"
          />
        </div>
      </div>
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">发起时间：</div>
          <DatePicker
            placement="bottom-end"
            placeholder="请选择发起时间"
            style="width: 200px; margin-right: 14px"
            format="yyyy-MM-dd"
            :transfer="true"
            v-model="rangeDate"
            @on-change="handelRangeDateChange"
          ></DatePicker>
        </div>
        <div class="mb-item">
          <div class="label">结束时间：</div>
          <DatePicker
            placement="bottom-end"
            placeholder="请选择结束时间"
            style="width: 200px; margin-right: 14px"
            format="yyyy-MM-dd"
            :transfer="true"
            v-model="endDate"
            @on-change="handelEndDateChange"
          ></DatePicker>
        </div>
      </div>
      <div class="c-btnbox-item">
        <Button
          type="primary"
          style="margin-right: 14px"
          @click="handleMainListSearch"
          >搜索</Button
        >
        <Button @click="handleMainListSearch('reset')">重置</Button>
        <Button type="primary" style="margin-left: 14px" @click="downloadAll">批量下载罚单</Button>
      </div>
    </div>
    <div class="second-title">
      <div class="shu" />
      整改事项数据
    </div>
    <Table
      :loading="mainLoading"
      size="small"
      :columns="mainCloums"
      :data="mainList"
      :width="ScreenWidth - 350"
    >
      <template slot-scope="{ row, index }" slot="status">
        <div class="btnbox-flex">
          <div>
            {{ row.status | rectifyStatusFilters(statusList) }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <Button
          type="info"
          style="margin-right: 8px"
          @click="toRectifyRate(row)"
          >查看进度</Button
        >
        <Button
          type="primary"
          style="margin-right: 8px"
          @click="toRectifyAttachment(row)"
          >查看附件</Button
        >
        <Button
          type="primary"
          style="margin-right: 8px"
          @click="download(row)"
          >下载罚单</Button
        >
      </template>
    </Table>
    <page
      show-total
      class="page-box"
      :page-size="mainSize"
      :total="mainTotal"
      :current="mainPage"
      @on-change="handleMainPageChange"
    />
    <!-- 整改管理-查看附件 -->
    <Modal
      v-model="showRectifyRateFlag"
      :loading="showRectifyRateNext"
      :mask-closable="false"
      ok-text="确定"
      title="整改单附件"
      @on-ok="toFinishRectifyRate()"
      @on-cancel="toFinishRectifyRate()"
      width="900"
    >
      <Table
        :columns="rectifyRateColumns"
        :data="rectifyRateData"
        :loading="rectifyRateLoad"
      >
        <template slot-scope="{ row }" slot="taskStatus">
          {{ row.taskStatus | teamStatus(row.taskStatus) }}
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <div class="btnbox-flex">
            <Button
              class="bf-style"
              type="success"
              @click="toRectifyAttachment(row)"
              >查看附件</Button
            >
          </div>
        </template>
      </Table>
    </Modal>
  </div>
</template>

<script>
import rictifyExpandRow from "../template/tableExpandRectify.vue";
export default {
  name: "rectifyTemp",
  data() {
    return {
      searchMainListData: {
        status: "",
        plate: "",
        batch: "",
        startDate: "",
        endDate: "",
        searchValue: ""
      },
      mainList: [],
      mainCloums: [
        {
          title: "罚单标题",
          key: "title"
        },
        {
          title: "工程名称",
          key: "projectName",
        },
        {
          title: "罚款类别",
          key: "fineType",
        },
        {
          title: "罚款金额",
          key: "fineAmount",
        },
        // {
        //   title: "问题描述",
        //   key: "describes",
        // },
        // {
        //   title: "整改措施建议",
        //   key: "advise",
        // },
        {
          title: "罚款事由",
          key: "fineReason",
        },
        {
          title: "罚款日期",
          key: "fineDate",
        },
        {
          title: "状态名称",
          key: "statusStr",
        },
        {
          title: "罚单期限",
          key: "term",
        },
        {
          title: "操作",
          slot: "action",
          width: 340,
          align: "center",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 15,
      mainTotal: 0,
      rangeDate: '',
      endDate: '',
      exportData: {
        date: "",
      },
      statusList: [],
      rectifySearchDate: "",
      showRectifyRateFlag: false,
      showRectifyRateNext: true,
      rectifyRateColumns: [
        {
          type: "expand",
          width: 50,
          render: (h, params) => {
            return h(rictifyExpandRow, {
              props: {
                row: params.row,
              },
            });
          },
        },
        {
          title: "执行人",
          key: "userId",
        },
        {
          title: "角色",
          key: "roleName",
        },
        {
          title: "标题",
          key: "title",
        },
        // {
        //   title: "内容",
        //   key: "describes"
        // },
        {
          title: "状态",
          key: "status",
        },
        {
          title: "时间",
          key: "operTime",
        },
        {
          title: "操作",
          slot: "action",
        },
      ],
      rectifyRateData: [],
      rectifyRateLoad: false,
      rectifyRatePage: 1,
      rectifyRateSize: 15,
      rectifyRateTotal: 0,
      prefix: "",
      checkPdfZipData: false,
    };
  },
  props: ["ProjectInfo", "ScreenWidth"],
  created() {},
  mounted() {
    this.getOSRectifyStatusList();
    this.handleMainListSearch();
    this.getCheckRectifyPdfZip();
  },
  filters: {
    rectifyStatusFilters(sid, list) {
      let newVal = "";
      list.forEach((val) => {
        if (sid == val.dictValue) {
          newVal = val.dictLabel;
        }
      });
      return newVal;
    },
  },
  watch: {},
  methods: {
    // 获取是否有批量下载
    getCheckRectifyPdfZip() {
      this.$axios({
        method: "get",
        url: `${this.apiUrlCros}/osapi/fine/por/project/${this.ProjectInfo.id}/fine/pdf/check`,
      }).then((res) => {
        if(res.data.code=='200'){
          this.checkPdfZipData = true
        }else{
          this.checkPdfZipData = false
        }
      });
    },
    // 下载
    download(info) {
      // console.log(info)
      if (info.pdfPath==null||info.pdfPath=='') {
        this.$Message.info("暂无罚单");
      } else {
        // window.open(`${this.prefix}/api/fine/por/fine/${info.id}/pdf`, "_blank");
        this.$axios({
        url:`${this.apiUrlCros}/osapi/fine/por/fine/${info.id}/pdf`,
        method: "get",
        responseType: 'arraybuffer',
      }).then((res) => {
        if (res.status==200) {
          const data = res.data;
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/pdf",
            })
          );
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          // let fileName = decodeURI(res.headers[`content-disposition`].substring(20));
          let name = "罚单";
          link.setAttribute("download", name + ".pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.$Message.error("下载失败");
        }
      });
      }
    },
    // 批量下载
    downloadAll() {
      if(this.checkPdfZipData) {
        // window.open(`${this.prefix}/api/fine/por/project/${this.ProjectInfo.id}/fine/pdf`, "_blank");

      this.$axios({
          url:`${this.apiUrlCros}/osapi/fine/por/project/${this.ProjectInfo.id}/fine/pdf`,
          method: "get",
          responseType: 'arraybuffer',
        }).then((res) => {
          if (res.status==200) {
            const data = res.data;
            const url = window.URL.createObjectURL(
              new Blob([data], {
                type: "application/zip",
              })
            );
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            // let fileName = decodeURI(res.headers[`content-disposition`].substring(20));
            let name = "罚单档案资料";
            link.setAttribute("download", name + ".zip");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            this.$Message.error("下载失败");
          }
        });


      }else{
        this.$Message.info("暂无罚单")
      }
    },
    getOSRectifyStatusList() {
      let data = {};
      this.$fetch(`/osapi/common/getRectifyStatusList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.statusList = res.data;
        } else {
          this.$Message.error("获取外部交换平台整改状态列表失败");
        }
      });
    },
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      let data = {
        pageSize: this.mainSize,
        pageNum: this.mainPage,
        projectId: this.ProjectInfo.id,
        startTime: this.common.formatDate(this.rangeDate) || "",
        endTime: this.common.formatDate(this.endDate) || "",
        // status: this.searchMainListData.status || "",
        // plate: this.searchMainListData.plate || "",
        // batch: this.searchMainListData.batch || "",
        searchValue: this.searchMainListData.searchValue || "",
      };
      this.$fetch(`/osapi/fine/por/list`, {
        method: "post",
        data
      }).then((res) => {
        if (res.code == "200") {
          this.mainTotal = res.total;
          this.mainList = res.resList;
          this.prefix = res.prefix;
          this.mainLoading = false;
        } else {
          this.mainLoading = false;
          this.$Message.info("查询整改列表失败，请稍后重试");
        }
      });
    },
    // 主表搜索
    handleMainListSearch(type) {
      if (type == "reset") {
        this.searchMainListData = {
          status: "",
          plate: "",
          batch: "",
          startDate: "",
          endDate: "",
        };
        this.rangeDate = '';
        this.endDate = '';
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch();
    },
    handelRangeDateChange(e) {
      // var date1 = new Date(e[0]);
      // var date2 = new Date(e[1]);
    },
    handelEndDateChange(e) {},
    // 整改流水-查看进度
    toRectifyRate(val) {
      this.rectifyRateData = [];
      this.showRectifyRateFlag = true;
      this.getRectifyRate(val);
    },
    getRectifyRate(val) {
      this.rectifyRateLoad = true;
      let data = {
        id: val.id,
      };
      // // console.log(formData)
      this.$fetch(`/osapi/fine/por/fineFlow`, {
        method: "post",
        data
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          this.rectifyRateData = res.data;
          this.rectifyRateLoad = false;
        } else {
          this.rectifyRateLoad = false;
          this.$Message.error("获取进度信息失败");
        }
      });
    },
    // 整改流水-查看附件
    toRectifyAttachment(val) {
      if (val.compressPath) {
        window.open(this.prefix + val.compressPath, "_blank");
      } else {
        this.$Message.info("暂无附件");
      }
    },
    toFinishRectifyRate() {
      this.showRectifyRateFlag = false;
    },
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
</style>