let integrationData = {
    data() {
        return {
            projectColumsList: [{
                    title: "任务唯一码",
                    key: "projectId",
                    width: 120,
                },
                {
                    title: "任务名称",
                    key: "projectName",
                    width: 200,
                },
                {
                    title: "管理分类",
                    key: "projectType",
                    width: 150,
                },
                {
                    title: "任务学时",
                    key: "projectTime",
                    width: 100,
                },
                {
                    title: "任务状态",
                    key: "projectStatus",
                    width: 100,
                },
                {
                    title: "创建时间",
                    key: "projectCreate",
                    width: 200,
                },
                {
                    title: "更新时间",
                    key: "projectUpdate",
                    width: 200,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                },
            ],
            rosterColumsList: [{
                    title: "姓名",
                    key: "name",
                    width: 120,
                },
                {
                    title: "手机号",
                    key: "phone",
                    width: 140,
                },
                {
                    title: "身份证",
                    key: "idcard",
                    width: 200
                },
                {
                    title: "工种",
                    slot: "workType",
                    width: 120
                },
                {
                    title: "实名状态",
                    key: "a",
                    minWidth: 120,
                },
                {
                    title: "加入班组状态",
                    key: "a",
                    minWidth: 120,
                },
                {
                    title: "完成新手任务状态",
                    key: "a",
                    minWidth: 150,
                },
                {
                    title: "下发考勤机状态",
                    key: "a",
                    minWidth: 140,
                },
                {
                    title: "在职状态",
                    key: "status",
                    width: 120,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                    fixed: "right"
                },
            ],
            blackColumsList: [{
                    title: "姓名",
                    key: "blackName"
                },
                {
                    title: "手机号",
                    key: "blackPhone"
                },
                {
                    title: "身份证",
                    key: "blackIDCard"
                },
                {
                    title: "导入时间",
                    key: "blackInTime"
                },
                {
                    title: "籍贯",
                    key: "nativePlace"
                },
                {
                    title: "所属班组",
                    key: "eventTeamName"
                },
                {
                    title: "所属项目",
                    key: "eventProjectName"
                },
                {
                    title: "事件",
                    key: "private"
                },
                {
                    title: "事件时间",
                    key: "eventTime"
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                },
            ],
            subcColumsList: [{
                    title: "总包项目名",
                    key: "turnkeyProjectName"
                },
                {
                    title: "分包企业名",
                    key: "subContractCorpName"
                },
                {
                    title: "分包项目名",
                    key: "subContractProjectName"
                }
            ],
            teamColumnsList: [{
                    title: "Id",
                    key: "id",
                    width: 80,
                },
                {
                    title: "班组名称",
                    key: "teamName",
                    minWidth: 100,
                },
                {
                    title: "负责人姓名",
                    key: "teamLeaderName",
                    minWidth: 100,
                },
                {
                    title: "负责人手机号码",
                    key: "teamLeaderPhone",
                    minWidth: 120,
                },
                {
                    title: "班组人数",
                    key: "totalNum",
                    minWidth: 100,
                },
                {
                    title: "班组二维码",
                    slot: "qrCode",
                    minWidth: 120,
                },
                {
                    title: "班组状态",
                    slot: "statusStr",
                    width: 120,
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    fixed: "right",
                    width: 150,
                },
            ],
            authColumnsList: [{
                    title: "Id",
                    key: "id",
                    width: 80,
                },
                {
                    title: "姓名",
                    key: "name",
                },
                {
                    title: "手机号",
                    key: "phone",
                },
                {
                    title: "所属企业",
                    key: "corpName",
                },
                {
                    title: "授权角色",
                    key: "roleName",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 200,
                    align: "center",
                },
            ],
            //违规记录
            irregularitiesColumnsList: [
            // {
            //     title: "违规记录id",
            //     key: "irregularId",
            //     width: 80,
            // },
            // {
            //     title: "项目id",
            //     key: "projectId",
            // },
            {
                title: "违规描述",
                key: "irregularDescription",
                ellipsis:true,
                align:"center",
                tooltip:true,
                minWidth: 100,


            },
            {
                title: "违规人员列表",
                key: "teamItems",
                slot: "teamItems",
                // ellipsis:true,
                width: 350,
                align:"center",
                
            },
            {
                title: "违规等级",
                key: "irregularLevel",
                slot: "irregularLevel",
                width: 100,
                ellipsis:true,
                align:"center",
                
            },
            
            {
                title: "违规备注",
                key: "irregularRemark",
                ellipsis:true,
                align:"center",
                tooltip:true,
                minWidth: 100,


            },
            {
                title: "违规图片",
                key: "irregularPicture",
                slot: "irregularPicture",
                // ellipsis:true,
                width: 450,
                align:"center",


            },
            
            {
                title: "违规时间",
                key: "irregularTime",
                width: 130,
                align:"center",


            },
            {
                title: "更新时间",
                key: "updateTime",
                width: 180,
                align:"center",

            },
            {
                title: "操作",
                slot: "action",
                width: 120,
                align: "center",
            },
        ],
            teamManUserColumnsList: [{
                    title: "Id",
                    key: "id",
                    width: 80,
                },
                {
                    title: "姓名",
                    key: "vname",
                    width: 80,
                },
                {
                    title: "手机号",
                    key: "phone",
                    width: 140,
                },
                {
                    title: "性别",
                    slot: "teamUserSex",
                    width: 80,
                },
                {
                    title: "工种",
                    key: "workTypeName",
                    width: 80,
                },
                {
                    title: "身份证",
                    key: "vidcard",
                    width: 200,
                },
                {
                    title: "是否为班组长",
                    slot: "teamUserLeader",
                    align: "",
                    width: 130,
                },
                {
                    title: "实名头像",
                    slot: "teamUserIcon",
                    width: 120,
                    align: "",
                },
                {
                    title: "加入班组时间",
                    key: "createTime",
                    width: 180,
                },
                {
                    title: "状态",
                    key: "statusStr",
                    width: 80,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 200,
                    align: "center",
                    fixed: "right"
                },
            ],
            teamManTasksColumnsList: [{
                    title: "任务名称",
                    key: "taskName",
                    width: 300,
                },
                {
                    title: "任务状态",
                    key: "competePer",
                    width: 100,
                },
                {
                    title: "任务总人数",
                    key: "taskMemberCount",
                    width: 140,
                },
                {
                    title: "任务完成人数",
                    key: "completeCount",
                    width: 140,
                },
                {
                    title: "任务未完成人数",
                    key: "incompleteCount",
                    width: 140,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center"
                },
            ],
            equipmentColumnsList: [{
                    title: "设备序列号",
                    key: "serialNo",
                    width: 150,
                },
                {
                    title: "设备名",
                    key: "name",
                    width: 150,
                },
                {
                    title: "绑定班组数量",
                    key: "teamCount",
                    width: 150,
                },
                {
                    title: "已下发人数",
                    key: "issueCount",
                    width: 150,
                },
                {
                    title: "考勤机状态",
                    slot: "status",
                    width: 150,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 300,
                    align: "center",
                },
            ],
            equipmentUserColumsList: [{
                    title: "姓名",
                    key: "realname",
                    width: 100,
                },
                {
                    title: "头像",
                    slot: "qrCode",
                    width: 130,
                },
                {
                    title: "工种",
                    key: "position",
                    width: 100,
                },
                {
                    title: "手机号码",
                    key: "phone",
                    width: 120,
                },
                {
                    title: "身份证号码",
                    key: "vidcard",
                    width: 180,
                },
                {
                    title: "在职状态",
                    key: "removedStatusStr",
                    width: 100,
                },
                {
                    title: "是否绑定考勤机",
                    key: "clockinStatusStr",
                    width: 120,
                },
                {
                    title: "绑定考勤机的时间",
                    key: "clockinTime",
                    width: 160,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center"
                },
            ],
            checkColumsList: [{
                    title: "姓名",
                    key: "vname",
                    width: 100,
                },
                {
                    title: "班组",
                    key: "vteamName",
                    width: 100,
                },
                {
                    title: "工种",
                    key: "workType",
                    width: 150,
                },
                {
                    title: "身份证",
                    key: "vidcard",
                    width: 180,
                },
                {
                    title: "在职状态",
                    key: "removedStatusStr",
                    width: 100,
                },
                {
                    title: "加入班组时间",
                    key: "createTime",
                    width: 200,
                },
                {
                    title: "出勤天数",
                    key: "clockInTimes",
                    width: 100,
                },
            ],
            formColumnsList: [{
                    title: "表格名称",
                    key: "name",
                    width: 300,
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 300,
                    align: "center",
                },
            ],
            noFinishUserColumnsList: [{
                    title: "姓名",
                    key: "realname"
                },
                {
                    title: "手机号码",
                    key: "phone"
                },
                {
                    title: "身份证",
                    key: "idCard"
                },
                {
                    title: "任务包名称",
                    key: "taskName"
                },
                {
                    title: "任务包状态",
                    slot: "taskStatus"
                },
            ],
            GMCheckColums: [{
                    title: "姓名",
                    key: "name",
                    fixed: "left",
                    width: "80",
                },
                {
                    title: "工种",
                    key: "postName",
                    fixed: "left",
                    width: "100",
                },
                {
                    title: "工种类别",
                    key: "typeName",
                    fixed: "left",
                    width: "100",
                },
                {
                    title: "班组名称",
                    key: "teamName",
                    fixed: "left",
                    width: "100",
                },
                {
                    title: "1号",
                    key: "st1",
                    width: "80",
                },
                {
                    title: "2号",
                    key: "nd2",
                    width: "80",
                },
                {
                    title: "3号",
                    key: "rd3",
                    width: "80",
                },
                {
                    title: "4号",
                    key: "th4",
                    width: "80",
                },
                {
                    title: "5号",
                    key: "th5",
                    width: "80",
                },
                {
                    title: "6号",
                    key: "th6",
                    width: "80",
                },
                {
                    title: "7号",
                    key: "th7",
                    width: "80",
                },
                {
                    title: "8号",
                    key: "th8",
                    width: "80",
                },
                {
                    title: "9号",
                    key: "th9",
                    width: "80",
                },
                {
                    title: "10号",
                    key: "th10",
                    width: "80",
                },
                {
                    title: "11号",
                    key: "th11",
                    width: "80",
                },
                {
                    title: "12号",
                    key: "th12",
                    width: "80",
                },
                {
                    title: "13号",
                    key: "th13",
                    width: "80",
                },
                {
                    title: "14号",
                    key: "th14",
                    width: "80",
                },
                {
                    title: "15号",
                    key: "th15",
                    width: "80",
                },
                {
                    title: "16号",
                    key: "th16",
                    width: "80",
                },
                {
                    title: "17号",
                    key: "th17",
                    width: "80",
                },
                {
                    title: "18号",
                    key: "th18",
                    width: "80",
                },
                {
                    title: "19号",
                    key: "th19",
                    width: "80",
                },
                {
                    title: "20号",
                    key: "th20",
                    width: "80",
                },
                {
                    title: "21号",
                    key: "st21",
                    width: "80",
                },
                {
                    title: "22号",
                    key: "nd22",
                    width: "80",
                },
                {
                    title: "23号",
                    key: "rd23",
                    width: "80",
                },
                {
                    title: "24号",
                    key: "th24",
                    width: "80",
                },
                {
                    title: "25号",
                    key: "th25",
                    width: "80",
                },
                {
                    title: "26号",
                    key: "th26",
                    width: "80",
                },
                {
                    title: "27号",
                    key: "th27",
                    width: "80",
                },
                {
                    title: "28号",
                    key: "th28",
                    width: "80",
                },
                {
                    title: "29号",
                    key: "th29",
                    width: "80",
                },
                {
                    title: "30号",
                    key: "th30",
                    width: "80",
                },
                {
                    title: "31号",
                    key: "st31",
                    width: "80",
                },
                {
                    title: "天数总计(天)",
                    key: "daysTotal",
                    fixed: "right",
                    width: "90",
                },
                {
                    title: "时长总计(小时)",
                    key: "durationTotal",
                    fixed: "right",
                    width: "90",
                },
                {
                    title: "数据年月",
                    key: "yearsMonth",
                    fixed: "right",
                    width: "100",
                },
                {
                    title: "预发工资总计",
                    key: "advancePay",
                    fixed: "right",
                    width: "120",
                },
            ],
            GMCheckPage: 1,
            GMCheckTotal: 0,
            GMCheckSize: 15,
            GMCheckLoading: false,
            GMCheckData: [],
            GMWorkTypeColums: [{
                    title: "工种",
                    key: "typeName",
                },
                {
                    title: "日薪",
                    key: "perDiem",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                },
            ],
            GMWorkTypePage: 1,
            GMWorkTypeTotal: 0,
            GMWorkTypeSize: 15,
            GMWorkTypeLoading: false,
            GMWorkTypeData: [],

            GMWorkerColums: [{
                    title: "施工人员姓名",
                    key: "userName",
                },
                {
                    title: "班组",
                    key: "teamName",
                },
                {
                    title: "日薪",
                    key: "perDiem",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                },
            ],
            GMWorkerPage: 1,
            GMWorkerTotal: 0,
            GMWorkerSize: 15,
            GMWorkerLoading: false,
            GMWorkerData: [],

            OSRectifyTypeColums: [{
                    title: "分类",
                    key: "name",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                },
            ],
            OSRectifyTypePage: 1,
            OSRectifyTypeTotal: 0,
            OSRectifyTypeSize: 15,
            OSRectifyTypeLoading: false,
            OSRectifyTypeData: [],

            OSFineTypeColums: [{
                    title: "分类",
                    key: "name",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 150,
                    align: "center",
                },
            ],
            OSFineTypePage: 1,
            OSFineTypeTotal: 0,
            OSFineTypeSize: 15,
            OSFineTypeLoading: false,
            OSFineTypeData: [],
            GMRealNameColums: [{
                    title: "人员状态",
                    key: "status",
                },
                {
                    title: "姓名",
                    key: "name",
                },
                {
                    title: "性别",
                    key: "sex",
                },
                {
                    title: "证件号码",
                    key: "cardNo",
                },
                {
                    title: "出生日期",
                    key: "birthday",
                },
                {
                    title: "住址",
                    key: "address",
                },
                {
                    title: "联系电话",
                    key: "phone",
                },
                {
                    title: "班组名称",
                    key: "teamName",
                },
                {
                    title: "人员类型",
                    key: "type",
                },
                {
                    title: "银行卡号",
                    key: "bankCard",
                },
                {
                    title: "工资卡办理状态",
                    key: "bankStatus",
                },
                {
                    title: "是否上传劳动合同",
                    key: "isUploadContract",
                },
                {
                    title: "用工起止日期",
                    slot: "time",
                },
                {
                    title: "是否在抖工平台",
                    key: "isDg",
                },
                // {
                //     title: "操作",
                //     width: "120",
                //     slot:"action",

                // },
            ],
            GMRealNamePage: 1,
            GMRealNameTotal: 0,
            GMRealNameSize: 15,
            GMRealNameLoading: false,
            GMRealNameData: [],
            safeVideoDataList: [],
            safeVideoDataListLoading: false,
            safeVideoDataListPage: 1,
            safeVideoDataListSize: 15,
            safeVideoDataListTotal: 0,
            safeTemplateTababFlag: "safeVideo",
            safeTemplateVideoColums: [{
                    title: "视频标题",
                    key: "title",
                },
                {
                    title: "视频类型",
                    key: "cateStr",
                },
                {
                    title: "视频简介",
                    key: "videoDesc",
                },
                {
                    title: "视频时长（秒）",
                    key: "duration",
                },
                {
                    title: "视频来源",
                    key: "sourceStr",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 380,
                    align: "center",
                },
            ],
            safeTemplateVideoData: [],
            safeTemplateVideoPage: 1,
            safeTemplateVideoSize: 15,
            safeTemplateVideoTotal: 0,
            safeTemplateVideoLoading: false,
            safeTemplateTypeColums: [{
                    title: "工种名称",
                    key: "name",
                },
                // {
                //   title: "操作",
                //   slot: "action",
                //   width: 200,
                //   align: "center",
                // },
            ],
            safeTemplateTypePage: 1,
            safeTemplateTypeSize: 15,
            safeTemplateTypeTotal: 0,
            safeTemplateTypeLoading: false,
            safeTemplateTypeData: [],
            safeTemplateDiscloseColums: [{
                    title: "分布分项工程",
                    key: "contentName",
                },
                {
                    title: "交底表名称",
                    // key: "name",
                    key: "parentName",
                },
                {
                    title: "适用工种",
                    slot: "workTypes",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 200,
                    align: "center",
                },
            ],
            safeTemplateDisclosePage: 1,
            safeTemplateDiscloseTotal: 0,
            safeTemplateDiscloseSize: 15,
            safeTemplateDiscloseLoading: true,
            safeTemplateDiscloseData: [],
            sdDiscloseColums: [{
                    title: "分布分项工程",
                    key: "parentName",
                },
                {
                    title: "交底表名称",
                    key: "name",
                },
                {
                    title: "适用工种",
                    slot: "workType",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 120,
                    align: "center",
                },
            ],
            sdDisclosePage: 1,
            sdDiscloseSize: 15,
            sdDiscloseTotal: 0,
            sdDiscloseLoading: false,
            sdDiscloseData: [],
            safeProjectCloumsList: [{
                    title: "任务名称",
                    key: "name",
                },
                {
                    title: "调用工种",
                    slot: "positionTypeStr",
                },
                // {
                //     title: "任务内容",
                //     key: "content",
                // },
                {
                    title: "任务周期",
                    slot: "cycle",
                },
                {
                    title: "备注",
                    key: "remark",
                },
                {
                    title: "最新发布时间",
                    key: "updateTime",
                },
                {
                    title: "发布人员",
                    key: "updateBy",
                },
                {
                    title: "任务状态",
                    key: "statusStr",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 120,
                    align: "center",
                },
            ],
            safeProjectPage: 1,
            safeProjectSize: 5,
            safeProjectTotal: 0,
            safeProjectLoading: false,
            safeProjectPickFlag: false,
            safeProjectDataList: [],
            sdVideoColumsList: [{
                    title: "视频标题",
                    key: "a",
                },
                {
                    title: "视频类型",
                    key: "b",
                },
                {
                    title: "视频简介",
                    key: "c",
                },
                {
                    title: "视频时长（秒）",
                    key: "d",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 320,
                    align: "center",
                },
            ],
            sdVideoDataList: [{
                a: "xx视频",
                b: "视频类型",
                c: "视频简介",
                d: "60",
            }, ],
            prejobTabFlag: "finish",
            signTabFlag: "signpeople",
            safeAutoTabFlag: "finish",
            teamUserTabFlag: "insured",
            teamUserTabPickFlag: false,
            curTeamUser: {},
            sdFilesPage: 1,
            sdFilesTotal: 0,
            sdFilesSize: 15,
            sdFilesColumsList: [{
                    title: "文件名称",
                    key: "name",
                },
                {
                    title: "文件格式",
                    key: "ext",
                },
                {
                    title: "文件大小（kb）",
                    key: "size",
                },
                {
                    title: "上传时间",
                    key: "createTime",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 200,
                    align: "center",
                },
            ],
            sdFilesDataList: [],
            safeAutoSearchData: {
                name: "",
                pid: "",
            },
            safeAutoListPage: 1,
            safeAutoListSize: 15,
            safeAutoListTotal: 0,
            safeAutoListLoading: false,
            safeAutoPickFlag: false,
            safeAutoCloumsList: [{
                    title: "样板名称",
                    key: "name",
                },
                {
                    title: "样板内容",
                    key: "content",
                },
                {
                    title: "样板描述",
                    key: "remark",
                },
                {
                    title: "交底周期",
                    key: "duration",
                },
                {
                    title: "创建时间",
                    key: "createTime",
                },
                {
                    title: "更新时间",
                    key: "updateTime",
                },
                {
                    title: "更新人员",
                    key: "updateBy",
                },
                {
                    title: "状态",
                    key: "statusStr",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 400,
                    align: "center",
                },
            ],
            safeAutoDataList: [],
            safeNewVideoData: {
                title: "",
                type: "",
                desc: "",
            },
            safeVideoCloumsList: [{
                    title: "视频封面",
                    slot: "icon",
                    width: 120,
                    align: "",
                },
                {
                    title: "视频标题",
                    key: "title",
                },
                {
                    title: "视频分类",
                    key: "cateStr",
                },
                {
                    title: "视频时长（分钟）",
                    key: "durationMin",
                },
                {
                    title: "视频高度",
                    key: "height",
                },
                {
                    title: "视频宽度",
                    key: "width",
                },
                {
                    title: "添加时间",
                    key: "createTime",
                },
                {
                    title: "更新时间",
                    key: "updateTime",
                },
                {
                    title: "视频简介",
                    key: "desp",
                },
                {
                    title: "视频状态",
                    key: "transcodeStr",
                },
                // {
                //   title: "备注",
                //   key: "remark",
                // },
                {
                    title: "操作",
                    slot: "action",
                    width: 180,
                    align: "center",
                },
            ],
            perjobFinishiTypeList: [{
                    value: "0",
                    label: "未完成",
                },
                {
                    value: "1",
                    label: "完成",
                },
            ],
            testModle: "",
            finishProjectCloumsList: [{
                    title: "人员名称",
                    key: "name",
                },
                {
                    title: "所属班组",
                    key: "teamName",
                },
                {
                    title: "手机号码",
                    key: "phone",
                },
                {
                    title: "发布时间",
                    key: "publishDate",
                },
                {
                    title: "完成情况",
                    key: "statusStr",
                },
                {
                    title: "任务进度",
                    key: "taskTempo",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 120,
                    align: "center",
                },
            ],
            finishProjectDataList: [],
            finishProjectDataPage: 1,
            finishProjectDataSize: 10,
            finishProjectDataTotal: 0,
            sdProjectCloumsList: [{
                    title: "人员名称",
                    key: "a",
                },
                {
                    title: "所属班组",
                    key: "b",
                },
                {
                    title: "手机号码",
                    key: "c",
                },
                {
                    title: "发布时间",
                    key: "d",
                },
                {
                    title: "完成情况",
                    key: "e",
                },
                {
                    title: "任务进度",
                    key: "f",
                },
            ],
            sdProjectDataList: [{
                a: "陈xx",
                b: "xx班组",
                c: "139******36",
                d: "2020-11-1",
                e: "90%",
                f: "90%",
            }, ],
            prejobVideoColumsList: [{
                    title: "视频标题",
                    key: "title",
                },
                {
                    title: "视频类型",
                    key: "typeStr",
                },
                {
                    title: "视频简介",
                    key: "desc",
                },
                {
                    title: "视频时长（秒）",
                    key: "duration",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 320,
                    align: "center",
                },
            ],
            prejobVideoDataPage: 1,
            prejobVideoDataSize: 10,
            prejobVideoDataTotal: 0,
            prejobVideoDataList: [],
            formDataList: [{
                    formName: "新工人安全教育汇总表",
                    formSrc: "/teamTemplate/exportXinAnJiaoHuiZong",
                },
                {
                    formName: "应急知识教育登记表",
                    formSrc: "/teamTemplate/exportYingJiZhiShiJiaoYu",
                },
                {
                    formName: "公司（第一级）安全教育记录",
                    templateSrc: "/teamTemplate/exportFristSafeTrainTemplate",
                    formSrc: "/teamTemplate/exportFristSafeTrain",
                },
                {
                    formName: "项目部（第二级）安全教育记录",
                    templateSrc: "/teamTemplate/exportSecondSafeTrainTemplate",
                    formSrc: "/teamTemplate/exportSecondSafeTrain",
                },
                {
                    formName: "班组（第三级）安全教育记录",
                    templateSrc: "/teamTemplate/exportThirdSafeTrainTemplate",
                    formSrc: "/teamTemplate/exportThirdSafeTrain",
                },
                {
                    formName: "新工人入场三级安全教育登记表",
                    templateSrc: "/teamTemplate/exportXinSanTemplate",
                    formSrc: "/teamTemplate/exportXinSan",
                },
                {
                    formName: "变换工种安全教育登记表",
                    formSrc: "/teamTemplate/exportChangePositionSafeTrain",
                },
                {
                    formName: "节前（节后）安全教育登记表",
                    formSrc: "/teamTemplate/exportHolidaySafeTrain",
                },
                {
                    formName: "急救人员安全教育登记表",
                    formSrc: "/teamTemplate/exportEmergencyPersonSafeTrain",
                },
                {
                    formName: "作业人员安全教育登记表",
                    formSrc: "/teamTemplate/exportWorkerSafeTrain",
                },
                {
                    formName: "日常安全教育登记表",
                    formSrc: "/teamTemplate/exportDailySafeTrain",
                },
            ],

        }
    }
}

export default integrationData;