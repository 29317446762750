<template>
  <div class="full-content-box">
    <div class="second-title">
      <div class="shu" />
      工种管理
    </div>
    <div class="c-btnbox">
      <!-- <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">关键字：</div>
          <Input
            placeholder="姓名/身份证/手机号码"
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.searchValue"
          />
        </div>
      </div>
      <div class="c-btnbox-item" style="margin-bottom: 14px">
        <Button
          type="primary"
          style="margin-right: 14px"
          @click="handleMainListSearch"
          >搜索</Button
        >
        <Button @click="handleMainListSearch('reset')">重置</Button>
      </div> -->
      <div class="c-btnbox-item">
        <!-- <Button
          type="primary"
          style="margin-right: 14px"
          @click="toAddWorkType('1')"
          >添加定制工种</Button
        > -->
        <Button
          type="info"
          style="margin-right: 14px"
          @click="toAddWorkType('2')"
          >添加工种</Button
        >
      </div>
    </div>
    <div class="second-title">
      <div class="shu" />
      工种
    </div>
    <Table
      :loading="mainLoading"
      :columns="mainCloums"
      :data="mainList"
      :width="800"
    >
      <!-- <template slot-scope="{ row, index }" slot="status">
        <div class="btnbox-flex">
          <div>
            {{ row.status | rectifyStatusFilters(statusList) }}
          </div>
        </div>
      </template> -->
      <template slot-scope="{ row, index }" slot="teachCode">
        <div class="table-qrcode-img" @click="showQR(row.teachCode)">
          <img :src="row.teachCode" alt />
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <Button
          type="warning"
          style="margin-right: 8px"
          @click="removePeople(row)"
          >移除</Button
        >
      </template>
    </Table>
    <page
      show-total
      class="page-box"
      :page-size="mainSize"
      :total="mainTotal"
      :current="mainPage"
      @on-change="handleMainPageChange"
    />
    <Modal
      :mask-closable="false"
      v-model="addWorkType1Flag"
      :loading="addWorkType1Next"
      :title="'设置定制工种'"
      @on-ok="handleAddWorkType1"
      @on-cancel="handleAddWorkType1('cancel')"
    >
      <div class="aa-item">
        <div class="label bitian">工种名称：</div>
          <Input
            placeholder="请输入工种名称"
            style="width: 200px; margin-right: 14px"
            v-model="addWorkTypeData1.name"
          />
      </div>
    </Modal>
    <Modal
      :mask-closable="false"
      v-model="addWorkType2Flag"
      :loading="addWorkType2Next"
      :title="'设置工种'"
      @on-ok="handleAddWorkType2"
      @on-cancel="handleAddWorkType2('cancel')"
    >
      <div class="aa-item">
        <div class="label bitian">工种：</div>
          <Select
            v-model="addWorkTypeData2.workTypeValues"
            multiple
            :max-tag-count="3"
            style="width: 300px"
            transfer
          >
            <Option
              v-for="item in corpWorkTypeList"
              :value="item.value"
              :key="item.value"
              >{{ item.name }}</Option
            >
          </Select>
      </div>
    </Modal>
    <!-- 放大二维码 -->
    <div class="show-qrcode" v-show="showQRFlag" @click="hiddenQR">
      <div class="qrcode-img">
        <img :src="showImgSrc" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "worktypeTemp",
  data() {
    return {
      searchMainListData: {},
      allList: [],
      mainList: [],
      mainCloums: [
        {
          title: "工种",
          key: "workTypeName",
        },
        {
          title: "操作",
          slot: "action",
          width: 240,
          align: "center",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 15,
      mainTotal: 0,
      rangeDate: [],
      exportData: {
        date: "",
      },
      workTypeList: [],
      showQRFlag: false,
      showImgSrc: "",
      file: null,
      editPeopleFlag: false,
      editPeopleNext: true,
      peopleInfo: {
        projectId: "", //	是	long	项目ID
        headImg: "", //	否	string	头像
        name: "", //	是	string	姓名
        birthDay: "", //	否	string	生日
        phone: "", //	是	string	手机号
        idCard: "", //	是	string	身份证号码
        sex: "", //	否	int	性别：0未知，1男，2女
        address: "", //	否	string	家庭住址
        workTypeId: "", //	否	long	工种ID
        issuingAuthority: "", //	否	string	发证机关
        isSpecial: "", //	否	int	是否特殊工种：0否，1是
        issueDate: "", //	否	string	发证日期
        certificateNo: "", //	否	string	证书编号
        validityDate: "", //	否	string	有效日期
      },
      tabFlag: "info",
      addFlag: true,
      Accept: ".xls,.xlsx", //上传文件格式限制
      Format: [".xls", ".xlsx"], //上传文件格式限制
      uploadData: {},
      editPeopleLoading: false,
      corpWorkTypeList: [],
      addWorkType1Flag: false,
      addWorkType1Next: true,
      addWorkType2Flag: false,
      addWorkType2Next: true,
      addWorkTypeData1: {
        parentId: '',
        name: '',
      },
      addWorkTypeData2:{
        workTypeValues: [],
      }
    };
  },
  props: ["ProjectInfo", "ScreenWidth", "UserInfo"],
  created() {},
  mounted() {
    this.getAllList()
    this.getCorpWorkTypeList()
    this.handleMainListSearch();
  },
  filters: {
    rectifyStatusFilters(sid, list) {
      let newVal = "";
      list.forEach((val) => {
        if (sid == val.dictValue) {
          newVal = val.dictLabel;
        }
      });
      return newVal;
    },
  },
  watch: {},
  methods: {
    getAllList() {
      let data = {
        projectId: this.ProjectInfo.id,
      };
      this.$fetch(
        `/sapi/workType/relate/getProjectRelates?projectId=${this.ProjectInfo.id}`,
        {
          method: "get",
          data,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            let list = res.data;
            this.allList = list;
          } else {
            this.$Message.info("查询工种列表失败，请稍后重试");
          }
        })
        .catch((err) => {
         // this.$Message.info("查询工种列表失败，请稍后重试");
        });
    },
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      let data = {
        pageSize: this.mainSize,
        pageNum: this.mainPage,
        projectId: this.ProjectInfo.id,
      };
      let apiurl = this.common.getUrl('/sapi/workType/relate/getProjectRelates/page',data)
      this.$fetch(
        apiurl,
        {
          method: "get",
          data,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.mainTotal = res.data.total;
            let list = res.data.records;
            this.mainList = list;
            this.mainLoading = false;
          } else {
            this.mainLoading = false;
            this.$Message.info("查询整改列表失败，请稍后重试");
          }
        })
        .catch((err) => {
          //this.$Message.info("查询整改列表失败，请稍后重试");
          this.mainLoading = false;
          
        });
    },
    // 主表搜索
    handleMainListSearch(type) {
      if (type == "reset") {
        this.searchMainListData = {
          name: "",
        };
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch();
    },
    hiddenQR() {
      this.showQRFlag = !this.showQRFlag;
    },
    showQR(e) {
      if (!e) {
        return false;
      }
      this.showQRFlag = !this.showQRFlag;
      this.showImgSrc = e;
    },
    addPeople() {
      this.peopleInfo = {
        projectId: "",
        headImg: "",
        name: "",
        birthDay: "",
        phone: "",
        idCard: "",
        sex: "",
        address: "",
        workTypeId: "",
        issuingAuthority: "",
        isSpecial: "",
        issueDate: "",
        certificateNo: "",
        validityDate: "",
      };
      this.addFlag = true;
      this.editPeopleFlag = true;
    },
    toAddWorkType(type){
      if(type == 1){
      this.addWorkTypeData1 = {
        parentId: '',
        name: '',
      }
        this.addWorkType1Flag = true
      }
      if(type == 2){
        let wlist = this.mainList
        let nlist = []
        wlist.forEach(val=>{
          nlist.push(val.workTypeValue)
        })
      this.addWorkTypeData2 = {
        workTypeValues: nlist
      }
        this.addWorkType2Flag = true
      }
    },
    editPeople(row) {
      // this.peopleInfo = row
      this.addFlag = false;
      this.editPeopleFlag = true;
      this.getPeopleDetail(row.id);
    },
    toEditPeople(type) {
      if (type == "close") {
        this.editPeopleFlag = false;
        return false;
      }
      if (this.addFlag) {
        this.addFun();
      } else {
        this.editFun();
      }
    },
    pickTab(e) {},
    addFun() {
      let ndata = this.peopleInfo;
      if (ndata.name == "" || ndata.phone == "" || ndata.idCard == "") {
        this.$Message.info("请填写完整资料");
        this.editPeopleNext = false;
        setTimeout(() => {
          this.editPeopleNext = true;
        }, 500);
        return false;
      }
      let data = {
        projectId: this.ProjectInfo.id,
        headImg: ndata.headImg,
        name: ndata.name,
        birthDay: ndata.birthDay,
        phone: ndata.phone,
        idCard: ndata.idCard,
        sex: ndata.sex,
        address: ndata.address,
        workTypeId: ndata.workTypeId,
        issuingAuthority: ndata.issuingAuthority,
        isSpecial: ndata.isSpecial,
        issueDate: ndata.issueDate,
        certificateNo: ndata.certificateNo,
        validityDate: ndata.validityDate,
      };
      this.$fetch("/osapi/roster/add", {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code == "200") {
          this.$Message.success("操作成功");
          this.handleMainListSearch("reset");
          this.editPeopleFlag = false;
        } else {
          this.editPeopleNext = false;
          setTimeout(() => {
            this.editPeopleNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    editFun() {
      let ndata = this.peopleInfo;
      this.$fetch("/osapi/roster/edit", {
        method: "post",
        data: ndata,
      }).then((res) => {
        if (res && res.code == "200") {
          this.$Message.success("操作成功");
          this.handleMainListSearch("reset");
          this.editPeopleFlag = false;
        } else {
          this.editPeopleNext = false;
          setTimeout(() => {
            this.editPeopleNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    removePeople(val) {
      let data = [val.id]
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否移除该工种</p>",
        onOk: () => {
          this.$fetch(`/sapi/workType/relate/delete`, {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("操作成功");
              this.handleMainListSearch("reset");
            } else {
              this.$Message.info("操作失败");
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 获取项目关联当前工种
    getCorpWorkTypeList() {
      let that = this
      let data = {};
      this.$fetch(
        `/sapi/workType/relate/getCorpRelate?corpId=${this.UserInfo.icorpid}`,
        {
          method: "get",
          data,
        }
      )
        .then((res) => {
          console.log(res)
          if (res.code == "200") {
            if(res.data.length > 0){
              let clist = []
              // let clist = res.data[0].childs
              res.data.forEach(item => {
                clist.push(...item.childs)
              })
              this.corpWorkTypeList = clist
              this.corpWorkTypeId = res.data[0].id
              // let ids = []
              // clist.forEach(val=>{
              //   ids.push(val.value)
              // })
              // this.addNewWorkType = ids
              // this.corpWorkTypeList = clist
              // console.log('test',res.data[0].childs)
            }else{
              this.$Message.info("请先在控制台，设置企业工种。");
            }
          } else {
            this.$Message.error("获取工种失败");
          }
        })
        .catch((err) => {
         // this.$Message.error("获取工种失败");
        });
    },
    // 获取人员信息
    getPeopleDetail(val) {
      let that = this;
      this.editPeopleLoading = true;
      let data = {
        id: val,
      };
      this.$fetch(`/osapi/roster/details`, {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.editPeopleLoading = false;
            let pdata = res.data;
            pdata.teachCode = res.prefix + pdata.teachCode;
            this.peopleInfo = pdata;
            console.log(this.peopleInfo);
          } else {
            this.$Message.error("获取工种失败");
            this.editPeopleFlag = false;
          }
        })
        .catch((err) => {
         // this.$Message.error("获取工种失败");
          this.editPeopleFlag = false;
        });
    },
    handleAddWorkType1(type){
      let that = this
      if(type == 'cancel'){
        that.addWorkType1Flag = false
        return;
      }
      if (!this.addWorkTypeData1.name) {
        this.$Message.info("请填写完整信息");
        this.addWorkType1Next = false;
        setTimeout(() => {
          this.addWorkType1Next = true;
        }, 500);
        return false;
      }
      let data = {
        projectId: this.ProjectInfo.id,
        parentId: this.corpWorkTypeId,//	是	long	父级id
        name: this.addWorkTypeData1.name,//	是	string	工种名称
      }
      that.$fetch('/sapi/workType/addSelfWorkType', {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code == "200") {
          that.handleMainListSearch("reset");
          that.addWorkType1Flag = false;
          console.log(res)
        } else {
          that.addWorkType1Next = false;
          setTimeout(() => {
            that.addWorkType1Next = true;
          }, 500);
          that.$Message.error("操作失败");
        }
      });
    },
    handleAddWorkType2(type){
      let that = this
      if(type == 'cancel'){
        that.addWorkType2Flag = false
        return;
      }
      if (this.addWorkTypeData2.workTypeValues.length < 1) {
        this.$Message.info("请选择工种");
        this.addWorkType2Next = false;
        setTimeout(() => {
          this.addWorkType2Next = true;
        }, 500);
        return false;
      }
      let data = {
        id: this.ProjectInfo.id,
        workTypeValues: this.addWorkTypeData2.workTypeValues
      }
      that.$fetch('/sapi/workType/relate/addProjectRelate', {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code == "200") {
          that.handleMainListSearch("reset");
          that.addWorkType2Flag = false;
          console.log(res)
        } else {
          that.addWorkType2Next = false;
          setTimeout(() => {
            that.addWorkType2Next = true;
          }, 500);
          that.$Message.error("操作失败");
        }
      });
    },
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
.p-box {
  .part {
    .p-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 7px;
    }
    .p-content {
      padding-left: 14px;
      display: flex;
      flex-wrap: wrap;
      .pc-part {
        flex: 1;
        .pc-item {
          margin-bottom: 18px;
          .label {
            margin-bottom: 4px;
          }
          .l-bitian {
            position: relative;
          }
          .l-bitian::before {
            content: "*";
            position: absolute;
            left: -7px;
            top: 0px;
            color: red;
          }
          img {
            width: 120px;
            height: auto;
          }
        }
      }
    }
  }
}
</style>