<template>
  <div class="full-content-box">
      <div class="second-title">
        <div class="shu" />
        考勤班组管理
      </div>
      <div class="c-btnbox">
        <div class="c-btnbox-item">
          <div class="mb-item">
            <div class="label">班组名称：</div>
            <Input
              v-model="searchMainListData.name"
              placeholder="请输入班组名称"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <div class="mb-item">
            <div class="label">负责人姓名：</div>
            <Input
              v-model="searchMainListData.leaderName"
              placeholder="请输入负责人姓名"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <div class="mb-item">
            <div class="label">负责人手机号：</div>
            <Input
              v-model="searchMainListData.leaderPhone"
              placeholder="请输入负责人手机号"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <!-- <div class="mb-item">
            <div class="label">班组状态：</div>
            <Select
              v-model="searchMainListData.isExist"
              style="width: 200px; margin-right: 14px"
            >
              <Option
                v-for="item in teamStatusList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div> -->
        </div>
        <div class="c-btnbox-item" style="margin-bottom: 14px">
          <Button
            type="primary"
            @click="handleMainListSearch"
            style="margin-right: 14px"
            >搜索</Button
          >
          <Button @click="handleMainListSearch('reset')">重置</Button>
        </div>
        <Table
          highlight-row
          ref="mainTable"
          :columns="mainCloums"
          :data="mainList"
          @on-current-change="handleMainListPick"
          :loading="mainLoading"
        >
        <template slot-scope="{ row, index }" slot="isExist">
                  <div>
                    {{row.isExist | statusFilters(teamStatusList)}}
                  </div>
                </template>
          <template slot-scope="{ row, index }" slot="action">
            <!-- <Button type="primary" style="margin-right: 8px">在线预览</Button>
            <Button type="primary">下载</Button> -->
          </template>
        </Table>
        <div class="fun-page-box">
          <Button @click="handleClearMain" style="margin-right: 14px"
            >清除列表选中</Button
          >
          <page show-total
            :total="mainTotal"
            :page-size="mainSize"
            @on-change="handleMainPageChange"
            :current="mainPage"
          />
        </div>
        <Divider />
        <Tabs type="card" @on-click="pickTab" v-model="tabFlag">
          <TabPane
            v-for="(item, index) in tabList"
            :key="index"
            :label="item.tabLabel"
            :name="item.tabName"
          ></TabPane>
        </Tabs>
        <!-- 子表-人员列表 -->
        <div class="tab-content-box" v-if="tabFlag == 'equipment'">
          <div class="tab-nullbox" v-if="!mainListPickFlag">
            <div class="nulltips">
              {{ tabDataNull ? "暂无数据" : "请先选择任务" }}
            </div>
          </div>
          <div v-else>
            <div class="tcb-item">
              <div class="tcbi-item">
                <div class="label">姓名：</div>
                <Input
                  v-model="searchSubListData.name"
                  placeholder="请输入搜索人员姓名"
                  style="width: 200px"
                />
              </div>
              <div class="tcbi-item">
                <div class="label">手机号码：</div>
                <Input
                  v-model="searchSubListData.phone"
                  placeholder="请输入搜索人员手机号码"
                  style="width: 200px"
                />
              </div>
              <div class="tcbi-item">
                <div class="label">身份证：</div>
                <Input
                  v-model="searchSubListData.idCard"
                  placeholder="请输入搜索人员身份证号"
                  style="width: 200px"
                />
              </div>
              <div class="tcbi-item">
                <div class="label">下发状态：</div>
                <Select v-model="searchSubListData.issueStatus" style="width: 150px">
                  <Option
                    v-for="item in issueStatusList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="tcbi-item">
                <div class="label">入场须知：</div>
                <Select v-model="searchSubListData.taskStatus" style="width: 150px">
                  <Option
                    v-for="item in taskStatusList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="tcbi-item">
                <div class="label">在职状态：</div>
                <Select v-model="searchSubListData.isRemoved" style="width: 150px">
                  <Option
                    v-for="item in statusList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
            </div>
            <div class="tcb-item tcb-item-spac" style="margin-bottom: 14px">
              <div>
                <Button
                  type="primary"
                  style="margin-right: 14px"
                  @click="handleSearchSubFilesList"
                  >搜索</Button
                >
                <Button @click="handleSearchSubFilesList('reset')">重置</Button>
        <!-- <Button @click="toDownEquipmentAll" style="margin-left: 34px" type="info">批量下发</Button> -->
              </div>
            </div>
            <Table
              :columns="subFilesCloums"
              :data="subFilesList"
              :loading="subFilesLoading"
              :width="ScreenWidth - 350"
              size="small"
            >
            <template slot-scope="{ row, index }" slot="issueStatus">
                  <div>
                    {{row.issueStatus | statusFilters(issueStatusList)}}
                  </div>
                </template>
            <template slot-scope="{ row, index }" slot="isRemoved">
                  <div>
                    {{row.isRemoved | statusFilters(statusList)}}
                  </div>
                </template>
            <template slot-scope="{ row, index }" slot="taskStatus">
                  <div>
                    {{row.taskStatus | statusFilters(taskStatusList)}}
                  </div>
                </template>
              <template slot-scope="{ row, index }" slot="pic">
                  <div class="head-img" @click="showQR(row.pic)">
                    <img v-if="row.pic" :src="row.pic" alt />
                    <img
                      v-else
                      src="../../../assets/images/test_head_icon.png"
                      alt
                    />
                  </div>
                </template>
              <template slot-scope="{ row, index }" slot="action">
                <!-- <Button
                  type="primary"
                  style="margin-right: 8px"
                  @click="toDownEquipment(row)"
                  >下发</Button
                > -->
              </template>
            </Table>
            <page show-total
              :total="subFilesTotal"
              :page-size="subFilesSize"
              :current="subFilesPage"
              @on-change="handleSubFilesPageChange"
              style="margin-top: 14px"
            />
          </div>
        </div>
        <!-- 子表-设备管理 -->
        <div class="tab-content-box" v-if="tabFlag == 'files'">
          <div class="tab-nullbox" v-if="!mainListPickFlag">
            <div class="nulltips">
              {{ tabDataNull ? "暂无数据" : "请先选择任务" }}
            </div>
          </div>
          <div v-else>
            <div class="tcb-item tcb-item-spac" style="margin-bottom: 14px">
              <div>
                <Button
                  type="primary"
                  style="margin-right: 14px"
                  @click="toAddEquipment"
                  >添加设备</Button
                >
              </div>
            </div>
            <Table
              :columns="subEquipmentCloums"
              :data="subEquipmentList"
              :loading="subEquipmentLoading"
              :width="ScreenWidth - 350"
              size="small"
            >
            <template slot-scope="{ row, index }" slot="status">
                  <div>
                    {{row.status | statusFilters(checkStatusList)}}
                  </div>
                </template>
              <template slot-scope="{ row, index }" slot="action">
                <Button
                  type="warning"
                  style="margin-right: 8px"
                  @click="removeEquipment(row)"
                  >移除设备</Button
                >
              </template>
            </Table>
            <page show-total
              :total="subEquipmentTotal"
              :page-size="subEquipmentSize"
              :current="subEquipmentPage"
              @on-change="handleSubEquipmentPageChange"
              style="margin-top: 14px"
            />
          </div>
        </div>
      </div>
      <!-- 添加设备 -->
    <Modal
      :mask-closable="false"
      v-model="addEquipmentFlag"
      :loading="addEquipmentNext"
      :title="'选择设备'"
      @on-ok="handleAddEquipment"
      @on-cancel="handleAddEquipment('cancel')"
    >
      <div class="equipment-box">
        <div v-if="bindEquimentList.length > 0">
          <div class="eb-item" v-for="(item,index) in bindEquimentList" :key="index" @click="pickEquipment(item)">
          <div class="left">
            <div class="check">
              <Checkbox @on-change="pickEquipmentCheckbox" v-model="item.pickFlag" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="num">{{item.serialNo}}</div>
            </div>
          </div>
          <div class="right">
            <span v-if="bindFlag == 'add'">已绑定{{item.teamCount}}个班组</span>
            <span v-if="bindFlag == 'down' || bindFlag == 'downAll'">{{item.status | statusFilters(checkStatusList)}}</span>
          </div>
        </div>
        </div>
        <div v-else>
          <span v-if="bindFlag == 'add'">暂无可绑定设备</span>
          <span v-if="bindFlag == 'down' || bindFlag == 'downAll'">暂无可下发设备</span>
        </div>
      </div>
    </Modal>
    <!-- 放大二维码 -->
    <div class="show-qrcode" v-show="showQRFlag" @click="hiddenQR">
      <div class="qrcode-img">
        <img :src="showImgSrc" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainSubTable",
  data() {
    return {
      searchMainListData: {
        name: "",
        leaderName: "",
        leaderPhone: "",
        isExist: "",
      },
      mainList: [],
      mainCloums: [
        {
          title: "班组名称",
          key: "name",
        },
        {
          title: "负责人姓名",
          key: "leaderName",
        },
        {
          title: "负责人手机号",
          key: "leaderPhone",
        },
        {
          title: "班组状态",
          slot: "isExist",
        },
        {
          title: "所属劳务公司",
          key: "corpName",
        },
        {
          title: "在岗人数",
          key: "personTotal",
        },
        {
          title: "已下发人数",
          key: "issueTotal",
        },
        {
          title: "未下发人数",
          key: "notIssueTotal",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 5,
      mainTotal: 0,
      mainListPickFlag: false,
      tabDataNull: false,
      tabFlag: "files",
      tabList: [
        {
          tabLabel: "已绑定设备",
          tabName: "files",
        },
        {
          tabLabel: "班组人员",
          tabName: "equipment",
        },
      ],
      searchSubListData: {
        name: "",
        phone: "",
        idCard: "",
        issueStatus: "",
        taskStatus: "",
        isRemoved: "",
      },
      issueStatusList: [
        {
          value: 0,
          label: "未下发",
        },
        {
          value: 1,
          label: "已下发",
        },
      ],
      taskStatusList: [
        {
          value: 0,
          label: "未完成",
        },
        {
          value: 1,
          label: "已完成",
        },
      ],
      statusList: [
        {
          value: 0,
          label: "在职",
        },
        {
          value: 1,
          label: "离岗",
        },
      ],
      subFilesList: [],
      subFilesCloums: [
        {
          title: "姓名",
          key: "name",
          width: 100,
        },
        {
          title: "头像",
          slot: "pic",
          width: 120,
        },
        {
          title: "工种",
          key: "workType",
          minWidth: 100,
        },
        {
          title: "手机号码",
          key: "phone",
          minWidth: 120,
        },
        {
          title: "身份证",
          key: "idCard",
          minWidth: 200,
        },
        {
          title: "在职状态",
          slot: "isRemoved",
          minWidth: 100,
        },
        {
          title: "下发状态",
          slot: "issueStatus",
          width: 120,
        },
        {
          title: "已下发设备",
          key: "issueBind",
          width: 240,
        },
        {
          title: "入场须知任务状态",
          slot: "taskStatus",
          width: 240,
        },
        {
          title: "操作",
          slot: "action",
          width: 240,
          align: "center",
          fixed: "right",
        },
      ],
      subFilesLoading: false,
      subFilesPage: 1,
      subFilesSize: 10,
      subFilesTotal: 0,
      teamStatusList: [
        {
          value: 0,
          label: "正常",
        },
        {
          value: 1,
          label: "禁止进入",
        },
        {
          value: 2,
          label: "班组已退场",
        },
      ],
      currentMainData: {},
      showQRFlag: false,
      showImgSrc: '',
      filesOnWhere: "",
      checkStatusList: [
        {
          value: 0,
          label: "离线",
        },
        {
          value: 1,
          label: "在线",
        },
      ],
      
      searchSubEquipmentListData: {},
      subEquipmentList: [],
      subEquipmentCloums: [
        {
          title: "设备名称",
          key: "name",
          minWidth: 120
        },
        {
          title: "设备序列号",
          key: "serialNo",
          minWidth: 120
        },
        {
          title: "状态",
          slot: "status",
          minWidth: 120
        },
        {
          title: "绑定时间",
          key: "createTime",
          minWidth: 240
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
          fixed: "right",
        },
      ],
      subEquipmentLoading: false,
      subEquipmentPage: 1,
      subEquipmentSize: 10,
      subEquipmentTotal: 0,
      addEquipmentFlag: false,
      addEquipmentNext: true,
      bindEquimentList: [],
      bindFlag: '',
      currentPeopleData: {},
      loadingListFlag: false
    };
  },
  props: ["ProjectInfo","ScreenWidth"],
  created() {},
  mounted() {
    this.handleMainListSearch("reset");
  },
  filters: {
    statusFilters(num, list) {
      let n = "-";
      list.forEach((val) => {
        if (num == val.value) {
          n = val.label;
        }
      });
      return n;
    },
  },
  watch: {},
  methods: {
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      let data = {
        pageSize: this.mainSize,
        pageNo: this.mainPage,
        projectId: this.ProjectInfo.id,
        name: this.searchMainListData.name,
        leaderName: this.searchMainListData.leaderName,
        leaderPhone: this.searchMainListData.leaderPhone,
        isExist: this.searchMainListData.isExist,
      };
      // console.log(data)
      this.$fetch(this.common.getUrl('/sapi/team/machine/list',data), {
        method: "get"
      }).then((res) => {
        if (res.code == "200") {
          // console.log(res.data)
          let list = []
          if(res.data){
            list = res.data.records
            list.forEach(val=>{
              val.personTotal = val.personTotal > 0 ? val.personTotal : 0
              val.issueTotal = val.issueTotal > 0 ? val.issueTotal : 0
              val.notIssueTotal = val.notIssueTotal > 0 ? val.notIssueTotal : 0
            })
            list.forEach(val=>{
              val.issueTotal = val.personTotal >= val.issueTotal ? val.issueTotal : val.personTotal
              val.notIssueTotal = val.personTotal >= val.notIssueTotal ? val.notIssueTotal : val.personTotal
            })
          }
          this.mainList = res.data ? list : [];
          this.mainTotal =  res.data ? res.data.total : 0;
          this.mainLoading = false;
        } else {
          this.mainLoading = false;
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 主表搜索
    handleMainListSearch(type) {
      this.handleClearMain()
      if (type == "reset") {
        this.searchMainListData = {
          name: "",
          leaderName: "",
          leaderPhone: "",
          isExist: "",
        };
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 选中主表
    handleMainListPick(currentRow, oldCurrentRow) {
      if (currentRow) {
        this.currentMainData = currentRow;
        this.mainListPickFlag = true;
        this.handleSearchSubFilesList("reset");
        this.handleSearchSubEquipmentList("reset");
        this.getEquipmentCanBindList()
      }
    },
    // 清除主表选择
    handleClearMain() {
      this.$refs.mainTable.clearCurrentRow();
      this.mainListPickFlag = false;
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch()
    },
    // 子表tab选择
    pickTab(e) {},
    // 子表搜索
    handleSearchSubFilesList(type) {
      if (type == "reset") {
        this.searchSubListData = {
        name: "",
        phone: "",
        idCard: "",
        issueStatus: "",
        taskStatus: "",
        isRemoved: "",
      }
        this.subFilesPage = 1;
      }
      this.getSubListFiles();
    },
    handleSubFilesPageChange(e) {
      this.subFilesPage = e;
      this.handleSearchSubFilesList();
    },
    getSubListFiles() {
      this.subFilesLoading = true;
      let data = {
        pageNo: this.subFilesPage,
        pageSize: this.subFilesSize,
        teamId: this.currentMainData.id,
        name: this.searchSubListData.name,
        phone: this.searchSubListData.phone,
        idCard: this.searchSubListData.idCard,
        issueStatus: this.searchSubListData.issueStatus,
        taskStatus: this.searchSubListData.taskStatus,
        isRemoved: this.searchSubListData.isRemoved
      };
      console.log(data)
      this.$fetch(this.common.getUrl('/sapi/teamItem/list',data), {
        method: "get"
      }).then((res) => {
        if (res && res.code === "200") {
          let data = res.data
          let list = res.data.records
          if(list){
            list.forEach(val=>{
              val.issueBind = ''
              if(val.issueList){
                let text = ''
                val.issueList.forEach(vall=>{
                  if(vall.status == '1'){
                    text = text + vall.serialNo + ';'
                  }
                })
                  if(text == ''){
                    text = '暂无'
                  }
                    val.issueBind = text
              }else{
                val.issueBind = '暂无'
              }
            })
          }else{
            list = []
          }
          this.subFilesTotal =  data ? data.total : 0;
          this.subFilesList = data ? list : [];
          this.subFilesLoading = false;
        } else {
          this.subFilesLoading = false;
          this.$Message.error("获取班组绑定设备列表失败");
        }
      });
    },

    handleSearchSubEquipmentList(type) {
      if (type == "reset") {
        this.searchSubEquipmentListData = {}
        this.subEquipmentPage = 1;
      }
      this.getEquipmentListFiles();
    },
    handleSubEquipmentPageChange(e) {
      this.subEquipmentPage = e;
      this.handleSearchSubEquipmentList();
    },
    getEquipmentListFiles() {
      this.subEquipmentLoading = true;
      // setTimeout(()=>{
      //   this.subEquipmentLoading = false
      // },1000)
      let data = {
        pageNo: this.subEquipmentPage,
        pageSize: this.subEquipmentSize,
        teamId: this.currentMainData.id
      };
      // console.log(data)
      this.$fetch(this.common.getUrl('/sapi/team/machine/exist/list',data), {
        method: "get"
      }).then((res) => {
        if (res && res.code === "200") {
          this.subEquipmentTotal = res.data ? res.data.total : 0;
          this.subEquipmentList = res.data ? res.data.records : [];
          this.subEquipmentLoading = false;
        } else {
          this.subEquipmentLoading = false;
          this.$Message.error("获取班组绑定设备列表失败");
        }
      });
    },
    
    toViewFile(src) {
      window.open(src, "_blank");
    },
    showQR(e) {
      if (!e) {
        return false;
      }
      this.showQRFlag = !this.showQRFlag;
      this.showImgSrc = e;
    },
    hiddenQR() {
      this.showQRFlag = !this.showQRFlag;
    },
    toAddEquipment(){
      this.bindFlag = 'add'
      let list = this.bindEquimentList
      list.forEach(val=>{
          val.pickFlag = false
      })
      this.bindEquimentList = list
      this.addEquipmentFlag = true
    },
    handleAddEquipment(type){
      let that = this
      if(type == 'cancel'){
        that.addEquipmentFlag = false
        return;
      }
      let elist = that.bindEquimentList
      let clist = []
      let apiUrl = ''
      let data = ''
      if(this.bindFlag == 'add'){
        elist.forEach(val=>{
          if(val.pickFlag){
            clist.push({
              serialNo: val.serialNo,
              name: val.name,
              teamId: this.currentMainData.id,
              id: val.id,
              status: val.status,
            })
          }
        })
        apiUrl = `/sapi/team/machine/add`
        data = clist
      }
      if(this.bindFlag == 'down'){
        elist.forEach(val=>{
          if(val.pickFlag){
            clist.push(val.serialNo)
          }
        })
        apiUrl = `/sapi/team/machine/person/issue`
        data = {
          idcard: this.currentPeopleData.idCard,
          phone: this.currentPeopleData.phone,
          serialNos: clist,
          teamId: this.currentMainData.id,
        }
      }
      if(this.bindFlag == 'downAll'){
        elist.forEach(val=>{
          if(val.pickFlag){
            clist.push(val.serialNo)
          }
        })
        apiUrl = `/sapi/team/machine/team/issue`
        data = {
          serialNos: clist,
          teamId: this.currentMainData.id,
        }
      }
      if(clist.length < 1){
        that.$Message.info("请选择设备")
        that.addEquipmentNext = false;
        setTimeout(() => {
          that.addEquipmentNext = true;
        }, 500);
        return;
      }
      that.$fetch(apiUrl, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code == "200") {
          let mlist = res.data
          if(mlist.length > 0){
            mlist.forEach(val=>{
              this.$Message.info(val)
            })
          }
          this.$Message.info('操作成功')
          if(this.bindFlag == 'add'){
            that.handleSearchSubFilesList("reset");
            that.handleSearchSubEquipmentList("reset");
          }else{
            this.subFilesLoading = true
            setTimeout(() => {
              that.handleSearchSubFilesList();
              that.handleSearchSubEquipmentList();
            }, 2000);
          }
          that.addEquipmentFlag = false;
        } else {
          that.addEquipmentNext = false;
          setTimeout(() => {
            that.addEquipmentNext = true;
          }, 500);
          that.$Message.error("操作失败");
        }
      });
    },
    getEquipmentCanBindList(){
      let data = {
        teamId: this.currentMainData.id
      };
      this.$fetch(this.common.getUrl('/sapi/team/machine/notexist/list',data), {
        method: "get"
      }).then((res) => {
        if (res.code == "200") {
          let list = res.data
          if(list){
            list.forEach(val=>{
              val.pickFlag = false
            })
          }else{
            list = []
          }
            this.bindEquimentList = list;
        } else {
          this.$Message.info("查询可绑定设备列表失败，请稍后重试");
        }
      });
    },
    pickEquipment(row){
      let list = this.bindEquimentList
      list.forEach(val=>{
        if(val.id == row.id){
          val.pickFlag = !val.pickFlag
        }
      })
      this.bindEquimentList = list
    },
    pickEquipmentCheckbox(){},
    toDownEquipment(row){
      if(row.taskStatus == 0){
        this.$Message.warning('该人员未完成入场须知，暂不可下发到设备。')
        return
      }
      this.bindFlag = 'down'
      this.currentPeopleData = row
      let list = JSON.parse(JSON.stringify(this.subEquipmentList));
      list.forEach(val=>{
          val.pickFlag = true
      })
      this.bindEquimentList = list
      this.addEquipmentFlag = true
    },
    toDownEquipmentAll(){
      this.bindFlag = 'downAll'
      let list = JSON.parse(JSON.stringify(this.subEquipmentList));
      list.forEach(val=>{
          val.pickFlag = true
      })
      this.bindEquimentList = list
      this.addEquipmentFlag = true
    },
    removeEquipment(val){
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否移除该设备</p>",
        onOk: () => {
          this.$axios.delete(`${this.apiUrlCros}/sapi/team/machine/${val.id}`)
          .then((res) => {
            // console.log(res)
            if (res.data.code == "200") {
              this.$Message.success("操作成功");
              this.handleSearchSubEquipmentList("reset");
            } else {
              this.$Message.info("操作失败");
            }
          }).catch((err)=>{
            this.$Message.info(err.response.data.msg)
          })
        },
        onCancel: () => {},
      });
    },
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
.equipment-box{
  display: flex;
  flex-direction: column;
  .eb-item:not(:last-child){
    margin-bottom: 7px;
    border-bottom: 1px solid #2d8cf0;
  }
  .eb-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 7px;
    .left{
      display: flex;
      align-items: center;
      .info{
        margin-left: 14px;
        .title{
          font-size: 14px;
          color: #2d8cf0;
        }
        .num{
          font-size: 12px;
        }
      }
    }
    .right{

    }
  }
}
</style>