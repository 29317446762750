<template>
  <div class="main">
    <!-- <div class="fun">
      <div class="title">
        <div class="csb-item">
          <div>企业级安全技术交底表：</div>
          <el-select v-model="edition" >
            <el-option label="2021版本" value="1"></el-option>
          </el-select>
        </div>
      </div>
    </div> -->
    <div class="cont">
      <div style="margin-bottom: 15px">
        <div style="display: flex; align-items: center">
          <div class="label">分部分项工程名称：</div>
          <!-- <Input
            placeholder="请输入企业名称"
            style="position:fixed;bottom:-999999px;"
            autocomplete="off"
            clearable
          /> -->
          <Input
            placeholder="请输入分部分项工程名称"
            style="width: 200px; margin: 0 14px"
            v-model="searchData.keyword"
            autocomplete="off"
            clearable
          />
          <!-- <div class="label">分项名称:</div> -->
          <el-select size="small" v-model="searchData.categoryId" style="width: 200px; margin: 0 14px" clearable>
            <el-option :label="item.categoryName" :value="item.categoryId" v-for="item in categoriesData" :key="item.categoryId">
              
            </el-option>
          </el-select>
          <Button
            type="primary"
            style="margin-right: 14px"
            @click="
              () => {
                this.corpPageData.current = 1;
                this.getData();
              }
            "
            >搜索</Button
          >
        </div>
        <Table
          highlight-row
          ref="corp"
          :columns="corptCloumsList"
          :data="corpDataList"
          :loading="corpTableLoading"
          style="margin: 10px 0 5px"
        >
          <template slot-scope="{ row }" slot="workTypes">
            <div v-if="row.workTypes.length == 0">暂未配置工种</div>
            <template v-else>
              <div v-for="item in row.workTypes" :key="item.workTypeValue">
                {{ item.workTypeName }}
              </div>
            </template>
          </template>
          <template slot-scope="{ row }" slot="tableData">
            <Button
              type="success"
              style="margin-right: 14px"
              @click="browse(row.contentId)"
              >预览</Button
            >
            <Button
              type="success"
              style="margin-right: 14px"
              @click="download(row)"
              >下载</Button
            >
          </template>
          <!-- <template slot-scope="{ row }" slot="content">
            <Input
              v-model="row.content"
              :autosize="true"
              class="tab-textarea"
              type="textarea"
              disabled
            />
          </template> -->
          <template slot-scope="{ row }" slot="status">
            <div v-if="row.workTypes.length == 0">未配置</div>
            <div v-else>已配置</div>
          </template>
          <template slot-scope="{ row }" slot="action">
            <Button type="primary" @click="editInfo(row)">{{
              row.workTypes.length == 0 ? "配置" : "编辑"
            }}</Button>
          </template>
        </Table>
        <div class="fun-page-box">
          <page
            show-total
            :total="corpPageData.total"
            :page-size="corpPageData.size"
            :current="corpPageData.current"
            @on-change="corpPageChange"
          />
        </div>
      </div>
    </div>
    <Modal
      v-model="editcloseFlag"
      title="编辑安全技术交底"
      @on-ok="editSecurity"
      :mask-closable="false"
      width="80%"
      @on-cancel="editClose"
    >
      <div class="aa-item">
        <div class="label">分部分项工程名称：</div>
        <div>{{ editData.parentName }}</div>
        <!-- <Input
          v-model="editData.parentName"
          placeholder="输入授权管理员姓名"
          clearable
          style="width: 200px"
        /> -->
      </div>
      <div class="aa-item">
        <div class="label">交底表名称：</div>
        <div>{{ editData.contentName }}</div>
        <!-- <Input
          v-model="editData.contentName"
          placeholder="输入授权管理员姓名"
          clearable
          style="width: 200px"
        /> -->
      </div>
      <div class="aa-item">
        <div class="label">适用工种：</div>
        <el-select
          v-model="editData.workTypess"
          multiple
          filterable
          placeholder="请选择工种"
          width="500"
        >
          <el-option
            v-for="item in workTypeList"
            :key="item.workTypeValue"
            :label="item.workTypeName"
            :value="item.workTypeValue"
          >
          </el-option>
        </el-select>
        <!-- <el-cascader
          :options="workTypeList"
          style="width: 250px; margin-right: 14px"
          :props="props"
          collapse-tags
          clearable
          v-model="editData.workTypes"
        >
        </el-cascader> -->
      </div>
      <div style="width: 80%">
        <div class="label">交底表内容：</div>
        <!-- :disabled="!editSafeFlag" -->
        <Input
          v-model="editData.content"
          :autosize="true"
          class="tab-textarea"
          type="textarea"
          placeholder="安全交底表内容"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
// import { a } from "element-ui"
export default {
  name: "numberCard",
  props: ["projectInfo", "UserInfo", "ScreenWidth"],
  data() {
    return {
      props: { multiple: true, emitPath: false },
      corpWorkType: "",
      editSafeFlag: false,
      editData: {
        content: "",
        contentId: undefined,
        contentName: "",
        parentName: "",
        workTypes: [],
        workTypess: [],
      },
      content: "",
      gongzhong: "",
      editcloseFlag: false,
      corpPageData: {
        total: 0,
        size: 15,
        current: 1,
      },
      searchData: {
        keyword: undefined,
        categoryId: "",
      },
      corptCloumsList: [
        {
          title: "分部分项工程名称",
          key: "parentName",
          align: "center",
        },
        {
          title: "交底内容",
          key: "contentName",
          width: "220",
          align: "center",
        },
        // {
        //   title: "交底内容",
        //   slot: "content",
        //   align: "center",
        //   width: 400,
        // },
        {
          title: "工种",
          slot: "workTypes",
          align: "center",
        },
        {
          title: "表格",
          slot: "tableData",
          align: "center",
          width: 200,
        },
        // {
        //   title: "状态",
        //   slot: "status",
        //   align: "center",
        //   width: "100",
        // },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      corpDataList: [],
      corpTableLoading: false,
      projectDataList: [],
      projectTableLoading: false,
      projectInfo: {},
      UserInfo: {},
      projectId: undefined,
      dataList: [],
      workTypeList: [],
      categoriesData: []
    };
  },
  mounted() {
    this.projectId = this.projectInfo.id;
    console.log(this.projectId);
    this.getData();
    this.getWorkTypeList();
    this.getcategoriesData();
  },
  methods: {
    browse(contentId) {
      let downUrl;
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/project/disclose/contents/${contentId}/file`
      );
      window.open(downUrl, "_blank");
    },
    download(info) {
      this.$axios({
        method: "get",
        url: `${this.apiUrlCros}/sapi/project/disclose/contents/${info.contentId}/file`,
        responseType: "arraybuffer",
      }).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.download = `${info.contentName}.pdf`;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
      });
    },
    editSecurity() {
      let data = JSON.parse(JSON.stringify(this.editData));
      this.$axios({
        method: "post",
        url: `${this.apiUrlCros}/sapi/project/disclose/contents/${data.contentId}`,
        data: {
          creatorId: -1,
          workTypeValues: data.workTypess,
          content: data.content,
        },
      }).then((res) => {
        if (res.data.code == "200") {
          this.$Message.success("设置成功");
          this.getData();
          this.editClose();
        }
      });
    },
    editClose() {
      this.editData = {
        content: "",
        contentId: undefined,
        contentName: "",
        parentName: "",
        workTypes: [],
        workTypess: [],
      };
    },
    // 获取工种
    getWorkTypeList() {
      let data = {};
      this.$fetch("/sapi/workType/relate/getProjectRelates", {
        method: "get",
        data,
        params: {
          projectId: this.projectId,
        },
      }).then((res) => {
        console.log(res);
        if (res && res.code === "200") {
          this.workTypeList = res.data;
          console.log(this.workTypeList);
        } else {
          this.$Message.error("获取失败，请稍后再试");
        }
      });
    },
    getData() {
      this.corpTableLoading = true
      this.$axios({
        method: "get",
        url: `${this.apiUrlCros}/sapi/project/disclose/contents`,
        params: {
          projectId: this.projectId,
          keyword: this.searchData.keyword || "",
          categoryId: this.searchData.categoryId || "",
          pageNo: this.corpPageData.current,
          pageSize: this.corpPageData.size,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.code == "200") {
          this.corpPageData.total = res.data.data.totalNum;
          this.corpDataList = res.data.data.dataList;
          console.log(this.corpDataList)
        }
      }).finally(()=>{
        this.corpTableLoading = false
      })
    },
    getcategoriesData() {
      this.$axios({
        method: "get",
        url: `${this.apiUrlCros}/sapi/disclose/categories`,
        params: {
          // projectId: this.projectId,
          dataId: this.projectId,
          dataType: 'PROJECT',
        },
      }).then((res) => {
        console.log(res)
        if(res.data.code=="200") {
          this.categoriesData = res.data.data
          console.log(this.categoriesData)
        }
      });
    },
    editInfo(info) {
      Object.assign(this.editData, info);
      let workType = JSON.parse(JSON.stringify(info.workTypes))
      workType.forEach((item) => {
        this.editData.workTypess.push(item.workTypeValue);
      });
      this.editcloseFlag = true;
      // Object.assign(this.editData, info);
      // this.$axios({
      //   method: "get",
      //   url: `/sapi/project/disclose/contents/${info.contentId}`,
      // }).then((res) => {
      //   console.log(res);
      //   if (res.data.code == "200") {
      //     Object.assign(this.editData, res.data.data);
      //     res.data.data.workTypes.forEach((item) => {
      //       this.editData.workTypess.push(item.workTypeValue);
      //     });
      //     console.log(this.editData);
      //     this.editcloseFlag = true;
      //   }
      // });
    },
    // 监听企业列表页码
    corpPageChange(e) {
      this.corpPageData.current = e;
      // if(this.searchData.keyword!="") {
      //   this.corpPageData.current = 1
      // }
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.csb-item {
  display: flex;
  padding-left: 30px;
  margin-bottom: 20px;
  align-items: center;
  .label {
    width: 80px;
  }
}
.main {
  width: 100%;
  height: 100%;
  .fun {
    padding: 14px;
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      h2 {
        margin-right: 20px;
      }
    }
    .radio {
      margin: 15px 0 20px;
    }
  }
  .cont {
    padding: 0 14px;
    width: 100%;
  }
}
</style>