<template>
  <div class="full-content-box">
    <!-- currentBox为taskList是列表 taskDetail是详情 taskEdit是新增/编辑-->
    <div v-if="currentBox == 'taskList'">
      <div class="second-title">
        <div class="shu" />
        任务列表管理
      </div>
      <div class="c-btnbox">
        <div class="c-btnbox-item">
          <div class="mb-item">
            <div class="label">关键字：</div>
            <Input
              placeholder="任务名称/任务内容"
              style="width: 300px; margin-right: 14px"
              v-model="searchMainListData.keyword"
            />
          </div>
          <div class="c-btnbox-item" style="margin-bottom: 14px">
            <Button
              type="primary"
              style="margin-right: 14px"
              @click="handleMainListSearch"
              >搜索</Button
            >
            <Button
              @click="handleMainListSearch('reset')"
              style="margin-right: 14px"
              >重置</Button
            >
            <Button type="primary" @click="addTask('add')">新增任务</Button>
          </div>
        </div>
      </div>
      <div class="second-title">
        <div class="shu" />
        任务列表
      </div>
      <Table
        :loading="mainLoading"
        size="small"
        :columns="mainCloums"
        :data="mainList"
        :width="ScreenWidth - 350"
      >
        <template slot-scope="{ row }" slot="taskStatus">
          <div class="btnbox-flex">
            <div>
              {{
                row.taskStatus == "SAVE"
                  ? "已保存"
                  : row.taskStatus == "PUBLISH"
                  ? "已发布"
                  : row.taskStatus == "START"
                  ? "已开始"
                  : row.taskStatus == "END"
                  ? "已结束"
                  : " "
              }}
            </div>
          </div>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <span>
            <Button
              type="primary"
              style="margin-right: 8px"
              @click="toDetail(row)"
              >查看详情</Button
            >
            <Button
              type="primary"
              v-if="row.taskStatus == 'SAVE' || row.taskStatus == 'PUBLISH'"
              style="margin-right: 8px"
              @click="addTask('edit', row)"
              >编辑</Button
            >
            <!-- 只有草稿和已保存状态的任务，可操作【编辑】 -->
            <!-- 已结束任务不允许任何操作 -->
            <Button
              type="warning"
              @click="startStudy(row)"
              v-if="row.taskStatus == 'PUBLISH' || row.taskStatus == 'START'"
              style="margin-right: 8px"
              >开始培训</Button
            >
            <Button
              type="primary"
              style="margin-right: 8px"
              @click="toDelete(row)"
              >删除</Button
            >
            <!-- 任务状态： 1 SAVE-已保存、2 PUBLISH-已发布、3 START-已开始、4 END-已结束 -->
            <!-- 任务发布后，允许修改，修改后，需重新再发布任务。 -->
          </span>
        </template>
      </Table>
      <page
        show-total
        class="page-box"
        :page-size="mainSize"
        :total="mainTotal"
        :current="mainPage"
        @on-change="handleMainPageChange"
      />
    </div>
    <div v-if="currentBox == 'taskEdit'">
      <div class="second-title">
        <div class="shu" />
        新增/编辑任务
      </div>
      <div style="margin-top: 20px">
        <div class="aa-item">
          <div class="label bitian" style="width: 300px">
            企业：
            {{ corpName }}
          </div>

          <div class="label bitian">
            项目：
            {{ ProjectInfo.vprojectname }}
          </div>
        </div>
        <div class="aa-item">
          <div class="label bitian">开始时间：</div>
          <DatePicker
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            v-model="taskData.taskStartTime"
            style="width: 350px"
            placeholder="请选择开始时间"
          ></DatePicker>
        </div>
        <div class="aa-item">
          <div class="label bitian">任务名称：</div>
          <Input
            v-model="taskData.taskName"
            placeholder="请输入任务名称"
            clearable
            style="width: 350px"
          />
        </div>
        <div class="aa-item">
          <div class="label bitian">任务内容：</div>
          <Input
            type="textarea"
            show-word-limit
            v-model="taskData.taskDesc"
            placeholder="请输入任务内容"
            clearable
            style="width: 350px"
            :autosize="{ minRows: 3 }"
          />
        </div>
        <div class="aa-item">
          <div class="label bitian">任务周期：</div>
          <InputNumber
            :min="0"
            v-model="taskData.duration"
            placeholder="请输入任务周期"
            type="number"
            clearable
            style="width: 350px"
          />小时
        </div>
        <div class="aa-item">
          <div class="label bitian">及格分数：</div>
          <InputNumber
            :min="0"
            :max="100"
            v-model="taskData.taskPassSore"
            placeholder="请输入及格分数"
            type="number"
            clearable
            style="width: 350px"
          />
        </div>
        <div class="aa-item" style="align-items: flex-start !important">
          <div class="label bitian">关联视频：</div>

          <div>
            <div
              v-if="selectedVideoArrays.length !== 0"
              style="
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
              "
            >
              <Button type="primary" @click="toSelectVideoModel">添加</Button>
            </div>
            <div v-else>
              <Button type="primary" @click="toSelectVideoModel">添加</Button>
            </div>

            <Table
              v-if="selectedVideoArrays.length !== 0"
              size="small"
              :columns="taskVideoSelectCloums"
              :data="selectedVideoArrays"
              :width="ScreenWidth - 550"
            >
              <template slot-scope="{ row, index }" slot="videoSource">
                {{
                  row.videoSource == "PLATFORM"
                    ? "平台"
                    : row.videoSource == "CORP"
                    ? "企业"
                    : ""
                }}
              </template>
              <template slot-scope="{ row, index }" slot="action">
                <!-- @click="toVideoPreview(row)" -->
                <Button
                  type="primary"
                  style="margin-left: 20px"
                  @click="downloadVideo(row)"
                  >视频预览</Button
                >
              </template>
            </Table>
            <!-- <span
              style="margin-right: 20px"
              v-for="(item, index) in selectedVideoArrays"
              :key="index"
            >
              {{ item.videoTitle }}
              <br />
            </span> -->
          </div>
        </div>
        <div class="aa-item" style="align-items: flex-start !important">
          <div class="label bitian">关联考题：</div>
          <div>
            <!-- <div
              v-if="selectedTestArrays.length !== 0"
              style="
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
              "
            >
              <Button type="primary" @click="toSelectTestModel">添加</Button>
            </div>
            <div v-else>
              <Button type="primary" @click="toSelectTestModel">添加</Button>
            </div> -->

            <!-- <Table
              v-if="selectedTestArrays.length !== 0"
              size="small"
              :columns="taskTestSelectCloums"
              :data="selectedTestArrays"
              :width="ScreenWidth - 550"
            >
              <template slot-scope="{ row, index }" slot="questionAnswer">
                {{
                  row.questionAnswer == true
                    ? "正确"
                    : row.questionAnswer == false
                    ? "错误"
                    : ""
                }}
              </template>
              <template slot-scope="{ row, index }" slot="score">
                <Input
                  placeholder="请输入分数"
                  style="width: 100px; margin-left: 20px"
                  v-model="row.score"
                  @input="complete(row)"
                />
              </template>
            </Table> -->

            <div
              style="display: flex"
              v-if="
                selectedTestArrays.length !== 0 ||
                selectedVideoArrays.length !== 0
              "
            >
              <div class="mb-item" style="posiotion: relative">
                <Input
                  placeholder="请输入视频名称"
                  style="width: 300px; margin-right: 14px"
                  v-model="searchDueToVideo.videoTitle"
                />
              </div>
              <div class="c-btnbox-item" style="margin-bottom: 14px">
                <Button
                  type="primary"
                  style="margin-right: 14px"
                  @click="handleDueToVideoSearch"
                  >搜索</Button
                >
                <Button
                  style="margin-right: 14px"
                  @click="handleDueToVideoSearch('reset')"
                  >重置</Button
                >
              </div>
            </div>
            <Table
              v-if="selectedTestArrays.length !== 0"
              size="small"
              :columns="taskTestSelectCloums"
              :data="selectedTestArrays"
              :width="ScreenWidth - 550"
              max-height="600"
            >
              <template slot-scope="{ row, index }" slot="questionAnswer">
                {{
                  row.questionAnswer == true
                    ? "正确"
                    : row.questionAnswer == false
                    ? "错误"
                    : ""
                }}
              </template>
              <template slot-scope="{ row, index }" slot="score">
                <Input
                  placeholder="请输入分数"
                  style="width: 100px; margin-left: 20px"
                  v-model="row.score"
                  @input="complete(row)"
                />
              </template>
              <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" @click="deleteSingleTest(row)"
                  >删除</Button
                >
              </template>
            </Table>
          </div>

          <!-- <div>
            {{ selectedTestArrays }}
            <span
              style="margin-right: 20px"
              v-for="(item, index) in selectedTestArrays"
              :key="index"
            >
              {{ item.questionContent }}
              <Input
                placeholder="请输入分数"
                style="width: 100px; margin-left: 20px"
                v-model="item.score"
              />
              <br />
            </span>
          </div> -->
        </div>
        <div style="margin-top: 50px">
          <Button type="primary" @click="backList">取消</Button>
          <Button
            type="primary"
            style="margin-left: 20px"
            @click="submit"
            :disabled="disabled"
            >保存</Button
          >
          <Button
            type="primary"
            style="margin-left: 20px"
            @click="startPublish"
            v-if="taskData.taskStatus == 'SAVE'"
            >发布培训任务</Button
          >
        </div>
      </div>
    </div>
    <div v-if="currentBox == 'taskDetail'">
      <div class="second-title">
        <div class="shu" />
        任务详情
      </div>
      <div style="margin-top: 20px">
        <div class="aa-item">
          <div class="label bitian" style="width: 300px">
            企业：
            {{ corpName }}
          </div>

          <div class="label bitian">
            项目：
            {{ ProjectInfo.vprojectname }}
          </div>
        </div>
        <div class="aa-item">
          <div class="label bitian">开始时间：</div>
          <div>{{ taskDetailData.taskStartTime }}</div>
        </div>
        <div class="aa-item">
          <div class="label bitian">任务名称：</div>
          <div>{{ taskDetailData.taskName }}</div>
        </div>
        <div class="aa-item">
          <div class="label bitian">任务内容：</div>
          <div>{{ taskDetailData.taskDesc }}</div>
        </div>
        <div class="aa-item">
          <div class="label bitian">任务周期：</div>
          <div>{{ taskDetailData.duration }}</div>
          小时
        </div>
        <div class="aa-item">
          <div class="label bitian">及格分数：</div>
          <div>{{ taskDetailData.taskPassSore }}</div>
        </div>
      </div>
      <Tabs
        style="margin-top: 50px"
        @on-click="pickTab"
        type="card"
        v-model="tabFlag"
      >
        <TabPane label="视频清单" name="video"></TabPane>
        <TabPane label="试题查看" name="test"></TabPane>
        <TabPane label="考试结果" name="result"></TabPane>
      </Tabs>
      <Table
        v-if="tabFlag == 'video'"
        size="small"
        :columns="taskVideoCloums"
        :data="taskVideoList"
        :width="ScreenWidth - 350"
      >
        <template slot-scope="{ row, index }" slot="videoSource">
          {{
            row.videoSource == "PLATFORM"
              ? "平台"
              : row.videoSource == "CORP"
              ? "企业"
              : ""
          }}
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <!-- <Button
            type="primary"
            style="margin-left: 20px"
            @click="toVideoPreview(row)"
            >视频预览</Button
          > -->
          <Button
            type="primary"
            style="margin-left: 20px"
            @click="downloadVideo(row)"
            >视频预览</Button
          >
        </template>
      </Table>
      <page
        v-if="tabFlag == 'video'"
        show-total
        class="page-box"
        :page-size="taskVideoSize"
        :total="taskVideoTotal"
        :current="taskVideoPage"
        @on-change="handleTaskVideoPageChange"
      />
      <Table
        v-if="tabFlag == 'test'"
        size="small"
        :columns="taskTestCloums"
        :data="taskTestList"
      >
        <template slot-scope="{ row, index }" slot="questionAnswer">
          {{
            row.questionAnswer == true
              ? "正确"
              : row.questionAnswer == false
              ? "错误"
              : ""
          }}
        </template>
      </Table>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <page
          v-if="tabFlag == 'test'"
          show-total
          class="page-box"
          :page-size="taskTestSize"
          :total="taskTestTotal"
          :current="taskTestPage"
          @on-change="handleTaskTestPageChange"
        />
        <!-- <div
          v-if="tabFlag == 'test'"
          style="margin-top: 20px; margin-right: 20px"
        >
          总分<span style="margin-left: 10px">
            {{ totalScore }}
          </span>
        </div> -->
      </div>
      <div
        style="display: flex; justify-content: flex-end; margin-bottom: 10px"
        v-if="tabFlag == 'result'"
      >
        <Button type="primary" @click="exportResult">导出</Button>
      </div>
      <Table
        v-if="tabFlag == 'result'"
        size="small"
        :columns="taskResultCloums"
        :data="taskResultList"
      >
        <template slot-scope="{ row, index }" slot="passStatus">
          <span>
            {{ row.passStatus ? "是" : row.passStatus == false ? "否" : "" }}
          </span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <Button
            type="primary"
            style="margin-right: 8px"
            @click="toViewTestDetail(row)"
            >查看考试情况
          </Button>
        </template>
      </Table>

      <div style="display: flex; align-items: center">
        <page
          v-if="tabFlag == 'result'"
          show-total
          class="page-box"
          :page-size="taskResultSize"
          :total="taskResultTotal"
          :current="taskResultPage"
          @on-change="handleTaskResultPageChange"
        />
      </div>

      <div style="margin-top: 50px">
        <Button type="primary" @click="backList">返回</Button>
      </div>
    </div>
    <div v-if="currentBox == 'taskResultDetail'">
      <div style="margin: 20px 0">
        <Button type="primary" @click="backToTaskDetail">返回</Button>
      </div>
      <div>
        <div class="aa-item">
          <div class="label bitian" style="width: 300px">
            姓名：
            {{ recordDetails.username }}
          </div>

          <div class="label bitian" style="width: 300px">
            电话：
            {{ recordDetails.phoneNumber }}
          </div>
          <div class="label bitian" style="width: 300px">
            职务：
            {{ recordDetails.job }}
          </div>
        </div>
        <div class="aa-item">
          <div class="label bitian" style="width: 300px">
            单位：
            {{ recordDetails.unit }}
          </div>
          <div class="label bitian" style="width: 300px">
            成绩：
            {{ recordDetails.totalScore }}
          </div>
        </div>

        <Table
          size="small"
          :columns="recordDetailsCloums"
          :data="recordDetails.questions"
          :width="ScreenWidth - 350"
          style="margin-top: 20px"
        >
          <!-- <template slot-scope="{ row, index }" slot="answerStatus">
            {{
              row.answerStatus == true
                ? "是"
                : row.answerStatus == false
                ? "否"
                : ""
            }}
          </template> -->

          <template slot-scope="{ row, index }" slot="questionAnswer">
            {{
              row.questionAnswer
                ? "是"
                : row.questionAnswer == false
                ? "否"
                : ""
            }}
          </template>
          <template slot-scope="{ row, index }" slot="userAnswer">
            <span
              style="color: red"
              v-if="row.userAnswer !== row.questionAnswer"
            >
              {{ row.userAnswer ? "是" : row.userAnswer == false ? "否" : "" }}
            </span>
            <span v-else>
              {{ row.userAnswer ? "是" : row.userAnswer == false ? "否" : "" }}
            </span>
          </template>
        </Table>
      </div>
    </div>

    <!-- 弹窗  视频 -->
    <Modal
      v-model="videoModel"
      title="关联视频"
      @on-cancel="closeVideoModel"
      :mask-closable="false"
      class="videoModel"
    >
      <div class="c-btnbox">
        <div class="c-btnbox-item">
          <div class="mb-item" style="display: flex; align-items: center">
            <div class="label">关键字：</div>
            <Input
              placeholder="视频名称/分类"
              style="width: 200px"
              v-model="searchVideoListData.keyword"
            />
            <div class="label" style="margin-left: 20px">视频分类：</div>

            <Select
              @on-open-change="openChange"
              style="width: 200px; margin-right: 14px"
              v-model="searchVideoListData.categoryId"
              @on-change="toSelectChildren"
            >
              <Option
                v-for="item in categoriesList"
                :value="item.categoryId"
                :key="item.categoryId"
                >{{ item.categoryName }}</Option
              >
            </Select>

            <Select
              style="width: 200px; margin-right: 14px"
              v-model="searchVideoListData.categoryId2"
            >
              <Option
                v-for="item in categoriesList2"
                :value="item.categoryId"
                :key="item.categoryId"
                >{{ item.categoryName }}</Option
              >
            </Select>
            <Button
              type="primary"
              style="margin-left: 20px"
              @click="getVideoList"
              >搜索</Button
            >
            <Button @click="handleVideoListSearch" style="margin-left: 14px"
              >重置</Button
            >
          </div>
        </div>
      </div>
      <Table size="small" :columns="videoCloums" :data="videoListData">
        <template slot-scope="{ row, index }" slot="videoOriginUrl">
          <video
            :src="row.videoOriginUrl"
            controls
            width="200"
            height="100"
            controlslist="nodownload"
          ></video>
        </template>
        <template slot-scope="{ row, index }" slot="videoSource">
          {{
            row.videoSource == "PLATFORM"
              ? "平台"
              : row.videoSource == "CORP"
              ? "企业"
              : ""
          }}
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <span
            v-if="
              selectedVideoArrays.find((info) => info.videoId === row.videoId)
            "
            class="select"
            @click="cancelSelectVideo(row)"
            >取消选择</span
          >
          <span v-else class="select" @click="toSelectVideo(row)">选择</span>
        </template>
      </Table>
      <page
        show-total
        class="page-box"
        style="margin-top: 20px"
        :page-size="searchVideoListData.videoSize"
        :total="videoTotal"
        :current="searchVideoListData.videoPage"
        @on-change="handleVideoPageChange"
      />
    </Modal>

    <!-- 弹窗  试题 -->
    <!-- <Modal
      v-model="testModel"
      title="关联试题"
      @on-cancel="closeTestModel"
      :mask-closable="false"
      class="videoModel"
    >
      <div class="c-btnbox">
        <div class="c-btnbox-item">
          <div class="mb-item" style="display: flex; align-items: center">
            <div class="label">关键字：</div>
            <Input
              placeholder="试题名称"
              style="width: 300px"
              v-model="searchTestListData.keyword"
            />
            <Button
              type="primary"
              style="margin-left: 20px"
              @click="getTestList"
              >搜索</Button
            >
            <Button @click="handleTestListSearch" style="margin-left: 14px"
              >重置</Button
            >
          </div>
        </div>
      </div>
      <Table size="small" :columns="testCloums" :data="testListData">
        <template slot-scope="{ row, index }" slot="questionAnswer">
          {{
            row.questionAnswer == true
              ? "正确"
              : row.questionAnswer == false
              ? "错误"
              : ""
          }}
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <span
            v-if="
              selectedTestArrays.find(
                (info) => info.questionId === row.questionId
              )
            "
            class="select"
            @click="cancelSelectTest(row)"
            >取消选择</span
          >
          <span v-else class="select" @click="toSelectTest(row)">选择</span>
        </template>
      </Table>
      <page
        show-total
        class="page-box"
        style="margin-top: 20px"
        :page-size="searchTestListData.testSize"
        :total="testTotal"
        :current="searchTestListData.testPage"
        @on-change="handleTestPageChange"
      />
    </Modal> -->

    <!-- 弹窗  视频 -->
    <Modal
      v-model="studyModel"
      title="开始培训"
      @on-cancel="closeStudyModel"
      :mask-closable="false"
      class="videoModel"
    >
      <div style="text-align: center">
        <div v-for="(item, index) in playVideoList" :key="index">
          <div v-if="index == playIndex">
            共{{ playVideoList.length }}个视频,当前播放第{{ index + 1 }}个
          </div>
          <div>
            <!-- @timeupdate="timeupdate" -->
            <!-- 这个是  禁止快进的功能 -->
            <video
              ref="video"
              v-if="index == playIndex"
              autoplay
              :src="item"
              width="500"
              controls
              crossorigin="anonymous"
              @ended="onEnded(item, index)"
              
              controlslist="nodownload"
            ></video>
          </div>
        </div>

        <img v-if="qrcode" :src="qrcode" alt="" height="200" />
      </div>
    </Modal>

    <!-- 弹窗  任务视频预览 -->
    <!-- <Modal
      v-model="videoPreviewModel"
      title="视频预览"
      @on-cancel="closeVideoPreviewModel"
      :mask-closable="false"
      class="videoModel"
    >
      <div style="text-align: center">
        <video
          ref="video"
          autoplay
          :src="videoPreviewUrl"
          width="500"
          controls
          controlslist="nodownload"
        ></video>
      </div>
    </Modal> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "taskListTemp",
  data() {
    return {
      // playerOptions: {
      //   playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
      //   autoplay: false, //如果true,则自动播放
      //   muted: false, // 默认情况下将会消除任何音频。
      //   loop: false, // 循环播放
      //   preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
      //   language: "zh-CN",
      //   aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
      //   fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
      //   sources: [
      //     {
      //       // 播放资源和资源格式
      //       type: "video/mp4",
      //       src: "http://img.ksbbs.com/asset/Mon_1703/05cacb4e02f9d9e.mp4", //你的视频地址（必填）
      //     },
      //   ],
      //   poster: "../static/image/course-cover.jpeg", //视频封面图
      //   width: document.documentElement.clientWidth, // 默认视频全屏时的最大宽度
      //   notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      // },

      searchMainListData: {
        keyword: "",
      },
      mainList: [],
      mainCloums: [
        {
          title: "任务名称",
          key: "taskName",
          width: 100,
        },
        {
          title: "任务内容",
          key: "taskDesc",
          width: 140,
        },
        {
          title: "开始时间",
          key: "taskStartTime",
          width: 160,
        },
        {
          title: "任务周期(小时)",
          key: "duration",
          width: 120,
        },
        //  {
        //   title: "及格分数",
        //   key: "taskPassSore",
        //   width: 120,
        // },
        {
          title: "所在项目",
          key: "projectName",
          minWidth: 140,
        },
        {
          title: "所在企业",
          key: "corpName",
          width: 180,
        },
        {
          title: "任务状态",
          key: "taskStatus",
          slot: "taskStatus",
          width: 100,
        },
        {
          title: "操作",
          slot: "action",
          width: 380,
          align: "center",
          fixed: "right",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 10,
      mainTotal: 0,
      currentBox: "taskList",

      taskData: {
        projectId: this.ProjectInfo.id,
        taskId: 0,
        taskName: "",
        taskDesc: "",
        taskStartTime: undefined,
        duration: null,
        taskPassSore: null,
        taskVideos: [],
        taskQuestions: [],
      },
      corpName: "",

      //视频列表  相关
      videoModel: false,
      videoListData: [], //视频列表
      videoCloums: [
        {
          title: "视频",
          key: "videoOriginUrl",
          width: 200,
          slot: "videoOriginUrl",
          align: "center",
        },
        {
          title: "视频名称",
          align: "center",
          key: "videoTitle",
          width: 260,
        },
        {
          title: "视频分类",
          align: "center",
          key: "categoryName",
          width: 180,
        },
        {
          title: "视频时长(秒)",
          align: "center",
          key: "videoDuration",
          width: 110,
        },
        {
          title: "视频来源",
          align: "center",
          key: "videoSource",
          slot: "videoSource",
          width: 100,
        },
        {
          title: "操作",
          slot: "action",
          width: 100,
          align: "center",
          fixed: "right",
        },
      ],

      searchVideoListData: {
        keyword: "",
        pageNo: 1,
        pageSize: 5,
        categoryId: 0,
        categoryId2: undefined,
      },
      videoTotal: 0,

      selectedVideoArrays: [],
      videoArrayIds: [],

      //试题列表  相关
      testModel: false,
      // testListData: [], //试题列表
      testCloums: [
        {
          title: "试题名称",
          key: "questionContent",
          width: 700,
        },
        {
          title: "正确答案",
          key: "questionAnswer",
          slot: "questionAnswer",
          width: 100,
        },
        {
          title: "操作",
          slot: "action",
          width: 100,
          align: "center",
          fixed: "right",
        },
      ],
      // searchTestListData: {
      //   keyword: "",
      //   pageNo: 1,
      //   pageSize: 10,
      // },
      testTotal: 0,
      totalScore: 0,
      selectedTestArrays: [],
      selectedTestArrays1: [],

      testArrayIds: [],

      taskDetailData: {}, //任务详情
      tabFlag: "video",
      taskVideoCloums: [
        {
          title: "视频名称",
          align: "center",
          key: "videoTitle",
          // width: 350,
        },
        {
          title: "视频类型",
          align: "center",
          key: "categoryName",
          // width: 260,
        },
        {
          title: "视频简介",
          align: "center",
          key: "videoDesc",
          // width: 350,
        },

        {
          title: "视频时长(秒)",
          align: "center",
          key: "videoDuration",
          // width: 110,
        },
        {
          title: "视频来源",
          align: "center",
          key: "videoSource",
          slot: "videoSource",

          // width: 100,
        },
        {
          title: "操作",
          slot: "action",
          width: 200,
          align: "center",
          // fixed: "right",
        },
      ], //视频

      taskTestCloums: [
        {
          title: "视频名称",
          align: "center",
          key: "videoTitle",
          // width: 350,
        },
        {
          title: "试题名称",
          key: "questionContent",
          // width: 700,
          align: "center",
        },
        {
          title: "正确答案",
          key: "questionAnswer",
          slot: "questionAnswer",
          align: "center",

          // width: 100,
        },
        {
          title: "分数",
          key: "score",
          // width: 100,
          align: "center",
        },
      ],
      taskVideoSelectCloums: [
        {
          title: "视频名称",
          align: "center",
          key: "videoTitle",
          // width: 260,
        },
        {
          title: "视频类型",
          align: "center",
          key: "categoryName",
          // width: 100,
        },
        {
          title: "视频简介",
          align: "center",
          key: "videoDesc",
          // width: 200,
        },

        {
          title: "视频时长(秒)",
          align: "center",
          key: "videoDuration",
          // width: 100,
        },
        {
          title: "视频来源",
          align: "center",
          key: "videoSource",
          slot: "videoSource",
          // width: 100,
        },
        {
          title: "操作",
          slot: "action",
          // width: 200,
          align: "center",
          // fixed: "right",
        },
      ], //视频
      taskTestSelectCloums: [
        {
          title: "视频名称",
          key: "videoTitle",
          // width: 660,
          align: "center",
        },
        {
          title: "试题名称",
          key: "questionContent",
          // width: 660,
          align: "center",
        },
        {
          title: "正确答案",
          key: "questionAnswer",
          slot: "questionAnswer",
          // width: 100,
          align: "center",
        },
        {
          title: "分数",
          key: "score",
          slot: "score",
          // width: 200,
          align: "center",
          renderHeader: (h, param) => {
            return h("div", [
              h(
                "div",
                {
                  style: {
                    display: "inline-block",
                    // width: '100px',
                    textAlign: "center",
                    marginLeft: "20px",
                  },
                },
                `分数(总分：${this.selectedTestArrays1TotalScore})`
              ),
            ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          // width: 200,
          align: "center",
        },
      ],
      taskResultCloums: [
        {
          title: "姓名",
          key: "username",
          // width: 200,
        },
        {
          title: "电话",
          key: "phoneNumber",
          // width: 200,
        },
        {
          title: "身份证号",
          key: "idCardNumber",
          // width: 200,
        },
        {
          title: "单位",
          key: "unit",
          // width: 200,
        },
        {
          title: "职务",
          key: "job",
          // width: 200,
        },
        {
          title: "成绩",
          key: "totalScore",
          // width: 100,
        },
        {
          title: "总分",
          key: "totalQuestionScore",
          // width: 100,
        },
        {
          title: "是否通过",
          key: "passStatus",
          slot: "passStatus",
          // width: 100,
        },

        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ], //试题
      taskVideoList: [],
      playVideoList: [],

      taskVideoPage: 1,
      taskVideoSize: 10,
      taskVideoTotal: 0,

      taskTestList: [], //考试结果
      taskTestPage: 1,
      taskTestSize: 10,
      taskTestTotal: 0,

      taskResultList: [],
      taskResultPage: 1,
      taskResultSize: 10,
      taskResultTotal: 0,
      taskId: 0,

      studyModel: false, //开始培训 自动播放视频
      qrcode: "",
      playIndex: 0,

      currTime: null,
      maxTime: 0,

      // videoPreviewModel: false, // 视频预览
      videoPreviewUrl: "", // 视频预览URL

      disabled: false,

      parentId: 0,
      categoriesList: [],
      categoriesList2: [],

      searchDueToVideo: {
        //根据视频名称搜索
        videoTitle: "",
      },

      recordDetails: {
        username: "",
        phoneNumber: "",
        idCardNumber: "",
        unit: "",
        job: "",
        totalScore: null,
        totalQuestionScore: 0,
        questions: [],
      },

      recordDetailsCloums: [
        {
          type: "index",
          title: "序号",
          align: "center",
          // width: 350,
        },
        {
          title: "试题名称",
          align: "center",
          key: "questionContent",
          // width: 350,
        },
        // {
        //   title: "正确与否",
        //   align: "center",
        //   key: "answerStatus",
        //   slot: "answerStatus",

        //   // width: 260,
        // },
        {
          title: "分数",
          key: "score",
          // width: 100,
          align: "center",
        },
        {
          title: "正确答案",
          align: "center",
          key: "questionAnswer",
          slot: "questionAnswer",
          // width: 350,
        },

        {
          title: "考生答案",
          align: "center",
          key: "userAnswer",
          slot: "userAnswer",

          // width: 110,
        },
      ],

      selectedTestArrays1TotalScore: 0,
    };
  },
  props: ["ProjectInfo", "ScreenWidth", "Userinfo"],
  mounted() {
    let user = this.common.getStorage(this.CONSTANTS.USER_INFO);
    (this.corpName = user.vcorp_name), //企业名称
      this.handleMainListSearch();
  },

  methods: {
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      let data = {
        pageSize: this.mainSize,
        pageNo: this.mainPage,
        projectId: this.ProjectInfo.id,
        keyword: this.searchMainListData.keyword, //否	string	关键字
      };
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/tasks`,
        method: "get",
        params: data,
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.mainTotal = res.data.resultData.totalNum;
          this.mainList = res.data.resultData.dataList;
          this.mainLoading = false;
        } else {
          this.mainLoading = false;
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },

    // 主表搜索
    handleMainListSearch(type) {
      if (type == "reset") {
        this.searchMainListData = {
          keyword: "",
        };
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch();
    },

    // 任务视频修改页码
    handleTaskVideoPageChange(e) {
      this.taskVideoPage = e;
      this.getTaskVideo();
    },
    // 任务试题修改页码
    handleTaskTestPageChange(e) {
      this.taskTestPage = e;
      this.getTaskTest();
    },
    // 任务考试结果修改页码
    handleTaskResultPageChange(e) {
      this.taskResultPage = e;
      this.getTaskResult();
    },

    //新增/编辑 任务
    addTask(type, data) {
      this.currentBox = "taskEdit";
      this.selectedTestArrays1TotalScore = 0;

      if (type == "add") {
        this.taskData = {
          taskId: 0,
          projectId: this.ProjectInfo.id,
          taskName: "",
          taskDesc: "",
          taskStartTime: undefined,
          duration: null,
          taskPassSore: null,
          taskVideos: [],
          taskQuestions: [],
        };
        this.selectedVideoArrays = [];
        this.selectedTestArrays = [];
      } else if (type == "edit") {
        this.getTaskDetail(data, type);
      }
      this.searchDueToVideo.videoTitle = "";
    },
    toDetail(data) {
      this.selectedTestArrays1TotalScore = 0;

      this.currentBox = "taskDetail";
      this.tabFlag = "video";
      this.getTaskDetail(data, "detail");
      this.taskId = data.taskId;
    },
    // 删除任务
    toDelete(data) {
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/${data.taskId}/delete`,
        method: "post",
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.$Message.success("删除成功");
          this.handleMainListSearch();
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },

    // 获取详情
    getTaskDetail(data, type) {
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/${data.taskId}`,
        method: "get",
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.selectedTestArrays1TotalScore = 0;

          if (type == "edit") {
            this.taskData = res.data.resultData;
            this.taskId = data.taskId;
            this.selectedTestArrays = this.taskData.taskQuestions;
            this.selectedTestArrays1 = this.selectedTestArrays;
            this.selectedVideoArrays = this.taskData.taskVideos;
            console.log(this.selectedTestArrays);

            this.selectedTestArrays.forEach((item, index) => {
              this.selectedTestArrays1TotalScore =
                this.selectedTestArrays1TotalScore + Number(item.score);
            });
          } else if (type == "detail") {
            this.taskDetailData = res.data.resultData;
            this.taskVideoSize = 10;
            this.getTaskVideo(this.taskId);
          }
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    pickTab(e) {
      if (e == "video") {
        this.getTaskVideo();
      } else if (e == "test") {
        this.getTaskTest();
      } else if (e == "result") {
        this.getTaskResult();
      }
    },
    //获取任务视频 列表
    getTaskVideo() {
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/task/${this.taskId}/videos`,
        method: "get",
        params: { pageNo: this.taskVideoPage, pageSize: this.taskVideoSize },
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.taskVideoTotal = res.data.resultData.totalNum;
          this.taskVideoList = res.data.resultData.dataList;
          //培训播放视频的时候才执行
          if (this.studyModel) {
            this.playVideoList = [];
            this.taskVideoList.forEach((item, index) => {
              this.playVideoList = this.playVideoList.concat(
                item.videoOriginUrl
              );
            });
          }
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    //获取任务试题 列表
    getTaskTest() {
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/${this.taskId}/questions`,
        method: "get",
        params: { pageNo: this.taskTestPage, pageSize: this.taskTestSize },
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.taskTestTotal = res.data.resultData.totalNum;
          this.taskTestList = res.data.resultData.dataList;
          // this.totalScore = res.data.resultData.totalScore;
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    //获取任务考试结果 列表
    getTaskResult() {
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/${this.taskId}/learn/records`,
        method: "get",
        params: { pageNo: this.taskResultPage, pageSize: 10 },
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.taskResultTotal = res.data.resultData.totalNum;
          this.taskResultList = res.data.resultData.dataList;
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },

    submit() {
      this.disabled = true;
      let data = JSON.parse(JSON.stringify(this.taskData));
      data.taskStartTime = moment(data.taskStartTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data.questions = this.selectedTestArrays1;
      data.questions.map((item, index) => {
        delete item.questionAnswer;
        delete item.questionContent;
        delete item._index;
        delete item._rowKey;
      });
      let a = [];

      this.selectedVideoArrays.forEach((item) => {
        a = a.concat(item.videoId);
      });
      data.videoIds = a;

      delete data.taskQuestions;
      delete data.taskVideos;

      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/${data.taskId}`,
        method: "post",
        data,
        headers: { accessToken: token.accessToken },
      })
        .then((res) => {
          if (res.data.resultStatus) {
            this.disabled = false;
            this.$Message.success(data.taskId == 0 ? "新增成功" : "编辑成功");
            this.mainLoading = false;
            if (data.taskId !== 0) {
              //编辑完执行
              this.getTaskDetail(data, "edit");
            } else if (res.data.resultData !== 0) {
              //新增完执行
              let data = {
                taskId: res.data.resultData,
              };
              this.getTaskDetail(data, "edit");
            }
          } else {
            this.disabled = false;
            this.mainLoading = false;
            this.$Message.error(res.data.errorMessage);
            if (res.data.errorCode == "001004") {
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((err) => {
          this.disabled = false;
        });
    },
    //发布
    startPublish() {
      // let token = this.common.getAuth();
      // this.$axios({
      //   url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/${this.taskId}/publish`,
      //   method: "post",
      //   headers: { accessToken: token.accessToken },
      // }).then((res) => {
      //   if (res.data.resultStatus) {
      //     this.$Message.success("发布成功");
      //     let data = {
      //       taskId: this.taskId,
      //     };
      //     this.getTaskDetail(data, "edit");
      //   } else {
      //     this.$Message.error(res.data.errorMessage);
      //     if (res.data.errorCode == "001004") {
      //       this.$router.push({ name: "login" });
      //     }
      //   }
      // });
      this.changeStatus("PUBLISH");
    },
    //开始培训
    startStudy(data) {
      this.taskId = data.taskId;
      this.getTaskVideo();
      this.taskVideoSize = 9999;
      this.studyModel = true;
      this.changeStatus("START");
    },
    changeStatus(taskStatus) {
      let token = this.common.getAuth();
      let data = {
        taskStatus: taskStatus,
      };

      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/${this.taskId}/status`,
        method: "post",
        data: data,
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.status) {
          if (taskStatus == "PUBLISH") {
            this.$Message.success("发布成功");
            let data = {
              taskId: this.taskId,
            };
            this.getTaskDetail(data, "edit");
          } else {
            this.getMainList();
          }
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    //考试二维码
    openQrcode() {
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/learn/qrcode`,
        method: "get",
        responseType: "blob",
        params: { taskId: this.taskId },
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.status) {
          let blob = new Blob([res.data]); // 返回的文件流数据
          let url = window.URL.createObjectURL(blob); // 将他转化为路径
          this.qrcode = url; // 将转换出来的路径赋值给变量，
          this.changeStatus("END");
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    //返回列表页面
    backList() {
      this.currentBox = "taskList";
      this.getMainList();
    },

    //视频
    toSelectVideoModel() {
      this.videoModel = true;
      this.parentId = 0;
      this.getVideoList();
    },
    toSelectChildren(e) {
      if (e) {
        this.parentId = e;
        this.getCategories2();
      }
    },
    openChange(e) {
      if (e) {
        this.getCategories();
      }
    },
    getCategories() {
      let data = {
        pageSize: 9999,
        pageNo: 1,
        parentId: 0,
        pageStatus: false, //否
      };
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/categories`,
        method: "get",
        params: data,
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.categoriesList = res.data.resultData;
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    getCategories2() {
      let data = {
        pageSize: 9999,
        pageNo: 1,
        parentId: this.parentId,
        pageStatus: false, //否
      };
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/categories`,
        method: "get",
        params: data,
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.categoriesList2 = res.data.resultData;
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },

    //重置
    handleVideoListSearch() {
      this.categoriesList = [];
      this.categoriesList2 = [];
      this.searchVideoListData.keyword = "";

      this.searchVideoListData.categoryId = 0;
      this.searchVideoListData.categoryId2 = undefined;

      this.parentId = 0;

      this.getVideoList();
    },
    //视频预览
    // toVideoPreview(data) {
    //   this.videoPreviewModel = true;
    //   this.videoPreviewUrl = data.videoOriginUrl;
    // },
    //关闭 视频播放的弹窗
    // closeVideoPreviewModel() {
    //   this.videoPreviewModel = false;
    //   this.videoPreviewUrl = "";
    // },
    getVideoList() {
      let params = JSON.parse(JSON.stringify(this.searchVideoListData));
      if (params.categoryId2) {
        params.categoryId = params.categoryId2;
      }
      delete params.categoryId2;
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/videos`,
        method: "get",
        params: params,
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.videoTotal = res.data.resultData.totalNum;
          this.videoListData = res.data.resultData.dataList;
          this.mainLoading = false;
        } else {
          this.mainLoading = false;
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    toSelectVideo(data) {
      this.selectedVideoArrays = this.selectedVideoArrays.concat(data);

      //调用接口查询选中的视频下的  试题
      this.getVideoQuestions(data.videoId);
    },
    getVideoQuestions(data) {
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/video/${data}/questions`,
        method: "get",
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.selectedTestArrays = this.selectedTestArrays.concat(
            res.data.resultData
          );
          this.selectedTestArrays1 = JSON.parse(
            JSON.stringify(this.selectedTestArrays)
          );
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    deleteSingleTest(data) {
      console.log(data);
      let a = [];
      this.selectedTestArrays1TotalScore = 0;
      this.selectedTestArrays1.forEach((item, index) => {
        if (item.questionId == data.questionId) {
          a = JSON.parse(JSON.stringify(this.selectedTestArrays1));
          a.splice(index, 1);
        }
      });
      this.selectedTestArrays = a;
      this.selectedTestArrays1 = this.selectedTestArrays;

      this.selectedTestArrays1.forEach((item, index) => {
        if (item.score) {
          this.selectedTestArrays1TotalScore =
            this.selectedTestArrays1TotalScore + Number(item.score);
        }
      });
    },
    cancelSelectVideo(data) {
      let a = [];
      this.selectedVideoArrays.forEach((item, index) => {
        if (item.videoId == data.videoId) {
          a = JSON.parse(JSON.stringify(this.selectedVideoArrays));
          a.splice(index, 1);
        }
      });
      this.selectedVideoArrays = a;

      if (this.selectedVideoArrays.length == 0) {
        this.searchDueToVideo.videoTitle = "";
      }

      // 跟视频相关的试题都清除
      // this.selectedTestArrays.forEach((item, index) => {
      //   if (item.videoId == data.videoId) {
      //     console.log(1);

      //   }
      // });

      this.selectedTestArrays = this.selectedTestArrays1.filter((num) => {
        return num.videoId !== data.videoId;
      });
      this.selectedTestArrays1 = this.selectedTestArrays;
      this.selectedTestArrays1TotalScore = 0;

      this.selectedTestArrays1.forEach((item, index) => {
        if (item.score) {
          this.selectedTestArrays1TotalScore =
            this.selectedTestArrays1TotalScore + Number(item.score);
        }
      });
    },
    closeVideoModel() {
      this.videoModel = false;
    },
    // 修改页码
    handleVideoPageChange(e) {
      this.searchVideoListData.pageNo = e;
      this.getVideoList();
    },

    //试题
    // toSelectTestModel() {
    //   this.testModel = true;
    //   this.getTestList();
    // },
    // getTestList() {
    //   let token = this.common.getAuth();
    //   this.$axios({
    //     url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/questions`,
    //     method: "get",
    //     params: this.searchTestListData,
    //     headers: { accessToken: token.accessToken },
    //   }).then((res) => {
    //     if (res.status == "200") {
    //       this.testListData = res.data.resultData.dataList;
    //       this.testTotal = res.data.resultData.totalNum;
    //     } else {
    //       this.$Message.info("查询失败，请稍后重试");
    //     }
    //   });
    // },
    //重置
    // handleTestListSearch() {
    //   this.searchTestListData.keyword = "";
    //   this.getTestList();
    // },
    // toSelectTest(data) {
    //   this.selectedTestArrays = this.selectedTestArrays.concat(data);
    //   this.selectedTestArrays.map((item) => {
    //     if (!item.score) {
    //       item.score = null;
    //     }
    //   });
    // },
    // cancelSelectTest(data) {
    //   let a = [];
    //   this.selectedTestArrays.forEach((item, index) => {
    //     if (item.questionId == data.questionId) {
    //       a = JSON.parse(JSON.stringify(this.selectedTestArrays));
    //       a.splice(index, 1);
    //     }
    //   });
    //   this.selectedTestArrays = a;
    // },
    // closeTestModel() {
    //   this.testModel = false;
    // },
    //关闭 视频播放的弹窗
    closeStudyModel() {
      this.studyModel = false;
      this.playVideoList = [];
      this.qrcode = "";
      this.playIndex = 0;
    },
    // 修改页码
    // handleTestPageChange(e) {
    //   this.searchTestListData.pageNo = e;
    //   this.getTestList();
    // },
    //导出
    exportResult() {
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/${this.taskId}/learn/record/export`,
        method: "get",
        responseType: "blob", // 返回文件的类型
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.status) {
          const link = document.createElement("a");
          const blob = new Blob([res.data]);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", `考试结果.` + "xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$Message.success("导出成功");
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    //结束播放
    onEnded(data, index) {
      this.playIndex++;
      if (index == this.playVideoList.length - 1) {
        this.openQrcode();
      }
    },
    //禁止快进
    timeupdate(e) {
      if (e.srcElement.currentTime - this.currTime > 1) {
        e.srcElement.currentTime =
          this.currTime > this.maxTime ? this.currTime : this.maxTime;
        console.log("快进了");
      }
      this.currTime = e.srcElement.currentTime;
      this.maxTime =
        this.currTime > this.maxTime ? this.currTime : this.maxTime;
    },
    complete(e) {
      this.selectedTestArrays1.map((item, index) => {
        if (e.questionId == item.questionId) {
          item.score = e.score;
        }
      });

      this.selectedTestArrays1TotalScore = 0;
      this.selectedTestArrays1.forEach((item, index) => {
        if (item.score) {
          this.selectedTestArrays1TotalScore =
            this.selectedTestArrays1TotalScore + Number(item.score);
        }
      });

      // this.selectedTestArrays=this.selectedTestArrays1
    },
    //下载视频
    downloadVideo(e) {
      window.open(e.videoOriginUrl, "_blank");
    },
    //根据视频名称搜索
    handleDueToVideoSearch(type) {
      let data = JSON.parse(JSON.stringify(this.selectedTestArrays));
      if (type == "reset") {
        this.searchDueToVideo.videoTitle = "";
        this.selectedTestArrays = this.selectedTestArrays1;
      } else {
        let list = data.filter(
          (item) =>
            item.videoTitle
              .toString()
              .indexOf(this.searchDueToVideo.videoTitle) >= 0
        );
        this.selectedTestArrays = list;
      }
    },
    toViewTestDetail(e) {
      // 查看考试详情页面
      this.currentBox = "taskResultDetail";
      let data = {
        taskLearnRecordId: e.taskLearnRecordId,
      };
      let token = this.common.getAuth();
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/special/task/learn/record/detail`,
        method: "get",
        params: data,
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.recordDetails = res.data.resultData;
        } else {
          this.$Message.error(res.data.errorMessage);
          if (res.data.errorCode == "001004") {
            this.$router.push({ name: "login" });
          }
        }
      });
    },
    //返回任务详情页面
    backToTaskDetail() {
      this.currentBox = "taskDetail";
    },
  },
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
.p-box {
  .part {
    .p-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 7px;
    }
    .p-content {
      padding-left: 14px;
      display: flex;
      flex-wrap: wrap;
      .pc-part {
        flex: 1;
        .pc-item {
          margin-bottom: 18px;
          .label {
            margin-bottom: 4px;
          }
          .l-bitian {
            position: relative;
          }
          .l-bitian::before {
            content: "*";
            position: absolute;
            left: -7px;
            top: 0px;
            color: red;
          }
          img {
            width: 120px;
            height: auto;
          }
        }
      }
    }
  }
}
/deep/ .ivu-modal {
  width: 1000px !important;
}
.select {
  color: #2d8cf0;
  cursor: pointer;
}
/deep/ .ivu-modal-footer .ivu-btn-primary {
  display: none;
}
</style>
