<template>
    <div class="counts">
    <!-- <div class="counts-part">
      <div class="cp-session">
        <div class="cps-item">
          <div class="left">

          </div>
          <div class="right"></div>
        </div>
      </div>
    </div> -->
    <div class="count-part-box" style="width: 460px;">
      <div class="count-part">
        <div class="head">
          <div class="title">项目人数</div>
          <div class="small-label">
            <!-- <Tag color="purple">月</Tag> -->
            <!-- <Tag color="green">周</Tag> -->
            <!-- <Tag color="blue">日</Tag> -->
          </div>
        </div>
        <div class="content content-flex">
          <div class="cf-box">
            <div id="chartPie" class="pie-wrap"></div>
            <div class="c-right-content">
              <div class="crc" style="margin-top:20px;">
                <div class="crc-left" style="background: #19be6b;">
                  <img src="@assets/images/renshuyilu.png" alt="">
                </div>
                <div class="crc-right">
                  <div class="crc-info">
                    <span style="font-size:24px;color: #19be6b;">{{statData.dutyCompeteInterTaskCount}}</span>
                    <span style="font-size:10px;margin-left:4px;">人</span>
                  </div>
                  <div class="crc-label">完成"入场安全须知"任务包(在岗)</div>
                </div>
              </div>
              <div class="crc">
                <div class="crc-left" style="background: #ed4014;">
                  <img src="@assets/images/renshuweilu.png" alt="">
                </div>
                <div class="crc-right">
                  <div class="crc-info">
                    <span style="font-size:24px;color: #ed4014;">{{statData.isRemovedCompeteInterTaskCount}}</span>
                    <span style="font-size:10px;margin-left:4px;">人</span>
                  </div>
                  <div class="crc-label">完成"入场安全须知"任务包(离岗)</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="c-foot">
            <div class="txt">项目培训人次</div>
            <div class="num">{{statData.totalMemberCount}} 人</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="count-part-box" style="width: 460px;">
      <div class="count-part">
        <div class="head">
          <div class="title">每日新增人数</div>
          <div class="small-label">
            <!-- <Tag color="purple">月</Tag> -->
            <!-- <Tag color="green">周</Tag> -->
            <!-- <Tag color="blue">日</Tag> -->
          </div>
        </div>
        <div class="content">
          <div class="" style="display:flex;align-items:center;">
            <div id="chartLine" class="line-wrap"></div>
            <div class="line-content-right">
              <div class="lcr-item">
                <div class="info" style="color: #1890ff;">
                  {{todayNew}} <span style="color:#515a6e;">人</span>
                </div>
                <div class="small-title">
                  今日新增
                </div>
              </div>
              <!-- <div class="lcr-item">
                <div class="info" style="color: #52c41a;">
                  113 <span style="color:#515a6e;">人</span>
                </div>
                <div class="small-title">
                  今日完成入场任务
                </div>
              </div> -->
            </div>
          </div>
          <div class="c-foot">
            <div class="txt">过去一周新增人数</div>
            <div class="num">{{newTotal}} 人</div>
          </div>
        </div>
      </div>
    </div>
    <div class="count-part-box" style="width: 460px;" v-if="checkFlag">
      <div class="count-part">
        <div class="head">
          <div class="title">每日考勤人数</div>
          <div class="small-label">
            <!-- <Tag color="purple">月</Tag> -->
            <!-- <Tag color="green">周</Tag> -->
            <!-- <Tag color="blue">日</Tag> -->
          </div>
        </div>
        <div class="content">
          <div class="" style="display:flex;align-items:center;">
            <div id="clockInChartLine" class="line-wrap"></div>
            <div class="line-content-right">
              <div class="lcr-item">
                <div class="info" style="color: #1890ff;">
                  {{todayClockIn}} <span style="color:#515a6e;">人</span>
                </div>
                <div class="small-title">
                  今日出勤
                </div>
              </div>
              <!-- <div class="lcr-item">
                <div class="info" style="color: #52c41a;">
                  113 <span style="color:#515a6e;">人</span>
                </div>
                <div class="small-title">
                  今日完成入场任务
                </div>
              </div> -->
            </div>
          </div>
          <div class="c-foot">
            <div class="txt">过去一周考勤人数</div>
            <div class="num">{{clockInTotal}} 人</div>
          </div>
        </div>
      </div>
    </div>
    <div class="count-part-box" style="width: 460px;">
      <div class="count-part">
        <div class="head">
          <div class="title">“入场安全须知”完成率</div>
          <div class="small-label">
            <!-- <Tag color="purple">月</Tag> -->
            <!-- <Tag color="green">周</Tag> -->
            <!-- <Tag color="blue">日</Tag> -->
          </div>
        </div>
        <div class="content">
          <div class="c-label" style="margin-bottom: 8px;margin-left: 14px;">{{projectPer}}%</div>
          <Tooltip :content="'任务完成率' + projectPer + '%'" style="width: 100%">
            <Progress :percent="projectPer" :stroke-width="50" status="active" hide-info />
          </Tooltip>
          <div class="info-box">
            <div class="ibb">
              <div class="ibb-label">在岗完成任务人数</div>
              <div class="ibb-info">
                <!-- <span>{{statData.dutyCompeteAllTaskCount}}</span> -->
                <span>{{statData.dutyCompeteInterTaskCount}}</span>
                <Icon type="md-arrow-dropup" style="color: #ed4014;" />
              </div>
            </div>
            <!-- <div class="ibb">
              <div class="ibb-label">日同比</div>
              <div class="ibb-info">
                <span>5.5%</span>
                <Icon type="md-arrow-dropdown" style="color: #19be6b;" />
              </div>
            </div> -->
          </div>
          <div class="c-foot">
            <div class="txt">总参与培训人次(在岗)</div>
            <div class="num">{{statData.dutyCount}} 人</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Card>
      <div class="second-title">
        <div class="shu" />项目统计信息
      </div>
      <div class="statisticsBox">
        <div class="sb-item">
          <div id="chartPie" class="pie-wrap"></div>
        </div>
        <div class="sb-item">
          <div id="chartLine" class="line-wrap"></div>
        </div>
        <div class="sb-item">
          <div id="banPie" class="ban-wrap"></div>
          <div id="banPie2" class="ban-wrap"></div>
        </div>
        <div class="sb-item">
          <div id="sample" class="line-wrap"></div>
        </div>
      </div>
    </Card> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: 'my_count',
  props: {
    projectId: Number,
    icorpId: Number,
    numOne: Number,
    numTwo: Number,
    numThree: Number,
  },
  data() {
    return {
      chartPie: null,
      chartLine: null,
      clockInChartLine: null,
      chartBanPie: null,
      chartBanPie2: null,
      chartGauge: null,
      statData: {},
      todayNew: 0,
      newTotal: 0,
      projectPer: 0,
      clockInTotal: 0,
      todayClockIn: 0,
      checkFlag: true
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.drawPieChart();
    //   this.drawLineChart();
    //   // this.drawBanPieChart();
    //   // this.drawBanPieChart2();
    //   // this.drawGaugeChart()
    // });
  },
  watch:{
    projectId(newVal, oldVal){
      this.getStatData(newVal)
    },
    icorpId(newVal, oldVal){
      if(newVal == 153){
        this.checkFlag = false
      }
    }
  },
  methods: {
    drawPieChart() {
      let option = {

        title: {
        text: `项目培训总人数：${this.numOne}人`,
        left:'center',
        top:'47%',
        // padding:[24,0],
        textStyle:{
          color:'#515a6e',
          fontSize: 12,
          align:'center'
        }
      },
        tooltip: {
          //提示框组件。
          trigger: "item", //'item' 数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
          formatter: "{a} <br/>{b} : {c} ({d}%)", //{a}（系列名称），{b}（数据项名称），{c}（数值）, {d}（百分比）
        },
        //         legend: {
        //     orient: 'vertical',
        //     left: 10,
        //     data: ['20-25岁', '26-30岁', '31-35岁', '36-40岁', '41岁以上']
        // },
        color:['#2db7f5','#ff9900'],
        grid: {
          top: "0%",
          left: '0%',
          right: '0%',
          bottom: '0%'
          },
        series: [
          {
            name: "人数占比",
            type: "pie",
            radius: ["45%", "68%"], //饼图的半径
            center: ["50%", "50%"], //饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。
            data: [
              { value: this.numTwo, name: `在岗${this.numTwo}人` },
              { value: this.numThree, name: `离岗${this.numThree}人` },
            ],
            itemStyle: {
              //图形样式。
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            labelLine: {
              length: "5",
              length2: "10"
            }
          },
        ],
      };
      this.chartPie = echarts.init(document.getElementById("chartPie"));
      this.chartPie.setOption(option);
    },
    drawLineChart() {
      let list = this.statData.weeklyAdditionalCountMap
      let xlist = []
      let dlist = []
      let total = 0
      for( let key in list){
        let keyArr = key.split('-')
        xlist.push(keyArr[1] + '-' + keyArr[2])
        dlist.push(list[key])
        total += list[key]
      }
      this.newTotal = total
      this.todayNew = dlist[6]
      this.chartLine = echarts.init(
        document.getElementById("chartLine"),
        "shine"
      ); // 基于准备好的dom，初始化echarts实例
      let option = {
        title: {
          show: false
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "3%",
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
          },
        xAxis: {
          type: "category",
          data: xlist,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            formatter: "{value}", // 使用字符串模板，模板变量为刻度默认标签 {value}
          },
          splitLine: {
                show: false
            }
        },
        series: [
          {
            name: "新增人数",
            type: "line",
            data: dlist,
            color: ["#2db7f5","#19be6b"]
          },
          // {
          //   name: "当日完成入场任务人数",
          //   type: "line",
          //   data: [4, 2, 1, 0, 14, 40, 10],
          //   color: ["#19be6b"]
          // }
        ],
      };
      // 使用刚指定的配置项和数据显示图表
      this.chartLine.setOption(option);
    },
    drawLineChart2() {
      let list = this.statData.weeklyClockInCountMap
      // console.log(list)
      let xlist = []
      let dlist = []
      let total = 0
      for( let key in list){
        let keyArr = key.split('-')
        xlist.push(keyArr[1] + '-' + keyArr[2])
        dlist.push(list[key])
        total += list[key]
      }
      this.clockInTotal = total
      this.todayClockIn = dlist[6]
      this.clockInChartLine = echarts.init(
        document.getElementById("clockInChartLine"),
        "shine"
      ); // 基于准备好的dom，初始化echarts实例
      let option = {
        title: {
          show: false
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "3%",
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
          },
        xAxis: {
          type: "category",
          data: xlist,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            formatter: "{value}", // 使用字符串模板，模板变量为刻度默认标签 {value}
          },
          splitLine: {
                show: false
            }
        },
        series: [
          {
            name: "新增人数",
            type: "line",
            data: dlist,
            color: ["#2db7f5","#19be6b"]
          },
          // {
          //   name: "当日完成入场任务人数",
          //   type: "line",
          //   data: [4, 2, 1, 0, 14, 40, 10],
          //   color: ["#19be6b"]
          // }
        ],
      };
      // 使用刚指定的配置项和数据显示图表
      this.clockInChartLine.setOption(option);
    },
    drawBanPieChart() {
      this.chartBanPie = echarts.init(
        document.getElementById("banPie"),
        "shine"
      ); // 基于准备好的dom，初始化echarts实例
      let current = 25; // 当前用量
      let all = 100; // 总量
      let option = {

        grid: {
          top: "3%",
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
          },
        series: [
          {
            type: "pie",
            label: {
              show: false,
            },
            center: ["50%", "50%"],
            radius: ["50%", "70%"],
            startAngle: 180,
            data: [
              {
                name: "用量",
                value: current,
                itemStyle: {
                  color: "#ff9900",
                },
              },
              {
                name: "rest", // 实际显示部分是总量-用量
                value: all - current,
                itemStyle: {
                  color: "skyblue",
                },
              },
              {
                name: "bottom",
                value: all,
                itemStyle: {
                  color: "transparent",
                },
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表
      this.chartBanPie.setOption(option);
    },
    drawBanPieChart2() {
      this.chartBanPie2 = echarts.init(
        document.getElementById("banPie2"),
        "shine"
      ); // 基于准备好的dom，初始化echarts实例
      let current = 70; // 当前用量
      let all = 100; // 总量
      let option = {
        series: [
          {
            type: "pie",
            label: {
              show: false,
            },
            center: ["50%", "50%"],
            radius: ["50%", "70%"],
            startAngle: 180,
            data: [
              {
                name: "用量",
                value: current,
                itemStyle: {
                  color: "#ff9900",
                },
              },
              {
                name: "rest", // 实际显示部分是总量-用量
                value: all - current,
                itemStyle: {
                  color: "skyblue",
                },
              },
              {
                name: "bottom",
                value: all,
                itemStyle: {
                  color: "transparent",
                },
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表
      this.chartBanPie2.setOption(option);
    },
    drawGaugeChart(){

      this.chartGauge = echarts.init(
        document.getElementById("sample"),
        "shine"
      ); // 基于准备好的dom，初始化echarts实例
      let option = {
        tooltip: {
            formatter: '{a} <br/>{b} : {c}%'
        },
        toolbox: {
            feature: {
                restore: {},
                saveAsImage: {}
            }
        },
        series: [
            {
                name: '业务指标',
                type: 'gauge',
                detail: {formatter: '{value}%'},
                data: [{value: 50, name: '完成率'}]
            }
        ]
      }
      this.chartGauge.setOption(option);
    },
    getStatData(id){
      // cropid == 153
      this.$axios.get(`${this.apiUrlCros}/sapi/project/getProjectStats/${id}`)
      // this.$fetch(`/sapi/project/getProjectStats/${id}`, {
      //   method: "get",
      //   data,
      // })
      .then(res => {
        if (res.data.code == "200") {
          this.statData = res.data.data
          let val = res.data.data
          let num = Number((val.dutyCompeteInterTaskCount / val.dutyCount * 100).toFixed(0))
          this.projectPer = isNaN( num ) ? 0 : num
          if(this.statData.dutyCount < 1 && this.statData.isRemovedCount <1){
            this.statData.dutyCount = 1
          }
          this.$nextTick(() => {
            this.drawPieChart();
            this.drawLineChart();
            this.drawLineChart2();
            // this.drawBanPieChart();
            // this.drawBanPieChart2();
            // this.drawGaugeChart()
          });

        } else {
          this.$Message.error("获取项目统计数据失败");
        }
      }).catch(err => {
        this.$Message.error(err.message);
      })
    }
  },
};
</script>
<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.content-box-flex-son{
  display: flex;
  flex-direction: column;
  .cbfs-item{
    position: relative;
  }
}
.counts {
  color: #515a6e;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  // height: 100%;
  .second-title {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    .shu {
      width: 5px;
      height: 14px;
      background: #409eff;
      margin-right: 6px;
    }
  }
  .statisticsBox {
    display: flex;
    flex-wrap: wrap;
    .sb-item {
      padding: 20px;
      width: 50%;
      display: flex;
    }
  }
}
.pie-wrap {
  width: 63%;
  height: 250px;
}
.line-wrap {
  width: 70%;
  height: 250px;
}
.ban-wrap {
  width: 100%;
  height: 150px;
}
// .count-part-box:nth-child(odd){
//   padding-right: 8px;
// }
// .count-part-box:nth-child(even){
//   padding-left: 8px;
// }
.count-part-box{
  // padding: 8px;
  padding-right: 8px;
  width: 50%;
.count-part{
  background: #fff;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 16px;
  .head{
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8eaec;
    font-size: 16px;
  }
  .content{
    padding: 12px;
    .c-label{
      font-size: 30px;
    }
    .info-box{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 16px;
      margin-bottom: 24px;
      .ibb{
        display: flex;
        align-items: center;
        margin-right: 8px;
        .ibb-label{
          margin-right: 4px;
        }
        .ibb-info{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .c-foot{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #e8eaec;
      padding-top: 8px;
    }
    .line-content-right{
      flex: 1;
      .lcr-item:not(:last-child){
        margin-bottom: 8px;
      }
      .lcr-item{
        display: flex;
        flex-direction: column;
        padding-left: 24px;
        .info{
          font-size: 28px;
          span{
            font-size: 12px;
          }
        }
        .small-title{
          font-size: 12px;
          color: #808695;
          margin-top: -8px;
        }
      }
    }
  }
  .content-flex{
    display: flex;
    flex-direction: column;
    .cf-box{
      display: flex;
    }
    .c-right-content{
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .crc{
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .crc-left{
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          img{
            width: 75%;
            height: auto;
          }
        }
        .crc-right{
          .crc-label{
            font-size: 12px;
            color: #808695;
            margin-top: -8px;
          }
          .crc-info{

          }
        }
      }
    }
  }
}
}
// 鼠标移入效果
@keyframes myCardHover {
  0% {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  }
  100% {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  }
}
.count-part:hover {
  animation: myCardHover 0.0001s linear;
  animation-fill-mode: both;
}
</style>
