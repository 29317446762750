<template>
  <div class="full-content-box-row">
    <div class="left">
      <Spin fix v-if="teamListLoading">
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>Loading</div>
      </Spin>
      <div class="content">
        <div class="left-title">
          <div class="shu"></div>
          班组列表
        </div>
        <div class="search-box">
          <div class="s-item">
            <!-- <div class="lable">班组名称</div> -->
            <Input
              placeholder="输入搜索班组名称"
              style="width: 300px"
              search
              enter-button
            />
          </div>
        </div>
        <div class="team-list-box">
          <div class="tlb-content" v-if="teamList.length > 0">
            <div
              class="e-item"
              :class="[fitem.pickFlag ? 'e-item-active' : '']"
              v-for="(fitem, index) in teamList"
              :key="index"
              @click="pickTeam(index)"
            >
              <div class="label">{{ fitem.teamName }}</div>
              <Icon type="ios-arrow-forward" />
            </div>
          </div>
          <div v-else style="margin-top: 5px; padding-left: 5px" class>
            无班组信息。
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="second-title">
        <div class="shu" />
        保险管理
      </div>
      <div class="c-btnbox">
        <div class="c-btnbox-item">
          <div class="mb-item">
            <div class="label">工程名称：</div>
            <Input
              v-model="searchMainListData.projectName"
              placeholder="请输入工程名称"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <div class="mb-item">
            <div class="label">施工单位：</div>
            <Input
              v-model="searchMainListData.constructionUnit"
              placeholder="请输入施工单位"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <div class="mb-item">
            <div class="label">投保单位：</div>
            <Input
              v-model="searchMainListData.insuredUnit"
              placeholder="请输入投保单位"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <div class="mb-item">
            <div class="label">投保单号：</div>
            <Input
              v-model="searchMainListData.insuredNo"
              placeholder="请输入投保单号"
              style="width: 200px; margin-right: 14px"
            />
          </div>
        </div>
        <div class="c-btnbox-item">
          <div class="mb-item">
            <div class="label">签发机构：</div>
            <Input
              v-model="searchMainListData.insuranceAgency"
              placeholder="请输入签发机构"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <div class="mb-item">
            <div class="label">保险种类：</div>
            <Select
              v-model="searchMainListData.insuranceType"
              style="width: 200px; margin-right: 14px"
            >
              <Option
                v-for="item in insuranceStatusList"
                :value="item.dictValue"
                :key="item.dictValue"
                >{{ item.dictLabel }}</Option
              >
            </Select>
          </div>
          <div class="mb-item">
            <div class="label">是否记名：</div>
            <Select
              v-model="searchMainListData.registered"
              style="width: 200px; margin-right: 14px"
            >
              <Option
                v-for="item in registeredStatusList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="tcb-item tcb-item-spac" style="margin-bottom: 14px">
          <div>
            <Button
              type="primary"
              @click="handleMainListSearch"
              style="margin-right: 14px"
              >搜索</Button
            >
            <Button @click="handleMainListSearch('reset')">重置</Button>
          </div>
          <Button type="info" @click="addInsurance">新增保险</Button>
        </div>
        <div class="tcb-item">
          <Alert show-icon
            >黄色提示为保险有效期低于30天，红色提示为已过期保险信息。</Alert
          >
        </div>
        <Table
          highlight-row
          ref="mainTable"
          :columns="mainCloums"
          :data="mainList"
          @on-current-change="handleMainListPick"
          :loading="mainLoading"
          :width="ScreenWidth - 670"
          size="small"
          :row-class-name="handleMainTableClass"
        >
          <template slot-scope="{ row, index }" slot="registered">
            <div class>{{ row.registered == "0" ? "不记名" : "记名" }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="isRemoved">
            <div class>{{ row.isRemoved ? "失效" : "正常" }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="action">
            <Button
              size="small"
              type="info"
              style="margin-right: 8px"
              @click="toRectifyAttachment(row)"
              >查看附件</Button
            >
            <Button
              size="small"
              type="primary"
              style="margin-right: 8px"
              @click="editInsurance(row)"
              >编辑</Button
            >
            <!-- <Button size="small" type="warning" @click="removeInsurance(row)"
              >移除</Button
            > -->
            <Button size="small" type="warning" v-if="!row.isRemoved" @click="setInvalid(row)"
              >设置失效</Button
            >
            <Button size="small" type="warning" v-else @click="setInvalid(row)"
              >启用</Button
            >
          </template>
        </Table>
        <div class="fun-page-box">
          <Button @click="handleClearMain" style="margin-right: 14px"
            >清除列表选中</Button
          >
          <page
            show-total
            :total="mainTotal"
            :page-size="mainSize"
            @on-change="handleMainPageChange"
            :current="mainPage"
          />
        </div>
        <Divider />
        <!-- <Tabs type="card" @on-click="pickTab" v-model="tabFlag">
          <TabPane
            v-for="(item, index) in tabList"
            :key="index"
            :label="item.tabLabel"
            :name="item.tabName"
          ></TabPane>
        </Tabs> -->
        <!-- 子表-班组档案 -->
        <!-- <div class="tab-content-box" v-if="tabFlag == 'files'">
          <div class="tab-nullbox" v-if="!mainListPickFlag">
            <div class="nulltips">
              {{ tabDataNull ? "暂无数据" : "请先选择保险信息" }}
            </div>
          </div>
          <div v-else>
            <div v-if="currentMainData.registered == 1">
              <div class="tcb-item">
                <Button
                  type="info"
                  style="margin-right: 14px"
                  @click="toDownTemp"
                  >人员信息模板下载</Button
                >
                <Upload
                  action="/"
                  :name="'file'"
                  :multiple="false"
                  :before-upload="onBefore"
                  :accept="Accept"
                  :format="Format"
                >
                  <Button type="primary" icon="ios-cloud-upload-outline"
                    >导入花名册</Button
                  >
                </Upload>
              </div>
              <div class="tcb-item">
                <div class="tcbi-item">
                  <div class="label">姓名：</div>
                  <Input
                    v-model="searchSubListData.name"
                    placeholder="请输入搜索人员姓名"
                    style="width: 200px"
                  />
                </div>
                <div class="tcbi-item">
                  <div class="label">身份证：</div>
                  <Input
                    v-model="searchSubListData.idcard"
                    placeholder="请输入搜索人员身份证号"
                    style="width: 200px"
                  />
                </div>
              </div>
              <div class="tcb-item tcb-item-spac" style="margin-bottom: 14px">
                <div>
                  <Button
                    type="primary"
                    style="margin-right: 14px"
                    @click="handleSearchSubFilesList"
                    >搜索</Button
                  >
                  <Button @click="handleSearchSubFilesList('reset')"
                    >重置</Button
                  >
                </div>

                <Button type="info" @click="addInsurancePeople"
                  >新增保险人员</Button
                >
              </div>
              <Table
                :columns="subFilesCloums"
                :data="subFilesList"
                :loading="subFilesLoading"
                :width="ScreenWidth - 670"
                size="small"
              >
                <template slot-scope="{ row, index }" slot="sex">
                  <div class>{{ row.sex == "2" ? "女" : "男" }}</div>
                </template>
                <template slot-scope="{ row, index }" slot="faceidImage">
                  <div class="head-img" @click="showQR(row.faceidImage)">
                    <img v-if="row.faceidImage" :src="row.faceidImage" alt />
                    <img
                      v-else
                      src="../../../assets/images/test_head_icon.png"
                      alt
                    />
                  </div>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <Button
                    size="small"
                    type="primary"
                    style="margin-right: 8px"
                    @click="editInsurancePeople(row)"
                    >编辑</Button
                  >
                  <Button
                    size="small"
                    type="warning"
                    @click="removeInsurancePeople(row)"
                    >移除</Button
                  >
                </template>
              </Table>
              <page
                show-total
                :total="subFilesTotal"
                :page-size="subFilesSize"
                :current="subFilesPage"
                @on-change="handleSubFilesPageChange"
                style="margin-top: 14px"
              />
            </div>
            <div v-else>该保险为不记名团体保险</div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 新增、编辑保险 -->
    <Modal
      v-model="editInsuranceFlag"
      title="编辑保险信息"
      @on-ok="toAddInsurance"
      @on-cancel="toAddInsurance('close')"
      :loading="editInsuranceNext"
      :mask-closable="false"
      width="70"
    >
      <div class="aa-warp-box" style="display: flex; flex-wrap: wrap">
        <div class="awb-item">
          <div class="aa-item">
            <div class="label bitian">施工单位：</div>
            <Input
              v-model="newInsuranceData.constructionUnit"
              placeholder="输入施工单位"
              clearable
              style="width: 300px"
            />
          </div>
          <div class="aa-item">
            <div class="label bitian">投保单位：</div>
            <Input
              v-model="newInsuranceData.insuredUnit"
              placeholder="输入投保单位"
              clearable
              style="width: 300px"
            />
          </div>
          <div class="aa-item">
            <div class="label bitian">投保单号：</div>
            <Input
              v-model="newInsuranceData.insuredNo"
              placeholder="输入投保单号"
              clearable
              style="width: 300px"
            />
          </div>
          <!-- <div class="aa-item">
            <div class="label bitian">受益人：</div>
            <Input
              v-model="newInsuranceData.beneficiary"
              placeholder="输入受益人"
              clearable
              style="width: 300px"
            />
          </div> -->
          <div class="aa-item">
            <div class="label">被保险人数：</div>
            <Input
              v-model="newInsuranceData.insuredCount"
              placeholder="输入被保险人数"
              clearable
              type="number"
              style="width: 300px"
            />
          </div>
          <div class="aa-item">
            <div class="label bitian">合同生效日期：</div>
            <DatePicker
              v-model="newInsuranceData.effectiveDate"
              type="date"
              placeholder="选择合同生效日期"
              style="width: 300px"
            ></DatePicker>
          </div>
          <div class="aa-item">
            <div class="label bitian">合同满期日期：</div>
            <DatePicker
              v-model="newInsuranceData.expirationDate"
              type="date"
              placeholder="选择合同满期日期"
              style="width: 300px"
              @input="expirationDateChange($event)"
            ></DatePicker>
          </div>
          <div style="color:#ffcb6b;" v-show="noOverdue">{{noOverdueTxt}}</div>
          <div style="color:red;" v-show="overdue">{{overdueTxt}}</div>
        </div>
        <div class="awb-item">
          <div class="aa-item">
            <div class="label">合同成立日期：</div>
            <DatePicker
              v-model="newInsuranceData.establishmentDate"
              type="date"
              placeholder="选择合同成立日期"
              style="width: 300px"
            ></DatePicker>
          </div>
          <div class="aa-item">
            <div class="label">保险费合计：</div>
            <Input
              v-model="newInsuranceData.totalPremium"
              placeholder="输入保险费合计"
              clearable
              type="number"
              style="width: 300px"
            />
          </div>
          <div class="aa-item">
            <div class="label">交费方式：</div>
            <Input
              v-model="newInsuranceData.paymentMethod"
              placeholder="输入交费方式"
              clearable
              style="width: 300px"
            />
          </div>
          <div class="aa-item">
            <div class="label">签发机构：</div>
            <Input
              v-model="newInsuranceData.insuranceAgency"
              placeholder="输入签发机构"
              clearable
              style="width: 300px"
            />
          </div>
          <div class="aa-item">
            <div class="label">保险种类:</div>
            <Select
              v-model="newInsuranceData.insuranceType"
              multiple
              style="width: 300px"
            >
              <Option
                v-for="item in insuranceStatusList"
                :value="item.dictValue"
                :key="item.dictValue"
                >{{ item.dictLabel }}</Option
              >
            </Select>
          </div>
          <div class="aa-item">
            <div class="label bitian">是否记名:</div>
            <Select v-model="newInsuranceData.registered" style="width: 300px">
              <Option
                v-for="item in registeredStatusList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>
        </div>
      </div>
      <div class="aa-item">
        <div class="label">上传附件</div>
        <!-- v-if="currentUserInfo.dg_key == 1" -->
        <Upload
          action="/"
          :name="'file'"
          :multiple="false"
          :before-upload="onBeforePdf"
          :accept="AcceptPdf"
          :format="FormatPdf"
        >
          <Button icon="ios-cloud-upload-outline">{{
            newInsuranceData.enclosure ? "点击更换附件" : "点击上传附件"
          }}</Button>
        </Upload>
      </div>
    </Modal>
    <!-- 编辑、新增保险人员 -->
    <Modal
      v-model="editPeopleFlag"
      title="编辑保险人员"
      :mask-closable="false"
      @on-ok="toEditPeople"
      @on-cancel="toEditPeople('close')"
      :loading="editPeopleNext"
    >
      <div class="aa-item">
        <div class="label bitian">姓名：</div>
        <Input
          v-model="newInsurancePeopleData.name"
          placeholder="输入姓名"
          clearable
          style="width: 300px"
        />
      </div>
      <div class="aa-item">
        <div class="label bitian">身份证号码：</div>
        <Input
          v-model="newInsurancePeopleData.idNumber"
          placeholder="输入身份证号码"
          clearable
          style="width: 300px"
        />
      </div>
      <div class="aa-item">
        <div class="label bitian">性别:</div>
        <Select v-model="newInsurancePeopleData.sex" style="width: 300px">
          <Option
            v-for="item in sexList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
      </div>
      <div class="aa-item">
        <div class="label bitian">出生日期：</div>
        <DatePicker
          v-model="newInsurancePeopleData.birthday"
          type="date"
          placeholder="选择出生日期"
          style="width: 300px"
        ></DatePicker>
      </div>
      <div class="aa-item">
        <div class="label bitian">保险费：</div>
        <Input
          v-model="newInsurancePeopleData.premium"
          placeholder="输入保险费"
          clearable
          type="number"
          style="width: 300px"
        />
      </div>
      <div class="aa-item">
        <div class="label bitian">保险金额：</div>
        <Input
          v-model="newInsurancePeopleData.insuranceAmount"
          placeholder="输入保险金额"
          clearable
          type="number"
          style="width: 300px"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "insuranceByGroup",
  data() {
    return {
      AcceptPdf: ".pdf", //上传文件格式限制
      FormatPdf: [".pdf"], //上传文件格式限制
      teamListLoading: false,
      teamList: [],
      currentTeamData: {},
      currentTeamId: "",
      mainCloums: [
        // {
        //   title: "企业名称",
        //   key: "corpName",
        //   width: 100,
        // },
        // {
        //   title: "班组名称",
        //   key: "teamName",
        //   width: 100,
        // },
        {
          title: "险种名称",
          key: "insuranceTypeName",
          minWidth: 120,
        },
        {
          title: "投保单位",
          key: "insuredUnit",
          minWidth: 100,
        },
        {
          title: "投保单号",
          key: "insuredNo",
          minWidth: 100,
        },
        // {
        //   title: "被保险人受益人",
        //   key: "beneficiary",
        //   minWidth: 120,
        // },
        {
          title: "被保险人数",
          key: "insuredCount",
          width: 100,
        },
        {
          title: "合同生效日期",
          key: "effectiveDate",
          width: 120,
        },
        {
          title: "合同满期日期",
          key: "expirationDate",
          width: 120,
        },
        {
          title: "合同成立日期",
          key: "establishmentDate",
          width: 120,
        },
        {
          title: "保险费合计",
          key: "totalPremium",
          width: 100,
        },
        {
          title: "交费方式",
          key: "paymentMethod",
          width: 100,
        },
        {
          title: "签发机构",
          key: "insuranceAgency",
          width: 100,
        },
        {
          title: "工程名称",
          key: "projectName",
          minWidth: 100,
        },
        {
          title: "施工单位",
          key: "constructionUnit",
          minWidth: 100,
        },
        {
          title: "是否记名",
          slot: "registered",
          width: 100,
        },
        {
          title: "是否失效",
          slot: "isRemoved",
          width: 100,
        },
        // {
        //   title: "附件地址",
        //   key: "enclosure",
        // },
        {
          title: "操作",
          slot: "action",
          fixed: "right",
          align: "center",
          width: 240,
        },
      ],
      mainList: [],
      mainPage: 1,
      mainTotal: 0,
      mainSize: 10,
      mainLoading: false,
      searchMainListData: {
        projectName: "", //	否	string	工程名称
        constructionUnit: "", //	否	string	施工单位
        insuredUnit: "", //	否	string	投保单位
        insuredNo: "", //	否	string	投保单号
        insuranceAgency: "", //	否	string	签发机构
        insuranceType: "", //	否	int	险种
        registered: "", // 是否记名 0否 1是
      },
      mainListPickFlag: false,
      tabFlag: "files",
      tabList: [
        {
          tabLabel: "保险人员",
          tabName: "files",
        },
      ],
      tabDataNull: false,
      insuranceStatusList: [],
      subFilesList: [],
      subFilesCloums: [
        { title: "姓名", key: "name", width: 100 },
        { title: "证件号码", key: "idNumber", minWidth: 180 },
        { title: "出生日期", key: "birthday", width: 120 },
        { title: "性别", slot: "sex", width: 100 },
        // { title: "险种名称", key: "insuranceTypeName", minWidth: 180 },
        { title: "保险费", key: "premium", width: 100 },
        { title: "保险金额", key: "insuranceAmount", width: 100 },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
          fixed: "right",
        },
      ],
      subFilesLoading: false,
      subFilesPage: 1,
      subFilesSize: 10,
      subFilesTotal: 0,
      registeredStatusList: [
        {
          value: 0,
          label: "不记名",
        },
        {
          value: 1,
          label: "记名",
        },
      ],
      sexList: [
        {
          value: 0,
          label: "男",
        },
        {
          value: 1,
          label: "女",
        },
      ],
      currentMainData: {},
      editInsuranceFlag: false,
      editInsuranceNext: true,
      newInsuranceData: {
        corpId: "", //	是	long	企业ID
        projectId: "", //	是	long	项目ID
        corpName: "", //	是	string	企业名称
        teamId: "", //	是	long	班组ID
        teamName: "", //	是	string	班组名称
        projectName: "", //	是	string	工程名称
        creater: "", //	是	string	创建人

        constructionUnit: "", //	是	string	施工单位
        insuredUnit: "", //	是	string	投保单位
        insuredNo: "", //	是	string	投保单号
        // beneficiary: "", //	是	string	被保险人/受益人
        insuredCount: "", //	是	int	被保险人数
        effectiveDate: "", //	是	string	合同生效日期
        expirationDate: "", //	是	string	合同满期日期
        establishmentDate: "", //	是	string	合同成立日期
        totalPremium: "", //	是	double	保险费合计
        paymentMethod: "", //	是	string	交费方式
        insuranceAgency: "", //	是	string	签发机构
        insuranceType: [], //	是	int	险种
        registered: "", //	是	int	是否记名: 0否，1是
        enclosure: "", //	是	string	附件地址
      },
      upLoadFileLoading: false,
      funFlag: "",
      editPeopleFlag: false,
      editPeopleNext: true,
      newInsurancePeopleData: {
        insuranceId: "",
        insuranceType: "",
        creater: "",

        name: "",
        idNumber: "",
        birthday: "",
        sex: "",
        premium: "",
        insuranceAmount: "",
      },
      Accept: ".xls,.xlsx", //上传文件格式限制
      Format: [".xls", ".xlsx"], //上传文件格式限制
      noOverdue: false, //没过期
      overdue: false, //过期显示
      noOverdueTxt: '',
      overdueTxt: '',
    };
  },
  props: ["ProjectInfo", "ScreenWidth", "Userinfo"],
  created() {},
  mounted() {
    this.getTeamList();
    this.getInsuranceStatusList();
  },
  filters: {},
  watch: {},
  methods: {
    getTeamList() {
      this.teamListLoading = true;
      this.mainLoading = true;
      // this.teamList = [];
      this.mainList = [];
      this.mainPage = 1;
      this.mainTotal = 0;
      let data = {
        pageSize: "1000",
        projectId: this.ProjectInfo.id,
      };
      this.$fetch(`/sapi/team/getTeamsByProjectIdOrName`, {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          // this.handleFilesTeamList(res.data)
          let list = res.data;
          if (list.length > 0) {
            list.forEach((val) => {
              val.pickFlag = false;
            });
            list[0].pickFlag = true;
            this.teamList = list;
            this.teamListLoading = false;
            this.currentTeamData = list[0];
            this.currentTeamId = list[0].id;
            this.handleMainListSearch("reset");
          } else {
            this.teamListLoading = false;
            this.mainLoading = false;
          }
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    pickTeam(row) {
      let list = this.teamList;
      this.currentTeamData = list[row];
      this.currentTeamId = list[row].id;
      list.forEach((val, index) => {
        if (row == index) {
          val.pickFlag = true;
        } else {
          val.pickFlag = false;
        }
      });
      this.handleClearMain("reset");
      this.handleMainListSearch("reset");
    },
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      let data = {
        pageNum: this.mainPage, //	是	int	页数
        pageSize: this.mainSize, //	是	int	条数
        corpId: this.Userinfo.icorpid, //	否	long	企业ID
        teamId: this.currentTeamId, //	否	long	班组ID
        projectName: this.searchMainListData.projectName, //	否	string	工程名称
        constructionUnit: this.searchMainListData.constructionUnit, //	否	string	施工单位
        insuredUnit: this.searchMainListData.insuredUnit, //	否	string	投保单位
        insuredNo: this.searchMainListData.insuredNo, //	否	string	投保单号
        insuranceAgency: this.searchMainListData.insuranceAgency, //	否	string	insuranceAgency
        insuranceType: this.searchMainListData.insuranceType, //	否	int	险种
        registered: this.searchMainListData.registered, //	是否记名
      };
      this.$fetch("/osapi/insurance/getList", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          this.mainList = res.resList;
          this.mainTotal = res.total;
          this.mainLoading = false;
        } else {
          this.mainLoading = false;
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 主表搜索
    handleMainListSearch(type) {
      this.handleClearMain();
      if (type == "reset") {
        this.searchMainListData = {
          projectName: "",
          constructionUnit: "",
          insuredUnit: "",
          insuredNo: "",
          insuranceAgency: "",
          insuranceType: "",
          registered: "",
        };
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 选中主表
    handleMainListPick(currentRow, oldCurrentRow) {
      if (currentRow) {
        this.currentMainData = currentRow;
        this.mainListPickFlag = true;
        this.handleSearchSubFilesList("reset");
      }
    },
    // 清除主表选择
    handleClearMain() {
      this.$refs.mainTable.clearCurrentRow();
      this.mainListPickFlag = false;
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch();
    },
    // 子表tab选择
    pickTab(e) {},
    // 子表搜索
    handleSearchSubFilesList(type) {
      if (type == "reset") {
        this.searchSubListData = {
          name: "",
          phone: "",
          idcard: "",
          status: "",
        };
        this.subFilesPage = 1;
      }
      this.getSubListFiles();
    },
    handleSubFilesPageChange(e) {
      this.subFilesPage = e;
      this.handleSearchSubFilesList();
    },
    getSubListFiles() {
      this.subFilesLoading = true;
      let data = {
        pageNum: this.subFilesPage,
        pageSize: this.subFilesSize,
        // teamId: this.currentMainData.id,
        // isRemoved: this.searchSubListData.status,
        // phone: this.searchSubListData.phone,
        // vidcard: this.searchSubListData.idcard,
        // realname: this.searchSubListData.name,
        insuranceId: this.currentMainData.id, //	否	long	保险ID
        insuranceType: "", //	否	int	险种
        name: this.searchSubListData.name, //	否	string	姓名
        idNumber: this.searchSubListData.idcard, //	否	string	证件号码
      };
      this.$fetch(`/osapi/insuredlist/getInsuredList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.subFilesTotal = res.total;
          this.subFilesList = res.resList;
          this.subFilesLoading = false;
        } else {
          this.subFilesLoading = false;
          this.$Message.error("获取保险人员列表失败");
        }
      });
    },
    getInsuranceStatusList() {
      let data = {};
      this.$fetch(`/osapi/insurance/getInsuranceTypeList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          let list = res.data;
          list.forEach((val) => {
            val.dictValue = parseInt(val.dictValue);
          });
          this.insuranceStatusList = list;
        } else {
          this.$Message.error("获险种列表失败");
        }
      });
    },
    addInsurance() {
      this.newInsuranceData = {
        corpId: "", //	是	long	企业ID
        projectId: "",
        corpName: "", //	是	string	企业名称
        teamId: "", //	是	long	班组ID
        teamName: "", //	是	string	班组名称
        projectName: "", //	是	string	工程名称
        creater: "", //	是	string	创建人

        constructionUnit: "", //	是	string	施工单位
        insuredUnit: "", //	是	string	投保单位
        insuredNo: "", //	是	string	投保单号
        // beneficiary: "", //	是	string	被保险人/受益人
        insuredCount: "", //	是	int	被保险人数
        effectiveDate: "", //	是	string	合同生效日期
        expirationDate: "", //	是	string	合同满期日期
        establishmentDate: "", //	是	string	合同成立日期
        totalPremium: "", //	是	double	保险费合计
        paymentMethod: "", //	是	string	交费方式
        insuranceAgency: "", //	是	string	签发机构
        insuranceType: [], //	是	int	险种
        registered: "", //	是	int	是否记名: 0否，1是
        enclosure: "", //	是	string	附件地址
      };
      this.funFlag = "add";
      this.upLoadFileLoading = false;
      this.editInsuranceFlag = true;
    },
    editInsurance(val) {
      val.insuranceType = val.insuranceType.split(",")
      this.newInsuranceData = val;
      this.funFlag = "edit";
      // console.log(this.newInsuranceData)
      // console.log(this.newInsuranceData.insuranceType, typeof this.newInsuranceData.insuranceType)
      this.upLoadFileLoading = false;
      this.editInsuranceFlag = true;
    },

    addInsurancePeople() {
      this.newInsurancePeopleData = {
        insuranceId: "",
        insuranceType: "",
        creater: "",
        name: "",
        idNumber: "",
        birthday: "",
        sex: "",
        premium: "",
        insuranceAmount: "",
      };
      this.funFlag = "add";
      this.editPeopleFlag = true;
    },
    editInsurancePeople(val) {
      this.newInsurancePeopleData = val;
      this.funFlag = "edit";
      this.editPeopleFlag = true;
    },
    toAddInsurance(type) {
      if (type == "close") {
        this.editInsuranceFlag = false;
        this.getMainList()
        return false;
      }
      let ndata = this.newInsuranceData;
      if (this.upLoadFileLoading) {
        this.$Message.info("文件上传中，请稍后...");
        this.editInsuranceNext = false;
        setTimeout(() => {
          this.editInsuranceNext = true;
        }, 500);
        return false;
      }
      if (
        ndata.constructionUnit == "" ||
        ndata.insuredUnit == "" ||
        ndata.insuredNo == "" ||
        ndata.effectiveDate == "" ||
        ndata.expirationDate == "" ||
        ndata.registered < 0
      ) {
        this.$Message.info("请填写完整资料");
        this.editInsuranceNext = false;
        setTimeout(() => {
          // this.editInsuranceNext = true;
        }, 500);
        return false;
      }
      let data = {
        corpId: this.Userinfo.icorpid, //	是	long	企业ID
        projectId: this.ProjectInfo.id, //	是	long	企业ID
        corpName: this.Userinfo.vcorp_name, //	是	string	企业名称
        teamId: this.currentTeamId, //	是	long	班组ID
        teamName: this.currentTeamData.teamName, //	是	string	班组名称
        projectName: this.ProjectInfo.vprojectname, //	是	string	工程名称
        creater: this.Userinfo.vname, //	是	string	创建人

        constructionUnit: ndata.constructionUnit, //	是	string	施工单位
        insuredUnit: ndata.insuredUnit, //	是	string	投保单位
        insuredNo: ndata.insuredNo, //	是	string	投保单号
        beneficiary: ndata.beneficiary, //	是	string	被保险人/受益人
        insuredCount: ndata.insuredCount, //	是	int	被保险人数
        effectiveDate: this.common.formatDate(ndata.effectiveDate), //	是	string	合同生效日期
        expirationDate: this.common.formatDate(ndata.expirationDate), //	是	string	合同满期日期
        establishmentDate: this.common.formatDate(ndata.establishmentDate), //	是	string	合同成立日期
        totalPremium: ndata.totalPremium, //	是	double	保险费合计
        paymentMethod: ndata.paymentMethod, //	是	string	交费方式
        insuranceAgency: ndata.insuranceAgency, //	是	string	签发机构
        insuranceType:
          ndata.insuranceType.length > 0 ? ndata.insuranceType.join(",") : "", //	是	int	险种
        registered: ndata.registered, //	是	int	是否记名: 0否，1是
        enclosure: ndata.enclosure, //	是	string	附件地址
      };
      let apiUrl = "";
      if (this.funFlag == "add") {
        apiUrl = "osapi/insurance/addInsurance";
      }
      if (this.funFlag == "edit") {
        apiUrl = "osapi/insurance/editInsurance";
        data.id = ndata.id;
      }
      // console.log(data.establishmentDate)
      if(!data.establishmentDate){
        delete data.establishmentDate
      }
      if(!data.insuranceType){
        delete data.insuranceType
      }
      console.log(data)
      this.$fetch(apiUrl, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code == "200") {
          this.$Message.success("操作成功");
          this.handleClearMain("reset");
          this.handleMainListSearch("reset");
          this.editInsuranceFlag = false;
        } else {
          this.editInsuranceNext = false;
          setTimeout(() => {
            this.editInsuranceNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    onBeforePdf(files) {
      let that = this;
      const msg = this.$Message.loading({
        content: "附件上传中...",
        duration: 0,
      });
      this.upLoadFileLoading = true;
      let formData = new FormData();
      formData.append("file", files);
      this.$fetch("/osapi/insurance/uploadFile", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type": "multipart/form-data;",
        },
      })
        .then((res) => {
          if (res.code == "200") {
            setTimeout(msg, 0);
            this.newInsuranceData.enclosure = res.data;
            this.upLoadFileLoading = false;
            this.$Message.success("上传成功");
          }
        })
        .catch((err) => {
          // this.$Message.error(err);
          setTimeout(msg, 0);
          this.upLoadFileLoading = false;
          
        });
    },
    onBefore(files) {
      let that = this;
      let formData = new FormData();
      formData.append("file", files);
      formData.append("updateSupport", true);
      formData.append("insuranceId", this.currentMainData.id);
      formData.append("insuranceType", this.currentMainData.insuranceType);
      formData.append("operName", this.Userinfo.vname);
      this.$fetch("/osapi/insuredlist/importInsuredList", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type": "multipart/form-data;",
        },
      })
        .then((res) => {
          if (res.code == "0") {
            this.$Message.success("上传成功");
            this.handleSearchSubFilesList("reset");
          }
        })
        .catch((err) => {
         // this.$Message.error(err);
        });
    },
    removeInsurance(val) {
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否移除保险信息</p>`,
        onOk: () => {
          let data = {
            ids: val.id,
          };
          this.$fetch("/osapi/insurance/removeInsurance", {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("移除成功");
              this.handleClearMain("reset");
              this.handleMainListSearch("reset");
            } else {
              this.$Message.info(res.msg || "操作失败");
            }
          });
        },
        onCancel: () => {},
      });
    },
    removeInsurancePeople(val) {
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否移除保险人员信息</p>`,
        onOk: () => {
          let data = {
            ids: val.id,
          };
          this.$fetch("/osapi/insuredlist/removeInsuredList", {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("移除成功");
              this.handleSearchSubFilesList("reset");
            } else {
              this.$Message.info(res.msg || "操作失败");
            }
          });
        },
        onCancel: () => {},
      });
    },
    toEditPeople(type) {
      if (type == "close") {
        this.editPeopleFlag = false;
        return false;
      }
      let ndata = this.newInsurancePeopleData;
      if (
        ndata.name == "" ||
        ndata.idNumber == "" ||
        ndata.birthday == "" ||
        ndata.premium == "" ||
        ndata.sex < 0 ||
        ndata.insuranceAmount == ""
      ) {
        this.$Message.info("请填写完整资料");
        this.editPeopleNext = false;
        setTimeout(() => {
          this.editPeopleNext = true;
        }, 500);
        return false;
      }
      let data = {
        insuranceId: this.currentMainData.id,
        insuranceType: this.currentMainData.insuranceType,
        creater: this.Userinfo.login_id,

        name: ndata.name,
        idNumber: ndata.idNumber,
        birthday: ndata.birthday,
        sex: ndata.sex,
        premium: ndata.premium,
        insuranceAmount: ndata.insuranceAmount,
      };
      let apiUrl = "";
      if (this.funFlag == "add") {
        apiUrl = "osapi/insuredlist/addInsuredList";
      }
      if (this.funFlag == "edit") {
        apiUrl = "osapi/insuredlist/editInsuredList";
        data.id = ndata.id;
      }
      this.$fetch(apiUrl, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code == "200") {
          this.$Message.success("操作成功");
          this.handleSearchSubFilesList("reset");
          this.editPeopleFlag = false;
        } else {
          this.editPeopleNext = false;
          setTimeout(() => {
            this.editPeopleNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    toDownTemp() {
      let downUrl =
        "http://134.175.132.191:8086/profile/insurance/2021/04/19/01de8669-aa37-4c25-9411-688ae53e275f.xlsx";
      window.open(downUrl, "_blank");
    },
    handleMainTableClass(row, index) {
      if (row.isOverdue == 1) {
        return "table-highlin-style-red";
      } else {
        if (row.days < 30) {
          return "table-highlin-style-yellow";
        } else {
          return "";
        }
      }
    },
    toRectifyAttachment(val) {
      if (val.enclosure) {
        window.open(val.prefix + val.enclosure, "_blank");
      } else {
        this.$Message.info("暂无附件");
      }
    },
    // 获取日期判断
    expirationDateChange(a){
      // console.log(typeof)
      if(a==""){
        this.overdue = false
        this.noOverdue = false
      }
      // if(this.newInsuranceData.effectiveDate && a){
      if(a){
        var d1 = a.getTime()
        // var d2 = this.newInsuranceData.effectiveDate.getTime()
        // var d2 = new Date().getTime()
        var d3 = new Date(new Date().toLocaleDateString()).getTime()
        console.log(d3)
        
        var differenceData = parseInt(d1 - d3) / 1000 / 60 / 60 / 24
        if(differenceData>30){
          this.overdue = false
          this.noOverdue = false
        }
        if(differenceData<=30 && differenceData>=0){
          this.noOverdueTxt = `当期日期离保险到期还剩${differenceData}天`
          this.overdue = false
          this.noOverdue = true
        }else if(differenceData<0){
          this.noOverdue = false
          this.overdueTxt = '当前保险日期已过期'
          this.overdue = true
        }
        console.log(differenceData) // 天数
      }
    },
    // 设置接口失效
    setInvalid(data){
      console.log(data)
      this.$fetch(`osapi/insurance/${data.id}`, {
        // method: "PUT",
        method: "POST",

      }).then((res) => {
        if(res.code == "200"){
          this.$Message.success('操作成功')
        }
        console.log(res)
        this.getMainList()
      });
    }
  },
  destory() {},
};
</script>
<style lang="less">
.ivu-table .table-highlin-style-red td {
  background-color: #FBE2E4 !important;
  color: #333 !important;
}
.ivu-table .table-highlin-style-yellow td {
  background-color: #FEF4E3;
  color: #333;
}
</style>
<style lang="less" scoped>
@import "../public/style.less";
.aa-warp-box {
  .awb-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    .aa-item {
      margin-right: 24px !important;
    }
  }
}
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
.full-content-box-row {
  display: flex;
  padding: 12px !important;
  .left {
    background: #fff;
    height: 100%;
    overflow-y: auto;
    border-radius: 4px;
    width: 328px;
    margin-right: 14px;
    position: relative;
    .content {
      display: flex;
      flex-direction: column;
      padding: 14px;
      .search-box {
        .s-item {
          display: flex;
          align-items: center;
          .lable {
            width: 70px;
          }
        }
      }
      .left-title {
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        .shu {
          width: 5px;
          height: 14px;
          background: #409eff;
          margin-right: 6px;
        }
      }
      .team-list-box {
        .tlb-content {
          padding-top: 14px;
          .e-item-active {
            color: #fff !important;
            background: #2d8cf0 !important;
          }
          .e-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 4px;
            border-bottom: 1px solid #e8eaec;
            cursor: pointer;
          }
          .e-item:hover {
            background: #e8eaec;
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    height: 100%;
    background: #fff;
    border-radius: 4px;
    overflow-y: auto;
    padding: 14px;
    .c-btnbox {
      // display: flex;
      // flex-direction: column;
      margin-bottom: 14px;
      padding: 6px 0 0 12px;
      .c-btnbox-item {
        display: flex;
        flex-wrap: wrap;
        .mb-item {
          display: flex;
          align-items: center;
          margin-bottom: 14px;
        }
      }
      .fun-page-box {
        padding-top: 14px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        padding-right: 14px;
      }
      .tab-content-box {
        // display: flex;
        // flex-direction: column;
        padding-left: 8px;
        .tcb-item {
          display: flex;
          .tcbi-item {
            display: flex;
            align-items: center;
            margin-right: 16px;
            .lable {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
</style>