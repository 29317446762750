<template>
  <div class="full-content-box">
    <div class="second-title">
      <div class="shu" />
      整改事项
    </div>
    <div class="c-btnbox">
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">状态：</div>
          <Select
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.status"
          >
            <Option
              v-for="item in statusList"
              :value="item.dictValue"
              :key="item.dictValue"
              >{{ item.dictLabel }}</Option
            >
          </Select>
        </div>
        <!-- <div class="mb-item">
          <div class="label">考核版块：</div>
          <Input
            placeholder="请输入考核版块"
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.plate"
          />
        </div>
        <div class="mb-item">
          <div class="label">考核批次：</div>
          <Input
            placeholder="请输入考核批次"
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.batch"
          />
        </div> -->
      </div>
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">发起时间：</div>
            <!-- type="daterange" -->
          <DatePicker
            placement="bottom-end"
            placeholder="请选择发起时间"
            style="width: 200px; margin-right: 14px"
            format="yyyy-MM-dd"
            :transfer="true"
            v-model="rangeDate"
            @on-change="handelRangeDateChange"
          ></DatePicker>
        </div>
        <div class="mb-item">
          <div class="label">结束时间：</div>
          <DatePicker
            type="date"
            placement="bottom-end"
            placeholder="请选择结束时间"
            style="width: 200px; margin-right: 14px"
            format="yyyy-MM-dd"
            :transfer="true"
            v-model="endDate"
            @on-change="handelEndDateChange"
          ></DatePicker>
        </div>
      </div>
      <div class="c-btnbox-item">
        <Button
          type="primary"
          style="margin-right: 14px"
          @click="handleMainListSearch"
          >搜索</Button
        >
        <Button @click="handleMainListSearch('reset')">重置</Button>
        <Button type="primary" style="margin-left: 14px" @click="downloadAll">批量下载整改单</Button>
      </div>
    </div>
    <div class="second-title">
      <div class="shu" />
      整改事项数据
    </div>
    <el-table
      ref="table"
      :loading="mainLoading"
      size="small"
      :data="mainList"
      :width="ScreenWidth - 350"
      :style="'width:'+ScreenWidth - 350+'px;'"
    >
      <!-- :width="ScreenWidth - 350" -->
      <!-- :columns="mainCloums" -->
      <el-table-column type="expand">
        <template slot-scope="props">
          <!-- <el-form label-position="left" inline class="exp-content">
            <el-form-item label="描述" class="exp-item" v-if="props.row.describes">
              <span>描述:{{ props.row.describes }}</span>
            </el-form-item>
            <el-form-item label="措施" class="exp-item" v-if="props.row.advise">
              <span>措施:{{ props.row.advise }}</span>
            </el-form-item>
          </el-form> -->
          <div class="exp-content">
            <div class="exp-item" v-if="props.row.describes">
                <div class="label">描述:</div>
                <div class="info">{{props.row.describes}}</div>
            </div>
            <div class="exp-item" v-if="props.row.advise">
                <div class="label">措施:</div>
                <div class="info">{{props.row.advise}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="显示隐藏" align="center">
        <template slot-scope="scope">
          <i v-show="!scope.row.expansion" class="el-icon-arrow-up" style="font-size:22px;" @click="expend(scope.row)"></i>
          <i v-show="scope.row.expansion" class="el-icon-arrow-down" style="font-size:22px;" @click="expend(scope.row)"></i>
        </template>
      </el-table-column>
      <el-table-column label="发起人" prop="sponsorId" width="80" align="center" ></el-table-column>
      <el-table-column label="签发部门" prop="dept" align="center" ></el-table-column>
      <el-table-column label="整改期限" prop="term" align="center" ></el-table-column>
      <el-table-column label="地点" prop="place" align="center" ></el-table-column>
      <el-table-column label="班组负责人" prop="teamLeaderIds" align="center" ></el-table-column>
      <el-table-column label="项目负责人" prop="projectLeaderIds" align="center" ></el-table-column>
      <el-table-column label="抄送人" prop="ccIds" align="center" ></el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="props">
          <div class="btnbox-flex">
            <div>
              {{ props.row.status | rectifyStatusFilters(statusList) }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="发起时间" prop="launchTime" align="center" ></el-table-column>
      <el-table-column label="操作" align="center" width="350">
        <template slot-scope="props">
          <Button
            type="info"
            style="margin-right: 8px"
            @click="toRectifyRate(props.row)"
            >查看进度</Button
          >
          <Button
            type="primary"
            style="margin-right: 8px"
            @click="toRectifyAttachment(props.row)"
            >查看附件</Button
          >
          <Button
            type="primary"
            style="margin-right: 8px"
            @click="download(props.row)"
            >下载整改单</Button
          >
        </template>
      </el-table-column>
      <!-- <template slot-scope="{ row, index }" slot="status">
        <div class="btnbox-flex">
          <div>
            {{ row.status | rectifyStatusFilters(statusList) }}
          </div>
        </div>
      </template> -->
      <!-- <template slot-scope="{ row, index }" slot="action">
        <Button
          type="info"
          style="margin-right: 8px"
          @click="toRectifyRate(row)"
          >查看进度</Button
        >
        <Button
          type="primary"
          style="margin-right: 8px"
          @click="toRectifyAttachment(row)"
          >查看附件</Button
        >
        <Button
          type="primary"
          style="margin-right: 8px"
          @click="download(row)"
          >下载整改单</Button
        >
      </template> -->
    </el-table>
    <page
      show-total
      class="page-box"
      :page-size="mainSize"
      :total="mainTotal"
      :current="mainPage"
      @on-change="handleMainPageChange"
    />
    <!-- 整改管理-查看附件 -->
    <Modal
      v-model="showRectifyRateFlag"
      :loading="showRectifyRateNext"
      :mask-closable="false"
      ok-text="确定"
      title="整改单附件"
      @on-ok="toFinishRectifyRate()"
      @on-cancel="toFinishRectifyRate()"
      width="900"
    >
        <!-- :columns="rectifyRateColumns" -->
      <el-table
        ref="table2"
        :data="rectifyRateData"
        :loading="rectifyRateLoad"
      >
      <el-table-column type="expand">
        <template slot-scope="props">
          <!-- <el-form label-position="left" inline class="exp-content">
            <el-form-item label="描述" class="exp-item" v-if="props.row.describes">
              <span>描述:{{ props.row.describes }}</span>
            </el-form-item>
            <el-form-item label="措施" class="exp-item" v-if="props.row.advise">
              <span>措施:{{ props.row.advise }}</span>
            </el-form-item>
          </el-form> -->
          <div class="exp-content">
            <div class="exp-item" v-if="props.row.describes">
                <div class="label">描述:</div>
                <div class="info">{{props.row.describes}}</div>
            </div>
            <div class="exp-item" v-if="props.row.advise">
                <div class="label">措施:</div>
                <div class="info">{{props.row.advise}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="显示隐藏" align="center">
        <template slot-scope="scope">
          <i v-show="!scope.row.expansion" class="el-icon-arrow-up" style="font-size:22px;" @click="expend2(scope.row)"></i>
          <i v-show="scope.row.expansion" class="el-icon-arrow-down" style="font-size:22px;" @click="expend2(scope.row)"></i>
        </template>
      </el-table-column>
      <el-table-column label="执行人" prop="userId"></el-table-column>
      <el-table-column label="角色" prop="roleName"></el-table-column>
      <el-table-column label="标题" prop="title"></el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="props">
          <div class="btnbox-flex">
            <div>
              {{ props.row.taskStatus | rectifyStatusFilters(statusList) }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="时间" prop="operTime"></el-table-column>
      <el-table-column label="操作" align="center">
        <div slot-scope="props" class="btnbox-flex">
            <Button
              class="bf-style"
              type="success"
              @click="toRectifyAttachment(props.row)"
              >查看附件</Button
            >
        </div>
      </el-table-column>
        <!-- <template slot-scope="{ row }" slot="taskStatus">
          {{ row.taskStatus | teamStatus(row.taskStatus) }}
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <div class="btnbox-flex">
            <Button
              class="bf-style"
              type="success"
              @click="toRectifyAttachment(row)"
              >查看附件</Button
            >
          </div>
        </template> -->
      </el-table>
    </Modal>
  </div>
</template>

<script>
import rictifyExpandRow from "../template/tableExpandRectify.vue";
export default {
  name: "rectifyTemp",
  data() {
    return {
      searchMainListData: {
        status: "",
        plate: "",
        batch: "",
        startDate: "",
        endDate: "",
      },
      mainList: [],
      mainCloums: [
        {
          type: "expand",
          width: 50,
          render: (h, params) => {
            return h(rictifyExpandRow, {
              props: {
                row: params.row,
              },
            });
          },
        },
        {
          title: "发起人",
          key: "sponsorId",
          width: 80,
        },
        // {
        //   title: "考核模板",
        //   key: "plate",
        // },
        // {
        //   title: "考核批次",
        //   key: "batch",
        // },
        {
          title: "签发部门",
          key: "dept",
        },
        // {
        //   title: "问题描述",
        //   key: "describes",
        // },
        // {
        //   title: "整改措施建议",
        //   key: "advise",
        // },
        {
          title: "整改期限",
          key: "term",
        },
        {
          title: "地点",
          key: "place",
        },
        {
          title: "班组负责人",
          key: "teamLeaderIds",
        },
        {
          title: "项目负责人",
          key: "projectLeaderIds",
        },
        {
          title: "抄送人",
          key: "ccIds",
        },
        {
          title: "状态",
          slot: "status",
        },
        {
          title: "发起时间",
          key: "launchTime",
        },
        {
          title: "操作",
          slot: "action",
          width: 340,
          align: "center",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 15,
      mainTotal: 0,
      rangeDate: '',
      endDate: '',
      exportData: {
        date: "",
      },
      statusList: [],
      rectifySearchDate: "",
      showRectifyRateFlag: false,
      showRectifyRateNext: true,
      rectifyRateColumns: [
        {
          type: "expand",
          width: 50,
          render: (h, params) => {
            return h(rictifyExpandRow, {
              props: {
                row: params.row,
              },
            });
          },
        },
        {
          title: "执行人",
          key: "userId",
        },
        {
          title: "角色",
          key: "roleName",
        },
        {
          title: "标题",
          key: "title",
        },
        // {
        //   title: "内容",
        //   key: "describes"
        // },
        {
          title: "状态",
          key: "status",
        },
        {
          title: "时间",
          key: "operTime",
        },
        {
          title: "操作",
          slot: "action",
        },
      ],
      rectifyRateData: [],
      rectifyRateLoad: false,
      rectifyRatePage: 1,
      rectifyRateSize: 15,
      rectifyRateTotal: 0,
      prefix: "",
      checkPdfZipData: false,
    };
  },
  props: ["ProjectInfo", "ScreenWidth"],
  created() {},
  mounted() {
    this.getOSRectifyStatusList();
    this.handleMainListSearch();
    this.getCheckRectifyPdfZip();
  },
  filters: {
    rectifyStatusFilters(sid, list) {
      let newVal = "";
      list.forEach((val) => {
        if (sid == val.dictValue) {
          newVal = val.dictLabel;
        }
      });
      return newVal;
    },
  },
  watch: {},
  methods: {
    // 获取是否有批量下载
    getCheckRectifyPdfZip() {
      this.$axios({
        method: "get",
        url: `${this.apiUrlCros}/osapi/rectify/checkRectifyPdfZip?projectId=${this.ProjectInfo.id}`,
      }).then((res) => {
        if(res.data.code=='200'){
          this.checkPdfZipData = true
        }else{
          this.checkPdfZipData = false
        }
      });
    },
    // 下载
    download(info) {
      if (info.pdfPath==null||info.pdfPath=='') {
        this.$Message.info("暂无整改单");
        // /profile/rectify/pdf/458/85/rectify_85.pdf
      } else {
        // window.open(`${this.prefix}/api/rectify/getRectifyPdf?rectifyId=${info.id}`, "_blank");
      this.$axios({
        url:`${this.apiUrlCros}/osapi/rectify/getRectifyPdf?rectifyId=${info.id}`,
        method: "get",
        responseType: 'arraybuffer',
      }).then((res) => {
        if (res.status==200) {
          const data = res.data;
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/pdf",
            })
          );
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          // let fileName = decodeURI(res.headers[`content-disposition`].substring(20));
          let name = "整改单";
          link.setAttribute("download", name + ".pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.$Message.error("下载失败");
        }
      });


      }
    },
    // 批量下载
    downloadAll() {
      if(this.checkPdfZipData) {
        // window.open(`${this.prefix}/api/rectify/getRectifyPdfZip?projectId=${this.ProjectInfo.id}`, "_blank");


        this.$axios({
          url:`${this.apiUrlCros}/osapi/rectify/getRectifyPdfZip?projectId=${this.ProjectInfo.id}`,
          method: "get",
          responseType: 'arraybuffer',
        }).then((res) => {
          if (res.status==200) {
            const data = res.data;
            const url = window.URL.createObjectURL(
              new Blob([data], {
                type: "application/zip",
              })
            );
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            // let fileName = decodeURI(res.headers[`content-disposition`].substring(20));
            let name = "整改档案资料";
            link.setAttribute("download", name + ".zip");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            this.$Message.error("下载失败");
          }
        });

      }else{
        this.$Message.info("暂无整改单");
      }
      // this.$axios({
      //   method: "get",
      //   url: `/osapi/rectify/getRectifyPdfZip?projectId=${this.ProjectInfo.id}`,
      //   responseType: "arraybuffer",
      // }).then((res) => {
      //   console.log(res)
      //   // let url = window.URL.createObjectURL(new Blob([res.data]));
      //   // let link = document.createElement("a");
      //   // link.style.display = "none";
      //   // link.href = url;
      //   // link.download = `${info.contentName}.pdf`;
      //   // document.body.appendChild(link);
      //   // link.click();
      //   // window.URL.revokeObjectURL(link.href);
      // });
      
    },
    getOSRectifyStatusList() {
      let data = {};
      this.$fetch(`/osapi/common/getRectifyStatusList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.statusList = res.data;
        } else {
          this.$Message.error("获取外部交换平台整改状态列表失败");
        }
      });
    },
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      let data = {
        pageSize: this.mainSize,
        pageNum: this.mainPage,
        projectId: this.ProjectInfo.id,
        startTime: this.common.formatDate(this.rangeDate) || "",
        endTime: this.common.formatDate(this.endDate) || "",
        status: this.searchMainListData.status || "",
        plate: this.searchMainListData.plate || "",
        batch: this.searchMainListData.batch || "",
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      this.$fetch(`/osapi/rectify/getRectifyList`, {
        method: "post",
        data: formData,
      }).then((res) => {
        if (res.code == "200") {
          this.mainTotal = res.total;
          let mainList = res.resList;
          mainList.forEach(item=>{
            item.expansion = false
          })
          this.mainList = mainList
          console.log('mainList', mainList)
          this.prefix = res.prefix;
          this.mainLoading = false;
        } else {
          this.mainLoading = false;
          this.$Message.info("查询整改列表失败，请稍后重试");
        }
      });
    },
    expend(row){
      let $table = this.$refs.table;
      this.mainList.map((item) => {
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false)
          item.expansion = false
        }
        else{
          item.expansion = !item.expansion
        }
      })
      $table.toggleRowExpansion(row);
    },
    expend2(row){
      let $table = this.$refs.table2;
      this.rectifyRateData.map((item) => {
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false)
          item.expansion = false
        }
        else{
          item.expansion = !item.expansion
        }
      })
      $table.toggleRowExpansion(row);
    },
    // 主表搜索
    handleMainListSearch(type) {
      if (type == "reset") {
        this.searchMainListData = {
          status: "",
          plate: "",
          batch: "",
          startDate: "",
          endDate: "",
        };
        this.rangeDate = '';
        this.endDate = '';
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch();
    },
    handelRangeDateChange(e) {
      var date1 = new Date(e[0]);
      var date2 = new Date(e[1]);
    },
    handelEndDateChange(e) {},
    // 整改流水-查看进度
    toRectifyRate(val) {
      this.rectifyRateData = [];
      this.showRectifyRateFlag = true;
      this.getRectifyRate(val);
    },
    getRectifyRate(val) {
      this.rectifyRateLoad = true;
      let data = {
        rectifyId: val.id,
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      // // console.log(formData)
      this.$fetch(`/osapi/rectify/getFlowListByRectifyId`, {
        method: "post",
        data: formData,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          let rectifyRateData = res.data;
          rectifyRateData.forEach(item=>{
            item.expansion = false
          })
          this.rectifyRateData = rectifyRateData
          this.rectifyRateLoad = false;
        } else {
          this.rectifyRateLoad = false;
          this.$Message.error("获取进度信息失败");
        }
      });
    },
    // 整改流水-查看附件
    toRectifyAttachment(val) {
      // console.log(this.prefix + val.compressPath);
      // return
      if (val.compressPath) {
        window.open(this.prefix + val.compressPath, "_blank");

        //   this.$axios({
        //   url:`${this.apiUrlCros}/profile/rectify/CompressFile/14`,
        //   method: "get",
        //   responseType: 'arraybuffer',
        // }).then((res) => {
        //   if (res.status==200) {
        //     const data = res.data;
        //     const url = window.URL.createObjectURL(
        //       new Blob([data], {
        //         type: "application/zip",
        //       })
        //     );
        //     const link = document.createElement("a");
        //     link.style.display = "none";
        //     link.href = url;
        //     // let fileName = decodeURI(res.headers[`content-disposition`].substring(20));
        //     let name = "整改单附件";
        //     link.setAttribute("download", name + ".zip");
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //   } else {
        //     this.$Message.error("下载失败");
        //   }
        // });

      } else {
        this.$Message.info("暂无附件");
      }
    },
    toFinishRectifyRate() {
      this.showRectifyRateFlag = false;
    },
  },
  destory() {},
};
</script>

<style lang="less" scoped>
.exp-content{
  display: flex;
  .exp-item{
    width: 50%;
    padding: 0 16px;
    .label{
      font-size: 14px;
    }
    .info{
      font-size: 12px;
    }
  }
}
/deep/ .el-table__expand-column .cell {
  display: none !important;
  width: 1px !important;
}
/deep/ .demo-table-expand {
        font-size: 0;
    }
/deep/ .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
/deep/ .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
/deep/ .el-table_1_column_1{
  display: inline-block !important;
  width: 1px !important;
}
.el-table__row{
  /deep/ td:last-child{
    display: none !important;
  }
}
@import "../public/style.less";
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
</style>