<template>
  <div class="full-content-box">
    <div class="second-title">
      <div class="shu" />
      设备管理
    </div>
    <div class="c-btnbox">
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">设备名称：</div>
          <Input
            v-model="searchMainListData.name"
            placeholder="请输入设备名称"
            style="width: 200px; margin-right: 14px"
          />
        </div>
        <div class="mb-item">
          <div class="label">设备序列号：</div>
          <Input
            v-model="searchMainListData.serialNo"
            placeholder="请输入设备序列号"
            style="width: 200px; margin-right: 14px"
          />
        </div>
      </div>
      <div class="c-btnbox-item" style="margin-bottom: 14px">
        <Button
          type="primary"
          @click="handleMainListSearch"
          style="margin-right: 14px"
          >搜索</Button
        >
        <Button @click="handleMainListSearch('reset')">重置</Button>
        <Button @click="addEquipment" style="margin-left: 34px" type="info">添加设备</Button>
      </div>
      <Table :loading="mainLoading" :columns="mainCloums" :data="mainList">
        <template slot-scope="{ row, index }" slot="action">
          <Button
            type="primary"
            style="margin-right: 4px"
            @click="editEquipment(row)"
            >编辑名称</Button
          >
          <Button type="warning"
          @click="deleteEquipment(row)"
            >移除</Button
          >
        </template>
      </Table>
      <div class="fun-page-box">
          <page show-total
            :total="mainTotal"
            :page-size="mainSize"
            @on-change="handleMainPageChange"
            :current="mainPage"
          />
        </div>
    </div>
    <!-- 添加设备 -->
    <Modal
      v-model="addModalFlag"
      :loading="addModalNext"
      :mask-closable="false"
      title="编辑设备信息"
      @on-ok="toAddEquipment"
      @on-cancel="toAddEquipment('close')"
    >
      <div class="aa-item">
        <div class="label">设备序列号：</div>
            <Input
              v-model="newEquipmentData.serialNo"
              placeholder="输入设备序列号"
              clearable
              style="width: 300px"
            />
      </div>
      <div class="aa-item">
        <div class="label">设备名称：</div>
            <Input
              v-model="newEquipmentData.name"
              placeholder="输入设备名称"
              clearable
              style="width: 300px"
            />
      </div>
      <div class="aa-item">
        <div class="label">限制人数：</div>
            <Input
              v-model="newEquipmentData.limitNumber"
              placeholder="输入限制人数"
              clearable
              style="width: 300px"
            />
      </div>
      <div class="aa-item">
        <div class="label">设备识别距离(cm)：</div>
            <Input
              v-model="newEquipmentData.distance"
              placeholder="输入设备识别距离"
              clearable
              style="width: 300px"
            />
      </div>
      <div class="aa-item">
        <div class="label">设备公司：</div>
            <Input
              v-model="newEquipmentData.firm"
              placeholder="输入设备公司"
              clearable
              style="width: 300px"
            />
      </div>
    </Modal>
    <!-- 编辑设备 -->
    <Modal
      v-model="editModalFlag"
      :loading="editModalNext"
      :mask-closable="false"
      title="编辑设备信息"
      @on-ok="toEditEquipment"
      @on-cancel="toEditEquipment('close')"
    >
      <div class="aa-item">
        <div class="label">设备名称：</div>
            <Input
              v-model="editEquipmentData.name"
              placeholder="输入设备名称"
              clearable
              style="width: 300px"
            />
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "checkByEquipment",
  data() {
    return {
      searchMainListData: {
        name: "",
        serialNo: "",
      },
      mainCloums: [
        {
          title: "设备名称",
          key: "name",
        },
        {
          title: "设备序列号",
          key: "serialNo",
        },
        {
          title: "人脸识别上限（人/个）",
          key: "limitNumber",
        },
        {
          title: "考勤机识别距离（cm）",
          key: "distance",
        },
        {
          title: "考勤机厂商",
          key: "firm",
        },
        {
          title: "考勤机状态",
          key: "statusCN",
        },
        {
          title: "操作",
          slot: "action",
          width: 240,
          align: "center",
        },
      ],
      mainList: [],
      mainTotal: 0,
      mainSize: 20,
      mainPage: 1,
      mainLoading: false,
      addModalFlag: false,
      addModalNext: true,
      editModalFlag: false,
      editModalNext: true,
      newEquipmentData: {
        serialNo: '',
        name: '',
        limitNumber: '',
        distance: '',
        firm: '',
      },
      editEquipmentData: {
        name: '',
        id: ''
      },
      editEquipmentFlag: false
    };
  },
  props: ["ProjectInfo", "ScreenWidth"],
  created() {},
  mounted() {
    this.handleMainListSearch('reset')
  },
  filters: {},
  watch: {},
  methods: {
    handleMainListSearch(type) {
      if (type == "reset") {
        this.searchMainListData = {
          name: "",
          serialNo: "",
        };
        this.mainPage = 1;
      }
      this.getMainList();
    },
    getMainList(){
      this.mainLoading = true;
      let data = {
        pageNo: this.mainPage,
        pageSize: this.mainSize,
        projectId: this.ProjectInfo.id,
        name: this.searchMainListData.name,
        serialNo: this.searchMainListData.serialNo
      };
      this.$fetch(this.common.getUrl('/sapi/machine/list',data), {
        method: "get"
      }).then((res) => {
        if (res.code == "200") {
          this.mainList = res.data.records;
          this.mainTotal = res.data.total;
          this.mainLoading = false;
        } else {
          this.mainLoading = false;
          this.$Message.info("查询考勤机列表失败，请稍后重试");
        }
      });
    },
    handleMainPageChange(e) {
      this.mainPage = e
      this.handleMainListSearch()
    },
    toEditEquipment(type){
      if (type == "close") {
        this.editModalFlag = false;
        return false;
      }
      let ndata = this.editEquipmentData;
      if (
        ndata.name == ""
      ) {
        this.$Message.info("请填写修改名称");
        this.editModalNext = false;
        setTimeout(() => {
          this.editModalNext = true;
        }, 500);
        return false;
      }
      let data = {
        name: ndata.name
      };
      this.$fetch(`/sapi/machine/edit/${ndata.id}`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code == "200") {
          this.$Message.success("操作成功");
          this.handleMainListSearch("reset");
          this.editModalFlag = false;
        } else {
          this.editModalNext = false;
          setTimeout(() => {
            this.editModalNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    toAddEquipment(type){
      if (type == "close") {
        this.addModalFlag = false;
        return false;
      }
      let ndata = this.newEquipmentData;
      if (
        ndata.serialNo == "" ||
        ndata.name == "" ||
        ndata.limitNumber == "" ||
        ndata.distance == "" ||
        ndata.firm == ""
      ) {
        this.$Message.info("请填写完整资料");
        this.addModalNext = false;
        setTimeout(() => {
          this.addModalNext = true;
        }, 500);
        return false;
      }
      let data = {
        name: ndata.name,
        serialNo: ndata.serialNo,
        projectId: ndata.projectId,
        limitNumber: ndata.limitNumber,
        distance: ndata.distance,
        firm: ndata.firm,
      };
      this.$fetch('/sapi/machine/save', {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code == "200") {
          this.$Message.success("操作成功");
          this.handleMainListSearch("reset");
          this.addModalFlag = false;
        } else {
          this.addModalNext = false;
          setTimeout(() => {
            this.addModalNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    addEquipment(){
      this.newEquipmentData = {
        serialNo: '',
        name: '',
        limitNumber: '',
        distance: '',
        firm: '',
      }
      this.newEquipmentData.projectId = this.ProjectInfo.id
      this.addModalFlag = true
    },
    editEquipment(val){
      this.editEquipmentData = {
        name: val.name,
        id: val.id
      }
      this.editModalFlag = true
    },
    deleteEquipment(val){
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否删除该设备</p>",
        onOk: () => {
          this.$fetch(`/sapi/machine/${val.id}`, {
            // method: "delete"
            method: "post"
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("操作成功");
              this.handleMainListSearch("reset");
            } else {
              this.$Message.info("操作失败");
            }
          });
        },
        onCancel: () => {},
      });
    }
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
</style>