<template>
  <div class="full-content-box">
    <div class="second-title">
      <div class="shu" />
      考勤数据
    </div>
    <div class="c-btnbox">
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">姓名：</div>
          <Input
            v-model="searchMainListData.name"
            placeholder="请输入姓名"
            style="width: 300px; margin-right: 14px"
          />
        </div>
        <div class="mb-item">
          <div class="label">时间范围：</div>
          <DatePicker
            type="daterange"
            @on-change="handelRangeDateChange"
            v-model="rangeDate"
            placement="bottom-end"
            placeholder="选择时间范围"
            style="width: 300px"
          ></DatePicker>
        </div>
      </div>
      <!-- <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">班组：</div>
                <Select v-model="searchMainListData.teamIds" multiple style="width:300px; margin-right: 14px">
                  <Option v-for="item in teamList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </div>
              <div class="mb-item">
                <div class="label">工种：</div>
                <Select v-model="searchMainListData.workType" multiple style="width:300px; margin-right: 14px">
                  <Option v-for="item in workTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
              </div>
            </div> -->
      <div class="c-btnbox-item" style="margin-bottom: 14px">
        <Button
          type="primary"
          style="margin-right: 14px"
          @click="handleMainListSearch"
          >搜索</Button
        >
        <Button @click="handleMainListSearch('reset')">重置</Button>
        <!-- <Button
                type="info"
                style="margin-right: 14px;margin-left: 34px;"
                @click="downDoc"
                >导出报表</Button
              >
              <Button
                type="info"
                style="margin-right: 14px"
                >更新考勤数据</Button
              > -->
      </div>

      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">导出日期范围（月）：</div>
          <!-- <DatePicker
            @on-change="handelExportDateChange"
            type="month"
            placement="bottom-end"
            placeholder="请选择考勤表日期"
            style="width: 200px; margin-right: 14px"
          ></DatePicker> -->
          <DatePicker
            type="daterange"
            @on-change="handelRangeDateChange"
            v-model="exportData.date"
            placement="bottom-end"
            placeholder="请选择考勤表日期"
            style="width: 300px; margin-right: 14px"
          ></DatePicker>
        </div>
        <Button type="primary" style="margin-right: 14px" @click="exportTable"
          >导出考勤数据表</Button
        >
        <Button type="primary" style @click="updateCheck">更新考勤数据</Button>
      </div>
    </div>
    <div class="second-title">
      <div class="shu" />
      数据内容
    </div>
    <Alert show-icon
      >红色背景: 当天打卡人员为红码; 绿色背景：当天打卡人员为绿码; 黄色背景:
      当天打卡人员为黄码;</Alert
    >
    <el-table
      stripe
      :loading="mainLoading"
      size="small"
      :data="mainList"
      :style="'width:' + (ScreenWidth - 350) + 'px'"
    >
      <!-- :columns="mainCloums" -->
      <el-table-column
        label="姓名"
        prop="name"
        width="80"
        fixed="left"
      ></el-table-column>
      <el-table-column
        label="班组名称"
        prop="teamName"
        width="180"
        fixed="left"
      ></el-table-column>
      <el-table-column label="出勤天数" prop="days" width="100" fixed="left">
        <!-- <template slot-scope="scope">
        <span style="margin-left: 10px">{{ scope.row.days }}</span>
      </template> -->
      </el-table-column>
      <el-table-column
        :label="item"
        :prop="item"
        v-for="(item, index) in cloumnsTime"
        :key="index"
        width="70"
        align="center"
      >
        <!-- width="70" height="40" -->
        <template slot-scope="scope">
          <span>
            <div
              :style="
                scope.row[item].codeType === 1
                  ? 'background:#ccffcc !important'
                  : scope.row[item].codeType === 2
                  ? 'background:#ffff99 !important'
                  : scope.row[item].codeType === 3
                  ? 'background:#ff0000 !important'
                  : ''
              "
            >
              {{ scope.row[item].minTime || "-" }}
            </div>
            <div
              :style="
                scope.row[item].codeType === 1
                  ? 'background:#ccffcc !important'
                  : scope.row[item].codeType === 2
                  ? 'background:#ffff99 !important'
                  : scope.row[item].codeType === 3
                  ? 'background:#ff0000 !important'
                  : ''
              "
            >
              {{ scope.row[item].maxTime }}
            </div>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <page
      show-total
      class="page-box"
      :page-size="mainSize"
      :total="mainTotal"
      :current="mainPage"
      @on-change="handleMainPageChange"
    />
  </div>
</template>

<script>
export default {
  name: "checkByList",
  data() {
    return {
      searchMainListData: {
        name: "",
        workType: "",
        teamIds: [],
        startDate: "",
        endDate: "",
      },
      mainList: [],
      cloumnsTime: [],
      mainCloumsBlack: [
        {
          title: "姓名",
          key: "name",
          fixed: "left",
          width: "80",
        },
        {
          title: "班组名称",
          key: "teamName",
          fixed: "left",
          width: "180",
        },
        // {
        //   title: "工种",
        //   key: "workType",
        //   fixed: "left",
        //   width: "100",
        // },
        {
          title: "出勤天数",
          key: "days",
          fixed: "left",
          width: "100",
        },
      ],
      mainCloums: [
        {
          title: "姓名",
          key: "name",
          fixed: "left",
          width: "80",
        },
        {
          title: "班组名称",
          key: "teamName",
          fixed: "left",
          width: "180",
        },
        // {
        //   title: "工种",
        //   key: "workType",
        //   fixed: "left",
        //   width: "100",
        // },
        {
          title: "出勤天数",
          key: "days",
          fixed: "left",
          width: "100",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 15,
      mainTotal: 0,
      workTypeList: [
        {
          value: 1,
          label: "普工",
        },
        {
          value: 2,
          label: "高级工",
        },
      ],
      teamList: [
        {
          value: 1,
          label: "班组A",
        },
        {
          value: 2,
          label: "班组B",
        },
      ],
      rangeDate: "",
      exportData: {
        date: "",
      },
    };
  },
  props: ["ProjectInfo", "ScreenWidth"],
  created() {},
  mounted() {
    this.resetDate();
    this.handleMainListSearch();
  },
  filters: {},
  watch: {},
  methods: {
    resetDate() {
      let now = new Date(); //当前日期
      let nowMonth = now.getMonth(); //当前月
      let nowYear = now.getFullYear(); //当前年
      //本月的开始时间
      let monthStartDate = new Date(nowYear, nowMonth, 1);
      //本月的结束时间
      let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
      this.rangeDate = [monthStartDate, monthEndDate];
    },
    // 获取主表
    getMainList() {
      console.log(22223333);
      this.mainLoading = true;
      // setTimeout(()=>{
      //   this.mainLoading = false
      // },1000)
      if (this.rangeDate[0] == "") {
        this.resetDate();
      }
      let data = {
        pageSize: this.mainSize,
        pageNo: this.mainPage,
        projectId: this.ProjectInfo.id,
        startDate: this.common.formatDate(this.rangeDate[0]),
        endDate: this.common.formatDate(this.rangeDate[1]),
        name: this.searchMainListData.name,
        // teamId: "",
        // workType: "",
      };
      this.$fetch(
        `/sapi/person/access/record/list?pageNo=${this.mainPage}&pageSize=${this.mainSize}`,
        {
          method: "post",
          data,
        }
      ).then((res) => {
        console.log(res, "ddddd");
        if (res.code == "200") {
          this.mainTotal = res.data.total;
          // this.handleMainList(res.data.records);
          this.handleMainList([
            {
              days: 4,
              everyDayAttendanceVos: [
                {
                  date: "2022-04-01",
                  minTime: "17:21",
                  maxTime: "17:21",
                  status: 0,
                  codeType: 2,
                },
                {
                  date: "2022-04-19",
                  minTime: "17:09",
                  maxTime: "17:09",
                  status: 0,
                  codeType: 3,
                },
                {
                  date: "2022-04-20",
                  minTime: "06:41",
                  maxTime: "17:07",
                  status: 0,
                  codeType: 3,
                },
                {
                  date: "2022-04-21",
                  minTime: "06:41",
                  maxTime: "06:42",
                  status: 0,
                  codeType: 3,
                },
              ],

              idCard: "130423198310104019",
              name: "张志永",
              teamId: 800,
              teamName: "第二标段",
              workType: "67",
            },
            {
              days: 2,
              everyDayAttendanceVos: [
                {
                  date: "2022-04-01",
                  minTime: "12:25",
                  maxTime: "17:21",
                  status: 0,
                  codeType: null,
                },
                {
                  date: "2022-04-19",
                  minTime: "16:55",
                  maxTime: "16:55",
                  status: 0,
                  codeType: null,
                },
              ],

              idCard: "130423198602284031",
              name: "秦海辰",
              teamId: 800,
              teamName: "第二标段",
              workType: "61",
            },
          ]);
        } else {
          this.mainLoading = false;
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 处理主表数据
    handleMainList(data) {
      console.log(data);
      if (data.length > 0) {
        this.handleMainListCloumns(data[0].everyDayAttendanceVos);
        let vlist = [];
        data.forEach((val) => {
          val.everyDayAttendanceVos.forEach((vall) => {
            let newDate = "";
            if (vall.status == "1") {
              newDate = "补卡";
            } else if (vall.status == "2") {
              newDate = "请假";
            } else if (vall.status == "3") {
              newDate = "-";
            } else {
              newDate = vall.minTime + vall.maxTime;
            }
            // val[
            //   "" + vall.date.split("-")[1] + "-" + vall.date.split("-")[2]
            // ] = newDate;
            var vallTitle =
              vall.date.split("-")[1] + "-" + vall.date.split("-")[2];
            val["" + vall.date.split("-")[1] + "-" + vall.date.split("-")[2]] =
              JSON.parse(
                JSON.stringify({
                  minTime: vall.minTime,
                  maxTime: vall.maxTime,
                  newDate: newDate,
                  codeType: vall.codeType,
                  vallTitle: vallTitle,
                })
              );
            // val.codeType = vall.codeType
          });
          vlist.push(val);
        });
        // var k
        // vlist.forEach(item=>{
        //   k = Object.keys(item)
        //   for(let i=0; i<7;i++){
        //     delete k[i]
        //   }
        //   return
        // })

        this.mainList = vlist;
        this.mainLoading = false;
      } else {
        this.mainList = [];
        this.mainLoading = false;
      }
    },
    handleMainListCloumns(clist) {
      let cloumns = JSON.parse(JSON.stringify(this.mainCloumsBlack));
      console.log(cloumns,'cloumns');
      this.cloumnsTime = [];
      clist.forEach((val) => {
        let test = val.date.split("-")[1] + "-" + val.date.split("-")[2];
        this.cloumnsTime.push(test);
        cloumns.push({
          title: test,
          key: test,
          width: "70",
          codeType: val.codeType,
        });
      });
      this.mainCloums = cloumns;
    },
    // 主表搜索
    handleMainListSearch(type) {
      if (type == "reset") {
        this.searchMainListData = {
          name: "",
          workType: "",
          teamIds: [],
          startDate: "",
          endDate: "",
        };
        this.resetDate();
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch();
    },
    handelRangeDateChange(e) {
      var date1 = new Date(e[0]);
      var date2 = new Date(e[1]);
      // this.attendanceDateDetail.clockStartTime = Date.parse(date1) / 1000;
      // this.attendanceDateDetail.clockEndTime = Date.parse(date2) / 1000;
      // // console.log(this.attendanceDateDetail)
    },
    downDoc() {
      console.log();
      // let downUrl = "";
      // downUrl = this.common.openUrl(
      //   "8082",
      //   "sapi",
      //   `/teamItem/getTeamLegal/${this.currentTeamData.id}`
      // );
      // window.open(downUrl, "_blank");
    },

    handelExportDateChange(e) {
      this.exportData.date = e;
    },
    exportTable() {
      if (this.exportData.date[0] == "" || this.exportData.date[1] == "") {
        this.$Message.error("请选择导出考勤数据日期范围");
        return false;
      }
      let data = {
        projectId: this.ProjectInfo.id,
        startDate: this.common.formatDate(this.exportData.date[0]),
        endDate: this.common.formatDate(this.exportData.date[1]),
        // teamId: "",
        // workType: "",
      };
      // this.$fetch(
      //   `/sapi/person/access/record/list/excel`,
      //   {
      //     method: "post",
      //     data,
      //   }
      // ).then((res) => {
      //   console.log(res)
      // });
      this.$axios
        .post(`${this.apiUrlCros}/sapi/person/access/record/list/excel`, data, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = res.data;
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = (e) => {
            let a = document.createElement("a");
            a.download = `考勤数据.xlsx`;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };
        })
        .catch((err) => {
         // this.$Message.error(err);
        });
    },
    updateCheck() {
      let data = {
        iprojectid: this.ProjectInfo.id,
      };
      this.$fetch("/sapi/teamCheckinRelate/getAllProjectPersons", {
        method: "post",
        data,
      })
        .then((res) => {
          // // console.log(res);
          if (res.code == "200") {
            this.$Message.success("更新成功");
          } else {
            this.$Message.success("操作失败，请稍后再试");
          }
        })
        .catch((err) => {
         // this.$Message.success("操作失败，请稍后再试");
        });
    },
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.full-content-box {
}
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
</style>