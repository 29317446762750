<template>
  <div class="full-content-box">
    <div class="second-title">
      <div class="shu" />
      培训工作进度
    </div>
    <mycollapse>
      <div v-show="speedActive" class="collapsebox">
        <div class="section-info">
          <!-- <div class="si-item">
            <div class="num">100</div>
            <div class="info">项目登记在册人数</div>
          </div>
          <div class="si-item" style="background: rgb(149, 222, 100)">
            <div class="num">100</div>
            <div class="info">系统实名人数</div>
          </div>
          <div class="si-item" style="background: rgb(255, 156, 110)">
            <div class="num">90</div>
            <div class="info">系统在岗人数</div>
          </div>
          <div class="si-item" style="background: rgb(179, 127, 235)">
            <div class="num">10</div>
            <div class="info">系统离岗人数</div>
          </div>
          <div class="si-item" style="background: rgb(255, 214, 102)">
            <div class="num">90</div>
            <div class="info">项目培训人次</div>
          </div>
          <div class="si-item2">
            <div class="info">项目登记在册人数</div>
            <div class="num">100,00<span>人</span></div>
          </div>
          <div class="si-item2">
            <div class="info" style="background:rgb(243 122 167)">系统实名人数</div>
            <div class="num" :title="'test'" style="border-color: rgb(243 122 167);">100<span>人</span></div>
          </div>
          <div class="si-item2">
            <div class="info" style="background: rgb(255, 156, 110)">系统在岗人数</div>
            <div class="num" style="border-color: rgb(255, 156, 110);">90<span>人</span></div>
          </div>
          <div class="si-item2">
            <div class="info" style="background: rgb(179, 127, 235)">系统离岗人数</div>
            <div class="num" style="border-color: rgb(179, 127, 235);">10<span>人</span></div>
          </div>
          <div class="si-item2">
            <div class="info" style="background: rgb(143, 130, 255);">项目培训人次</div>
            <div class="num" style="border-color: rgb(143, 130, 255);">90<span>人</span></div>
          </div> -->
          <div class="cbn-item">
            <div class="cbni-left">
              <div class="icon-box" style="background: #a9f7ff">
                <img src="../../../assets/images/new_icon_1.png" alt />
              </div>
            </div>
            <div class="cbni-right">
              <div class="info">项目登记在册人数</div>
              <div class="num">
                {{ ProjectInfo.total_realname_count || 0 }}
              </div>
            </div>
          </div>
          <div class="cbn-item">
            <div class="cbni-left">
              <div class="icon-box" style="background: #ff534d">
                <img src="../../../assets/images/icon_shiming.png" alt />
              </div>
            </div>
            <div class="cbni-right">
              <div class="info">系统实名人数</div>
              <div class="num">
                {{ ProjectInfo.total_person_count || 0 }}
              </div>
            </div>
          </div>
          <div class="cbn-item">
            <div class="cbni-left">
              <div class="icon-box" style="background: rgb(255, 214, 102)">
                <img src="../../../assets/images/icon_banzu.png" alt />
              </div>
            </div>
            <div class="cbni-right">
              <div class="info">系统在岗人数</div>
              <div class="num">{{ ProjectInfo.team_count || 0 }}</div>
            </div>
          </div>
          <div class="cbn-item">
            <div class="cbni-left">
              <div class="icon-box" style="background: #7dc360">
                <img src="../../../assets/images/icon_banzu.png" alt />
              </div>
            </div>
            <div class="cbni-right">
              <div class="info" style="margin-bottom: 4px">系统离岗人数</div>
              <div class="num">
                {{ ProjectInfo.person_count || 0 }}
              </div>
            </div>
          </div>
          <div class="cbn-item">
            <div class="cbni-left">
              <div class="icon-box" style="background: #25c6fc">
                <img src="../../../assets/images/new_icon_4.png" alt />
              </div>
            </div>
            <div class="cbni-right">
              <div class="info" style="margin-bottom: 4px">项目培训人次</div>
              <div class="num">
                {{ ProjectInfo.person_count || 0 }}
              </div>
            </div>
          </div>
        </div>
        <div class="section-speed">
          <div class="ss-title">
            <span style="width: 100%; display: flex; justify-content: flex-end"
              >任务完成进度</span
            >
          </div>
          <div class="speed-box">
            <div class="ss-item">
              <el-progress
                type="circle"
                :stroke-width="15"
                :percentage="40"
                :color="colors"
              ></el-progress>
              <div class="info">入场必备完成进度</div>
            </div>
            <div class="ss-item">
              <el-progress
                type="circle"
                :stroke-width="15"
                :percentage="20"
                :color="colors"
              ></el-progress>
              <div class="info">公司级安全教育完成进度</div>
            </div>
            <div class="ss-item">
              <el-progress
                type="circle"
                :stroke-width="15"
                :percentage="10"
                :color="colors"
              ></el-progress>
              <div class="info">项目级安全教育完成进度</div>
            </div>
            <div class="ss-item">
              <el-progress
                type="circle"
                :stroke-width="15"
                :percentage="80"
                :color="colors"
              ></el-progress>
              <div class="info">班组级安全教育完成进度</div>
            </div>
            <div class="ss-item">
              <el-progress
                type="circle"
                :stroke-width="15"
                :percentage="100"
                :color="colors"
              ></el-progress>
              <div class="info">相关专业工种安全教育完成进度</div>
            </div>
            <!-- <div class="ss-item">
            <div class="info">公司级安全教育完成进度：</div>
            <Progress :percent="30" :stroke-width="20" text-inside />
          </div>
          <div class="ss-item">
            <div class="info">项目级安全教育完成进度：</div>
            <Progress :percent="10" :stroke-width="20" text-inside />
          </div>
          <div class="ss-item">
            <div class="info">班组级安全教育完成进度：</div>
            <Progress :percent="50" :stroke-width="20" text-inside />
          </div>
          <div class="ss-item">
            <div class="info">相关专业工种安全教育完成进度：</div>
            <Progress :percent="90" :stroke-width="20" text-inside />
          </div> -->
          </div>
        </div>
      </div>
    </mycollapse>

    <div class="close-box" @click="showOrClose('speed')">
      <div class="center-content">
        <span>{{ speedActive ? "收起" : "展开" }}</span>
        <Icon :type="speedActive ? 'md-arrow-dropup' : 'md-arrow-dropdown'" />
      </div>
    </div>
<!-- 代办事项 -->
    <div class="second-title">
      <div class="shu" />
      代办事项
    </div>
    <mycollapse>
      <div v-show="agencyActive" class="collapsebox">
        <div class="section-agency">
          <Badge :offset="[7, 7]" text="!">
            <Tooltip content="点击前往登记" theme="light" placement="top-end">
              <div class="sa-item" style="background: rgb(105, 192, 255)">
                <div class="sa-img">
                  <img src="../../../assets/images/icon.png" alt />
                </div>
                <div class="left">
                  <div class="icon">
                    <img src="../../../assets/images/new_icon_1.png" alt />
                  </div>
                </div>
                <div class="right">
                  <div class="title">项目登记在册人员</div>
                  <div class="num">100<span>人</span></div>
                </div>
              </div>
            </Tooltip>
          </Badge>
          <Badge :offset="[7, 7]" dot>
            <Tooltip content="点击前往查看" theme="light" placement="top-end">
              <div class="sa-item" style="background: rgb(149, 222, 100)">
                <div class="sa-img">
                  <img src="../../../assets/images/icon.png" alt />
                </div>
                <div class="left">
                  <div class="icon">
                    <img src="../../../assets/images/new_icon_2.png" alt />
                  </div>
                </div>
                <div class="right">
                  <div class="title">未实名工人</div>
                  <div class="num">0<span>人</span></div>
                </div>
              </div>
            </Tooltip>
          </Badge>
          <Badge :offset="[7, 7]" text="!">
            <Tooltip content="点击前往查看" theme="light" placement="top-end">
              <div class="sa-item" style="background: rgb(255, 156, 110)">
                <div class="sa-img">
                  <img src="../../../assets/images/icon.png" alt />
                </div>
                <div class="left">
                  <div class="icon">
                    <img src="../../../assets/images/new_icon_3.png" alt />
                  </div>
                </div>
                <div class="right">
                  <div class="title">未加班组工人</div>
                  <div class="num">5<span>人</span></div>
                </div>
              </div>
            </Tooltip>
          </Badge>
          <Tooltip content="点击前往查看" theme="light" placement="top-end">
            <div class="sa-item" style="background: rgb(179, 127, 235)">
              <div class="sa-img">
                <img src="../../../assets/images/icon.png" alt />
              </div>
              <div class="left">
                <div class="icon">
                  <img src="../../../assets/images/new_icon_4.png" alt />
                </div>
              </div>
              <div class="right">
                <div class="title">未完成入场必备工人</div>
                <div class="num">4<span>人</span></div>
              </div>
            </div>
          </Tooltip>
          <Tooltip content="点击前往查看" theme="light" placement="top-end">
            <div class="sa-item" style="background: rgb(255, 214, 102)">
              <div class="sa-img">
                <img src="../../../assets/images/icon.png" alt />
              </div>
              <div class="left">
                <div class="icon">
                  <img src="../../../assets/images/new_icon_5.png" alt />
                </div>
              </div>
              <div class="right">
                <div class="title">未下发考勤机工人</div>
                <div class="num">5<span>人</span></div>
              </div>
            </div>
          </Tooltip>
          <Tooltip content="点击前往查看" theme="light" placement="top-end">
            <div class="sa-item" style="background: rgb(92, 219, 211)">
              <div class="sa-img">
                <img src="../../../assets/images/icon.png" alt />
              </div>
              <div class="left">
                <div class="icon">
                  <img src="../../../assets/images/new_icon_6.png" alt />
                </div>
              </div>
              <div class="right">
                <div class="title">未考勤工人</div>
                <div class="num">5<span>人</span></div>
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
    </mycollapse>
    <div class="close-box" @click="showOrClose('agency')">
      <div class="center-content">
        <span>{{ agencyActive ? "收起" : "展开" }}</span>
        <Icon :type="agencyActive ? 'md-arrow-dropup' : 'md-arrow-dropdown'" />
      </div>
    </div>
<!-- 快捷功能 -->

    <div class="second-title">
      <div class="shu" />
      快捷功能
    </div>
    <mycollapse>
      <div v-show="quickActive" class="collapsebox">
        <div class="section-quick">
          <div class="sq-item sq-item1">
            <div class="app_icon app_icon1">
              <img src="../../../assets/images/app_icon_1.png" alt />
            </div>
            <!-- <Icon class="icon_style_new" type="ios-filing-outline" /> -->
            <div class="info">岗前培训资料管理</div>
          </div>
          <div class="sq-item sq-item2">
            <div class="app_icon app_icon2">
              <img src="../../../assets/images/app_icon_2.png" alt />
            </div>
            <!-- <Icon
              style="color: #7d5fff"
              class="icon_style_new"
              type="ios-filing-outline"
            /> -->
            <div class="info">安全技术交底资料管理</div>
          </div>
          <div class="sq-item sq-item3">
            <div class="app_icon app_icon3">
              <img src="../../../assets/images/app_icon_3.png" alt />
            </div>
            <!-- <Icon
              style="color: #fff200"
              class="icon_style_new"
              type="ios-filing-outline"
            /> -->
            <div class="info">专项资料管理</div>
          </div>
        </div>
      </div>
    </mycollapse>
    <div class="close-box" @click="showOrClose('quick')">
      <div class="center-content">
        <span>{{ quickActive ? "收起" : "展开" }}</span>
        <Icon :type="quickActive ? 'md-arrow-dropup' : 'md-arrow-dropdown'" />
      </div>
    </div>
  </div>
</template>

<script>
import mycollapse from "../public/collapse";
export default {
  name: "projectWork",
  components: {
    mycollapse,
  },
  data() {
    return {
      speedActive: true,
      agencyActive: true,
      quickActive: true,
      // colors: '#5cb87a'
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
    };
  },
  props: ["ProjectInfo"],
  created() {},
  mounted() {},
  filters: {},
  watch: {},
  methods: {
    showOrClose(type) {
      if (type == "speed") {
        this.speedActive = !this.speedActive;
      }
      if (type == "agency") {
        this.agencyActive = !this.agencyActive;
      }
      if (type == "quick") {
        this.quickActive = !this.quickActive;
      }
    },
  },
  destory() {},
};
</script>

<style lang="less" scoped>
.second-title {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .shu {
    width: 5px;
    height: 14px;
    background: #409eff;
    margin-right: 6px;
  }
  .close {
    display: flex;
    align-items: center;
    margin-left: 14px;
    color: #409eff;
  }
}
.full-content-box {
  flex-grow: 1;
  flex-basis: 0;
  // flex: 1;
  border-radius: 4px;
  background: #fff;
  padding: 14px;
  position: relative;
  overflow-y: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.full-content-box::-webkit-scrollbar {
  width: 0 !important;
}
.section-info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // justify-content: space-between;
  padding: 14px 14px 0 14px;
  z-index: 15;
  .si-item2 {
    margin-right: 44px;
    margin-bottom: 14px;
    cursor: pointer;
    .info {
      background: rgb(105, 192, 255);
      color: #fff;
      font-size: 14px;
      width: 150px;
      text-align: center;
      padding: 4px 14px;
      border-radius: 4px 4px 0 0;
    }
    .num {
      width: 100%;
      text-align: center;
      font-size: 38px;
      font-weight: bold;
      span {
        font-size: 14px;
        font-weight: bold;
        margin-left: 4px;
      }
      // border: 1px solid rgb(105, 192, 255);
    }
  }
  .si-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100px;
    height: 100px;
    background: rgb(105, 192, 255);
    color: #fff;
    border-radius: 4px;
    margin-right: 24px;
    margin-bottom: 14px;
    .num {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 32px;
      font-weight: bold;
      border-bottom: 1px solid #fff;
    }
    .info {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 14px;
      text-align: center;
    }
  }
  .cbn-item {
    display: flex;
    // width: 200px;
    // // border: 1px solid #e8eaec;
    // border-radius: 6px;
    // padding: 4px;
    // margin-bottom: 8px;
    width: 232px;
    border-radius: 6px;
    // padding: 4px 4px 4px 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
    .cbni-left {
      .icon-box {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 70%;
          height: auto;
        }
      }
    }
    .cbni-right {
      display: flex;
      flex-direction: column;
      color: #515a6e;
      width: 50%;
      .info {
        font-size: 12px;
      }
      .num {
        font-size: 28px;
        font-weight: bold;
        padding-left: 4px;
      }
      .txt {
        font-size: 14px;
        padding-left: 4px;
      }
      .bigTxt {
        font-size: 16px;
        font-weight: bold;
        padding-left: 4px;
      }
    }
  }
}
.section-speed {
  .ss-title {
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 14px;
    color: #afafaf;
  }
  .speed-box {
    display: flex;
    flex-wrap: wrap;
  }
  .ss-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    // margin-left: 10px;
    // height: 150px;
    width: 240px;
    cursor: pointer;
    .info {
      font-size: 16px;
      color: #409eff;
      text-align: right;
      margin-top: 14px;
      font-weight: bold;
    }
  }
}
.section-agency {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  .sa-item:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.15);
  }
  .sa-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 14px;
    border-radius: 4px;
    width: 300px;
    color: #fff;
    cursor: pointer;
    margin: 7px;
    position: relative;
    overflow: hidden;
    .sa-img {
      position: absolute;
      // width: 100%;
      width: 160px;
      right: -14px;
      bottom: -14px;
      img {
        width: 100%;
        height: auto;
        opacity: 0.1;
      }
    }
    // .left::after{
    //   position: absolute;
    //   content: " ";
    //   width: 2px;
    //   height: 80%;
    //   background: #fff;
    //   right: -24px;
    //   top: 10%;
    // }
    .left {
      position: relative;
      .icon {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .right {
      .title {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        margin-bottom: 4px;
      }
      .num {
        display: flex;
        justify-content: flex-end;
        font-size: 28px;
        align-items: flex-end;
        span {
          font-size: 14px;
          margin-bottom: 7px;
        }
      }
    }
  }
}
.app_icon {
  width: 80px;
  height: 80px;
  border: none;
  background: linear-gradient(
    0deg,
    rgba(251, 33, 117, 1) 0%,
    rgba(234, 76, 137, 1) 100%
  );
  color: #fff;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  img{
    width: 60%;
    height: auto;
  }
}
.sq-item1:hover{
  .app_icon{
  box-shadow:0 5px 20px rgba(234, 76, 137, .5);
  }
}
.app_icon2 {
  background: linear-gradient(
    0deg,
    rgba(0, 172, 238, 1) 0%,
    rgba(2, 126, 251, 1) 100%
  );
}
.sq-item2:hover{
  .app_icon{
  box-shadow:0 5px 20px rgba(2, 126, 251, .5);
  }
}
.app_icon3 {
  background-color: #89d8d3;
  background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
}
.sq-item3:hover{
  .app_icon{
  box-shadow:0 5px 20px rgba(3, 200, 168, .5);
  }
}
.section-quick {
  display: flex;
  flex-wrap: wrap;
  padding: 14px 24px;
  .sq-item:hover {
    color: #409eff;
  }
  .sq-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    margin-right: 24px;
    width: 200px;
    cursor: pointer;
    .icon_style_new {
      color: #ff9f1a;
      font-size: 28px;
    }
  }
}
.close-box{
  width: 100%;
  padding: 7px 0;
  cursor: pointer;
  color: #c5c8ce;
  position: relative;
  display: flex;
  justify-content: center;
}
.center-content{
  position: relative;
}
.center-content::after{
  position: absolute;
  content: " ";
  left: -110px;
  top: 50%;
  margin-top: -1px;
  width: 100px;
  height: 1px;
  background: #c5c8ce;
}
.center-content::before{
  position: absolute;
  content: " ";
  right: -110px;
  top: 50%;
  margin-top: -1px;
  width: 100px;
  height: 1px;
  background: #c5c8ce;
}
.collapsebox {
  padding-bottom: 24px;
}
// 鼠标移入效果
@keyframes myCardHover {
  0% {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  }
  100% {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.full-content-box:hover {
  animation: myCardHover 0.0001s linear;
  animation-fill-mode: both;
}
</style>