<template>
  <div class="full-content-box">
      <div class="second-title">
        <div class="shu" />
        档案管理
      </div>
      <div class="c-btnbox">
    <mycollapse>
      <div v-show="mainActive" class="collapsebox">
          <div class="c-btnbox-item">
            <div class="mb-item">
              <div class="label">班组名称：</div>
              <Input
                v-model="searchMainListData.name"
                placeholder="请输入班组名称"
                style="width: 200px; margin-right: 14px"
              />
            </div>
            <div class="mb-item">
              <div class="label">负责人姓名：</div>
              <Input
                v-model="searchMainListData.people"
                placeholder="请输入负责人姓名"
                style="width: 200px; margin-right: 14px"
              />
            </div>
            <div class="mb-item">
              <div class="label">班组名称：</div>
              <Input
                v-model="searchMainListData.teamName"
                placeholder="请输入班组名称"
                style="width: 200px; margin-right: 14px"
              />
            </div>
            <div class="mb-item">
              <div class="label">班组状态：</div>
              <Select
                v-model="searchMainListData.type"
                style="width: 200px; margin-right: 14px"
              >
                <Option
                  v-for="item in teamStatusList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="c-btnbox-item" style="margin-bottom: 14px">
            <Button
              type="primary"
              @click="handleMainListSearch"
              style="margin-right: 14px"
              >搜索</Button
            >
            <Button @click="handleMainListSearch('reset')">重置</Button>
          </div>
          <Table
            highlight-row
            ref="mainTable"
            :columns="mainCloums"
            :data="mainList"
            @on-current-change="handleMainListPick"
            :loading="mainLoading"
          >
            <template slot-scope="{ row, index }" slot="action">
              <!-- <Button type="primary" style="margin-right: 8px">在线预览</Button>
              <Button type="primary">下载</Button> -->
              <div v-if="row.teamLeaderName||row.teamLeaderPhone" style="margin-top:10px">
                  <Button
                  v-if="row.showOrHide=='hide'
                  "
                  type="primary"
                  @click.stop="showMainConceal(row)"
                  >显示</Button
                  >
                  <Button
                    v-else
                    type="primary"
                    @click.stop="hideMainConceal(row)"
                    >隐藏</Button
                  >

                </div>
            </template>
          </Table>
          <div class="fun-page-box">
            <Button @click="handleClearMain" style="margin-right: 14px"
              >清除列表选中</Button
            >
            <page show-total
              :total="mainTotal"
              :page-size="mainSize"
              @on-change="handleMainPageChange"
              :current="mainPage"
            />
          </div>
          </div>
    </mycollapse>
    <div class="close-box" @click="showOrClose('main')">
      <div class="center-content">
        <span>{{ mainActive ? "收起" : "展开" }}</span>
        <Icon :type="mainActive ? 'md-arrow-dropup' : 'md-arrow-dropdown'" />
      </div>
    </div>
        <!-- <Divider /> -->
        <Tabs type="card" @on-click="pickTab" v-model="tabFlag">
          <TabPane
            v-for="(item, index) in tabList"
            :key="index"
            :label="item.tabLabel"
            :name="item.tabName"
          ></TabPane>
        </Tabs>
        <!-- 子表-班组档案 -->
        <div class="tab-content-box" v-if="tabFlag == 'files'">
          <div class="tab-nullbox" v-if="!mainListPickFlag">
            <div class="nulltips">
              {{ tabDataNull ? "暂无数据" : "请先选择任务" }}
            </div>
          </div>
          <div v-else>
            <div class="tcb-item">
              <div class="tcbi-item">
                <Button type="primary" @click="toDownFiles">批量下载</Button>
              </div>
            </div>
            <Table
              :columns="subFilesCloums"
              :data="subFilesList"
              :loading="subFilesLoading"
            >
              <template slot-scope="{ row, index }" slot="action">
                <Button
                  type="primary"
                  style="margin-right: 8px"
                  @click="toViewFile(row.url)"
                  >在线预览</Button
                >
                <Button type="primary" @click="toViewFile(row.path)"
                  >下载</Button
                >
              </template>
            </Table>
            <page show-total
              :total="subFilesTotal"
              :page-size="subFilesSize"
              :current="subFilesPage"
              @on-change="handleSubFilesPageChange"
              style="margin-top: 14px"
            />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import mycollapse from "../public/collapse";
import {nameConceal,phoneConceal} from "@/utils/desensitization"
export default {
  name: "MainSubTable",
  components: {
    mycollapse,
  },
  data() {
    return {
      mainActive: true,
      searchMainListData: {
        name: "",
      },
      mainList: [],
      mainList1: [],//深拷贝  脱敏

      mainCloums: [
        {
          title: "班组名称",
          key: "teamName",
        },
        {
          title: "负责人姓名",
          key: "teamLeaderName",
        },
        {
          title: "负责人手机号",
          key: "teamLeaderPhone",
        },
        {
          title: "班组状态",
          key: "statusStr",
        },
        {
          title: "操作",
          slot: "action",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 5,
      mainTotal: 0,
      mainListPickFlag: false,
      tabDataNull: false,
      tabFlag: "files",
      tabList: [
        {
          tabLabel: "班组档案",
          tabName: "files",
        },
      ],
      searchSubListData: {
        name: "",
        phone: "",
        idCard: "",
        status: "",
      },
      statusList: [
        {
          value: "0",
          label: "在职",
        },
        {
          value: "1",
          label: "离岗",
        },
      ],
      subFilesList: [],
      subFilesCloums: [
        {
          title: "文件名称",
          key: "name",
        },
        {
          title: "文件格式",
          key: "ext",
        },
        {
          title: "文件大小（kb）",
          key: "size",
        },
        {
          title: "上传时间",
          key: "createTime",
        },
        {
          title: "操作",
          slot: "action",
          width: 200,
          align: "center",
        },
      ],
      subFilesLoading: false,
      subFilesPage: 1,
      subFilesSize: 10,
      subFilesTotal: 0,
      teamStatusList: [
        {
          value: "0",
          label: "正常",
        },
        {
          value: "1",
          label: "禁止进入",
        },
        {
          value: "2",
          label: "班组已退场",
        },
      ],
      currentMainData: {}
    };
  },
  props: ["ProjectInfo"],
  created() {},
  mounted() {
    this.handleMainListSearch("reset");
  },
  filters: {},
  watch: {},
  methods: {
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      let data = {
        pageSize: this.mainSize,
        pageNumber: this.mainPage,
        projectId: this.ProjectInfo.id,
        name: this.searchMainListData.name,
      };
      this.$fetch("/sapi/team/getTeamsByProjectIdOrName", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          // this.mainList = res.data;
          this.mainList1 = res.data;

          this.mainList= JSON.parse(JSON.stringify(this.mainList1));//处理深拷贝  浅拷贝
            // 默认先脱敏
          this.mainList.forEach((e) => {
            e.teamLeaderName = e.teamLeaderName?nameConceal(e.teamLeaderName.toString()):'';//姓名
            e.teamLeaderPhone = e.teamLeaderPhone?phoneConceal(e.teamLeaderPhone.toString()):'';//手机号

            e.showOrHide = "hide";//显示/隐藏
          });



          this.mainTotal = res.total;
          this.mainLoading = false;
        } else {
          this.mainLoading = false;
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 展示
    showMainConceal(data){
       this.mainList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.teamLeaderName = item.teamLeaderName;//姓名
         data.teamLeaderPhone = item.teamLeaderPhone;//手机号
         data.showOrHide = "show";//显示/隐藏
        }
      })
    },
    //隐藏
    hideMainConceal(data){
      this.mainList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.teamLeaderName = item.teamLeaderName?nameConceal(item.teamLeaderName.toString()):'';//姓名
         data.teamLeaderPhone= item.teamLeaderPhone?phoneConceal(item.teamLeaderPhone.toString()):'';//手机号
         data.showOrHide = "hide";//显示/隐藏
        }
      })
    },
    // 主表搜索
    handleMainListSearch(type) {
      this.handleClearMain()
      if (type == "reset") {
        this.searchMainListData = {
          name: "",
        };
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 选中主表
    handleMainListPick(currentRow, oldCurrentRow) {
      if(currentRow){
        this.currentMainData = currentRow
        this.mainListPickFlag = true
        this.handleSearchSubFilesList('reset')
      }
    },
    // 清除主表选择
    handleClearMain() {
      this.$refs.mainTable.clearCurrentRow();
      this.mainListPickFlag = false
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      // this.handleSearchSubFilesList()
      this.getMainList()

    },
    // 子表tab选择
    pickTab(e) {},
    // 子表搜索
    handleSearchSubFilesList(type) {
      if(type == 'reset'){
        this.subFilesPage = 1
      }
      this.getSubListFiles()
    },
    handleSubFilesPageChange(e) {
        this.subFilesPage = e
      this.handleSearchSubFilesList()
    },
    getSubListFiles(){
      this.subFilesLoading = true;
      let data = {
          pageNumber: this.subFilesPage,
          pageSize: this.subFilesSize,
          teamId: this.currentMainData.id,
          type: 14,
        };
        this.$fetch(`/sapi/trainingData/list`, {
          method: "post",
          data,
        }).then((res) => {
          if (res.code == "200") {
            this.subFilesLoading = false;
            this.subFilesTotal = res.total;
            this.subFilesList = res.data;
          } else {
            this.subFilesLoading = false;
            this.$Message.info("获取详情失败，请稍后重试");
          }
        });
    },
    toViewFile(src) {
      window.open(src, "_blank");
    },
    toDownFiles(){
      let downUrl = ''
      downUrl = this.common.openUrl(
          "8082",
          "sapi",
          `/trainingData/downloadTeamTrainData/${this.currentMainData.id}`
        );
        window.open(downUrl, "_blank");
    },
    showOrClose(type) {
      if (type == "main") {
        this.mainActive = !this.mainActive;
      }
    },
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.c-btnbox-item{
  display: flex;
  flex-wrap: wrap;
  .mb-item{
    margin-bottom: 14px;
  }
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
</style>