<template>
  <div class="mapBox">
    <div class="showBox">
      <div class="upBox">
        <div class="inputBox">
          <input type="text" placeholder="请输入关键字" id="search" />
        </div>
        <div id="gaoMap"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lnglat: {
      type: Array,
      // default: () => [116.368904, 39.913423],
      default: () => [112.51510,37.86388],

    },
  },
  data() {
    return {
      AMap: {},
      name: "",
      address: "",
      // lnglatXY: [116.397428, 39.90923],
      // center: [116.397428, 39.90923],
      lnglatXY: [112.51510,37.86388],
      center: [112.51510,37.86388],//公元时代城新都汇2号楼
    };
  },
  created() {
    if (this.lnglat && this.lnglat.length) {
      this.lnglatXY = this.lnglat;
      this.center = this.lnglat;
    }
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      let that = this;

      const AMap = (this.AMap = window.AMap);
      let map = new AMap.Map("gaoMap", {
        center: that.center,
        zoom: 20,
        lang: "zh_cn",
      });

      // map.setZoomAndCenter(16, that.center);
      // let start = new AMap.Marker({
      //   map: map,
      //   position: this.lnglatXY,
      // });

      // AMap.plugin("AMap.Point", function () {
      //   const point = new AMap.Point(
      //     that.lnglatXY.length === 0 ? 116.3974911 : that.lnglatXY[0],
      //     that.lnglatXY.length === 0 ? 39.90871987 : that.lnglatXY[1]
      //   ); // 创建点坐标
      // });
      // AMap.plugin("AMap.ToolBar", "AMap.Autocomplete", function () {
      //   console.log(new AMap.ToolBar());
      //   // 实例化Autocomplete
      //   var autoOptions = {
      //     //city 限定城市，默认全国
      //     city: "全国",
      //   };
      //   var autoComplete = new AMap.Autocomplete(autoOptions);
      //   autoComplete.search(keyword, function (status, result) {
      //     console.log(status, result);
      //     // 搜索成功时，result即是对应的匹配数据
      //   });
      // });
      AMap.plugin(
        ["AMap.ToolBar", "AMap.Autocomplete", "AMap.PlaceSearch", "AMap.Geocoder", "AMap.Marker"],
        function () {
          // console.log(AMap);
          map.addControl(
            new AMap.ToolBar({
              locate: false,
            })
          );
          const autoOptions = {
            city: "",
            input: "search",
          };
          let autocomplete = new AMap.Autocomplete(autoOptions);
          // map.addControl(autocomplete);
          //搜索事件
          const placeSearch = new AMap.PlaceSearch({
            city: "",
            map: map,
          });
          // console.log(AMap.event);
          AMap.event.addListener(autocomplete, "select", function (e) {
            that.$emit("onSelect", e.poi);
            placeSearch.search(e.poi.name);
          });
          // console.log(AMap);
          //点击marker事件
          AMap.event.addListener(placeSearch, "markerClick", function (e) {
            // console.log(e);
            console.log("第五步")
            that.address = `${e.data.pname}${e.data.cityname}${e.data.adname}${e.data.address}`;
            that.name = e.data.name;
            that.lnglatXY = [e.data.location.lng, e.data.location.lat];
            //  用于搜索后辅助打点
            // AMap.event.addListener(map, "click", downMark(e.event));
            geocoder.getAddress(that.lnglatXY, function (status, result) {
              // console.log(result);
              if (status === "complete" && result.info === "OK") {
                marker.setPosition(that.lnglatXY);
                // const addstr = `${result.regeocode.addressComponent.province}${result.regeocode.addressComponent.city}${result.regeocode.addressComponent.district}${result.regeocode.addressComponent.township}`;
                // that.name = result.regeocode.formattedAddress.substring(
                //   addstr.length,
                //   result.regeocode.formattedAddress.length
                // );
                const addressData = result.regeocode.addressComponent;
                let obj = {};
                obj.province = addressData.province;
                obj.city = addressData.city ? addressData.city : addressData.province;
                obj.district = addressData.district;
                obj.town = addressData.township;
                obj.detailAddress = that.name;
                obj.lnglat = that.lnglatXY;
                // obj.address = addstr;
                // console.log(obj, "address");
                that.$emit("onSure", obj);
                that.address = result.regeocode.formattedAddress;
                // console.log(addstr);
              }
            });
          });

          //点击设置marker
          const marker = new AMap.Marker({
            map: map,
          });

          const geocoder = new AMap.Geocoder();
          //  用于搜索后辅助打点
          // let downMark = function (e) {
          //   // map.remove(start);
          //   that.lnglatXY = [e.lnglat.lng, e.lnglat.lat];
          //   geocoder.getAddress(that.lnglatXY, function (status, result) {
          //     console.log(result);
          //     if (status === "complete" && result.info === "OK") {
          //       marker.setPosition(that.lnglatXY);
          //       const addstr = `${result.regeocode.addressComponent.province}${result.regeocode.addressComponent.city}${result.regeocode.addressComponent.district}${result.regeocode.addressComponent.township}`;
          //       that.name = result.regeocode.formattedAddress.substring(
          //         addstr.length,
          //         result.regeocode.formattedAddress.length
          //       );
          //       const addressData = result.regeocode.addressComponent;
          //       let obj = {};
          //       // obj.province = addressData.province;
          //       // obj.city = addressData.city ? addressData.city : addressData.province;
          //       // obj.district = addressData.district;
          //       // obj.town = addressData.township;
          //       // obj.detailAddress = that.name;
          //       obj.lnglat = that.lnglatXY;
          //       obj.address = addstr;
          //       console.log(obj, "address");
          //       that.$emit("onSure", obj);
          //       that.address = result.regeocode.formattedAddress;
          //       // console.log(addstr);
          //     }
          //   });
          // };
          
          AMap.event.addListener(map, "click", function (e) {
            // map.remove(start);
            // console.log(e);
            console.log("第四步")
            that.lnglatXY = [e.lnglat.lng, e.lnglat.lat];
            geocoder.getAddress(that.lnglatXY, function (status, result) {
              // console.log(result);
              if (status === "complete" && result.info === "OK") {
                marker.setPosition(that.lnglatXY);
                // const addstr = `${result.regeocode.addressComponent.province}${result.regeocode.addressComponent.city}${result.regeocode.addressComponent.district}${result.regeocode.addressComponent.township}`;
                // that.name = result.regeocode.formattedAddress.substring(
                //   addstr.length,
                //   result.regeocode.formattedAddress.length
                // );
                const addressData = result.regeocode.addressComponent;
                const addressDate = result.regeocode;
                let obj = {};
                obj.province = addressData.province;
                obj.city = addressData.city ? addressData.city : addressData.province;
                obj.district = addressData.district;
                obj.town = addressData.township;
                if (obj.province == obj.city) {
                  obj.detailAddress = addressDate.formattedAddress.substring(
                    obj.province.length + obj.district.length + obj.town.length,
                    addressDate.formattedAddress.length
                  );
                } else {
                  obj.detailAddress = addressDate.formattedAddress.substring(
                    obj.province.length + obj.city.length + obj.district.length + obj.town.length,
                    addressDate.formattedAddress.length
                  );
                }

                obj.lnglat = that.lnglatXY;
                // obj.address = addstr;
                // console.log(obj, "address");
                that.$emit("onSure", obj);
                // that.address = result.regeocode.formattedAddress;
                // console.log(addstr);
              }
            });
          });
        }
      );




    },
  },
  watch: {
    lnglat: {
      handler: function (val) {
        if (val && val.length > 0) {
          this.lnglatXY = val;
          this.center = val;
          this.initMap();
        } else {
          this.lnglatXY = [116.397428, 39.90923];
          this.center = [116.397428, 39.90923];
          this.initMap();
        }
      },
    },
  },
};
</script>

<style rel="stylesheet/less" lang="less">
.amap-sug-result {
  width: 150px;
  overflow: hidden;
  margin-top: 5px;
}
.mapBox {
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.2);
  // overflow: hidden;
  // z-index: 1000;
  .showBox {
    width: 100%;
    height: 400px;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: #fff;
    // position: absolute;
    // left: 0;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // margin: auto;
    .upBox {
      width: 100%;
      height: 100%;
      padding: 15px;
      box-sizing: border-box;
      position: relative;
    }
    .downBox {
      width: 100%;
      height: 90px;
      padding: 10px 20px;
      box-sizing: border-box;
      display: flex;
      p {
        padding: 0;
        margin: 0;
      }
    }
  }
  .inputBox {
    display: flex;
    position: absolute;
    overflow: hidden;
    right: 20px;
    top: 20px;
    z-index: 10000;
    width: 50%;
    height: 60px;
    border-radius: 5px;
    background-color: #fff;
    // border: 1px solid rgb(216, 216, 216);
    padding: 15px;
    box-sizing: border-box;
    .title {
      width: 6rem;
      text-align: justify;
      padding: 0.4rem 0.7rem;
      display: inline-block;
      text-justify: distribute-all-lines;
      text-align-last: justify;
      -moz-text-align-last: justify;
      -webkit-text-align-last: justify;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      font-size: 12px;
    }
    input {
      flex: 1;
      width: 100%;
      background: #fff;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
      height: 40px;
      box-sizing: border-box;
      &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba(128, 189, 255, 0.1);
      }
    }
  }
  .imgBox {
    position: absolute;
    right: 20px;
    bottom: 17px;
    z-index: 10000;
    width: 150px;
    height: 155px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    img {
      display: block;
      width: 110px;
      height: 110px;
      margin: 10px auto 0;
    }
    > div {
      text-align: center;
      line-height: 30px;
    }
  }
  .addressBox {
    flex-grow: 1;
    .name {
      height: 40px;
      padding: 5px 0;
      box-sizing: border-box;
      span {
        font-size: 14px;
        padding-right: 20px;
        line-height: 30px;
        color: #4d4d4d;
      }
      input {
        width: 175px;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #d3d3d3;
        border-radius: 4px;
        padding: 0 15px;
        color: #b5b5b5;
      }
    }
    .address {
      font-size: 14px;
      color: #4d4d4d;
      span {
        padding: 0 10px;
      }
    }
  }
  .phoneBox {
    min-width: 215px;
    line-height: 70px;
    text-align: center;
    a {
      color: #00ba0e;
      font-size: 16px;
      text-decoration: underline;
    }
  }
  .btnBox {
    min-width: 200px;
    button {
      width: 80px;
      height: 30px;
      margin-top: 20px;
      border: 0;
      border-radius: 4px;
      cursor: pointer;
      &.close {
        float: left;
        background: #fff;
        border: 1px solid #e5e5e5;
        color: #a5a5a5;
      }
      &.submit {
        float: right;
        background: #00ba0e;
        color: #fff;
      }
    }
  }
  #gaoMap {
    width: 100%;
    height: 100%;
  }
  #search {
    height: 30px;
  }
}
</style>