<style scoped>
    .expand-row{
        margin-bottom: 16px;
    }
</style>
<template>
    <div class="exp-content">
        <div class="exp-item" v-if="row.describes">
            <div class="label">描述:</div>
            <div class="info">{{row.describes}}</div>
        </div>
        <div class="exp-item" v-if="row.advise">
            <div class="label">措施:</div>
            <div class="info">{{row.advise}}</div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            row: Object
        }
    };
</script>
<style lang="less" scoped>
.exp-content{
    display: flex;
    .exp-item{
        width: 50%;
        padding: 0 16px;
        .label{
            font-size: 14px;
        }
        .info{
            font-size: 12px;
        }
    }
}
</style>