import { render, staticRenderFns } from "./ModalMap.vue?vue&type=template&id=1a28f225"
import script from "./ModalMap.vue?vue&type=script&lang=js"
export * from "./ModalMap.vue?vue&type=script&lang=js"
import style0 from "./ModalMap.vue?vue&type=style&index=0&id=1a28f225&prod&rel=stylesheet%2Fless&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports