<template>
  <div class="full-content-box">
      <div class="second-title">
        <div class="shu" />
        个人保险管理
      </div>
      <div>暂未开放</div>
  </div>
</template>

<script>
export default {
  name: "insuranceByPersonal",
  data() {
    return {
    };
  },
  props: ["ProjectInfo","ScreenWidth"],
  created() {},
  mounted() {
  },
  filters: {},
  watch: {},
  methods: {
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
</style>