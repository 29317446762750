<template>
  <div class="test-project">
    <!-- 左边导航栏-选择项目-新增项目 -->
    <div class="left-nav" :style="{ height: clientHeight + 'px' }">
      <!-- 新增项目按钮 -->
      <div class="nav-btnbox">
        <div class="addProjectBtn" @click="addNewProject">
          <Icon
            type="md-add"
            color="#fff"
            size="16"
            style="margin-right: 4px"
          />
          <span>新增项目</span>
        </div>
      </div>
      <!-- 选择项目导航栏 -->
      <Menu
        ref="side_menu"
        :active-name="activeName"
        :open-names="openName"
        :accordion="true"
        @on-select="pickMenu"
        @on-open-change="pickProjectMenu"
        :theme="'dark'"
        :width="'auto'"
      >
        <div v-for="(item, index) in projectData" :key="index">
          <Submenu :name="index">
            <template slot="title">
              <Icon type="ios-filing" />
              {{ item.vprojectname }}
            </template>
            <MenuItem :name="'info' + ':' + index">项目信息</MenuItem>
            <!-- <MenuItem :name="'work' + ':' + index">项目工作台</MenuItem> -->
            <MenuItem
              v-if="item.sub_contract_flag != '1'"
              :name="'right' + ':' + index"
              >项目授权</MenuItem
            >
            <MenuItem :name="'worktype' + ':' + index">工种管理</MenuItem>
            <MenuItem :name="'team' + ':' + index">班组管理</MenuItem>
            <MenuItem :name="'rosterlist' + ':' + index">花名册管理</MenuItem>

            <Submenu :name="'prejob' + ':' + index">
              <template slot="title">岗前安全培训</template>
              <MenuItem :name="'prejob1' + ':' + index">任务管理</MenuItem>
              <MenuItem :name="'prejob2' + ':' + index">档案管理</MenuItem>
            </Submenu>
            <Submenu :name="'safe' + ':' + index">
              <template slot="title">安全技术交底</template>
              <MenuItem :name="'safe1' + ':' + index"
                >安全技术交底表管理</MenuItem
              >
              <MenuItem :name="'safe2' + ':' + index">交底样板管理</MenuItem>
              <MenuItem :name="'safe3' + ':' + index">任务管理</MenuItem>
              <MenuItem :name="'safe4' + ':' + index">档案管理</MenuItem>
              <!-- <MenuItem :name="'safe5' + ':' + index">项目视频库</MenuItem> -->
              <!-- <MenuItem :name="'safe6' + ':' + index">安全技术交底</MenuItem> -->
            </Submenu>
            <MenuItem v-if="specialTaskSwitch" :name="'taskList' + ':' + index">内部培训管理</MenuItem>
            <!-- <Submenu :name="'comsafe' + ':' + index">
              <template slot="title">企业安全技术交底</template>
              <MenuItem :name="'comsafe1' + ':' + index"
                >企业安全技术交底</MenuItem
              >
            </Submenu> -->
            <!-- <Submenu :name="'special' + ':' + index">
              <template slot="title">专项培训</template>
              <MenuItem :name="'special1' + ':' + index"
                >专项签字表管理</MenuItem
              >
              <MenuItem :name="'special1' + ':' + index">模板管理</MenuItem>
              <MenuItem :name="'special2' + ':' + index">任务管理</MenuItem>
              <MenuItem :name="'special3' + ':' + index">档案管理</MenuItem>
            </Submenu> -->
            <!-- v-if="checkFlag" -->
            <Submenu
              v-if="mobileAttendanceSwitch"
              :name="'oncheck' + ':' + index"
            >
              <template slot="title">考勤管理</template>
              <MenuItem :name="'oncheck4' + ':' + index">考勤规则</MenuItem>
              <MenuItem :name="'oncheck5' + ':' + index">考勤数据</MenuItem>
            </Submenu>
            <Submenu
              v-if="!mobileAttendanceSwitch && checkFlag"
              :name="'oncheck' + ':' + index"
            >
              <template slot="title">考勤管理</template>
              <MenuItem :name="'oncheck1' + ':' + index">设备管理</MenuItem>
              <MenuItem :name="'oncheck2' + ':' + index">班组考勤管理</MenuItem>
              <MenuItem :name="'oncheck3' + ':' + index">考勤统计</MenuItem>
            </Submenu>
            <MenuItem :name="'sign' + ':' + index">签字管理</MenuItem>
            <Submenu :name="'insurance' + ':' + index" v-if="insuranceFlag">
              <template slot="title">保险管理</template>
              <MenuItem :name="'insurance1' + ':' + index">团体保险</MenuItem>
              <!-- <MenuItem :name="'insurance2' + ':' + index">个人保险</MenuItem>
              <MenuItem :name="'insurance3' + ':' + index">社会保险</MenuItem>
              <MenuItem :name="'insurance4' + ':' + index">其他保险</MenuItem> -->
            </Submenu>
            <MenuItem  v-if="mobileAttendanceSwitch" :name="'irregularities' + ':' + index">违规记录</MenuItem>
            <Submenu :name="'outside' + ':' + index" v-if="osPowerFlag">
              <template slot="title">{{
                projectInfo.icorpid == 153 ? "质安整改" : "外部交换平台"
              }}</template>
              <MenuItem
                :name="'outside1' + ':' + index"
                v-if="projectInfo.icorpid != 153"
                >实名制人员清单</MenuItem
              >
              <MenuItem
                :name="'outside2' + ':' + index"
                v-if="projectInfo.icorpid != 153"
                >企业内部管理系统</MenuItem
              >
              <MenuItem
                :name="'outside3' + ':' + index"
                v-if="projectInfo.icorpid != 153"
                >考勤数据</MenuItem
              >
              <MenuItem
                :name="'outside4' + ':' + index"
                v-if="projectInfo.icorpid != 153"
                >设置工种薪资</MenuItem
              >
              <MenuItem :name="'outside5' + ':' + index">整改单管理</MenuItem>
              <MenuItem :name="'outside7' + ':' + index">罚单管理</MenuItem>
              <MenuItem :name="'outside6' + ':' + index">分类设置</MenuItem>
            </Submenu>

            <MenuGroup title="分包项目" v-if="item.sub_contracts">
              <Submenu
                style="padding-left: 10px"
                :name="'subc' + ':' + index + ':' + sindex"
                v-for="(subcitem, sindex) in item.sub_contracts"
                :key="'s' + sindex"
              >
                <template slot="title">{{ subcitem.vprojectname }}</template>
                <MenuItem :name="'subc1' + ':' + index + ':' + sindex"
                  >项目信息</MenuItem
                >
                <MenuItem :name="'subc2' + ':' + index + ':' + sindex"
                  >班组管理</MenuItem
                >
                <MenuItem
                  v-if="subcitem.has_check_in == '1'"
                  :name="'subc3' + ':' + index + ':' + sindex"
                  >考勤数据</MenuItem
                >
              </Submenu>
            </MenuGroup>
          </Submenu>
        </div>
      </Menu>
    </div>
    <!-- 右边内容栏-内容模块 -->
    <div class="right-main">
      <!-- 面包屑模块 -->
      <div class="bread-box">
        <Breadcrumb>
          <BreadcrumbItem v-if="currentContentBox != 'newProject'">{{
            currentProjectName
          }}</BreadcrumbItem>
          <BreadcrumbItem
            v-for="(bitem, index) in breadcrumbList"
            :key="index"
            >{{ bitem.name }}</BreadcrumbItem
          >
        </Breadcrumb>
      </div>
      <!-- 项目工作台 -->
      <ProjectWork
        :ProjectInfo="projectInfo"
        v-if="currentContentBox == 'work'"
      />
      <!-- 项目信息 -->
      <div class="info-mk">
        <div
          class="content-box"
          v-if="currentContentBox == 'info'"
          style="margin-right: 14px; min-height: 800px"
        >
          <Card
            style="
              margin-bottom: 20px;
              width: 914px;
              position: relative;
              overflow: hidden;
            "
            v-if="!noProjectFlag"
          >
            <div class="bgimg" v-if="!editFlag">
              <img src="../../assets/images/dgtech_logo.png" alt />
            </div>
            <div class="second-title">
              <div class="shu" />
              基本信息 
              <span  class="showBasicOrHideBasic">
                <span v-if="basicHide" @click="showBasicConceal" >显示</span> 
                <span v-else @click="hideBasicConceal">隐藏</span>
              </span>
            </div>
            <!-- 基本信息不可修改 -->
            <div class="cb-bigbox">
              <!-- <div class="cb-info-box">
                <div class="content-info">
                  <div class="ci-label">项目名称:</div>
                  <div class="ci-text">{{projectInfo.vprojectname || '未填写'}}</div>
                </div>
                <div class="content-info">
                  <div class="ci-label">系统项目编码:</div>
                  <div class="ci-text">{{projectInfo.vprojectcode || '未填写'}}</div>
                </div>
                <div class="content-info">
                  <div class="ci-label">项目负责人:</div>
                  <div class="ci-text">{{projectInfo.vresponsible_person_name || '未填写'}}</div>
                </div>
                <div class="content-info">
                  <div class="ci-label">项目负责人手机号码:</div>
                  <div class="ci-text">{{projectInfo.vresponsible_person_phone || '未填写'}}</div>
                </div>
              </div>-->
              <div class="cb-newbox">
                <!-- <div class="cbn-item">
                  <div class="cbni-left">
                    <div class="icon-box" style="background: #91c924;">
                      <img src="../../assets/images/icon_xiangmu.png" alt />
                    </div>
                  </div>
                  <div class="cbni-right">
                    <div class="info" style="margin-bottom: 4px;">项目信息</div>
                    <div class="txt">{{projectInfo.vprojectname || '未填写'}}</div>
                    <div class="txt">{{projectInfo.vprojectcode || '未填写'}}</div>
                  </div>
                </div> -->
                <div class="cbn-item">
                  <div class="cbni-left">
                    <div class="icon-box" style="background: #25c6fc">
                      <img src="../../assets/images/icon_man.png" alt />
                    </div>
                  </div>
                  <div class="cbni-right">
                    <div class="info" style="margin-bottom: 4px">
                      项目负责人
                    </div>
                    <div class="txt">
                      <!-- {{ projectInfo.vresponsible_person_name || "未填写" }} -->
                      <span v-if="projectInfo.vresponsible_person_name==''">未填写</span>
                     
                      <span v-else>
                        <span v-if="basicHide">{{projectInfo.vresponsible_person_name |  nameConcealFilter}}</span>
                        <span v-else>{{projectInfo.vresponsible_person_name }}</span>
                        
                      </span>
                    </div>
                    <div class="txt">
                      <!-- {{ projectInfo.vresponsible_person_phone || "未填写" }} -->
                       <span v-if="projectInfo.vresponsible_person_phone==''">未填写</span>
                       <span v-else>
                          <span v-if="basicHide">
                            {{projectInfo.vresponsible_person_phone |  phoneConcealFilter}}
                          </span>  
                          <span v-else>
                            {{projectInfo.vresponsible_person_phone}}
                          </span>                        
                       </span>
                    </div>
                  </div>
                </div>
                <div class="cbn-item">
                  <div class="cbni-left">
                    <div class="icon-box" style="background: #a9f7ff">
                      <img src="../../assets/images/icon_shiming.png" alt />
                    </div>
                  </div>
                  <div class="cbni-right">
                    <div class="info">项目实名人数</div>
                    <div class="num">
                      {{ projectInfo.total_realname_count || 0 }}
                    </div>
                  </div>
                </div>
                <!--                <div class="cbn-item">
                  <div class="cbni-left">
                    <div class="icon-box" style="background: #ff534d">
                      <img src="../../assets/images/icon_renshu.png" alt />
                    </div>
                  </div>
                  <div class="cbni-right">
                    <div class="info">项目培训人次</div>
                    <div class="num">
                      {{ projectInfo.total_person_count || 0 }}
                    </div>
                  </div>
                </div>-->
                <div class="cbn-item">
                  <div class="cbni-left">
                    <div
                      class="icon-box"
                      style="background: rgb(255, 214, 102)"
                    >
                      <img src="../../assets/images/icon_banzu.png" alt />
                    </div>
                  </div>
                  <div class="cbni-right">
                    <div class="info">项目班组数量</div>
                    <div class="num">{{ projectInfo.team_count || 0 }}</div>
                  </div>
                </div>
                <div class="cbn-item">
                  <div class="cbni-left">
                    <div class="icon-box" style="background: #ab60c3">
                      <img src="../../assets/images/icon_zhiwei.png" alt />
                    </div>
                  </div>
                  <div class="cbni-right">
                    <div class="info" style="margin-bottom: 4px">
                      当前项目角色
                    </div>
                    <div class="bigTxt">
                      {{ projectInfo.role_name || "未填写" }}
                    </div>
                  </div>
                </div>
                <div class="cbn-item">
                  <div class="cbni-left">
                    <div class="icon-box" style="background: #7dc360">
                      <img src="../../assets/images/icon_renshu.png" alt />
                    </div>
                  </div>
                  <div class="cbni-right">
                    <div class="info" style="margin-bottom: 4px">
                      项目在岗人数
                    </div>
                    <div class="num">
                      {{ projectInfo.person_count || 0 }}
                    </div>
                  </div>
                </div>
                <!-- <div class="cbn-item">
                  <div class="cbni-left">
                    <div class="icon-box" style="background: #f3a749">
                      <img src="../../assets/images/icon_renshu.png" alt />
                    </div>
                  </div>
                  <div class="cbni-right">
                    <div class="info" style="margin-bottom: 4px">
                      项目离岗人数
                    </div>
                    <div class="num">
                      {{ projectInfo.is_removed_person_count || 0 }}
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- <div class="cb-statistics-box">
                <div class="content-info">
                  <div class="ci-label">参与项目人数:</div>
                  <div class="ci-text">{{projectInfo.total_person_count || 0}}</div>
                </div>
                <div class="content-info">
                  <div class="ci-label">参加项目在岗人数:</div>
                  <div class="ci-text">{{projectInfo.person_count || 0}}</div>
                </div>
              <div class="content-info">-->
              <!-- <div class="ci-label">参加项目离岗人数:</div>
              <div class="ci-text">{{projectInfo.is_remove_person_count || 0}}</div>-->
              <!-- <div class="ci-label">完成入场任务人数:</div>
                  <div class="ci-text">{{projectInfo.compete_inter_task_count || 0}}</div>
                </div>
                <div class="content-info">
                  <div class="ci-label">班组数量:</div>
                  <div class="ci-text">{{projectInfo.team_count || 0}}</div>
                </div>
              </div>-->
            </div>
            <div class="second-title">
              <div class="shu" />
              详细信息
            </div>
            <div class="edit-btn-box">
              <Button
                class="ebb-style"
                type="primary"
                v-if="!editFlag && projectInfo.sub_contract_flag != '1'"
                @click="toEditProject('to')"
                >编辑项目信息</Button
              >
              <Button
                class="ebb-style"
                type="success"
                v-if="editFlag"
                @click="toSaveProject"
                >保存</Button
              >
              <Button
                class="ebb-style"
                v-if="editFlag"
                @click="toEditProject('cancel')"
                >取消</Button
              >
            </div>
            <div
              class="cb-edit-box"
              :style="{ border: editFlag ? '1px solid #409eff' : '0px' }"
            >
              <div class="cebi" style="margin-right: 24px">
                <div class="cbe-item">
                  <div class="label">工程项目报建编码:</div>
                  <div class="show" v-if="!editFlag">
                    {{ projectInfo.record_code || "未填写" }}
                  </div>
                  <div class="inp" v-else>
                    <Input
                      v-model="projectInfo.record_code"
                      placeholder="请输入工程项目报建编码"
                      style="width: 280px"
                    />
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">项目负责人身份证:</div>
                  <div class="show" v-if="!editFlag">
                    <!-- {{ projectInfo.vresponsible_person_id_number || "未填写" }} -->
                    <span v-if="projectInfo.vresponsible_person_id_number==''">未填写</span>
                    <span v-else>{{projectInfo.vresponsible_person_id_number |  IdcardConcealFilter}}</span>

                  </div>
                  <div class="inp" v-else>
                    <Input
                      v-model="projectInfo.vresponsible_person_id_number"
                      placeholder="请输入项目负责人身份证"
                      style="width: 280px"
                    />
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">当前用户手机号:</div>
                  <div class="show" v-if="!editFlag">
                    <!-- {{ projectInfo.vresponsible_person_phone || "未填写" }} -->
                    <span v-if="projectInfo.vresponsible_person_phone==''">未填写</span>
                    <span v-else>{{projectInfo.vresponsible_person_phone |  phoneConcealFilter}}</span>
                  </div>
                  <div class="inp" v-else>
                    <Input
                      v-model="projectInfo.vresponsible_person_phone"
                      placeholder="请输入当前用户手机号"
                      style="width: 280px"
                    />
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">施工单位:</div>
                  <div class="show" v-if="!editFlag">
                    {{ projectInfo.vcontractor_corp_name || "未填写" }}
                  </div>
                  <div class="inp" v-else>
                    <Input
                      v-model="projectInfo.vcontractor_corp_name"
                      placeholder="请输入施工单位"
                      style="width: 280px"
                    />
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">培训二维码:</div>
                  <div class="show" v-if="!editFlag">
                    {{ projectInfo.is_qrcode === 1 ? "显示" : "隐藏" }}
                  </div>
                  <div class="inp" v-else>
                    <i-switch
                      size="large"
                      @on-change="changeQRCodeStatus"
                      :value="projectInfo.is_qrcode"
                      :true-value="1"
                      :false-value="0"
                    >
                      <span slot="open">显示</span>
                      <span slot="close">隐藏</span>
                    </i-switch>
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">工程地区:</div>
                  <div class="show" v-if="!editFlag">
                    {{ provinceName + " " + cityName }}
                  </div>
                  <div class="areabox" v-else>
                    <Select
                      v-model="province"
                      placeholder="省"
                      @on-change="changeProvince"
                      label-in-value
                      transfer
                      :value="province"
                    >
                      <Option
                        v-for="item in provinceList"
                        :value="item.value || ''"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                    <Select
                      v-model="city"
                      placeholder="市"
                      @on-change="changeCity"
                      label-in-value
                      transfer
                      :key="city"
                      :value="city"
                    >
                      <Option
                        v-for="item in cityList"
                        :value="item.value || ''"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">工程详细地址:</div>
                  <div class="show" v-if="!editFlag">
                    {{ projectInfo.vprojectaddress || "未填写" }}
                  </div>
                  <div class="inp" v-else>
                    <Input
                      v-model="projectInfo.vprojectaddress"
                      placeholder="请输入工程详细地址"
                      style="width: 280px"
                    />
                  </div>
                </div>
              </div>
              <!-- <div>
                <div>
                  <Select
                    v-model="province"
                    placeholder="省"
                    @on-change="changeProvince"
                    label-in-value
                    transfer
                    :value="province"
                  >
                    <Option
                      v-for="item in provinceList"
                      :value="item.value || ''"
                      :key="item.value"
                    >{{ item.label }}</Option>
                  </Select>
                </div>
                <div>
                  <Select
                    v-model="city"
                    placeholder="市"
                    @on-change="changeCity"
                    label-in-value
                    transfer
                    :key="city"
                    :value="city"
                  >
                    <Option
                      v-for="item in cityList"
                      :value="item.value || ''"
                      :key="item.value"
                    >{{ item.label }}</Option>
                  </Select>
                </div>
              </div>-->
              <div class="cebi">
                <div class="cbe-item">
                  <div class="label">工程造价(万元):</div>
                  <div class="show" v-if="!editFlag">
                    {{ projectInfo.dinvest || "未填写" }}
                  </div>
                  <div class="inp" v-else>
                    <Input
                      v-model="projectInfo.dinvest"
                      placeholder="请输入工程造价(万元)"
                      style="width: 280px"
                    />
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">建筑面积(㎡):</div>
                  <div class="show" v-if="!editFlag">
                    {{ projectInfo.dbuilding_area || "未填写" }}
                  </div>
                  <div class="inp" v-else>
                    <Input
                      v-model="projectInfo.dbuilding_area"
                      placeholder="请输入建筑面积(㎡)"
                      style="width: 280px"
                    />
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">工程类别:</div>
                  <div class="show" v-if="!editFlag">
                    <span v-if="projectInfo.vcategory">{{
                      projectInfo.vcategory
                        | vcategoryFormat(projectList.vcategoryList)
                    }}</span>
                    <span v-else>未选择</span>
                  </div>
                  <div class="inp" v-else>
                    <Select
                      v-model="projectInfo.vcategory"
                      style="width: 280px"
                      @on-change="selectProjectInfo"
                    >
                      <Option
                        v-for="item in projectList.vcategoryList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">工程用途:</div>
                  <div class="show" v-if="!editFlag">
                    <span v-if="projectInfo.vcategory">{{
                      projectInfo.vfunction_num
                        | vcategoryFormat(projectList.vfunction_numList)
                    }}</span>
                    <span v-else>未选择</span>
                  </div>
                  <div class="inp" v-else>
                    <Select
                      v-model="projectInfo.vfunction_num"
                      style="width: 280px"
                    >
                      <Option
                        v-for="item in projectList.vfunction_numList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">工程状态:</div>
                  <div class="show" v-if="!editFlag">
                    <span v-if="projectInfo.vcategory">{{
                      projectInfo.vprojectstatus
                        | vcategoryFormat(projectList.vprojectstatusList)
                    }}</span>
                    <span v-else>未选择</span>
                  </div>
                  <div class="inp" v-else>
                    <Select
                      v-model="projectInfo.vprojectstatus"
                      style="width: 280px"
                    >
                      <Option
                        v-for="item in projectList.vprojectstatusList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                </div>
                <div class="cbe-item">
                  <div class="label">结构类型:</div>
                  <div class="show" v-if="!editFlag">
                    <span v-if="projectInfo.vcategory">{{
                      projectInfo.frame_type
                        | vcategoryFormat(projectList.frame_typeList)
                    }}</span>
                    <span v-else>未选择</span>
                  </div>
                  <div class="inp" v-else>
                    <Select
                      v-model="projectInfo.frame_type"
                      style="width: 280px"
                      placement="top"
                    >
                      <Option
                        v-for="item in projectList.frame_typeList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                </div>
                <div class="cbe-item" v-if="areaAuthFlag">
                  <div class="label">所属片区:</div>
                  <div class="show" v-if="!editFlag">
                    <span v-if="projectInfo.zone_code">{{
                      projectInfo.zone_code | vcategoryFormat(zoneList)
                    }}</span>
                    <span v-else>未选择</span>
                  </div>
                  <div class="inp" v-else>
                    <Select
                      v-model="projectInfo.zone_code"
                      style="width: 280px"
                      placement="top"
                      disabled
                    >
                      <Option
                        v-for="item in zoneList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="second-title"
              v-if="projectInfo.sub_contract_flag != '1'"
            >
              <div class="shu" />
              项目服务
            </div>
            <div class="cb-bigbox" v-if="projectInfo.sub_contract_flag != '1'">
              <div class="cb-serve-box">
                <div class="sitem">
                  <div class="si-icon" style="background: #2d8cf0">
                    <img src="../../assets/images/dzq.png" alt />
                  </div>
                  <div class="si-info">
                    <div class="sii-lable">电子签名</div>
                    <div class="sii-btn">
                      <div v-if="projectInfo.has_pay_sign == 2">审核中</div>
                      <i-switch
                        size="large"
                        v-model="projectInfo.has_pay_sign"
                        @on-change="handelSignatureChange"
                        :true-value="1"
                        :false-value="0"
                        v-if="projectInfo.has_pay_sign != 2"
                      >
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                      </i-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </Card>
          <Card
            style="
              width: 100%;
              margin-bottom: 20px;
              max-width: 1300px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            v-else
          >
            <div class="no-project-box">
              <div class="addproject-btn-box" @click="addNewProject">
                <Icon
                  type="md-add"
                  color="#fff"
                  size="16"
                  style="margin-right: 4px"
                />
                <div>新增项目</div>
              </div>
              <div class="tips-img">
                <img src="../../assets/images/tage_tips.png" alt />
              </div>
              <div class="tips-txt">
                您还没有项目哦，点击"新增项目"按钮添加项目吧~
              </div>
            </div>
          </Card>
        </div>
        <CountsModule
          ref="my_count"
          v-if="!noProjectFlag && currentContentBox == 'info'"
          :projectId="projectInfo.id"
          :icorpId="projectInfo.icorpid"
          :key="countSModuleTimer"
          :numOne="projectInfo.total_person_count"
          :numTwo="projectInfo.person_count"
          :numThree="projectInfo.is_removed_person_count"
        />
      </div>
      <!-- 培训任务 -->
      <div class="content-box" v-if="currentContentBox == 'task'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="team-project-box">
            <Button type="primary" class="tmb-btn-style" @click="toAddProject"
              >新增任务</Button
            >
            <div class="search-part">
              <Input
                search
                enter-button
                placeholder="请输入搜索关键词"
                class="sp-inp"
              />
              <Button>重置</Button>
            </div>
          </div>
          <Table :columns="projectColumsList" :data="projectDataList">
            <template slot-scope="{ row, index }" slot="action">
              <div class="btnbox-flex">
                <Button class="bf-style" type="success">使用</Button>
                <!-- <Button type="warning">停用</Button> -->
                <Button type="primary">编辑</Button>
              </div>
            </template>
          </Table>
          <page show-total class="page-box" :total="100" />
        </Card>
      </div>
      <!-- 项目授权 -->
      <div class="content-box" v-if="currentContentBox == 'right'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <Button
            type="primary"
            v-if="
              currentUserInfo.dg_key === 1 ||
              (currentUserInfo.dg_key === 3 && currentUserInfo.rid === 3) ||
              (currentUserInfo.dg_key === 3 && currentUserInfo.rid === 6)
            "
            @click="addAuth('open')"
            >新增授权</Button
          >
          <Table
            :columns="authColumnsList"
            :data="authDataList"
            :loading="authTableLoading"
          >
            <template slot-scope="{ row, index }" slot="action">
              <Button
                v-if="
                  currentUserInfo.dg_key === 1 ||
                  (currentUserInfo.dg_key === 3 && currentUserInfo.rid === 3) ||
                  (currentUserInfo.dg_key === 3 && currentUserInfo.rid === 6)
                "
                type="warning"
                @click="removeAuth(row)"
                >移除</Button
              >
              <span v-if="row.name||row.phone" style="margin-left:20px">
                  <Button
                  v-if="row.showOrHide=='hide'
                  "
                  type="primary"
                  @click="showAuthConceal(row)"
                  >显示</Button
                >
                <Button
                  v-else
                  type="primary"
                  @click="hideAuthConceal(row)"
                  >隐藏</Button
                >
              </span>
              

            </template>
          </Table>
          <!-- <page show-total class="page-box" :total="100" /> -->
        </Card>
      </div>
      <!-- 班组管理 -->
      <div class="content-box" v-if="currentContentBox == 'team'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="team-manage-box">
            <div class="search-part">
              <Input
                search
                style="width: 280px"
                enter-button
                placeholder="请输入搜索班组的名称或关键词"
                @on-search="toSearchTeam"
                v-model="searchTeamTxt"
                class="sp-inp"
              />
              <Button @click="clearSearchTeam">重置</Button>
            </div>
            <Button
              v-if="projectInfo.sub_contract_flag != '1'"
              type="primary"
              @click="addTeam"
              >新增班组</Button
            >
          </div>
          <Table
            stripe
            size="small"
            :columns="teamColumnsList"
            :data="teamDataList"
            :loading="teamTableLoading"
          >
            <template slot-scope="{ row }" slot="statusStr">
              {{ row.isExit == 1 ? "班组退场" : row.statusStr }}
            </template>
            <template slot-scope="{ row, index }" slot="qrCode">
              <div class="table-qrcode-img" @click="showQR(row.qrCodeUrl)">
                <img :src="row.qrCodeUrl" alt />
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="action">
              <Button
                v-if="
                  row.status == 1 &&
                  projectInfo.sub_contract_flag != '1' &&
                  row.isExit != '1'
                "
                type="success"
                size="small"
                style="margin-right: 5px; margin-bottom: 4px; margin-top: 4px"
                @click="handleTeamItem('recovery', row.id)"
                >启用加入班组</Button
              >
              <Button
                v-if="
                  row.status == 0 &&
                  projectInfo.sub_contract_flag != '1' &&
                  row.isExit != '1'
                "
                type="warning"
                size="small"
                style="margin-right: 5px; margin-bottom: 4px; margin-top: 4px"
                @click="handleTeamItem('suspension', row.id)"
                >暂停加入班组</Button
              >
              <Button
                type="primary"
                size="small"
                style="margin-right: 5px; margin-bottom: 4px; margin-top: 4px"
                @click="toTeamEdit(row)"
                >班组详情</Button
              >
              <Button
                v-if="projectInfo.sub_contract_flag != '1' && row.isExit != '1'"
                type="error"
                size="small"
                style="margin-right: 5px; margin-bottom: 4px; margin-top: 4px"
                @click="toTeamExit(row)"
                >班组退场</Button
              >

              <div v-if="row.teamLeaderName||row.teamLeaderPhone">
                <Button
                  size="small"
                  v-if="row.showOrHide=='hide'
                  "
                  type="primary"
                  @click="showTeamConceal(row)"
                  >显示</Button
                >
                <Button
                  size="small"
                  v-else
                  type="primary"
                  @click="hideTeamConceal(row)"
                  >隐藏</Button
                >
              </div>
              <Button
                type="primary"
                size="small"
                style="margin-right: 5px; margin-bottom: 4px; margin-top: 4px"
                @click="toExportPDF(row)"
              >导出工种课程PDF</Button>
              <!-- <Button type="error" size="small" @click="handleTeamItem('delete',row.id)">删除</Button> -->
            </template>
          </Table>
          <page
            show-total
            class="page-box"
            @on-change="toTeamPageChange"
            :page-size="10"
            :total="teamListTotal"
          />
        </Card>
      </div>
      <!-- 考勤管理 - 设备管理 -->
      <CheckByEquipment
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="!mobileAttendanceSwitch && currentContentBox == 'oncheck1'"
      />
      <AttendanceRules
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="mobileAttendanceSwitch && currentContentBox == 'oncheck1'"
      />
      <!-- 考勤管理 - 班组管理 -->
      <CheckByTeam
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'oncheck2'"
      />
      <!-- 考勤管理 - 考勤统计 -->
      <CheckByList
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'oncheck3'"
      />
      <!-- 考勤管理 - 考勤规则 -->
      <AttendanceRules
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'oncheck4'"
      />
      <!-- 考勤管理 - 考勤数据 -->
      <AttendanceData
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'oncheck5'"
      />
      <!-- 考勤管理-设备管理 -->
      <!-- 设备管理 -->
      <!-- <div
        class="content-box"
        v-if="
          currentContentBox == 'equipment' || currentContentBox == 'oncheck1'
        "
      >
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="team-manage-box">
            <Button type="primary" @click="showModal('addEquipment')"
              >新增设备</Button
            >
          </div>
          <Table
            stripe
            size="small"
            :columns="equipmentColumnsList"
            :data="equipmentDataList"
            width="100%"
            :loading="checkInRelateTableLoading"
          >
            <template slot-scope="{ row }" slot="status">
              {{ row.status == 1 ? "正常" : "断连" }}
            </template>
            <template slot-scope="{ row, index }" slot="action">
              <Button
                type="primary"
                size="small"
                @click="toEquipmentEdit(row)"
                style="margin-right: 5px"
                >查看详情</Button
              >
              <Button
                type="info"
                size="small"
                style="margin-right: 5px"
                @click="showModal('bindEquipment', row)"
                >绑定班组</Button
              >
            </template>
          </Table>
          <page
            show-total
            @on-change="handelEquipmentListPageChange"
            class="page-box"
            :page-size="10"
            :total="checkInRelateTotal"
          />
        </Card>
      </div> -->
      <!-- 考勤管理-考勤数据 -->
      <!-- 考勤数据 -->
      <!-- <div
        class="content-box"
        v-if="currentContentBox == 'check' || currentContentBox == 'oncheck2'"
      >
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="second-title">
            <div class="shu" />
            考勤数据筛选条件
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">姓名：</div>
                <Input
                  v-model="attendanceSearchData.aName"
                  placeholder="请输入班组人员姓名"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">状态：</div>
                <Select
                  v-model="attendanceSearchData.aStatus"
                  style="width: 150px; margin-right: 14px"
                >
                  <Option
                    v-for="item in checkStatusList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="mb-item">
                <div class="label">考勤天数时间范围：</div>
                <DatePicker
                  @on-change="handelAttendanceDateChange"
                  v-model="attendanceDate"
                  type="daterange"
                  placement="bottom-end"
                  placeholder="选择时间"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
            </div>
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">班组：</div>
                <Select
                  :loading="attendanceTeamListLoading"
                  v-model="attendanceSearchData.aTeamList"
                  multiple
                  :max-tag-count="2"
                  style="width: 260px; margin-right: 14px"
                >
                  <Option
                    v-for="item in checkTeamList"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.vteamName }}</Option
                  >
                </Select>
              </div>
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handelAttendanceSearch('ok')"
                >搜索</Button
              >
              <Button @click="handelAttendanceSearch('reset')">重置</Button>
            </div>
            <div class="c-btnbox-item"></div>
          </div>
          <div class="second-title">
            <div class="shu" />
            导出考勤表筛选
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">导出日期范围（月）：</div>
                <DatePicker
                  @on-change="handelExportDateChange"
                  type="month"
                  placement="bottom-end"
                  placeholder="请选择考勤表日期"
                  style="width: 200px; margin-right: 14px"
                ></DatePicker>
              </div>
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="exportTable"
                >导出考勤数据表</Button
              >
              <Button type="primary" style @click="updateCheck"
                >更新考勤数据</Button
              >
            </div>
          </div>
          <Table
            stripe
            :loading="attendanceDataTableLoading"
            size="small"
            :columns="checkColumsList"
            :data="checkDataList"
            width="100%"
          ></Table>
          <page
            show-total
            class="page-box"
            @on-change="handelAttendanceListPageChange"
            :page-size="10"
            :total="attendanceListTotal"
          />
        </Card>
      </div> -->
      <!-- 新增项目 -->
      <div class="content-box" v-if="currentContentBox == 'newProject'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <Spin fix v-if="addNewProjectDataLoading">
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            ></Icon>
            <div>Loading</div>
          </Spin>
          <div class="second-title">
            <div class="shu" />
            新增项目
          </div>
          <div class="addnew-content">
            <div class="ac-part">
              <div class="ac-item">
                <div class="label">项目编码：</div>
                <Input
                  v-model="addNewProjectData.vprojectcode"
                  disabled
                  placeholder="项目编码由系统自动生成"
                  clearable
                  style="width: 300px"
                />
              </div>
              <div class="ac-item ac-item-must">
                <div class="label">项目名称：</div>
                  <!-- v-if="!userInfo.richForceProjectSelectSwitch" -->
                <Input
                 v-if="!userInfo.richForceProjectSelectSwitch" 
                  v-model="addNewProjectData.vprojectname"
                  placeholder="请输入项目名称"
                  clearable
                  style="width: 300px"
                
                />
                <Select
                  v-if="userInfo.richForceProjectSelectSwitch"
                  v-model="addNewProjectData.vprojectname"
                  style="width: 300px"
                  placeholder="请选择项目名称"
                  @on-change="selectProjectName"
                >
                  <Option
                    v-for="item in projectNameInfo"
                    :value="item.vbillname"
                    :key="item.vbillname"
                    >{{ item.vbillname }}</Option
                  >
                </Select>
              </div>
              <div class="ac-item ac-item-must">
                <div class="label">项目负责人：</div>
                <Input
                  v-model="addNewProjectData.vresponsible_person_name"
                  placeholder="请输入项目负责人"
                  clearable
                  style="width: 300px"
                />
              </div>
              <div class="ac-item ac-item-must">
                <div class="label">项目负责人手机号：</div>
                <Input
                  v-model="addNewProjectData.vresponsible_person_phone"
                  placeholder="请输入项目负责人手机号"
                  clearable
                  style="width: 300px"
                />
              </div>
              <div class="ac-item ac-item-must">
                <div class="label">施工单位：</div>
                <Input
                  v-model="addNewProjectData.vcontractor_corp_name"
                  placeholder="请输入施工单位"
                  clearable
                  style="width: 300px"
                />
              </div>
              <div class="ac-item ac-item-must">
                <div class="label">选择省、市：</div>
                <div class="ac-areaBox">
                  <Select
                    placeholder="省"
                    @on-change="changeProvinceNew"
                    label-in-value
                    transfer
                    :value="province"
                  >
                    <Option
                      v-for="item in provinceList"
                      :value="item.value || ''"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                  <Select
                    placeholder="市"
                    @on-change="changeCityNew"
                    label-in-value
                    transfer
                    :value="city"
                  >
                    <Option
                      v-for="item in cityList"
                      :value="item.value || ''"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </div>
              </div>
              <div class="ac-item ac-item-must">
                <div class="label">工程地址：</div>
                <Input
                  v-model="addNewProjectData.vprojectaddress"
                  placeholder="请输入工程地址"
                  clearable
                  style="width: 300px"
                />
              </div>
              <div class="ac-item ac-item-must" v-if="areaAuthFlag">
                <div class="label">所属片区：</div>
                <Select v-model="zoneData" transfer style="width: 300px">
                  <Option
                    v-for="item in zoneList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="ac-item">
                <div class="label">工程项目报建编码：</div>
                <Input
                  v-model="addNewProjectData.record_code"
                  placeholder="请输入工程项目报建编码"
                  clearable
                  style="width: 300px"
                />
              </div>
              <div class="ac-item">
                <div class="label">项目工种：</div>
                <Select
                  v-model="addNewWorkType"
                  multiple
                  :max-tag-count="2"
                  style="width: 300px"
                  transfer
                >
                  <Option
                    v-for="item in corpWorkTypeList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.name }}</Option
                  >
                </Select>
              </div>
            </div>
            <div class="ac-part">
              <div class="ac-item">
                <div class="label">项目负责人身份证：</div>
                <Input
                  v-model="addNewProjectData.vresponsible_person_id_number"
                  placeholder="请输入项目负责人身份证"
                  clearable
                  style="width: 300px"
                />
              </div>
              <div class="ac-item">
                <div class="label">当前用户手机号：</div>
                <Input
                  v-model="addNewProjectData.phone"
                  disabled
                  :placeholder="currentUserInfo.login_id"
                  clearable
                  style="width: 300px"
                />
              </div>
              <div class="ac-item">
                <div class="label">工程类别：</div>
                <Select
                  v-model="addNewProjectData.vcategory"
                  style="width: 300px"
                >
                  <Option
                    v-for="item in projectList.vcategoryList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="ac-item">
                <div class="label">工程用途：</div>
                <Select
                  v-model="addNewProjectData.vfunction_num"
                  style="width: 300px"
                >
                  <Option
                    v-for="item in projectList.vfunction_numList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="ac-item">
                <div class="label">工程状态：</div>
                <Select
                  v-model="addNewProjectData.vprojectstatus"
                  style="width: 300px"
                >
                  <Option
                    v-for="item in projectList.vprojectstatusList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="ac-item">
                <div class="label">结构类型：</div>
                <Select
                  v-model="addNewProjectData.frame_type"
                  style="width: 300px"
                >
                  <Option
                    v-for="item in projectList.frame_typeList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="ac-item">
                <div class="label">建筑面积(㎡)：</div>
                <Input
                  v-model="addNewProjectData.dbuilding_area"
                  placeholder="请输入建筑面积"
                  clearable
                  style="width: 300px"
                />
              </div>
              <div class="ac-item">
                <div class="label">工程造价(万元)：</div>
                <Input
                  v-model="addNewProjectData.dinvest"
                  placeholder="请输入工程造价"
                  clearable
                  style="width: 300px"
                />
              </div>
            </div>
          </div>
          <div class="addnew-btnbox">
            <Button type="success" @click="toAddNewProject">新增项目</Button>
          </div>
        </Card>
      </div>
      <!-- 工种管理 -->
      <WorkTypeTemp
        :ProjectInfo="projectInfo"
        :UserInfo="currentUserInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'worktype'"
      />
      <!-- 花名册管理 -->
      <RosterListTemp
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'rosterlist'"
      />
       <!-- 内部培训管理 任务列表 -->
      <TaskListTemp
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'taskList'"
      />
      <!-- <div class="content-box" v-if="currentContentBox == 'rosterlist'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="second-title">
            <div class="shu" />
            项目在场人员管理
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">姓名：</div>
                <Input
                  v-model="searchPeopleList.name"
                  placeholder="请输入人员姓名"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">手机号码：</div>
                <Input
                  v-model="searchPeopleList.phone"
                  placeholder="请输入人员手机号码"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">身份证号码：</div>
                <Input
                  v-model="searchPeopleList.idcard"
                  placeholder="请输入人员身份证号码"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
            </div>
            <div class="c-btnbox-item">
            </div>
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handleSearchPeopleList"
                >搜索</Button
              >
              <Button
                style="margin-right: 14px"
                @click="handleSearchPeopleList('reset')"
                >重置</Button
              >
            </div>
            <div class="c-btnbox-item">
              <Button
                type="info"
                style="margin-right: 14px"
                @click="toDownRoster"
                >下载花名册模板</Button
              >
              <Upload
                action="/gbApi/uploadFile"
                :name="'file'"
                :multiple="false"
                :before-upload="onBefore"
                :accept="Accept"
                :format="Format"
                :data="uploadData"
              >
                <Button type="success" icon="ios-cloud-upload-outline"
                  >导入花名册</Button
                >
              </Upload>
            </div>
            <div class="c-btnbox-item">
              <Alert type="warning" show-icon
                >黄色名单为未注册在该项目下的人员。</Alert
              >
            </div>
          </div>
          <div class="second-title">
            <div class="shu" />
            项目在场人员列表
          </div>
          <Table
            :columns="rosterColumsList"
            :data="rosterDataList"
            :loading="rosterListLoading"
            :row-class-name="rosterClassName"
            :width="screenWidth - 400"
          >
            <template slot-scope="{ row, index }" slot="workType">
              <div class="btnbox-flex">
                <div>
                  {{ row.workType | workTypeFilters(projectWorkTypeList) }}
                </div>
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="status">
              <div class="btnbox-flex">
                <div>{{ row.isRemoved == "0" ? "在职" : "离岗" }}</div>
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="action">
              <div class="btnbox-flex">
                <Button
                  type="warning"
                  v-if="row.isExistInProject == 1 && row.isRemoved == 0"
                  @click="toExitPeople(row.idcard)"
                  >离岗</Button
                >
              </div>
            </template>
          </Table>
          <page
            show-total
            class="page-box"
            :page-size="rosterListSize"
            :total="rosterListTotal"
            @on-change="changeRosterListPage"
          />
        </Card>
      </div> -->
      <!-- 分包项目 -->
      <div
        class="content-box"
        v-if="
          currentContentBox == 'subcontract' &&
          projectInfo.sub_contract_flag != '1'
        "
      >
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="second-title">
            <div class="shu" />
            分包项目列表
          </div>
          <Table
            :columns="subcColumsList"
            :data="subcDataList"
            :loading="subContractLoading"
          >
            <!-- <template slot-scope="{ row, index }" slot="action">
              <div class="btnbox-flex">
                <Button type="warning">移除</Button>
              </div>
            </template> -->
          </Table>
          <page
            show-total
            class="page-box"
            :page-size="subContractSize"
            :total="subContractTotal"
            @on-change="changeSubcPage"
          />
        </Card>
      </div>
      <!-- 设备管理--详情页 -->
      <div
        class="content-box content-box-column"
        v-if="currentContentBox == 'equipmentEdit'"
      >
        <Card style="width: 100%; margin-bottom: 10px">
          <div class="second-title">
            <div class="shu" />
            设备信息
          </div>
          <div class="ccc-box">
            <div class="cb-item">
              <div class="cbi-li">
                <div class="label">设备名：</div>
                <div class="info">{{ currentEquipmentData.name }}</div>
              </div>
              <div class="cbi-li">
                <div class="label">设备序列号：</div>
                <div class="info">{{ currentEquipmentData.serialNo }}</div>
              </div>
              <div class="cbi-li">
                <Button type="primary" @click="showModal('bindEquipment')"
                  >绑定班组</Button
                >
              </div>
            </div>
            <div class="cb-item2">
              <div class="content-info">
                <div class="ci-label">班组绑定:</div>
                <div class="ci-text">
                  {{ currentEquipmentData.teamCount || 0 }}
                </div>
              </div>
              <div class="content-info">
                <div class="ci-label">绑定人数:</div>
                <div class="ci-text">
                  {{ currentEquipmentData.issueCount || 0 }}
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Row style="width: 100%; margin-bottom: 20px" :gutter="10">
          <i-col span="6">
            <Spin fix v-if="equipmentTeamLoading">
              <Icon
                type="ios-loading"
                size="18"
                class="demo-spin-icon-load"
              ></Icon>
              <div>Loading</div>
            </Spin>
            <Card>
              <div class="second-title">
                <div class="shu" />
                绑定设备班组列表
              </div>
              <div class="mainbox">
                <!-- <Select v-model="equipmentTeamSearch" @on-change="searchEquipmentTeam" filterable placeholder="请输入搜索的班组名">
                  <Option
                    v-for="item in equipmentTeamSearchList"
                    :value="item.id"
                    :key="item.id"
                  >{{ item.vteamName }}</Option>
                </Select>-->
                <div class="mbbox">
                  <Input
                    style="margin-right: 10px"
                    search
                    enter-button="搜索"
                    placeholder="输入搜索班组"
                    v-model="equipmentTeamSearch"
                    @on-search="searchEquipmentTeam"
                  />
                  <Button @click="handelReset('list')">重置</Button>
                </div>
                <div class="equipmentList" v-if="equipmentTeamList.length > 0">
                  <div
                    class="e-item"
                    :class="[eitem.pickFlag ? 'e-item-active' : '']"
                    v-for="(eitem, index) in equipmentTeamList"
                    :key="index"
                    @click="pickEquipmentTeam(index)"
                  >
                    <div class="label">{{ eitem.vteamName }}</div>
                    <Icon type="ios-arrow-forward" />
                  </div>
                </div>
                <div v-else style="margin-top: 5px; padding-left: 5px" class>
                  无班组信息。
                </div>
                <page
                  show-total
                  class="page-box"
                  @on-change="changePageEquipmentList"
                  :page-size="10"
                  :total="equipmentListTotal"
                />
              </div>
            </Card>
          </i-col>
          <i-col span="18">
            <Spin fix v-if="equipmentTeamDetailLoading">
              <Icon
                type="ios-loading"
                size="18"
                class="demo-spin-icon-load"
              ></Icon>
              <div>Loading</div>
            </Spin>
            <Card>
              <div class="second-title">
                <div class="shu" />
                绑定设备班组详情
              </div>
              <div class="mainbox">
                <div class="m-status">
                  <div class="left">
                    <div class="left-i">
                      班组名称：{{ currentEquipmentTeamData.vteamName }}
                    </div>
                    <div class="left-i">
                      负责人名字：{{
                        currentEquipmentTeamData.vresponsiblePersonName
                      }}
                    </div>
                    <div class="left-i">
                      负责人手机：{{
                        currentEquipmentTeamData.vresponsiblePersonPhone
                      }}
                    </div>
                  </div>
                  <div class="left">
                    <div class="left-i">
                      班组总人数：{{ currentEquipmentTeamData.memberCount }}
                    </div>
                    <div class="left-i">
                      已绑定考勤机人数：{{
                        currentEquipmentTeamData.issueCount
                      }}
                    </div>
                    <div class="left-i">
                      绑定时间：{{ currentEquipmentTeamData.bindDate }}
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    type="success"
                    @click="toUpdateCheck"
                    style="margin-right: 14px"
                    >更新考勤数据</Button
                  >
                  <Button type="warning" @click="toRemoveCheck"
                    >解绑考勤机</Button
                  >
                </div>
                <Divider size="small"></Divider>
                <div class="m-btnbox">
                  <Input
                    style="width: 250px; margin-right: 14px"
                    search
                    enter-button="搜索"
                    placeholder="请输入班组人员姓名"
                    v-model="equipmentTeamDataSearch.eName"
                    @on-search="searchEquipmentTeamDetail"
                  />
                  <div class="mb-item">
                    <div class="label">在职情况：</div>
                    <Select
                      v-model="equipmentTeamDataSearch.eJob"
                      style="width: 150px; margin-right: 14px"
                      @on-change="searchEquipmentTeamDetail()"
                    >
                      <Option
                        v-for="item in equipmentUserSearchList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                  <div class="mb-item">
                    <div class="label">考勤状态：</div>
                    <Select
                      v-model="equipmentTeamDataSearch.eStatus"
                      @on-change="searchEquipmentTeamDetail()"
                      style="width: 150px"
                    >
                      <Option
                        v-for="item in equipmentUserJobSearchList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                  <Button
                    style="margin-left: 14px"
                    @click="handelReset('detail')"
                    >重置</Button
                  >
                </div>
                <Table
                  :size="'small'"
                  :columns="equipmentUserColumsList"
                  :data="equipmentUserDataList"
                  :loading="equipmentUserTableLoading"
                >
                  <template slot-scope="{ row, index }" slot="qrCode">
                    <div
                      class="table-qrcode-img"
                      @click="showQR(row.faceidImage)"
                    >
                      <img v-if="row.faceidImage" :src="row.faceidImage" alt />
                      <img
                        v-else
                        src="../../assets/images/test_head_icon.png"
                        alt
                      />
                    </div>
                  </template>
                  <template slot-scope="{ row, index }" slot="action">
                    <div class="btnbox-flex">
                      <Button
                        class="bf-style"
                        @click="issueSttendance(row.id, row.clockinStatus)"
                        type="primary"
                        >下发考勤机</Button
                      >
                    </div>
                  </template>
                </Table>
                <page
                  show-total
                  class="page-box"
                  @on-change="changePageEquipmentTeam"
                  :page-size="10"
                  :total="equipmentTeamUserTotal"
                />
              </div>
            </Card>
          </i-col>
        </Row>
      </div>
      <!-- 班组编辑 -->
      <div class="content-box" v-if="currentContentBox == 'teamEdit'">
        <Card style="width: 100%; margin-bottom: 20px; max-width: 1300px">
          <div class="second-title">
            <div class="shu" />
            班组信息
            <span  class="showBasicOrHideBasic">
              <span v-if="teamHide" @click="showTeamConceal(currentTeamData)" >显示</span> 
              <span v-else @click="hideTeamConceal(currentTeamData)">隐藏</span>
            </span>
          </div>
          <div class="cb-tasks-box" style="margin-left: 8px">
            <div class="tasks-msg">
              <div class="content-info">
                <div class="ci-label">班组名称:</div>
                <input
                  class="ci-inp"
                  type="text"
                  v-model="teamTasksData.teamName"
                  v-if="teamTasksDataChange"
                />
                <div class="ci-text" v-else>
                   {{teamTasksData.teamName}}
                </div>
              </div>
              <div class="content-info">
                <div class="ci-label">班组长姓名:</div>
                <input
                  class="ci-inp"
                  type="text"
                  v-model="teamTasksData.teamLeaderName"
                  v-if="teamTasksDataChange"
                  disabled
                  style="border: 0"
                />
                <div class="ci-text" v-else>
                  {{ currentTeamData.teamLeaderName }}
                </div>
              </div>
              <div class="content-info">
                <div class="ci-label">班组长手机号码:</div>
                <input
                  class="ci-inp"
                  type="text"
                  v-model="teamTasksData.teamLeaderPhone"
                  v-if="teamTasksDataChange"
                  disabled
                  style="border: 0"
                />
                <div class="ci-text" v-else>
                  {{ currentTeamData.teamLeaderPhone }}
                </div>
              </div>
              <div class="content-info">
                <div class="ci-label">状态:</div>
                <div class="ci-select" v-if="teamTasksDataChange">
                  <Dropdown :trigger="'click'">
                    <a href="javascript:void(0)" style="color: #fff">
                      {{ teamTasksData.statusStr }}
                      <Icon type="ios-arrow-down"></Icon>
                    </a>
                    <DropdownMenu slot="list">
                      <DropdownItem
                        @click.native="changeTeamStatus('0', '正常')"
                        >正常</DropdownItem
                      >
                      <DropdownItem
                        @click.native="changeTeamStatus('1', '中止 ')"
                        >中止</DropdownItem
                      >
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div class="ci-text" v-else>
                  {{ currentTeamData.statusStr }}
                </div>
              </div>
              <div class="content-info">
                <div class="ci-label">加入班组免审核:</div>
                <div class="ci-select" v-if="teamTasksDataChange">
                  <Dropdown :trigger="'click'">
                    <a href="javascript:void(0)" style="color: #fff">
                      {{ teamTasksData.isNoAudit == 1 ? "是" : "否" }}
                      <Icon type="ios-arrow-down"></Icon>
                    </a>
                    <DropdownMenu slot="list">
                      <DropdownItem @click.native="changeTeamAuto('0')"
                        >否</DropdownItem
                      >
                      <DropdownItem @click.native="changeTeamAuto('1')"
                        >是</DropdownItem
                      >
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div class="ci-text" v-else>
                  {{ teamTasksData.isNoAudit == 1 ? "是" : "否" }}
                </div>
              </div>
              <div
                class="cb-edit-btn"
                @click="editTeamTasks"
                v-if="projectInfo.sub_contract_flag != '1'"
              >
                <div class="l-edit">
                  <img
                    v-if="teamTasksDataChange"
                    src="../../assets/images/little-confirm.png"
                    alt
                  />
                  <img v-else src="../../assets/images/little-edit.png" alt />
                </div>
              </div>
            </div>
            <div class="tasks-statistics">
              <div class="content-info">
                <div class="ci-label">总人数:</div>
                <div class="ci-text">{{ currentTeamData.totalNum }}人</div>
              </div>
              <div class="content-info">
                <div class="ci-label">已完成全部任务人数:</div>
                <div class="ci-text">{{ currentTeamData.finishNum }}人</div>
              </div>
              <div class="content-info">
                <div class="ci-label">在岗总人数:</div>
                <div class="ci-text">{{ currentTeamData.dutyCount }}人</div>
              </div>
            </div>
          </div>
          <div class="second-title">
            <div class="shu" />
            成员信息
          </div>
          <div>
            <!-- <Table
              :columns="teamManUserColumnsList"
              :data="teamManUserDataList"
              :loading="teamUserTableLoading"
              ref="teamtable"
            > -->
            <div class="c-btnbox">
              <div class="c-btnbox-item" style="display: flex; flex-warp: warp">
                <div class="mb-item" style="margin-right: 14px">
                  <div class="label">姓名：</div>
                  <Input
                    v-model="searchTeamItemData.name"
                    placeholder="请输入搜索人员姓名"
                    style="width: 200px"
                  />
                </div>
                <div class="mb-item" style="margin-right: 14px">
                  <div class="label">手机号码：</div>
                  <Input
                    v-model="searchTeamItemData.phone"
                    placeholder="请输入搜索人员手机号码"
                    style="width: 200px"
                  />
                </div>
                <div class="mb-item" style="margin-right: 14px">
                  <div class="label">身份证：</div>
                  <Input
                    v-model="searchTeamItemData.idcard"
                    placeholder="请输入搜索人员身份证号"
                    style="width: 200px"
                  />
                </div>
                <div class="mb-item" style="margin-right: 14px">
                  <div class="label">在职状态：</div>
                  <Select
                    v-model="searchTeamItemData.status"
                    style="width: 150px"
                  >
                    <Option
                      v-for="item in checkStatusList"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </div>
              </div>
              <div class="c-btnbox-item" style="margin-bottom: 14px">
                <div>
                  <Button
                    type="primary"
                    style="margin-right: 14px"
                    @click="handleSearchTeamItem"
                    >搜索</Button
                  >
                  <Button @click="handleSearchTeamItem('reset')">重置</Button>
                </div>
              </div>
            </div>
            <Table
              :columns="teamManUserColumnsList"
              :data="teamManUserDataList"
              :loading="teamUserTableLoading"
              highlight-row
              @on-current-change="handleTeamTablePick"
              ref="teamtable"
            >
              <template slot-scope="{ row, index }" slot="teamUserSex">
                <div class>{{ row.bsex == "2" ? "女" : "男" }}</div>
              </template>
              <template slot-scope="{ row, index }" slot="teamUserLeader">
                <i-switch
                  :disabled="row.isTeamLeader == 1 ? true : false"
                  :value="row.isTeamLeader"
                  :true-value="1"
                  :false-value="0"
                  @on-change="handleTeamLeaderChange(row)"
                  v-if="updateSwitch"
                >
                  <span slot="open">是1</span>
                  <span slot="close">否</span>
                </i-switch>
              </template>
              <template slot-scope="{ row, index }" slot="teamUserIcon">
                <div class="head-img" @click="showQR(row.vpicurl)">
                  <img v-if="row.vpicurl" :src="row.vpicurl" alt />
                  <img
                    v-else
                    src="../../assets/images/test_head_icon.png"
                    alt
                  />
                </div>
              </template>
              <template
                slot-scope="{ row, index }"
                slot="action"
                v-if="projectInfo.sub_contract_flag != '1'"
              >
              <div>
                <Button
                  v-if="row.isRemoved == '0'"
                  type="warning"
                  @click="changeTeamUserStatus('remove', row)"
                  >离岗</Button
                >
                <Button
                  v-else
                  type="success"
                  @click="changeTeamUserStatus('restore', row)"
                  >返岗</Button
                >
              </div>
                
                <div v-if="row.vname||row.phone||row.vidcard" style="margin-top:10px">
                  <Button
                  v-if="row.showOrHide=='hide'
                  "
                  type="primary"
                  @click.stop="showTeamUserConceal(row)"
                  >显示</Button
                  >
                  <Button
                    v-else
                    type="primary"
                    @click.stop="hideTeamUserConceal(row)"
                    >隐藏</Button
                  >

                </div>

              </template>
            </Table>
            <div class="fun-page-box">
              <Button @click="handleClearTeamUser" style="margin-right: 14px"
                >清除列表选中</Button
              >
              <page
                show-total
                @on-change="toTeamUserPageChange"
                :page-size="5"
                :total="teamUserTotal"
              />
            </div>
            <Divider />
            <Tabs
              type="card"
              @on-click="pickTeamUserTab"
              v-model="teamUserTabFlag"
            >
              <TabPane label="参保登记" name="insured"></TabPane>
              <TabPane label="教育情况" name="education"></TabPane>
              <TabPane label="资质证书" name="qualifications"></TabPane>
              <TabPane label="体检报告" name="medically"></TabPane>
              <TabPane label="劳动合同" name="labor"></TabPane>
            </Tabs>
            <!-- 参保登记 -->
            <div class="tab-content-box" v-if="teamUserTabFlag == 'insured'">
              <div class="tab-nullbox" v-if="!teamUserTabPickFlag">
                <div class="nulltips">请先选择人员</div>
              </div>
              <div v-else>
                <Upload
                  action="/sapi/blackList/upload"
                  :name="'file'"
                  :multiple="false"
                  :before-upload="onBeforeFile1"
                >
                  <Button type="primary">上传档案</Button>
                </Upload>
                <Table
                  :columns="teamUserFileListColums"
                  :data="teamUserFileList1"
                  :loading="teamUserFileListLoading1"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <!-- <Button type="primary" style="margin-right: 14px;">在线预览</Button> -->
                    <Button type="primary" @click="toViewFile(row.url)"
                      >下载</Button
                    >
                  </template>
                </Table>
                <page
                  show-total
                  :total="teamUserFileListTotal1"
                  :page-size="teamUserFileListSize1"
                  :current="teamUserFileListPage1"
                  :on-change="handleTeamUserListPageChange1"
                  style="margin-top: 14px"
                />
              </div>
            </div>
            <!-- 教育情况 -->
            <div class="tab-content-box" v-if="teamUserTabFlag == 'education'">
              <div class="tab-nullbox" v-if="!teamUserTabPickFlag">
                <div class="nulltips">请先选择人员</div>
              </div>
              <div v-else>
                <div class="img-box" style="width: 250px">
                  <img
                    :src="teamUserQRCode"
                    alt=""
                    style="width: 100%; height: auto"
                  />
                </div>
                <!-- <Button type="primary" @click="toEditWorkType('auto')"
                  >配置通用工种</Button
                >
                <Table
                  :columns="teamUserFileListColums"
                  :data="safeTemplateTypeData"
                  :loading="safeTemplateTypeLoading"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button type="error">删除</Button>
                  </template>
                </Table>
                <page show-total
                  :total="safeTemplateTypeTotal"
                  :page-size="safeTemplateTypeSize"
                  :current="safeTemplateTypePage"
                  :on-change="handleSafeTemplateTypePageChange"
                  style="margin-top: 14px"
                /> -->
              </div>
            </div>
            <!-- 资质证书 -->
            <div
              class="tab-content-box"
              v-if="teamUserTabFlag == 'qualifications'"
            >
              <div class="tab-nullbox" v-if="!teamUserTabPickFlag">
                <div class="nulltips">请先选择人员</div>
              </div>
              <div v-else>
                <Upload
                  action="/sapi/blackList/upload"
                  :name="'file'"
                  :multiple="false"
                  :before-upload="onBeforeFile1"
                >
                  <Button type="primary">上传档案</Button>
                </Upload>
                <Table
                  :columns="teamUserFileListColums"
                  :data="teamUserFileList2"
                  :loading="teamUserFileListLoading2"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button type="primary" @click="toViewFile(row.url)"
                      >下载</Button
                    >
                  </template>
                </Table>
                <page
                  show-total
                  :total="teamUserFileListTotal2"
                  :page-size="teamUserFileListSize2"
                  :current="teamUserFileListPage2"
                  :on-change="handleTeamUserListPageChange2"
                  style="margin-top: 14px"
                />
              </div>
            </div>
            <!-- 体检报告 -->
            <div class="tab-content-box" v-if="teamUserTabFlag == 'medically'">
              <div class="tab-nullbox" v-if="!teamUserTabPickFlag">
                <div class="nulltips">请先选择人员</div>
              </div>
              <div v-else>
                <Upload
                  action="/sapi/blackList/upload"
                  :name="'file'"
                  :multiple="false"
                  :before-upload="onBeforeFile1"
                >
                  <Button type="primary">上传档案</Button>
                </Upload>
                <Table
                  :columns="teamUserFileListColums"
                  :data="teamUserFileList3"
                  :loading="teamUserFileListLoading3"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button type="primary" @click="toViewFile(row.url)"
                      >下载</Button
                    >
                  </template>
                </Table>
                <page
                  show-total
                  :total="teamUserFileListTotal3"
                  :page-size="teamUserFileListSize3"
                  :current="teamUserFileListPage3"
                  :on-change="handleTeamUserListPageChange3"
                  style="margin-top: 14px"
                />
              </div>
            </div>
            <!-- 劳动合同 -->
            <div class="tab-content-box" v-if="teamUserTabFlag == 'labor'">
              <div class="tab-nullbox" v-if="!teamUserTabPickFlag">
                <div class="nulltips">请先选择人员</div>
              </div>
              <div v-else>
                <Upload
                  action="/sapi/blackList/upload"
                  :name="'file'"
                  :multiple="false"
                  :before-upload="onBeforeFile1"
                >
                  <Button type="primary">上传档案</Button>
                </Upload>
                <Table
                  :columns="teamUserFileListColums"
                  :data="teamUserFileList4"
                  :loading="teamUserFileListLoading4"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button type="primary" @click="toViewFile(row.url)"
                      >下载</Button
                    >
                  </template>
                </Table>
                <page
                  show-total
                  :total="teamUserFileListTotal4"
                  :page-size="teamUserFileListSize4"
                  :current="teamUserFileListPage4"
                  :on-change="handleTeamUserListPageChange4"
                  style="margin-top: 14px"
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <!-- 岗前安全培训-任务管理 -->
      <div class="content-box" v-if="currentContentBox == 'prejob1'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <!-- <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div> -->
          <div class="second-title">
            <div class="shu" />
            任务管理
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">任务名称：</div>
                <Input
                  v-model="prejobProjectSearchData.name"
                  placeholder="请输入任务名称"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <!-- <div class="mb-item">
                <div class="label">管理分类：</div>
                <Select
                  v-model="prejobProjectSearchData.type"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in projectManTypeList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.name }}</Option
                  >
                </Select>
              </div> -->
              <Button
                type="primary"
                @click="handleSearchPrejobTask"
                style="margin-right: 14px"
                >搜索</Button
              >
              <Button @click="handleSearchPrejobTask('reset')">重置</Button>
            </div>
            <Table
              highlight-row
              ref="prejobProject"
              :columns="prejobProjectCloumsList"
              :data="prejobProjectDataList"
              @on-current-change="handlePrejobTablePick"
              :loading="prejobProjectLoading"
            >
              <template slot-scope="{ row, index }" slot="positionTypeStr">
                <Tooltip
                  max-width="300"
                  :content="row.positionTypeStr"
                  :transfer="true"
                >
                  {{ row.pts }}
                </Tooltip>
              </template>
            </Table>
            <div class="fun-page-box">
              <Button @click="handleClearCurrentRow" style="margin-right: 14px"
                >清除列表选中</Button
              >
              <page
                show-total
                :total="prejobProjectTotal"
                :page-size="prejobProjectSize"
                @on-change="prejobProjectPageChange"
                :current="prejobProjectPage"
              />
            </div>
            <Divider />
            <Tabs type="card" @on-click="pickPrejobTab" v-model="prejobTabFlag">
              <TabPane label="任务完成情况" name="finish"></TabPane>
              <TabPane label="视频清单" name="video"></TabPane>
            </Tabs>
            <!-- 任务完成情况 -->
            <div class="tab-content-box" v-if="prejobTabFlag == 'finish'">
              <div class="tab-nullbox" v-if="!prejobProjectPickFlag">
                <div class="nulltips">
                  {{ tabDataNull ? "暂无数据" : "请先选择任务" }}
                </div>
              </div>
              <div v-else>
                <div class="tcb-item">
                  <div class="tcbi-item">
                    <div class="lable">所属班组</div>
                    <Select
                      v-model="searchPrejobTaskData.teamId"
                      style="width: 200px"
                    >
                      <Option
                        v-for="item in teamInfo"
                        :value="item.id"
                        :key="item.id"
                        >{{ item.teamName }}</Option
                      >
                    </Select>
                  </div>
                  <div class="tcbi-item">
                    <div class="lable">完成情况</div>
                    <Select
                      v-model="searchPrejobTaskData.status"
                      style="width: 200px"
                    >
                      <Option
                        v-for="item in perjobFinishiTypeList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                  <div class="tcbi-item">
                    <div class="lable">开始时间</div>
                    <DatePicker
                      type="date"
                      placeholder="选择开始时间"
                      style="width: 200px"
                      format="yyyy-MM-dd"
                      @on-change="handleDate1"
                    ></DatePicker>
                  </div>
                  <div class="tcbi-item">
                    <div class="lable">完成时间</div>
                    <DatePicker
                      type="date"
                      placeholder="选择完成时间"
                      style="width: 200px"
                      format="yyyy-MM-dd"
                      @on-change="handleDate2"
                    ></DatePicker>
                  </div>
                </div>
                <div class="tcb-item" style="margin-top: 14px">
                  <Button
                    type="primary"
                    style="margin-right: 14px"
                    @click="handleSearchPrejobFinish"
                    >搜索</Button
                  >
                  <Button @click="handleSearchPrejobFinish('reset')"
                    >重置</Button
                  >
                </div>
                <Table
                  :columns="finishProjectCloumsList"
                  :data="finishProjectDataList"
                  :loading="prejobTaskFinishTableFlag"
                  style="margin-top: 14px"
                >
                <template
                  slot-scope="{ row }"
                  slot="action"
                >
                  <div v-if="row.name||row.phone" style="margin-top:10px">
                    <Button
                    v-if="row.showOrHide=='hide'
                    "
                    type="primary"
                    @click.stop="showFinishProjectConceal(row)"
                    >显示</Button
                    >
                    <Button
                      v-else
                      type="primary"
                      @click.stop="hideFinishProjectConceal(row)"
                      >隐藏</Button
                    >

                  </div>
                </template>
                
                
                </Table>
                <page
                  show-total
                  :total="finishProjectDataTotal"
                  :page-size="finishProjectDataSize"
                  :current="finishProjectDataPage"
                  @on-change="handleSearchPrejobFinishPageChange"
                  style="margin-top: 14px"
                />
              </div>
            </div>
            <!-- 视频清单 -->
            <div class="tab-content-box" v-if="prejobTabFlag == 'video'">
              <div class="tab-nullbox" v-if="!prejobProjectPickFlag">
                <div class="nulltips">请先选择任务</div>
              </div>
              <div v-else>
                <Table
                  :columns="prejobVideoColumsList"
                  :data="prejobVideoDataList"
                  :loading="prejobTaskVideoTableFlag"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="toViewFile(row.url)"
                      >视频预览</Button
                    >
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="toViewFile(row.pdfUrl)"
                      >pdf预览</Button
                    >
                    <Button type="primary" @click="toOpenProblem(row.id)"
                      >查看考题</Button
                    >
                  </template>
                </Table>
                <page
                  show-total
                  :total="prejobVideoDataTotal"
                  :page-size="prejobVideoDataSize"
                  :current="prejobVideoDataPage"
                  @on-change="handlePrejobVideoDataPageChange"
                  style="margin-top: 14px"
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <!-- 岗前安全培训-档案管理 -->
      <MainSubTablePrejob
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'prejob2'"
      />
      <!-- 安全技术交底-安全技术交底表管理 -->
      <div class="content-box" v-if="currentContentBox == 'safe1'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <SecurityList
            :projectInfo="projectInfo"
            :UserInfo="currentUserInfo"
            :ScreenWidth="screenWidth"
          />

          <!-- <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="second-title">
            <div class="shu" />
            安全技术交底表管理
          </div> -->
          <!-- <Cascader
            :data="safeSelectData"
            v-model="safeSelectValue"
            style="width: 500px; margin-bottom: 14px; margin-left: 14px"
            placeholder="请选择安全交底表格"
            @on-change="handleSafeSelectChange"
            filterable
          ></Cascader> -->
          <!-- <div class="tab-second-title">
              <div class="shu"></div>表格囊括人员
            </div>
            <Transfer
              :data="safeTableUserList"
              :target-keys="pickSafeUserArr"
              :render-format="userRender"
              @on-change="handleSafeUserChange"
              :titles="['未选中人员','已选中人员']"
              style="margin-bottom:24px;margin-left: 14px;"
              :list-style="{width:'220px',height:'400px'}"
            /> -->
          <!-- <Button
            style="margin-left: 14px"
            type="success"
            icon="ios-copy-outline"
            @click="updateSafeDisclose"
            >获取企业模板</Button
          > -->
          <!-- <Button
            style="margin-left: 14px"
            type="primary"
            icon="ios-download-outline"
            @click="downSafeList"
            >下载安全技术交底表</Button
          > -->
          <!-- <div class="tab-tips">温馨提示:  当前下载安全技术交底表表格为广东省建筑施工安全统一用表（2011年版）</div> -->
          <!-- 安全技术交底表编辑 -->
          <!-- <div class="tab-second-title" style="margin-top: 14px">
            <div class="shu"></div>
            安全技术交底表内容
          </div> -->
          <!-- <div class="tab-btnbox" style="flex-direction: column">
            <Button
              style="margin-right: 8px; margin-bottom: 14px; width: 150px"
              type="primary"
              @click="toEditWorkType('disclose')"
              >配置工种</Button
            >
            <div class="tab-newbox">
              <div class="label">适用工种：</div>
              <div class="tagbox">
                <Tag
                  color="blue"
                  v-for="(item, index) in currentWorkTypeList"
                  :key="index"
                  >{{ item | workTypeFilters(projectWorkTypeList) }}</Tag
                >
              </div>
            </div>
          </div>
          <div class="tab-btnbox">
            <Button
              style="margin-right: 8px"
              @click="toEditSafeContent"
              type="info"
              v-if="!editSafeFlag"
              >编辑安全技术交底表</Button
            >
            <Button
              style="margin-right: 8px"
              type="success"
              v-if="editSafeFlag"
              @click="toChangeSafeContent('save')"
              >保存</Button
            >
            <Button
              style="margin-right: 8px"
              v-if="editSafeFlag"
              @click="toChangeSafeContent('cancel')"
              >取消</Button
            >
          </div>
          <div style="position: relative">
            <Spin fix v-if="getSafeContentLoading">
              <Icon
                type="ios-loading"
                size="18"
                class="demo-spin-icon-load"
              ></Icon>
              <div>Loading</div>
            </Spin>
            <Input
              v-model="currentSafeContent"
              :disabled="!editSafeFlag"
              :autosize="true"
              class="tab-textarea"
              type="textarea"
              placeholder="安全交底表内容"
            />
          </div> -->
        </Card>
      </div>
      <!-- 安全技术交底-交底样板管理 -->
      <div class="content-box" v-if="currentContentBox == 'safe2'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <!-- <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div> -->
          <div class="second-title">
            <div class="shu" />
            交底样板管理
          </div>
          <div class="c-btnbox">
            <Button
              type="primary"
              style="margin-right: 14px; margin-bottom: 14px; width: 150px"
              @click="addNewTemplate"
              >添加样板</Button
            >
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">样板名称：</div>
                <Input
                  v-model="safeAutoSearchData.name"
                  placeholder="请输入样板名称"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <!-- <div class="mb-item">
                <div class="label">分部分项工程：</div>
                <Select
                  v-model="safeAutoSearchData.pid"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in safeSelectData"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div> -->
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handleSafeAutoSearch"
                >搜索</Button
              >
              <Button @click="handleSafeAutoSearch('reset')">重置</Button>
            </div>
            <Table
              highlight-row
              ref="safeAuto"
              :columns="safeAutoCloumsList"
              :data="safeAutoDataList"
              :loading="safeAutoListLoading"
              @on-current-change="handleSafeTablePick"
            >
              <template slot-scope="{ row, index }" slot="action">
                <Button
                  type="primary"
                  style="margin-right: 8px"
                  @click="toEditTemplate(row)"
                  >编辑</Button
                >
                <Button
                  type="primary"
                  style="margin-right: 8px"
                  @click="toEditRecord(row)"
                  >查看修改记录</Button
                >
                <Button
                  type="success"
                  style="margin-right: 8px"
                  v-if="row.status == 6 || row.status == '0'"
                  @click="toRunTemplate(row.id, '5')"
                  >启用</Button
                >
                <Button
                  type="warning"
                  style="margin-right: 8px"
                  v-if="row.status == 5"
                  @click="toRunTemplate(row.id, '6')"
                  >停用</Button
                >
              </template>
            </Table>
            <div class="fun-page-box">
              <Button @click="handleClearSafe" style="margin-right: 14px"
                >清除列表选中</Button
              >
              <page
                show-total
                :total="safeAutoListTotal"
                :page-size="safeAutoListSize"
                @on-change="handleSafeAutoPageChange"
              />
            </div>
            <Divider />
            <Tabs
              type="card"
              @on-click="pickSafeTemplateTab"
              v-model="safeTemplateTababFlag"
            >
              <TabPane label="视频清单" name="safeVideo"></TabPane>
              <!-- <TabPane label="适用工种" name="safeType"></TabPane> -->
              <TabPane label="交底表" name="safeDisclose"></TabPane>
            </Tabs>
            <!-- 视频清单 safeAutoPickFlag -->
            <div
              class="tab-content-box"
              v-if="safeTemplateTababFlag == 'safeVideo'"
            >
              <div class="tab-nullbox" v-if="!safeAutoPickFlag">
                <div class="nulltips">请先选择任务</div>
              </div>
              <div v-else>
                <Button
                  type="primary"
                  @click="toAddVideo(0)"
                  style="margin-right: 14px"
                  >添加抖工视频</Button
                >
                <!-- <Button type="primary" @click="toAddVideo(1)"
                  >添加企业视频</Button
                > -->
                <Table
                  :columns="safeTemplateVideoColums"
                  :data="safeTemplateVideoData"
                  :loading="safeTemplateVideoLoading"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="toViewFile(row.videoUrl)"
                      >视频预览</Button
                    >
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="toViewFile(row.pdfUrl)"
                      >pdf预览</Button
                    >
                    <Button
                      type="info"
                      style="margin-right: 8px"
                      @click="toAddProblem(row.id)"
                      v-if="row.sourceStr == '企业视频库'"
                      >配置考题</Button
                    >
                    <Button type="error" @click="delVideo(row.id)">删除</Button>
                  </template>
                </Table>
                <page
                  show-total
                  :total="safeTemplateVideoTotal"
                  :page-size="safeTemplateVideoSize"
                  style="margin-top: 14px"
                  @on-change="handleSafeTemplateVideoPageChange"
                />
              </div>
            </div>
            <!-- 适用工种 -->
            <!-- <div
              class="tab-content-box"
              v-if="safeTemplateTababFlag == 'safeType'"
            >
              <div class="tab-nullbox" v-if="!safeAutoPickFlag">
                <div class="nulltips">请先选择任务</div>
              </div>
              <div v-else>
                <Button type="primary" @click="toEditWorkType('auto')"
                  >配置通用工种</Button
                >
                <Table
                  :columns="safeTemplateTypeColums"
                  :data="safeTemplateTypeData"
                  :loading="safeTemplateTypeLoading"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button type="error">删除</Button>
                  </template>
                </Table>
                <page
                  show-total
                  :total="safeTemplateTypeTotal"
                  :page-size="safeTemplateTypeSize"
                  :current="safeTemplateTypePage"
                  :on-change="handleSafeTemplateTypePageChange"
                  style="margin-top: 14px"
                />
              </div>
            </div> -->
            <!-- 交底表 -->
            <div
              class="tab-content-box"
              v-if="safeTemplateTababFlag == 'safeDisclose'"
            >
              <div class="tab-nullbox" v-if="!safeAutoPickFlag">
                <div class="nulltips">请先选择任务</div>
              </div>
              <div v-else>
                <Table
                  :columns="safeTemplateDiscloseColums"
                  :data="safeTemplateDiscloseData"
                  style="margin-top: 14px"
                >
                  <!-- :loading="safeTemplateDiscloseLoading" -->
                  <template slot-scope="{ row, index }" slot="workTypes">
                    <!-- <Tooltip
                      max-width="300"
                      :content="row.workTypes"
                      :transfer="true"
                    >
                      {{ row.workTypes.workTypeName }}
                    </Tooltip> -->
                    <div
                      v-for="item in row.workTypes"
                      :key="item.workTypeValue"
                    >
                      {{ item.workTypeName }}
                    </div>
                  </template>
                  <template slot-scope="{ row, index }" slot="action">
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="downSafeList3(row)"
                      >预览</Button
                    >
                  </template>
                </Table>
                <page
                  show-total
                  :total="safeTemplateDiscloseTotal"
                  :page-size="safeTemplateDiscloseSize"
                  :current="safeTemplateDisclosePage"
                  @on-change="handleSafeTemplateDisclosePageChange"
                  style="margin-top: 14px"
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <!-- 安全技术交底-任务管理 -->
      <div class="content-box" v-if="currentContentBox == 'safe3'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <!-- <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div> -->
          <div class="second-title">
            <div class="shu" />
            任务管理
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">任务名称：</div>
                <Input
                  v-model="safeProjectSearchData.name"
                  placeholder="请输入任务名称"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <!-- <div class="mb-item">
                <div class="label">分部分项工程：</div>
                <Select
                  v-model="safeProjectSearchData.type"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in safeSelectData"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div> -->
              <div class="mb-item">
                <div class="label">任务状态：</div>
                <Select
                  v-model="safeProjectSearchData.status"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in checkStatusList2"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handleSearchSafeTask"
                >搜索</Button
              >
              <Button @click="handleSearchSafeTask('reset')">重置</Button>
            </div>
            <Table
              highlight-row
              ref="safeProject"
              :columns="safeProjectCloumsList"
              :data="safeProjectDataList"
              :loading="safeProjectLoading"
              @on-current-change="handleSafeProjectTablePick"
            >
              <template slot-scope="{ row, index }" slot="positionTypeStr">
                <Tooltip
                  max-width="300"
                  :content="row.positionTypeStr"
                  :transfer="true"
                >
                  {{ row.pts }}
                </Tooltip>
              </template>
              <template slot-scope="{ row, index }" slot="cycle"
                >{{ row.startTime }}--{{ row.endTime }}</template
              >
              <template
                slot-scope="{ row }"
                slot="action"
              >
                <div v-if="row.updateBy" style="margin-top:10px">
                  <Button
                  v-if="row.showOrHide=='hide'
                  "
                  type="primary"
                  @click.stop="showSafeProjectConceal(row)"
                  >显示</Button
                  >
                  <Button
                    v-else
                    type="primary"
                    @click.stop="hideSafeProjectConceal(row)"
                    >隐藏</Button
                  >

                </div>
             </template>
            </Table>
            <div class="fun-page-box">
              <Button @click="handleClearSafeProject" style="margin-right: 14px"
                >清除列表选中</Button
              >
              <page
                show-total
                :total="safeProjectTotal"
                :page-size="safeProjectSize"
                :current="safeProjectPage"
                @on-change="safeProjectPageChange"
              />
            </div>
            <Divider />
            <Tabs
              type="card"
              @on-click="pickSafeAutoTab"
              v-model="safeAutoTabFlag"
            >
              <TabPane label="任务完成情况" name="finish"></TabPane>
              <TabPane label="视频清单" name="video"></TabPane>
              <TabPane label="交底表" name="disclose"></TabPane>
            </Tabs>
            <!-- 任务完成情况 safeProjectPickFlag -->
            <div class="tab-content-box" v-if="safeAutoTabFlag == 'finish'">
              <div class="tab-nullbox" v-if="!safeProjectPickFlag">
                <div class="nulltips">
                  {{ tabDataNull ? "暂无数据" : "请先选择任务" }}
                </div>
              </div>
              <div v-else>
                <div class="tcb-item">
                  <div class="tcbi-item">
                    <div class="lable">所属班组</div>
                    <Select
                      v-model="searchPrejobTaskData.teamId"
                      style="width: 200px"
                    >
                      <Option
                        v-for="item in teamInfo"
                        :value="item.id"
                        :key="item.id"
                        >{{ item.teamName }}</Option
                      >
                    </Select>
                  </div>
                  <div class="tcbi-item">
                    <div class="lable">完成情况</div>
                    <Select
                      v-model="searchPrejobTaskData.status"
                      style="width: 200px"
                    >
                      <Option
                        v-for="item in perjobFinishiTypeList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                  <div class="tcbi-item">
                    <div class="lable">开始时间</div>
                    <DatePicker
                      type="date"
                      placeholder="选择开始时间"
                      style="width: 200px"
                      format="yyyy-MM-dd"
                      @on-change="handleDate1"
                    ></DatePicker>
                  </div>
                  <div class="tcbi-item">
                    <div class="lable">完成时间</div>
                    <DatePicker
                      type="date"
                      placeholder="选择完成时间"
                      style="width: 200px"
                      format="yyyy-MM-dd"
                      @on-change="handleDate2"
                    ></DatePicker>
                  </div>
                </div>
                <div class="tcb-item" style="margin-top: 14px">
                  <Button
                    type="primary"
                    style="margin-right: 14px"
                    @click="handleSearchPrejobFinish"
                    >搜索</Button
                  >
                  <Button @click="handleSearchPrejobFinish('reset')"
                    >重置</Button
                  >
                </div>
                <Table
                  :columns="finishProjectCloumsList"
                  :data="finishProjectDataList"
                  :loading="prejobTaskFinishTableFlag"
                  style="margin-top: 14px"
                >
                <template
                  slot-scope="{ row }"
                  slot="action"
                >
                  <div v-if="row.name||row.phone" style="margin-top:10px">
                    <Button
                    v-if="row.showOrHide=='hide'
                    "
                    type="primary"
                    @click.stop="showFinishProjectConceal(row)"
                    >显示</Button
                    >
                    <Button
                      v-else
                      type="primary"
                      @click.stop="hideFinishProjectConceal(row)"
                      >隐藏</Button
                    >

                  </div>
                </template>
                </Table>
                <page
                  show-total
                  :total="finishProjectDataTotal"
                  :page-size="finishProjectDataSize"
                  @on-change="handleSearchPrejobFinishPageChange"
                  style="margin-top: 14px"
                />
              </div>
            </div>
            <!-- 视频清单 -->
            <div class="tab-content-box" v-if="safeAutoTabFlag == 'video'">
              <div class="tab-nullbox" v-if="!safeProjectPickFlag">
                <div class="nulltips">
                  {{ tabDataNull ? "暂无数据" : "请先选择任务" }}
                </div>
              </div>
              <div v-else>
                <Table
                  :columns="prejobVideoColumsList"
                  :data="prejobVideoDataList"
                  :loading="prejobTaskVideoTableFlag"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="toViewFile(row.url)"
                      >视频预览</Button
                    >
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="toViewFile(row.pdfUrl)"
                      >pdf预览</Button
                    >
                    <Button type="primary" @click="toOpenProblem(row.id)"
                      >查看考题</Button
                    >
                  </template>
                </Table>
                <page
                  show-total
                  :total="prejobVideoDataTotal"
                  :page-size="prejobVideoDataSize"
                  :current="prejobVideoDataPage"
                  @on-change="handlePrejobVideoDataPageChange"
                  style="margin-top: 14px"
                />
              </div>
            </div>
            <!-- 交底表 -->
            <div class="tab-content-box" v-if="safeAutoTabFlag == 'disclose'">
              <div class="tab-nullbox" v-if="!safeProjectPickFlag">
                <div class="nulltips">
                  {{ tabDataNull ? "暂无数据" : "请先选择任务" }}
                </div>
              </div>
              <div v-else>
                <Table
                  :columns="sdDiscloseColums"
                  :data="sdDiscloseData"
                  :loading="sdDiscloseLoading"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="workType">
                    <Tooltip
                      max-width="300"
                      :content="row.workType"
                      :transfer="true"
                    >
                      {{ row.wts }}
                    </Tooltip>
                  </template>
                  <template slot-scope="{ row, index }" slot="action">
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="downSafeList2(row.id)"
                      >预览</Button
                    >
                  </template>
                </Table>
                <page
                  show-total
                  :total="sdDiscloseTotal"
                  :current="sdDisclosePage"
                  :page-size="sdDiscloseSize"
                  @on-change="handleSdDisclosePageChange"
                  style="margin-top: 14px"
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <!-- 安全技术交底-安全技术交底 -->
      <!-- <div class="content-box" v-if="currentContentBox == 'safe6'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <SecurityList :projectInfo="projectInfo" :UserInfo="currentUserInfo" :ScreenWidth="screenWidth" />
        </Card>
      </div> -->
      <!-- 企业安全技术交底- -->
      <!-- <div class="content-box" v-if="currentContentBox == 'comsafe1'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <Securitycompany :projectInfo="projectInfo" :UserInfo="currentUserInfo" :ScreenWidth="screenWidth" />
        </Card>
      </div> -->
      <!-- 安全技术交底-档案管理 -->
      <MainSubTableTemp
        :ProjectInfo="projectInfo"
        v-if="currentContentBox == 'safe4'"
      />
      <!-- 安全技术交底-项目视频库 -->
      <div
        class="content-box content-box-column"
        v-if="currentContentBox == 'safe5'"
      >
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <!-- <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div> -->
          <template v-if="!addSafeVideoFlag">
            <div class="second-title">
              <div class="shu" />
              视频管理
            </div>
            <div class="c-btnbox">
              <div class="c-btnbox-item">
                <div class="mb-item">
                  <div class="label">视频标题：</div>
                  <Input
                    v-model="searchSafeVideoData"
                    placeholder="请输入视频标题"
                    style="width: 200px; margin-right: 14px"
                  />
                </div>
                <Button
                  type="primary"
                  style="margin-right: 14px"
                  @click="handleSafeVideoListSearch"
                  >搜索</Button
                >
                <Button @click="handleSafeVideoListSearch('reset')"
                  >重置</Button
                >
              </div>
              <Button
                type="primary"
                style="margin-right: 14px; margin-bottom: 14px; width: 150px"
                @click="addSafeVideo"
                >添加视频</Button
              >
              <Table
                :columns="safeVideoCloumsList"
                :data="safeVideoDataList"
                :loading="safeVideoDataListLoading"
              >
                <template slot-scope="{ row, index }" slot="icon">
                  <div class="head-img" @click="showQR(row.coverUrl)">
                    <img v-if="row.coverUrl" :src="row.coverUrl" alt />
                    <img v-else src="../../assets/images/fzpt_img.png" alt />
                  </div>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <div class="btnbox-flex">
                    <Button
                      type="primary"
                      style="margin-right: 6px"
                      @click="editVideo(row)"
                      >编辑</Button
                    >
                    <Button type="warning" @click="removeVideo(row)"
                      >移除</Button
                    >
                  </div>
                </template>
              </Table>
              <div class="page-box">
                <page
                  show-total
                  :total="safeVideoDataListTotal"
                  :page-size="safeVideoDataListSize"
                  :current="safeVideoDataListPage"
                  @on-change="handleSafeVideoListPageChange"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="second-title">
              <div class="shu" />
              添加视频
            </div>
            <div class="new-btn-box">
              <div class="nbb-item">
                <div class="label">视频标题</div>
                <Input
                  placeholder="请输入视频标题"
                  style="width: 300px; margin-right: 14px"
                  v-model="safeNewVideoData.title"
                />
              </div>
              <div class="nbb-item">
                <div class="label">视频分类</div>
                <Select v-model="safeNewVideoData.type" style="width: 300px">
                  <Option
                    v-for="item in videoTypeList"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </div>
              <div class="nbb-item">
                <div class="label">视频简介</div>
                <Input
                  v-model="safeNewVideoData.desc"
                  maxlength="300"
                  autosize
                  show-word-limit
                  type="textarea"
                  placeholder="输入视频简介"
                  style="width: 300px"
                />
              </div>
              <div class="nbb-item">
                <div class="label">上传视频</div>
                <uploader
                  :key="uploader_key"
                  :options="options"
                  class="uploader-example"
                  :autoStart="false"
                  @file-success="onFileSuccess"
                  @file-added="filesAdded"
                >
                  <Spin fix v-if="videoLoading">
                    <Icon
                      type="ios-loading"
                      size="18"
                      class="demo-spin-icon-load"
                    ></Icon>
                    <div>Loading</div>
                  </Spin>
                  <uploader-unsupport></uploader-unsupport>
                  <uploader-drop>
                    <uploader-btn :single="true" :attrs="attrs">{{
                      videoFileId ? "上传更新视频" : "上传新增视频"
                    }}</uploader-btn>
                  </uploader-drop>
                  <uploader-list></uploader-list>
                </uploader>
              </div>
              <div class="nbb-item">
                <div class="label">上传教材</div>
                <!-- v-if="currentUserInfo.dg_key == 1" -->
                <Upload
                  action="/sapi/blackList/upload"
                  :name="'file'"
                  :multiple="false"
                  :before-upload="onBeforePdf"
                  :accept="AcceptPdf"
                  :format="FormatPdf"
                  :data="uploadDataPdf"
                >
                  <Button icon="ios-cloud-upload-outline">{{
                    pdfId ? "点击更换教材" : "点击上传教材"
                  }}</Button>
                </Upload>
              </div>
              <div class="nbb-item" style="margin-top: 14px">
                <Button
                  type="success"
                  style="margin-right: 14px"
                  @click="toSaveSafeVideo"
                  >保存</Button
                >
                <Button @click="toSaveSafeVideo('cancle')">取消</Button>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <!-- 专项培训-专项签字表管理 -->
      <div class="content-box" v-if="currentContentBox == 'special4'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="second-title">
            <div class="shu" />
            专项签字表管理
          </div>
        </Card>
      </div>
      <!-- 专项培训-模板管理 -->
      <div class="content-box" v-if="currentContentBox == 'special1'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="second-title">
            <div class="shu" />
            模板管理
          </div>
          暂未开放
        </Card>
      </div>
      <!-- 专项培训-任务管理 -->
      <div class="content-box" v-if="currentContentBox == 'special2'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="second-title">
            <div class="shu" />
            任务管理
          </div>
          暂未开放
        </Card>
      </div>
      <!-- 专项培训-档案管理 -->
      <!-- <div
        class="content-box content-box-column"
        v-if="currentContentBox == 'special3'"
      > -->
      <div class="content-box" v-if="currentContentBox == 'special3'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="second-title">
            <div class="shu" />
            档案管理
          </div>
          暂未开放
        </Card>
        <!-- <Row style="width: 100%; margin-bottom: 20px" :gutter="10">
          <i-col span="6">
            <Spin fix v-if="getFilesTeamListLoading">
              <Icon
                type="ios-loading"
                size="18"
                class="demo-spin-icon-load"
              ></Icon>
              <div>Loading</div>
            </Spin>
            <Card>
              <div class="second-title">
                <div class="shu" />
                班组列表
              </div>
              <div class="mainbox">
                <div class="equipmentList" v-if="filesTeamList.length > 0">
                  <div
                    class="e-item"
                    :class="[fitem.pickFlag ? 'e-item-active' : '']"
                    v-for="(fitem, index) in filesTeamList"
                    :key="index"
                    @click="pickFilesTeam(index)"
                  >
                    <div class="label">{{ fitem.teamName }}</div>
                    <Icon type="ios-arrow-forward" />
                  </div>
                </div>
                <div v-else style="margin-top: 5px; padding-left: 5px" class>
                  无班组信息。
                </div>
              </div>
            </Card>
          </i-col>
          <i-col span="18">
            <Spin fix v-if="getFilesDetailLoading">
              <Icon
                type="ios-loading"
                size="18"
                class="demo-spin-icon-load"
              ></Icon>
              <div>Loading</div>
            </Spin>
            <Card>
              <div class="second-title">
                <div class="shu" />
                档案列表详情
              </div>
              <Alert type="warning" show-icon>黄色标识为该目录下暂无档案</Alert>
              <Collapse simple :accordion="true" v-model="currentCollapseName">
                <Panel
                  :name="'panel' + index"
                  v-for="(pitem, index) in filesDetail"
                  :key="index"
                  :class="pitem.isWarn ? 'warnPanel' : ''"
                >
                  {{ pitem.name }}
                  <div slot="content" v-if="pitem.childs">
                    <div
                      class="panel-contentbox"
                      v-for="(fitem, index) in pitem.childs"
                    >
                      <div class="pc-item pc-item-name">{{ fitem.name }}</div>
                      <div class="pc-item">{{ fitem.updateTime }}</div>
                      <div class="pc-item">{{ fitem.ext }}</div>
                      <div class="pc-item">
                        <Button
                          type="primary"
                          style="margin-right: 14px"
                          @click="toDownFile(fitem.path)"
                          >下载</Button
                        >
                        <Button type="info" @click="toViewFile(fitem.url)"
                          >预览</Button
                        >
                      </div>
                    </div>
                  </div>
                  <div slot="content" class="panel-nullbox" v-else>
                    暂无数据
                  </div>
                </Panel>
              </Collapse>
            </Card>
          </i-col>
        </Row> -->
      </div>
      <!-- 签字管理 -->
      <div class="content-box" v-if="currentContentBox == 'sign'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="second-title">
            <div class="shu" />
            签字管理
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">描述：</div>
                <Input
                  v-model="signSearchData.name"
                  placeholder="请输入描述"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <Button
                type="primary"
                @click="handleSearchSign"
                style="margin-right: 14px"
                >搜索</Button
              >
              <Button @click="handleSearchSign('reset')">重置</Button>
            </div>
            <Table
              highlight-row
              ref="signTable"
              :columns="signCloumsList"
              :data="signDataList"
              @on-current-change="handleSignTablePick"
              :loading="signLoading"
            >
            </Table>
            <div class="fun-page-box">
              <Button @click="handleClearSign" style="margin-right: 14px"
                >清除列表选中</Button
              >
              <page
                show-total
                :total="signListTotal"
                :page-size="signListSize"
                @on-change="signPageChange"
                :current="signListPage"
              />
            </div>
            <Divider />
            <Tabs type="card" @on-click="pickSignTab" v-model="signTabFlag">
              <TabPane label="签字人" name="signpeople"></TabPane>
              <TabPane label="签字文件" name="signfiles"></TabPane>
            </Tabs>
            <!-- 签字人详细 -->
            <div class="tab-content-box" v-if="signTabFlag == 'signpeople'">
              <div class="tab-nullbox" v-if="!signPickFlag">
                <div class="nulltips">请先选择任务</div>
              </div>
              <div v-else>
                <div class="tcb-item">
                  <Button
                    type="primary"
                    style="margin-right: 14px"
                    @click="toAddSignPeople"
                    >添加签字人</Button
                  >
                </div>
                <Table
                  :columns="signDetailColumsList"
                  :data="signDetailList"
                  :loading="signDetailLoading"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="toDownFile(row.fileUrl)"
                      >签字预览</Button
                    >
                    <Button type="error" @click="toDeletePeople(row.id)"
                      >删除</Button
                    >
                     <span v-if="row.name||row.phone" style="margin-top:10px;margin-left:10px">
                      <Button
                      v-if="row.showOrHide=='hide'
                      "
                      type="primary"
                      @click.stop="showSignDetailConceal(row)"
                      >显示</Button
                      >
                      <Button
                        v-else
                        type="primary"
                        @click.stop="hideSignDetailConceal(row)"
                        >隐藏</Button
                      >

                    </span>
                  </template>
                </Table>
                <page
                  show-total
                  :total="signDetailTotal"
                  :page-size="signDetailSize"
                  :current="signDetailPage"
                  @on-change="signDetailPageChange"
                  style="margin-top: 14px"
                />
              </div>
            </div>
            <!-- 签字人文件 -->
            <div class="tab-content-box" v-if="signTabFlag == 'signfiles'">
              <div class="tab-nullbox" v-if="!signPickFlag">
                <div class="nulltips">请先选择任务</div>
              </div>
              <div v-else>
                <div class="tcb-item">
                  <div class="tcbi-item">
                    <div class="lable">所属模块</div>
                    <Select
                      v-model="searchSignFilesData.fileType"
                      style="width: 200px"
                    >
                      <Option
                        v-for="item in signFileTypeList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </div>
                  <div class="tcbi-item">
                    <div class="lable">文件名称</div>
                    <Input
                      v-model="searchSignFilesData.fileName"
                      placeholder="请输入文件名称"
                      style="width: 200px; margin-right: 14px"
                    />
                  </div>
                </div>
                <div class="tcb-item" style="margin-top: 14px">
                  <Button
                    type="primary"
                    style="margin-right: 14px"
                    @click="handleSearchSignFile"
                    >搜索</Button
                  >
                  <Button @click="handleSearchSignFile('reset')">重置</Button>
                </div>
                <!-- <div class="tcb-item">
                  <Button
                    type="primary"
                    style="margin-right: 14px"
                    >添加签字文件</Button
                  >
                </div> -->
                <Table
                  :columns="signFilesColumsList"
                  :data="signFilesList"
                  :loading="signFilesLoading"
                  style="margin-top: 14px"
                >
                  <template slot-scope="{ row, index }" slot="action">
                    <Button
                      type="primary"
                      style="margin-right: 8px"
                      @click="toDownFile(row.fileUrl)"
                      >文件预览</Button
                    >
                    <Button type="error">删除</Button>
                  </template>
                </Table>
                <page
                  show-total
                  :total="signFilesTotal"
                  :page-size="signFilesSize"
                  :current="signFilesPage"
                  @on-change="signFilesPageChange"
                  style="margin-top: 14px"
                />
              </div>
            </div>
          </div>
        </Card>
      </div>

      <!-- 违规记录 -->
      <div class="content-box" v-if="currentContentBox == 'irregularities'">
        <!-- max-width: 1300px; -->
        <Card
          style="
            max-width: 1600px;
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="team-manage-box">
            <div class="c-btnbox">
                <div class="c-btnbox-item">
                  <div class="mb-item">
                    <div class="label">关键字：</div>
                    <Input
                      style="width: 200px; margin-right: 14px"
                      placeholder="请输入关键词搜索"
                      v-model="irregularitieListKeyword"
                    />
                  </div>
                  <div class="mb-item">
                    <div class="label">等级：</div>

                    <Select
                      style="width: 200px; margin-right: 14px"
                      v-model="irregularitieListIrregularLevel"
                    >
                      <!-- <Option value="" key="">全部</Option> -->
                      <Option value="MILD" key="MILD">轻微</Option>
                      <Option value="MONDERATE" key="MONDERATE">中等</Option>
                      <Option value="SEVERE" key="SEVERE">严重</Option>
                      <!-- MILD-轻微、MONDERATE-中等、SEVERE-严重 -->
                    </Select>
                  </div>
               </div>
                <div class="c-btnbox-item" style="margin-bottom: 14px">
                  <Button
                    type="primary"
                    style="margin-right: 14px"
                    @click="toSearchIrregularities"
                    >搜索</Button
                  >
                    <Button @click="clearIrregularities">重置</Button>
                  
                </div>
            </div>
          </div>
          
          <Table
            :columns="irregularitiesColumnsList"
            :data="irregularitiesDataList"
            :loading="irregularitiesLoading"
          >
           <template slot-scope="{ row, index }" slot="irregularLevel">
              <span>{{row.irregularLevel=='MILD'?'轻微':row.irregularLevel=='MONDERATE'?'中等':row.irregularLevel=='SEVERE'?'严重':'-'}}</span>
            </template>
            <template slot-scope="{ row, index }" slot="irregularPicture">
              <div style="display:flex;flex-wrap:wrap">
              <img @click="showQR(item)" style="width:100px;height:150px;margin:0 10px 10px 0" v-for="(item,index) in row.irregularPicture.split(';')" :key="index" :src="item" alt="">
              
              </div>

            
            </template>
            <template slot-scope="{ row, index }" slot="teamItems">
              
                <Tooltip>
                  <div slot="content">
                      <div v-for="(item,index) in row.teamItems" :key="index" >
                        {{item.username}}-{{item.teamName}}
                      </div>  
                  </div>
                  <div style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;"> 
                    <div v-for="(item,index) in row.teamItems" :key="index" >
                        {{item.username}}-{{item.teamName}}
                    </div> 
                  </div>
                </Tooltip>
              
             
            </template>
             <template slot-scope="{ row, index }" slot="action">
              <div v-if="row.teamItems" style="margin-top:10px">
                  <Button
                  v-if="row.showOrHide=='hide'
                  "
                  type="primary"
                  @click.stop="showIrregularitiesConceal(row)"
                  >显示</Button
                  >
                  <Button
                    v-else
                    type="primary"
                    @click.stop="hideIrregularitiesConceal(row)"
                    >隐藏</Button
                  >

                </div>
            </template>
          </Table>

          <page
            show-total
            class="page-box"
            @on-change="toIrregularitiesPageChange"
            :page-size="10"
            :total="irregularitiesListTotal"
          />
        </Card>
      </div>


      <!-- 保险管理 -->
      <!-- 团体保险管理 -->
      <InsuranceByGroup
        :ProjectInfo="projectInfo"
        :Userinfo="currentUserInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'insurance1'"
      />
      <!-- 个人保险管理 -->
      <InsuranceByPersonal
        :ProjectInfo="projectInfo"
        v-if="currentContentBox == 'insurance2'"
      />
      <!-- 社会保险管理 -->
      <InsuranceBySociety
        :ProjectInfo="projectInfo"
        v-if="currentContentBox == 'insurance3'"
      />
      <!-- 其他保险管理 -->
      <InsuranceByOther
        :ProjectInfo="projectInfo"
        v-if="currentContentBox == 'insurance4'"
      />
      <!-- 外部交换平台-实名制人员清单 -->
      <div class="content-box" v-if="currentContentBox == 'outside1'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="big-title">{{ fromVal }}</div>
          <div class="second-title">
            <div class="shu" />
            实名制人员清单筛选
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">姓名：</div>
                <Input
                  v-model="searchWorkerInfoData.name"
                  placeholder="请输入姓名"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">手机号：</div>
                <Input
                  v-model="searchWorkerInfoData.phone"
                  placeholder="请输入手机号"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">身份证：</div>
                <Input
                  v-model="searchWorkerInfoData.cardNo"
                  placeholder="请输入身份证"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
            </div>
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">用工开始时间：</div>
                <DatePicker
                  type="date"
                  placement="bottom-end"
                  placeholder="请选择用工开始时间"
                  style="width: 200px; margin-right: 14px"
                  format="yyyy-MM-dd"
                  @on-change="handleDateChange1"
                ></DatePicker>
              </div>
              <div class="mb-item">
                <div class="label">用工结束时间：</div>
                <DatePicker
                  type="date"
                  placement="bottom-end"
                  placeholder="请选择用工结束时间"
                  style="width: 200px; margin-right: 14px"
                  format="yyyy-MM-dd"
                  @on-change="handleDateChange2"
                ></DatePicker>
              </div>
            </div>
            <div class="c-btnbox-item">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handleSearchWorkerInfo"
                >搜索</Button
              >
              <Button @click="handleSearchWorkerInfo('reset')">重置</Button>
            </div>
          </div>
          <div class="second-title">
            <div class="shu" />
            实名制人员清单内容
          </div>
          <Table
            stripe
            :loading="GMRealNameLoading"
            size="small"
            :columns="GMRealNameColums"
            :data="GMRealNameData"
            width="100%"
          >
            <template slot-scope="{ row, index }" slot="time">
              <div class="btnbox-flex">
                {{ row.intoDate }} 至 {{ row.exitDate }}
              </div>
            </template>
            <!-- <template slot-scope="{ row, index }" slot="action">
              <span v-if="row.name||row.cardNo||row.address||row.phone||row.bankCard" style="margin-top:10px;margin-left:10px">
                  <Button
                  v-if="row.showOrHide=='hide'
                  "
                  type="primary"
                  @click.stop="showGMRealNameConceal(row)"
                  >显示</Button
                  >
                  <Button
                    v-else
                    type="primary"
                    @click.stop="hideGMRealNameConceal(row)"
                    >隐藏</Button
                  >

                </span>
            </template> -->
          </Table>
          <page
            show-total
            class="page-box"
            :page-size="GMRealNameSize"
            :total="GMRealNameTotal"
            :current="GMRealNamePage"
            @on-change="handleWorkerInfoPageChange"
          />
        </Card>
      </div>
      <!-- 外部交换平台-企业内部管理系统 -->
      <div class="content-box" v-if="currentContentBox == 'outside2'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="second-title">
            <div class="shu" />
            企业内部管理系统
          </div>
          <div>暂未开放</div>
        </Card>
      </div>
      <!-- 外部交换平台-考勤数据 -->
      <div class="content-box" v-if="currentContentBox == 'outside3'">
        <Card
          style="
            width: 100%;
            min-width: 1200px;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>
          <div class="big-title">{{ fromVal }}</div>
          <Tabs v-model="osCheckTab" @on-click="pickOSCheckTab">
            <TabPane label="普通工人考勤" name="worker"></TabPane>
            <TabPane label="管理人员考勤" name="manager"></TabPane>
          </Tabs>
          <div class="second-title">
            <div class="shu" />
            考勤数据
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">姓名：</div>
                <Input
                  v-model="searchOSCheckData.name"
                  placeholder="请输入姓名"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">工种：</div>
                <Select
                  v-model="searchOSCheckData.postId"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in osPostList"
                    :value="item.dictValue"
                    :key="item.dictValue"
                    >{{ item.dictLabel }}</Option
                  >
                </Select>
              </div>
              <div class="mb-item">
                <div class="label">工种类别：</div>
                <Select
                  v-model="searchOSCheckData.typeId"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in osPostTypeList"
                    :value="item.dictValue"
                    :key="item.dictValue"
                    >{{ item.dictLabel }}</Option
                  >
                </Select>
              </div>
            </div>
            <div class="c-btnbox-item">
              <div class="mb-item">
                <div class="label">月份：</div>
                <Select
                  v-model="searchOSCheckData.yearsMonth"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in osYearsMonthList"
                    :value="item.dictValue"
                    :key="item.dictValue"
                    >{{ item.dictLabel }}</Option
                  >
                </Select>
              </div>
            </div>
            <div class="c-btnbox-item">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="searchOSCheck"
                >搜索</Button
              >
              <Button @click="searchOSCheck('reset')">重置</Button>
            </div>
          </div>
          <div class="second-title">
            <div class="shu" />
            数据内容
          </div>
          <Alert show-icon closable
            >提示：考勤统计单位：小时。 ‘ - ’表示该天无考勤。</Alert
          >
          <Alert type="warning" show-icon closable v-if="contactFlag"
            >提示：暂无数据，请联系佛山抖工科技有限公司（400-640-6898）</Alert
          >
          <Table
            stripe
            :loading="GMCheckLoading"
            size="small"
            :columns="GMCheckColums"
            :data="GMCheckData"
            :width="screenWidth - 400"
          ></Table>
          <page
            show-total
            class="page-box"
            :page-size="GMCheckSize"
            :total="GMCheckTotal"
            :current="GMCheckPage"
            @on-change="handleOSCheckPageChange"
          />
        </Card>
      </div>
      <!-- 外部交换平台-设置工种薪资 -->
      <div class="content-box" v-if="currentContentBox == 'outside4'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="second-title">
            <div class="shu" />
            工种日薪
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="toAddWorkType"
                >新增工种日薪</Button
              >
            </div>
          </div>
          <div style="width: 800px">
            <Table
              width="800"
              stripe
              :loading="GMWorkTypeLoading"
              size="small"
              :columns="GMWorkTypeColums"
              :data="GMWorkTypeData"
            >
              <template slot-scope="{ row, index }" slot="action">
                <Button
                  type="primary"
                  style="margin-right: 8px"
                  @click="toEditWorkTypeMoney(row)"
                  >编辑</Button
                >
              </template>
            </Table>
          </div>
          <page
            show-total
            class="page-box"
            :page-size="GMWorkTypeSize"
            :total="GMWorkTypeTotal"
            :current="GMWorkTypePage"
            @on-change="handleOSWorkTypePageChange"
          />
          <Divider />
          <div class="second-title">
            <div class="shu" />
            施工人员日薪
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="toAddWorker"
                >新增施工人员日薪</Button
              >
            </div>
          </div>
          <div style="width: 800px">
            <Table
              width="800"
              stripe
              :loading="GMWorkerLoading"
              size="small"
              :columns="GMWorkerColums"
              :data="GMWorkerData"
            >
              <template slot-scope="{ row, index }" slot="action">
                <Button
                  type="primary"
                  style="margin-right: 8px"
                  @click="toEditWorker(row)"
                  >编辑</Button
                >
              </template>
            </Table>
          </div>
          <page
            show-total
            class="page-box"
            :page-size="GMWorkerSize"
            :total="GMWorkerTotal"
            :current="GMWorkerPage"
            @on-change="handleOSWorkerPageChange"
          />
        </Card>
      </div>

      <!-- 外部交换平台-整改单流水 -->
      <RectifyTemp
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'outside5'"
      /><!-- 外部交换平台-罚单流水 -->
      <FineTemp
        :ProjectInfo="projectInfo"
        :ScreenWidth="screenWidth"
        v-if="currentContentBox == 'outside7'"
      />
      <!-- 外部交换平台-设置整改、罚单分类 -->
      <div class="content-box" v-if="currentContentBox == 'outside6'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="second-title">
            <div class="shu" />
            整改分类
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="toAddRectifyType"
                >新增整改分类</Button
              >
            </div>
          </div>
          <div style="width: 800px">
            <Table
              width="800"
              stripe
              :loading="OSRectifyTypeLoading"
              size="small"
              :columns="OSRectifyTypeColums"
              :data="OSRectifyTypeData"
            >
              <template slot-scope="{ row, index }" slot="action">
                <Button
                  type="primary"
                  style="margin-right: 8px"
                  @click="toEditRectifyTypeRow(row)"
                  >编辑</Button
                >
              </template>
            </Table>
          </div>
          <page
            show-total
            class="page-box"
            :page-size="OSRectifyTypeSize"
            :total="OSRectifyTypeTotal"
            :current="OSRectifyTypePage"
            @on-change="handleOSRectifyTypePageChange"
          />
          <Divider />
          <div class="second-title">
            <div class="shu" />
            罚单分类
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="toAddFineType"
                >新增罚单分类</Button
              >
            </div>
          </div>
          <div style="width: 800px">
            <Table
              width="800"
              stripe
              :loading="OSFineTypeLoading"
              size="small"
              :columns="OSFineTypeColums"
              :data="OSFineTypeData"
            >
              <template slot-scope="{ row, index }" slot="action">
                <Button
                  type="primary"
                  style="margin-right: 8px"
                  @click="toEditFineTypeRow(row)"
                  >编辑</Button
                >
              </template>
            </Table>
          </div>
          <page
            show-total
            class="page-box"
            :page-size="OSFineTypeSize"
            :total="OSFineTypeTotal"
            :current="OSFineTypePage"
            @on-change="handleOSFineTypePageChange"
          />
        </Card>
      </div>
    </div>
    <!-- 新增授权对话框 -->
    <Modal
      v-model="addNewAuthFlag"
      title="新增授权"
      @on-ok="toAddAuth"
      @on-cancel="addAuth('close')"
      :loading="addNewAuthNext"
      :mask-closable="false"
    >
      <div class="aa-item">
        <div class="label">姓名：</div>
        <Input
          v-model="newAuthData.name"
          placeholder="输入授权管理员姓名"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">手机号：</div>
        <Input
          v-model="newAuthData.phone"
          placeholder="输入授权管理员手机号"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">角色:</div>
        <Select v-model="selectedRoleId" style="width: 200px">
          <Option
            v-for="item in roleOptions"
            :value="item.roleId"
            :key="item.roleId"
            >{{ item.roleName }}</Option
          >
        </Select>
      </div>
      <!-- <div class="aa-item" v-if="osPowerFlag">
        <div class="label">权限:</div>
        <Select v-model="selectedRolePermissionId" style="width: 200px">
          <Option
            v-for="item in rolePermissionOptions"
            :value="item.permissionId"
            :key="item.permissionId"
            >{{ item.permissionName }}</Option
          >
        </Select>
      </div> -->
      <div v-if="tipsAddAuth" class="aa-tips">
        该人员未注册/实名,是否继续授权
      </div>
    </Modal>
    <!-- 新增班组对话框 -->
    <Modal
      v-model="addNewTeamFlag"
      title="新增班组"
      :loading="addNewTeamNext"
      @on-ok="toAddNewTeam"
      @on-cancel
      :mask-closable="false"
      class="xjbz"
    >
      <div class="aa-item">
        <div class="label bitian">班组名称：</div>
        <Input
          v-model="newTeamData.vteam_name"
          placeholder="请输入班组名称"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label bitian">负责人名字：</div>
        <Input
          v-model="newTeamData.vresponsible_person_name"
          placeholder="请输入负责人名字"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label bitian">负责人手机号码：</div>
        <Input
          v-model="newTeamData.vresponsible_person_phone"
          placeholder="请输入负责人手机号码"
          clearable
          style="width: 200px"
        />
      </div>
      <!-- <div class="aa-item">
        <div class="label bitian">班组工种：</div>
        <Select multiple :max-tag-count="2" v-model="newTeamData.workTypeValues" @on-change="toHandleWorkType" style="width: 200px">
          <Option
            v-for="item in abproWorkTypeList"
            :value="item.workTypeValue"
            :key="item.workTypeValue"
            >{{ item.workTypeName }}</Option
          >
        </Select>
      </div> -->
      <div class="aa-item">
        <div class="label bitian">班组负责人工种：</div>
        <Select v-model="newTeamData.workTypeValue" style="width: 200px">
          <Option
            v-for="item in abproWorkTypeList"
            :value="item.workTypeValue"
            :key="item.workTypeValue"
            >{{ item.workTypeName }}</Option
          >
        </Select>
      </div>
      <!-- <div class="aa-item">
        <div class="label bitian">是否免审核加入：</div>
        <Select v-model="newTeamData.is_no_audit" style="width: 200px">
          <Option
            v-for="item in auditList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
      </div> -->
      <div class="aa-item">
        <div class="label">所属企业（劳务公司）：</div>

        <Select
          v-model="newTeamData.labour_corp_id"
          :label-in-value="true"
          style="width: 200px"
          @on-change="handleLabour"
        >
          <Option
            v-for="item in labourCorpList"
            :value="item.id"
            :key="item.id"
            >{{ item.name }}</Option
          >
        </Select>
        <!-- <Input
          v-model="newTeamData.corp_name"
          placeholder="请输入所属企业"
          clearable
          style="width: 200px"
        /> -->
      </div>
      <div class="aa-item">
        <div class="label bitian">进场时间：</div>
        <DatePicker
          v-model="enterTheFieldTime"
          @on-change="changeNewTeamDateEn"
          type="date"
          placeholder="选择进场时间"
          style="width: 200px"
        ></DatePicker>
      </div>
      <!-- <div class="aa-item">
        <div class="label">退场时间：</div>
        <DatePicker
          @on-change="changeNewTeamDateEx"
          type="date"
          placeholder="选择退场时间"
          style="width: 200px"
        ></DatePicker>
      </div> -->
      <div class="aa-item">
        <div class="label">备注：</div>
        <Input
          v-model="newTeamData.remark"
          maxlength="100"
          show-word-limit
          type="textarea"
          placeholder="请填写备注信息"
          style="width: 200px"
        />
      </div>
    </Modal>
    <!-- 新增任务对话框 -->
    <Modal v-model="addProjectFlag" title="新增培训任务" @on-ok @on-cancel>
      <div class="aa-item">
        <div class="label">姓名：</div>
        <Input
          v-model="newAuthData.name"
          placeholder="输入授权管理员姓名"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">手机号：</div>
        <Input
          v-model="newAuthData.phone"
          placeholder="输入授权管理员手机号"
          clearable
          style="width: 200px"
        />
      </div>
    </Modal>
    <!-- 项目授权--移除授权对话框 -->
    <Modal
      v-model="removeAuthFlag"
      title="提示"
      @on-ok="removeAuth('suc')"
      @on-cancel="removeAuth"
    >
      <p>是否将xxx从授权列表中移除</p>
    </Modal>
    <!-- 设备管理--新增设备对话框 -->
    <Modal
      v-model="modalFlag.addEquipment"
      title="新增设备"
      :loading="addEquipmentNext"
      @on-ok="toAddEquipment('ok')"
      @on-cancel="toAddEquipment('cancel')"
    >
      <div class="aa-item">
        <div class="label">设备序列号：</div>
        <Input
          v-model="newEquipmentData.eId"
          placeholder="请输入设备序列号"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">设备名称：</div>
        <Input
          v-model="newEquipmentData.eName"
          placeholder="请输入设备名"
          clearable
          style="width: 200px"
        />
      </div>
    </Modal>
    <!-- 设备管理--绑定设备对话框 -->
    <Modal
      v-model="modalFlag.bindEquipment"
      :loading="bindEquipmentLoading"
      :mask-closable="false"
      title="设备绑定新班组"
      @on-ok="toBindEquipment()"
      @on-cancel="handleBindEquipmentCancel"
    >
      <div class="aa-item">
        <div class="label">设备序列号：</div>
        <div>{{ bindNewEquipmentData.serialNo }}</div>
      </div>
      <div class="aa-item">
        <div class="label">设备名称：</div>
        <div>{{ bindNewEquipmentData.name }}</div>
      </div>
      <div class="aa-item">
        <div class="label">班组名称：</div>
        <Dropdown trigger="click">
          <Button v-if="!currentEquipmentTeamPick.vteamName">
            {{
              getEquipmentTeamLoading
                ? "正在获取班组列表"
                : "请从列表中选择班组"
            }}
            <Icon v-if="getEquipmentTeamLoading" type="ios-loading" />
            <Icon v-else type="ios-arrow-down"></Icon>
          </Button>
          <Button v-else>
            {{ currentEquipmentTeamPick.vteamName }}
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem
              v-for="(item, index) in equipmentTeamDataList"
              :key="index"
              @click.native="pickEquipmentNewTeam(item)"
              >{{ item.vteamName }}</DropdownItem
            >
          </DropdownMenu>
        </Dropdown>
      </div>
    </Modal>
    <!-- 设备管理--移除设备对话框 -->
    <Modal
      v-model="modalFlag.removeEquipment"
      title="移除设备"
      @on-ok="showTips('error', '无法绑定该设备，xxxx工班已绑定xxxxx设备')"
      @on-cancel
    >
      <div>是否移除xxxxxxxx设备</div>
      <div>序列号：xxxxxxxx</div>
    </Modal>
    <!-- 复制交底表 -->
    <Modal
      v-model="copySafeDiscloseFlag"
      title="获取企业安全技术交底表模板"
      @on-ok="toPickVersion"
      :mask-closable="false"
    >
      <div class="aa-item">
        <div class="label">模板版本：</div>
        <Select v-model="currentVersion" style="width: 280px">
          <Option
            v-for="item in versionList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
      </div>
    </Modal>
    <!-- 班组管理--班组退场 未完成任务用户列表 -->
    <Modal
      v-model="showExitTeamFlag"
      :loading="showExitTeamNext"
      :mask-closable="false"
      ok-text="线下完成培训"
      title="未完成任务人员名单"
      @on-ok="toTeamFinish()"
      @on-cancel="handleTeamFinishCancel"
      width="600"
    >
      <div class="aa-item">
        <div class="label">项目：</div>
        <div>{{ projectInfo.vprojectname }}</div>
      </div>
      <div class="aa-item">
        <div class="label">班组：</div>
        <div>{{ toFinishTeamData.teamName }}</div>
      </div>
      <Table
        :columns="noFinishUserColumnsList"
        :data="noFinishUserList"
        :loading="finishTeamUserTableLoading"
      >
        <template slot-scope="{ row }" slot="taskStatus">
          {{ row.taskStatus | teamStatus(row.taskStatus) }}
        </template>
      </Table>
      <page
        show-total
        class="page-box"
        @on-change="toTeamFinishPageChange"
        :total="toFinishTeamTotal"
      />
    </Modal>
    <!-- 安全技术--自动模板管理 添加模板 -->
    <Modal
      v-model="modalFlag.addTemplate"
      :title="editTemplateFlag ? '编辑样板' : '新增样板'"
      :loading="addTemplateNext"
      @on-ok="toAddTemplate('ok')"
      @on-cancel="toAddTemplate('cancel')"
      :mask-closable="false"
      width="70%"
      :modal-append-to-body="false"
    >
      <div class="aa-item">
        <div style="width: 155px">样板名称：</div>
        <Input
          v-model="newTemplateData.name"
          placeholder="请输入样板名称"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div style="width: 155px">周期（小时）：</div>
        <Input
          v-model="newTemplateData.duration"
          placeholder="请输入周期（小时）"
          type="number"
          :precision="0"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div style="width: 155px">内容简介：</div>
        <Input
          v-model="newTemplateData.content"
          placeholder="请输入内容"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div style="width: 155px">备注：</div>
        <Input
          v-model="newTemplateData.remark"
          placeholder="请输入备注"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div style="width: 155px">所属分部分项工程类别：</div>
        <Select
          v-model="newTemplateDataCategoryId"
          style="width: 250px"
          @on-change="onnewTemplateDataCategoryChange"
          placeholder="请选择项目安全技术交底表内容分类"
        >
          <Option
            v-for="item in workTypeListData"
            :value="item.categoryId"
            :key="item.categoryId"
            >{{ item.categoryName }}</Option
          >
        </Select>
        <!-- <Select
            v-model="newTemplateData.projectContentId"
            style="width: 200px;"
            @dropdownVisibleChange="onProjectContentChange"
          >
            <Option
              v-for="item in workTypeListData"
              :value="item.categoryId"
              :key="item.categoryId"
              >{{ item.categoryName }}</Option
            >
          </Select> -->
        <el-select
          v-model="newTemplateData.projectContentId"
          @visible-change="onProjectContentChange"
          size="small"
          placeholder="请选择所属分部分项工程类别"
        >
          <el-option
            v-for="optItem in projectContentData"
            :key="optItem.contentId"
            :label="optItem.contentName"
            :value="optItem.contentId"
          ></el-option>
        </el-select>
        <!-- <el-cascader
            :props="props"
            :options="safeSelectData"
            v-model="newTemplateData.projectContentId"
            style="width: 300px; "
            @change="handleSafeSelectChange2"
            filterable
          ></el-cascader> -->
        <!-- <el-cascader 
            clearable
            :props="props2"
            placeholder="请选择项目"
            :show-all-levels="false"
            @change="projectChange"
            style="width: 300px; "
            v-model="newTemplateData.projectContentId"
          >
          </el-cascader> -->
      </div>
    </Modal>
    <!-- 安全技术--自动模板管理 查看修改记录 -->
    <el-dialog
      :visible.sync="modalFlag.editRecordVisible"
      title="样板修改记录"
      width="70%"
    >
      <!-- :loading="editRecordLoading"
      :mask-closable="false"
      :modal-append-to-body="false" -->
      <!-- @on-cancel="toeditRecordOk('cancel')" -->

      <!-- @on-ok="toeditRecordOk('ok')" -->
      <Table
        :columns="editRecordColumnsList"
        :data="editRecordDataList"
        :loading="editRecordDataTableLoading"
      >
        <!-- <template slot-scope="{ row }" slot="content">
        {{row.content}} 
      </template> -->
      <template
          slot-scope="{ row }"
          slot="action"
        >
          <div v-if="row.realname||row.updateBy" style="margin-top:10px">
            <Button
            v-if="row.showOrHide=='hide'
            "
            type="primary"
            @click.stop="showRecordConceal(row)"
            >显示</Button
            >
            <Button
              v-else
              type="primary"
              @click.stop="hideRecordConceal(row)"
              >隐藏</Button
            >

          </div>
        </template>

      </Table>
      <page
        show-total
        :total="editRecordDataTotal"
        :current="editRecordDataPage"
        @on-change="handleeditRecordPageChange"
        :page-size="editRecordDataSize"
        style="margin-top: 14px"
      />
    </el-dialog>
    <!-- 安全技术交底--自动模板任务--添加视频 -->
    <Modal
      width="70"
      v-model="addVideoFlag"
      ok-text="保存"
      title="添加视频"
      :mask-closable="false"
      @on-ok="handleAddVideo"
      @on-cancel="handleAddVideo('reset')"
      :loading="addVideoNext"
    >
      <div class="newitem">
        <!-- <div class="ni-item">
          <div class="label">视频来源</div>
          <Select v-model="searchNewVideoData.from" @on-change="handleChangeVideoFrom" style="width: 200px">
            <Option
              v-for="item in videoFromList"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select>
        </div> -->
        <div class="ni-item" v-if="searchNewVideoData.from == 1">
          <div class="label">视频分类</div>
          <Select v-model="searchNewVideoData.type" style="width: 200px">
            <Option
              v-for="item in videoTypeList"
              :value="item.id"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </div>
        <div class="ni-item">
          <div class="label">视频标题</div>
          <Input
            placeholder="请输入视频标题"
            style="width: 200px; margin-right: 14px"
            v-model="searchNewVideoData.title"
          />
        </div>
      </div>
      <div class="newitem">
        <Button
          type="primary"
          style="margin-right: 14px"
          @click="handleSearchVideoList"
          >搜索</Button
        >
        <Button @click="handleSearchVideoList('reset')">重置</Button>
      </div>
      <Table
        border
        ref="selection"
        :columns="addNewVideoColumsList"
        :loading="addNewVideoDataLoading"
        :data="addNewVideoDataList"
        @on-select="handleVideoSelect"
        @on-select-cancel="handleVideoSelectCancle"
        @on-select-all="handleVideoSelectAll"
        @on-select-all-cancel="handleVideoSelectAllCancle"
      >
        <template slot-scope="{ row, index }" slot="pdf">
          <Button
            type="primary"
            style="margin-right: 8px"
            @click="toViewFile(row.pdfUrl)"
            >pdf预览</Button
          >
        </template>
        <template slot-scope="{ row, index }" slot="video">
          <Button
            type="primary"
            style="margin-right: 8px"
            @click="toViewFile(row.videoUrl)"
            >视频预览</Button
          >
        </template>
      </Table>
      <page
        show-total
        :total="addNewVideoDataTotal"
        :current="addNewVideoDataPage"
        @on-change="handleVideoPageChange"
        :page-size="addNewVideoDataSize"
        style="margin-top: 14px"
      />
    </Modal>
    <!-- 工种--穿梭框 -->
    <Modal
      :mask-closable="false"
      v-model="editWorkTypeFlag"
      title="配置工种"
      @on-ok="handleEditWorkType"
      @on-cancel="handleEditWorkType('cancel')"
      :loading="editWorkTypeNext"
    >
      <Transfer
        :data="transferData"
        :target-keys="transferTarget"
        filterable
        :filter-method="transferSearch"
        @on-change="handleTransferChange"
      ></Transfer>
    </Modal>
    <!-- 问题列表 -->
    <Modal
      :mask-closable="false"
      v-model="problemListFlag"
      :title="'考题'"
      width="60"
    >
      <Table
        :columns="problemCloumsList"
        :data="problemDataList"
        :loading="problemListLoading"
      >
      </Table>
      <page
        show-total
        style="margin-top: 14px"
        :total="problemListTotal"
        :page-size="problemListSize"
        @on-change="problemListPageChange"
        :current="problemListPage"
      />
    </Modal>
    <!-- 问题列表带添加 -->
    <Modal
      :mask-closable="false"
      v-model="addProblemListFlag"
      :title="'考题'"
      width="60"
    >
      <Button
        type="primary"
        style="margin-bottom: 14px"
        @click="toAddProblemAnswer"
        >添加问题</Button
      >
      <Table
        :columns="problemCloumsList1"
        :data="problemDataList"
        :loading="problemListLoading"
      >
        <template slot-scope="{ row, index }" slot="action">
          <Button type="error" @click="toDelProblem(row.videoQuestionId)"
            >删除</Button
          >
        </template>
      </Table>
      <page
        show-total
        style="margin-top: 14px"
        :total="problemListTotal"
        :page-size="problemListSize"
        @on-change="problemListPageChange"
        :current="problemListPage"
      />
    </Modal>
    <!-- 新增任务对话框 -->
    <Modal
      v-model="addProblemFlag"
      title="新增问题"
      :loading="addPNext"
      @on-ok="handleAddProblemConfirm"
      @on-cancel="handleAddProblemConfirm('reset')"
    >
      <div class="aa-item">
        <div class="label">问题：</div>
        <Input
          v-model="addProblemData.name"
          placeholder="输入问题"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">答案：</div>
        <RadioGroup v-model="addProblemData.answer">
          <Radio label="1">
            <span>正确</span>
          </Radio>
          <Radio label="2">
            <span>错误</span>
          </Radio>
        </RadioGroup>
      </div>
    </Modal>
    <!-- 签字管理--添加签字人 -->
    <Modal
      v-model="addSignPeopleFlag"
      title="添加签字人"
      :mask-closable="false"
      @on-ok="handleAddSignPeople"
      @on-cancel="handleAddSignPeople('reset')"
      :loading="addSignPeopleNext"
    >
      <div class="aa-item">
        <div class="label bitian">姓名：</div>
        <Input
          v-model="newSignPeopleData.name"
          placeholder="输入授权管理员姓名"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label bitian">手机号：</div>
        <Input
          v-model="newSignPeopleData.phone"
          placeholder="输入授权管理员手机号"
          clearable
          style="width: 200px"
        />
      </div>
      <!--  v-if="tagName === 'team_sign_name'" -->
      <div class="aa-item" v-if="tagName === 'team_sign_name' || tagName === 'project_sign_name'">
        <div class="label">班组（可选）：</div>
        <Select v-model="newSignPeopleData.teamId" multiple style="width: 200px">
          <Option
            v-for="item in teamDataList"
            :value="item.id"
            :key="item.id"
            >{{ item.teamName }}</Option
          >
        </Select>
      </div>
    </Modal>
    <!-- 外部交换平台--设置工种日薪 -->
    <Modal
      v-model="outsideWorkTypeFlag"
      title="设置工种日薪"
      :mask-closable="false"
      @on-ok="toEditTypeWork"
      @on-cancel="toEditTypeWork('reset')"
      :loading="outsideWorkTypeNext"
    >
      <div class="aa-item">
        <div class="label bitian">工种：</div>
        <Select style="width: 200px" v-model="newAddWorkType.type">
          <Option
            v-for="item in osPostList"
            :value="item.dictValue"
            :key="item.dictValue"
            >{{ item.dictLabel }}</Option
          >
        </Select>
      </div>
      <div class="aa-item">
        <div class="label bitian">设置日薪(元)：</div>
        <Input
          placeholder="请输入日薪"
          clearable
          style="width: 200px"
          v-model="newAddWorkType.money"
        />
      </div>
    </Modal>

    <!-- 外部交换平台--设置施工人员日薪 -->
    <Modal
      v-model="outsideWorkerFlag"
      title="设置施工人员日薪"
      :mask-closable="false"
      @on-ok="toEditConstruction"
      @on-cancel="toEditConstruction('reset')"
      :loading="outsideWorkerNext"
    >
      <div class="aa-item">
        <div class="label bitian">班组：</div>
        <Select
          @on-change="handlePickOSTeam"
          v-model="newAddWorker.team"
          style="width: 200px"
        >
          <Option v-for="item in osTeamList" :value="item.id" :key="item.id">{{
            item.name
          }}</Option>
        </Select>
      </div>
      <div class="aa-item">
        <div class="label bitian">人员姓名：</div>
        <Select style="width: 200px" v-model="newAddWorker.name" filterable>
          <Option
            v-for="item in osUserList"
            :value="item.recId"
            :key="item.recId"
            >{{ item.name }}</Option
          >
        </Select>
      </div>
      <div class="aa-item">
        <div class="label bitian">设置日薪(元)：</div>
        <Input
          v-model="newAddWorker.money"
          placeholder="请输入日薪"
          clearable
          style="width: 200px"
        />
      </div>
    </Modal>

    <!-- 外部交换平台--设置整改分类 -->
    <Modal
      v-model="outsideRectifyTypeFlag"
      title="设置整改分类"
      :mask-closable="false"
      @on-ok="toEditRectifyType"
      @on-cancel="toEditRectifyType('reset')"
      :loading="outsideRectifyTypeNext"
    >
      <div class="aa-item">
        <div class="label bitian">分类名称：</div>
        <Input
          placeholder="请输入分类名称"
          clearable
          style="width: 200px"
          v-model="newRectifyTypeData.name"
        />
      </div>
    </Modal>
    <!-- 外部交换平台--设置罚单分类 -->
    <Modal
      v-model="outsideFineTypeFlag"
      title="设置罚单分类"
      :mask-closable="false"
      @on-ok="toEditFineType"
      @on-cancel="toEditFineType('reset')"
      :loading="outsideFineTypeNext"
    >
      <div class="aa-item">
        <div class="label bitian">分类名称：</div>
        <Input
          placeholder="请输入分类名称"
          clearable
          style="width: 200px"
          v-model="newFineTypeData.name"
        />
      </div>
    </Modal>
    <!-- 放大二维码 -->
    <div class="show-qrcode" v-show="showQRFlag" @click="hiddenQR">
      <div class="qrcode-img">
        <img :src="showImgSrc" alt />
      </div>
    </div>


    <!-- 密码从未修改过的提示 -->
     <Modal
      :mask-closable="false"
      v-model="passwordNeverChangeModal"
      :title="'提示'"
      ok-text="好"
      @on-ok="toResetPassword"
      @on-cancel="toResetPassword1"
    >
      <!-- @on-cancel="toLogin"   正式的时候先用这个 -->
      <!-- 正式 -->
      <!-- <div class="passwordTips">
        您好，您的密码不满足强密码要求，密码要求最小长度位8位，必须同时包含字母、数字和特殊字符三种字符，点击“好”修改密码。
      </div> -->

      <!-- 测试 -->
      <div class="passwordTips">
        您好，您的密码不满足强密码要求，密码要求最小长度位8位，必须同时包含字母、数字和特殊字符三种字符，点击“好”修改密码，点击“取消”退出登录。
      </div>
    </Modal>


    <!-- 密码超时90天的提示 -->
     <Modal
      :mask-closable="false"
      v-model="passwordOverTimeModal"
      :title="'提示'"
      ok-text="去设置"
      @on-ok="toResetPassword"
      @on-cancel="toResetPassword('close')"
    >
      <div class="passwordTips">
        密码有效期已超90天，请重新设置密码！
      </div>
    </Modal>
  </div>
</template>

<script>
import selectJson from "./public/selectJson.js";
import area from "../../utils/area.json";
import integrationData from "./public/integrationData.js";
// import countSModule from "./template/countsModule.vue";
import SparkMD5 from "spark-md5";
import rictifyExpandRow from "./template/tableExpandRectify.vue";
import Storage from "../../utils/localStorage";
import constants from "../../constants/constants";
import {nameConceal,phoneConceal,IdcardConceal,addressConceal,bankCardConceal} from "@/utils/desensitization"

import moment from "moment"
import {
  CountsModule,
  MainSubTableTemp,
  MainSubTablePrejob,
  ProjectWork,
  InsuranceByGroup,
  InsuranceByOther,
  InsuranceByPersonal,
  InsuranceBySociety,
  CheckByEquipment,
  CheckByTeam,
  CheckByList,
  AttendanceRules,
  AttendanceData,
  RectifyTemp,
  FineTemp,
  RosterListTemp,
  TaskListTemp,
  WorkTypeTemp,
  SecurityList,
} from "./template/index";
export default {
  mixins: [integrationData],
  components: {
    CountsModule,
    MainSubTableTemp,
    MainSubTablePrejob,
    ProjectWork,
    InsuranceByGroup,
    InsuranceByOther,
    InsuranceByPersonal,
    InsuranceBySociety,
    CheckByEquipment,
    CheckByTeam,
    CheckByList,
    AttendanceRules,
    AttendanceData,
    RectifyTemp,
    FineTemp,
    RosterListTemp,
    TaskListTemp,
    WorkTypeTemp,
    SecurityList,
  },
  data() {
    return {
      // 密码一次也没修改的显示
      passwordNeverChangeModal:false,

       // 密码超时的显示
      passwordOverTimeModal:false,

      props: { emitPath: false },
      props2: {
        checkStrictly: true,
        lazy: true,
        lazyLoad: this.lazyLoad,
        // changeOnSelect:{
        //   default:false
        // },
      },
      projectId: undefined,
      projectContentData: [],
      newTemplateDataCategoryId: "",
      roleOptions: [{ roleId: 6, roleName: "安全员" }],
      rolePermissionOptions: [
        { permissionId: 1, permissionName: "开整改单开罚单" },
        { permissionId: 2, permissionName: "开整改单" },
        { permissionId: 3, permissionName: "开罚单" },
        { permissionId: 4, permissionName: "不可开整改单不可开罚单" },
      ],
      selectedRolePermissionId: null,
      selectedRoleId: 6,
      screenWidth: "",
      countSModuleTimer: "",
      attendanceDate: "",
      attendanceDateDetail: {
        clockStartTime: "",
        clockEndTime: "",
      },
      clientHeight: "",
      editFlag: false,
      projectList: selectJson,
      projectInfo: {},
      currentProjectName: "广州富力医院",
      activeName: "",
      currentProjectIndex: 0,
      currentSubcProjectIndex: 0,
      openName: ["info"],
      breadcrumbList: [
        {
          name: "项目信息",
          in: "info",
          index: 1,
        },
      ],
      currentVcategory: "", //选中的工程类别
      currentVfunction_num: "", //选中的工程用途
      currentVprojectstatus: "", //选中的工程状态
      currentFrame_type: "", //选中的结构类型
      currentContentBox: "info",
      projectDataList: [
        {
          projectId: "01",
          projectName: "入场前安全培训",
          projectType: "入场前培训",
          projectTime: "5",
          projectStatus: "使用中",
          projectCreate: "2020-06-22 15:26:27",
          projectUpdate: "2020-06-22 15:26:27",
        },
        {
          projectId: "01",
          projectName: "入场前安全培训",
          projectType: "入场前培训",
          projectTime: "5",
          projectStatus: "使用中",
          projectCreate: "2020-06-22 15:26:27",
          projectUpdate: "2020-06-22 15:26:27",
        },
        {
          projectId: "01",
          projectName: "入场前安全培训",
          projectType: "入场前培训",
          projectTime: "5",
          projectStatus: "使用中",
          projectCreate: "2020-06-22 15:26:27",
          projectUpdate: "2020-06-22 15:26:27",
        },
        {
          projectId: "01",
          projectName: "入场前安全培训",
          projectType: "入场前培训",
          projectTime: "5",
          projectStatus: "使用中",
          projectCreate: "2020-06-22 15:26:27",
          projectUpdate: "2020-06-22 15:26:27",
        },
      ],
      blackDataList: [
        {
          subcProjectName: "李大力",
          subcName: "19000000000",
          subcName2: "440000000000000000",
        },
        {
          blackName: "李大力",
          blackPhone: "19000000000",
          blackIDCard: "440000000000000000",
          blackInTime: "2020-06-22 15:26:27",
        },
        {
          blackName: "李大力",
          blackPhone: "19000000000",
          blackIDCard: "440000000000000000",
          blackInTime: "2020-06-22 15:26:27",
        },
        {
          blackName: "李大力",
          blackPhone: "19000000000",
          blackIDCard: "440000000000000000",
          blackInTime: "2020-06-22 15:26:27",
        },
      ],
      rosterDataList: [],
      subcDataList: [],
      authDataList: [],
      authDataList1: [],//深拷贝   脱敏处理

      teamDataList: [],
      teamDataList1: [],//深拷贝   脱敏处理


      teamManUserDataList: [],
      teamManUserDataList1: [],//深拷贝   脱敏处理

      teamManTasksDataList: [],
      teamManTaskDataTotal: 0,
      teamManTaskDataPage: 1,
      equipmentDataList: [],
      irregularitiesDataList: [],//违规记录列
      irregularitiesDataList1: [],//违规记录列


      modalFlag: {
        //对话框显示flag集合
        addEquipment: false, //新增设备
        bindEquipment: false, //绑定设备
        removeEquipment: false, //移除设备
        addTemplate: false, //新增模板
        editRecordVisible: false, // 查看修改记录
      },
      newEquipmentData: {
        eId: "",
        eName: "",
      },
      addNewAuthFlag: false,
      newAuthData: {
        name: "",
        phone: "",
      },
      teamTasksDataChange: false,
      teamTasksData: {
        name: "木工班组",
        leaderName: "熊涛",
        leaderPhone: "13923188888",
        status: "中止",
      },
      showQRFlag: false,
      currentTasksTab: 1,
      province: "",
      city: "",
      provinceName: "未选择",
      cityName: "未选择",
      provinceList: [],
      cityList: [],
      area: area,
      addProjectFlag: false, //添加任务对话框flag
      removeAuthFlag: false, //移除项目授权对话框flag
      equipmentTeamSearch: "",
      equipmentTeamSearchList: [
        {
          id: "0",
          vteamName: "无班组信息",
        },
      ],
      equipmentTeamList: [],
      equipmentUserSearchList: [
        {
          value: "0",
          label: "在职",
        },
        {
          value: "1",
          label: "离岗",
        },
      ],
      equipmentUserJobSearchList: [
        {
          value: "0",
          label: "未绑定",
        },
        {
          value: "1",
          label: "已绑定",
        },
      ],
      searchEquipmentUser: {
        uName: "",
        uStatus: "",
        uJob: "",
      },
      equipmentUserDataList: [],
      checkDataList: [],
      checkTeamList: [
        {
          id: "",
          vteamName: "",
        },
      ],
      checkStatusList: [
        {
          value: "0",
          label: "在职",
        },
        {
          value: "1",
          label: "离岗",
        },
      ],
      checkStatusList2: [
        {
          value: "0",
          label: "未开始",
        },
        {
          value: "1",
          label: "进行中",
        },
        {
          value: "2",
          label: "正常完成",
        },
        {
          value: "3",
          label: "逾期",
        },
        {
          value: "4",
          label: "逾期完成",
        },
        {
          value: "5",
          label: "模板启用",
        },
        {
          value: "6",
          label: "模板停用",
        },
      ],
      auditList: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],
      backupsProjectData: [],
      projectData: [],
      projectTotal: 0,
      reviseFlag: true,
      authInfo: [],
      authTableLoading: false,
      irregularitiesLoading:false,//违规记录
      teamTableLoading: false,
      teamInfo: [],
      showImgSrc: "../../assets/images/android_qr.png",
      searchTeamTxt: "",
      teamListTotal: 0,
      irregularitiesListTotal: 0,//违规记录的总数

      irregularitieListPageNo: 1,//违规记录的分页
      irregularitieListPageSize: 10,//违规记录的分页
      irregularitieListKeyword: '',//违规记录的keyword
      irregularitieListIrregularLevel: " ",//违规记录的违规等级查询


      addNewTeamFlag: false,
      currentTeamData: {},
      teamUserPage: 1,
      teamUserTotal: 0,
      teamUserTableLoading: false,
      newTeamData: {
        phone: "",
        vteam_name: "",
        vresponsible_person_name: "",
        vresponsible_person_phone: "",
        corp_name: "",
        vproject_code: "",
        dentry_time: "",
        dexit_time: "",
        remark: "",
        leader_work_type: "",
        is_no_audit: "1",
        workTypeValues: [],
        workTypeValue: "",
        workTypeName: "",
        labour_corp_id: undefined,
        // updateBy: "",
      },
      addNewTeamNext: true,
      tipsAddAuth: false,
      addNewAuthNext: true,
      checkInRelatePage: 1,
      checkInRelateTotal: 0,
      checkInRelateTableLoading: false,
      addEquipmentNext: true,
      currentEquipmentData: {},
      equipmentTeamLoading: false,
      equipmentTeamDetailLoading: false,
      equipmentListPage: 1,
      equipmentListTotal: 0,
      equipmentListSearch: "",
      currentEquipmentTeamData: {},
      equipmentTeamDataSearch: {
        eName: "",
        eStatus: "",
        eJob: "",
      },
      equipmentTeamUserTotal: 0,
      equipmentTeamUserPage: 1,
      equipmentUserTableLoading: false,
      attendanceDataTableLoading: false,
      attendanceSearchData: {
        aName: "",
        aTeamList: "",
        aStatus: "",
      },
      attendanceListTotal: 0,
      attendanceListPage: 1,
      attendanceTeamListLoading: false,
      exportData: {
        date: "",
      },
      teamManTasksDataLoading: false,
      currentEquipmentTeamPick: {
        vteamName: "",
      },
      equipmentTeamDataList: [
        {
          vteamName: "",
          id: "",
        },
      ],
      getEquipmentTeamLoading: false,
      bindNewEquipmentData: {},
      bindEquipmentLoading: true,
      addNewProjectData: {
        vprojectcode:"",//项目编码  只有项目下拉可选的时候才会有这个值
        record_code: "", //工程项目报建编码
        vprojectname: "", //项目名称
        vresponsible_person_name: "", //项目负责人
        vresponsible_person_phone: "", //项目负责人手机号
        vresponsible_person_id_number: "", //项目负责人身份证
        phone: "", //当前用户手机号
        vcategory: "", //工程类别
        vfunction_num: "", //工程用途
        dinvest: null, //工程造价
        vcontractor_corp_name: "", //施工单位
        dbuilding_area: null, //建筑面积
        vprojectstatus: "", //工程状态
        frame_type: "", //结构类型
        vprojectaddress: "", //工程地点
        province: "",
        city: "",
        full_location: "",
      },
      currentUserInfo: {},
      addNewProjectDataProvinceName: "",
      addNewProjectDataCityName: "",
      addNewProjectDataLoading: false,
      noProjectFlag: false,
      currentExcelProvince: "",
      excelProvinceList: [],
      excelDataList: [],
      currentExcelDataList: [],
      searchBlackListData: {
        name: "",
        phone: "",
        idcard: "",
      },
      safeSelectData: [],
      safeSelectValue: [],
      safeSelectShowValue: [],
      safeTableUserList: [
        { key: "1", label: "张三", disabled: false },
        { key: "2", label: "李四", disabled: false },
        { key: "3", label: "牛五", disabled: false },
        { key: "4", label: "张三", disabled: false },
        { key: "5", label: "李四", disabled: false },
        { key: "6", label: "牛五", disabled: false },
        { key: "7", label: "张三", disabled: false },
        { key: "8", label: "李四", disabled: false },
        { key: "9", label: "牛五", disabled: false },
        { key: "10", label: "张三", disabled: false },
        { key: "11", label: "李四", disabled: false },
        { key: "12", label: "牛五", disabled: false },
        { key: "13", label: "张三", disabled: false },
        { key: "14", label: "李四", disabled: false },
        { key: "15", label: "牛五", disabled: false },
      ],
      pickSafeUserArr: [],
      signatureSwitch: 0,
      pickSafeListId: [],
      teamFileFlag: false,
      subContractPage: 1,
      subContractSize: 15,
      subContractTotal: 0,
      subContractLoading: false,
      projectSubcData: [],
      projectSubcInfo: {},
      currentProjectSubcIndex: 0,
      rosterDataList: [],
      rosterListPage: 1,
      rosterListSize: 15,
      rosterListTotal: 0,
      rosterListLoading: false,
      Accept: ".xls,.xlsx", //上传文件格式限制
      Format: [".xls", ".xlsx"], //上传文件格式限制
      uploadData: {
        projectId: "",
      },
      workTypeList: [],
      pickWorkType: [],
      teamLeaderFlag: true,
      updateSwitch: true,
      currentSafeContent: "",
      editSafeFlag: false,
      getSafeContentLoading: false,
      copySafeDiscloseFlag: false,
      currentVersion: "",
      versionList: [],
      filesTeamList: [],
      getFilesTeamListLoading: false,
      getFilesDetailLoading: false,
      filesDetail: [],
      currentCollapseName: "panel0",
      corpList: [],
      showExitTeamFlag: false,
      showExitTeamNext: true,
      noFinishUserList: [],
      toFinishTeamId: "",
      toFinishTeamData: {},
      toFinishTeamPage: 1,
      toFinishTeamTotal: 0,
      toFinishTeamSize: 10,
      finishTeamUserTableLoading: false,
      signPickFlag: false,
      signCloumsList: [
        // {
        //   title: "签字标签",
        //   key: "tagName",
        // },
        {
          title: "描述",
          key: "name",
        },
        {
          title: "添加时间",
          key: "createDate",
        },
      ],
      signDataList: [],
      signLoading: false,
      signListPage: 1,
      signListTotal: 0,
      signListSize: 15,
      signDetailColumsList: [
        {
          title: "姓名",
          key: "name",
        },
        {
          title: "手机号码",
          key: "phone",
        },
        {
          title: "班组",
          key: "teamName",
        },
        {
          title: "添加时间",
          key: "createDate",
        },
        {
          title: "签字时间",
          key: "signDate",
        },
        {
          title: "签字状态",
          key: "statusStr",
        },
        {
          title: "授权状态",
          key: "isAuthStr",
        },
        {
          title: "操作",
          width: 350,
          slot: "action",
        },
      ],
      signDetailList: [],
      signDetailList1: [],

      signDetailLoading: false,
      signDetailPage: 1,
      signDetailTotal: 0,
      signDetailSize: 15,
      searchSignPeopleData: {
        name: "",
        phone: "",
        isUpload: "",
      },
      signFilesColumsList: [
        {
          title: "所属模块",
          key: "fileTypeStr",
        },
        {
          title: "文件名称",
          key: "fileName",
        },
        {
          title: "文件格式",
          key: "fileExt",
        },
        {
          title: "状态",
          key: "statusStr",
        },
        {
          title: "操作",
          width: 200,
          slot: "action",
        },
      ],
      signFilesList: [],
      signFilesLoading: false,
      signFilesPage: 1,
      signFilesTotal: 0,
      signFilesSize: 15,
      prejobProjectCloumsList: [
        {
          title: "任务名称",
          key: "name",
        },
        {
          title: "管理分类",
          key: "typeStr",
        },
        {
          title: "工种分类",
          slot: "positionTypeStr",
        },
        {
          title: "周期（小时）",
          key: "duration",
        },
        // {
        //   title: "任务状态",
        //   key: "statusStr",
        // },
        {
          title: "任务内容",
          key: "content",
        },
        {
          title: "备注",
          key: "remark",
        },
      ],
      prejobProjectDataList: [],
      prejobProjectPage: 1,
      prejobProjectSize: 5,
      prejobProjectTotal: 0,
      prejobProjectLoading: false,
      testList: [
        {
          value: "New York",
          label: "New York",
        },
        {
          value: "London",
          label: "London",
        },
        {
          value: "Sydney",
          label: "Sydney",
        },
        {
          value: "Ottawa",
          label: "Ottawa",
        },
        {
          value: "Paris",
          label: "Paris",
        },
        {
          value: "Canberra",
          label: "Canberra",
        },
      ],

      // 岗前安全培训-任务管理-搜索条件
      prejobProjectSearchData: {
        name: "",
        type: "",
      },
      // 安全技术交底-任务管理-搜索条件
      safeProjectSearchData: {
        name: "",
        type: "",
        status: "",
      },
      projectManTypeList: [],
      projectWorkTypeList: [],
      tabDataNull: false,
      prejobProjectPickFlag: false,
      safeProjectPickFlag: false,
      searchPrejobTaskData: {
        teamId: "",
        stauts: "",
        beginTime: "",
        finishTime: "",
      },
      prejobTaskFinishTableFlag: false,
      prejobTaskVideoTableFlag: false,
      currentPerjobTaskId: "",
      currentPerjobTaskTemId: "",
      currentSafeAutoVideoId: "",
      addTemplateNext: true,
      newTemplateData: {
        name: "",
        duration: "",
        content: "",
        remark: "",
        type: "",
        projectContentId: "",
      },
      editTemplateFlag: false,
      editTemplateData: {},
      addVideoFlag: false,
      addVideoNext: true,
      addNewVideoColumsList: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "标题",
          key: "title",
        },
        {
          title: "所属分类",
          key: "cateStr",
        },
        {
          title: "视频来源",
          key: "sourceStr",
        },
        {
          title: "简介",
          key: "videoDesc",
        },
        {
          title: "pdf",
          slot: "pdf",
        },
        {
          title: "视频预览",
          slot: "video",
        },
      ],
      addNewVideoDataPage: 1,
      addNewVideoDataSize: 15,
      addNewVideoDataTotal: 0,
      addNewVideoDataLoading: false,
      addNewVideoDataList: [],
      videoTypeList: [],
      videoFromList: [
        {
          value: "0",
          label: "抖工视频库",
        },
        {
          value: "1",
          label: "企业视频库",
        },
      ],
      searchNewVideoData: {
        from: "0",
        type: "",
        title: "",
      },
      choseVideoType: 0,
      choseVideoList: [],
      currentVideoTemplateId: "",
      addVideoType: 0,
      editWorkTypeFlag: false,
      editWorkTypeNext: true,
      currentWorkTypeData: {},
      transferData: [],
      transferTarget: [],
      currentWrokTypeFlag: "",
      danganFlag: 13,
      searchFilesUserData: {
        name: "",
        phone: "",
        idcard: "",
        status: "",
      },
      currentFilesTeamId: "",
      currentFilesTeamData: {},
      signatureFormFlag: false,
      QZTitle: "",
      QZType: "",
      QZItemId: "",
      problemListFlag: false,
      problemCloumsList: [
        {
          title: "考题",
          key: "content",
        },
        {
          title: "答案",
          key: "answerStr",
        },
      ],
      problemCloumsList1: [
        {
          title: "考题",
          key: "title",
        },
        {
          title: "答案",
          key: "answerStr",
        },
        {
          title: "操作",
          slot: "action",
          width: 140,
          align: "center",
        },
      ],
      problemDataList: [],
      problemListPage: 1,
      problemListTotal: 0,
      problemListSize: 15,
      problemListLoading: false,
      currentVideoId: "",
      currentWorkTypeList: [],
      addProblemListFlag: false,
      addProblemFlag: false,
      addProblemData: {
        name: "",
        answer: "",
      },
      addPNext: true,
      // 视频上传
      uploader_key: new Date().getTime(),
      options: {
        target: "/sapi/chunk/upload",
        testChunks: false,
        query: {
          corpId: JSON.stringify(
            JSON.parse(localStorage.getItem("user_info")).icorpid
          ),
        },
      },
      attrs: {
        accept: [
          ".png",
          ".jpg",
          ".jpeg",
          ".gif",
          ".bmp",
          ".mp4",
          ".wmv",
          ".avi",
          ".rmvb",
        ],
      },
      uploadFile: null,
      videoFileId: "",
      videoLoading: false,
      editVideoId: "",
      searchSafeVideoData: "",
      addSafeVideoFlag: false,
      pdfFile: null,
      pdfId: "",
      AcceptPdf: ".pdf", //上传文件格式限制
      FormatPdf: [".pdf"], //上传文件格式限制
      uploadDataPdf: {
        corpId: "",
      },
      zoneList: [],
      areaAuthFlag: false,
      zoneData: "",
      currentSignId: "",
      addSignPeopleFlag: false,
      addSignPeopleNext: true,
      newSignPeopleData: {
        name: "",
        phone: "",
        teamId: [],
      },
      signSearchData: {
        name: "",
      },
      searchSignFilesData: {
        fileType: "",
        fileName: "",
      },
      signFileTypeList: [
        {
          value: "16",
          label: "岗前培训",
        },
        {
          value: "17",
          label: "安全技术交底",
        },
        {
          value: "18",
          label: "专项培训",
        },
      ],

      outsideWorkTypeFlag: false,
      outsideWorkerFlag: false,
      outsideWorkTypeNext: false,
      outsideWorkerNext: false,
      searchWorkerInfoData: {
        name: "",
        phone: "",
        cardNo: "",
        intoDate: "",
        exitDate: "",
      },
      osPostList: [],
      osUserList: [],
      osTeamList: [],
      osRectifyStatusList: [],
      osPostTypeList: [],
      osYearsMonthList: [],
      searchOSCheckData: {
        name: "",
        postId: "",
        typeId: "",
        yearsMonth: "",
      },
      fTimer: null,
      teamUserQRCode: "",
      teamUserFileListColums: [
        {
          title: "文件名称",
          key: "origin_name",
        },
        {
          title: "文件格式",
          key: "file_type",
        },
        {
          title: "文件大小(B)",
          key: "file_size",
        },
        {
          title: "上传时间",
          key: "updated_time",
        },
        {
          title: "操作",
          slot: "action",
          width: 240,
          align: "center",
        },
      ],
      teamUserFileList1: [],
      teamUserFileList2: [],
      teamUserFileList3: [],
      teamUserFileList4: [],
      teamUserFileListPage1: 1,
      teamUserFileListTotal1: 0,
      teamUserFileListSize1: 15,
      teamUserFileListLoading1: false,
      teamUserFileListPage2: 1,
      teamUserFileListTotal2: 0,
      teamUserFileListSize2: 15,
      teamUserFileListLoading2: false,
      teamUserFileListPage3: 1,
      teamUserFileListTotal3: 0,
      teamUserFileListSize3: 15,
      teamUserFileListLoading3: false,
      teamUserFileListPage4: 1,
      teamUserFileListTotal4: 0,
      teamUserFileListSize4: 15,
      teamUserFileListLoading4: false,
      newAddWorkType: {
        type: "",
        money: "",
        id: null,
      },
      newAddWorker: {
        team: "",
        name: "",
        money: "",
        id: null,
      },
      osCheckTab: "worker",
      outsideRectifyTypeFlag: false,
      outsideFineTypeFlag: false,
      newRectifyTypeData: {
        name: "",
        id: null,
      },
      newFineTypeData: {
        name: "",
        id: null,
      },
      outsideRectifyTypeNext: true,
      outsideFineTypeNext: true,
      showRectifyAttachmentFlag: false,
      showRectifyAttachmentNext: true,
      osPowerFlag: false,
      insuranceFlag: false,
      checkFlag: false,
      mobileAttendanceSwitch: false,
      specialTaskSwitch: false,//显示或者隐藏
      contactFlag: false,
      fromVal: "",
      searchPeopleList: {
        name: "",
        phone: "",
        idcard: "",
        realname: "",
        inteam: "",
        incheck: "",
        infinish: "",
      },
      searchTeamItemData: {
        name: "",
        phone: "",
        idcard: "",
        status: "",
      },
      corpWorkType: "",
      addNewWorkType: [],
      corpWorkTypeList: [],
      abproWorkTypeList: [],
      abproWorkTypeSonList: [],
      labourCorpList: [],
      workTypeListData: [],
      editRecordLoading: false,
      editRecordColumnsList: [
        {
          title: "修改人姓名",
          key: "realname",
          align: "center",
        },
        {
          title: "修改人手机",
          key: "updateBy",
          align: "center",
        },
        {
          title: "修改内容",
          // slot: "content",
          key: "content",
          align: "center",
          width: 300,
        },
        {
          title: "修改时间",
          key: "createTime",
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          width: 120,
          align: "center",
        },
      ],
      editRecordDataTableLoading: false,
      editRecordDataList: [
        {
          name: "阿斯达",
          phone: 123456789,
          content: "啊实打实啊实打实",
          time: "2021-12-10",
        },
      ],
      // addVideoupdateBy: "",
      editRecordDataPage: 1,
      editRecordDataSize: 15,
      editRecordDataTotal: 0,
      editRecordTemplateId: "",
      enterTheFieldTime: undefined,
      userInfo: {},
      projectNameInfo: [],

      basicHide:true,
      teamHide:true,
      tagName: "" //team_sign_name
    };
  },
  created() {},
  mounted() {
    this.userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
     //获取当前时间
    let nowDate = new Date().getTime();
    //将密码更新时间转换为时间戳
    let passwordUpdateTime=this.userInfo.passwordUpdateTime!==null?moment().valueOf(this.userInfo.passwordUpdateTime):null;
    

    // 从未修改过密码
    if(passwordUpdateTime==null){

      console.log("密码从未修改过,不作任何提示");
      // this.passwordNeverChangeModal=true

    }else if((passwordUpdateTime-nowDate)>=90*24*60*60*1000){
      this.passwordOverTimeModal=true
    }else{
      this.passwordNeverChangeModal=false
      this.passwordOverTimeModal=false
    }
    this.screenWidth = document.body.clientWidth;
    // // console.log('test',this.$store.state.corpList)
    this.initializeArea();
    // 获取默认项目信息
    this.getProjects(1, 99);
    // 获取用户企业列表
    this.getCorpList();
    // 获取任务管理类型列表
    this.getProjectManTypeList();
    // 获取工种分类
    this.getProjectWorkTypeList();
    this.getWindowInnerHeight();
    // 判断有没有片区管理功能
    this.getAreaAuth();
    this.getZoneList();
    // 获取外部交换平台工种
    this.getOSPostList();
    this.getOSPostTypeList();
    this.getOSYearsMonthList();
    this.getOSTeamList();
    this.getOSRectifyStatusList();
    // 获取富装系统对接抖工接口
    // this.getProjectName();
    this.clientHeight = `${document.documentElement.clientHeight - 64}`; //获取浏览器可视区域高度
    let that = this;
    window.onresize = function () {
      that.fnThrottle(that.changeWidth(), 1000, 1000)();
      if (document.documentElement.clientHeight > 750) {
        that.clientHeight = `${document.documentElement.clientHeight - 64}`;
      } else {
        that.clientHeight = 750;
      }
    };
  },
  filters: {
    vcategoryFormat(num, list) {
      let n = "";
      list.forEach((val) => {
        if (num == val.value) {
          n = val.label;
        }
      });
      return n;
    },
    teamStatus(snum) {
      let newVal = "正常";
      if (snum == "0") {
        newVal = "未开始";
      } else if (snum == "1") {
        newVal = "进行中";
      } else if (snum == "2") {
        newVal = "正常完成";
      } else if (snum == "3") {
        newVal = "逾期";
      } else if (snum == "4") {
        newVal = "逾期完成";
      } else if (snum == "5") {
        newVal = "模板启用";
      } else if (snum == "6") {
        newVal = "模板停用";
      }
      return newVal;
    },
    workTypeFilters(wid, list) {
      let newVal = "";
      list.forEach((val) => {
        if (wid == val.value) {
          newVal = val.name;
        }
      });
      return newVal;
    },
  },
  watch: {
    openName() {
      this.$nextTick(() => {
        this.$refs.side_menu.updateOpened();
        this.$refs.side_menu.updateActiveName();
      });
    },
    currentProjectIndex() {
      this.projectInfo = this.projectData[this.currentProjectIndex];
    },
  },
  destory() {},
  methods: {
    getProjectName() {
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      this.$axios
        .get(`${this.apiDgtechUrlCros}/dgtech/api/open/third/projects`,
          // this.$axios.get("/dgtech/api/mgmt/user/corps",
          { headers: { accessToken: token.accessToken } }
        )
        .then((res) => {
          if (res.data.resultStatus) {
            this.projectNameInfo = res.data.resultData;
          } else {
            this.$Message.error("获取工种分类失败");
          }
        });
    },
    changeWidth() {
      this.screenWidth = document.body.clientWidth;
    },
    // 获取浏览器高度
    getWindowInnerHeight() {
      // // console.log(window.innerHeight);
    },
    // 获取用户企业列表
    getCorpList() {
      let data = {
        phone: this.currentUserInfo.login_id,
        pageSize: 999,
      };
      // this.$fetch("/sapi/mutliUser/getCorpMultiUsers2", {
      //   method: "post",
      //   data,})
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      let userinfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
      this.$axios
        .get(
          `${this.apiDgtechUrlCros}/dgtech/api/mgmt/user/corps`,
          // this.$axios.get("/dgtech/api/mgmt/user/corps",
          { headers: { accessToken: token.accessToken } }
        )
        .then((res) => {
          if (res.data.resultStatus) {
            this.corpList = res.data.resultData;
            this.$store.commit("setCorpList", res.data.resultData);
            let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
            let clist = res.data.resultData;
            clist.forEach((val) => {
              if (val.corpId == userInfo.icorpid) {
                this.checkFlag = val.attendanceMenuSwitch ? true : false;
                this.mobileAttendanceSwitch = val.mobileAttendanceSwitch
                  ? true
                  : false;
                  this.specialTaskSwitch = val.specialTaskSwitch
                  ? true
                  : false;

                this.osPowerFlag = val.rectificationMenuSwitch ? true : false;
                this.insuranceFlag = val.insuranceMenuSwitch ? true : false;
              }
            });
          }else if(res.data.errorCode=='001004'){
              this.$Message.error("登录失效");
              this.$router.push({ name: 'login' });
            }
        })
        .catch(() => {});
    },
    getMultiUserOptions(corpId) {
      this.$fetch("/sapi/mutliUser/getMultiUserOptions?" + "corpId=" + corpId, {
        method: "get",
      })
      // this.$axios
      //   .get("https://test.portal.dougongapp.com/sapi/mutliUser/getMultiUserOptions?" + "corpId=" + corpId, )
        .then((res) => {
          if (res.code == "200") {
            var localRoles = [];
            res.data.forEach((val) => {
              localRoles.push({ roleId: val.roleId, roleName: val.roleName });
            });
            this.roleOptions = localRoles;
          }
        })
        .catch(() => {});
    },
    // 编辑项目信息
    toEditProject(type) {
      if (type == "to") {
        this.editFlag = !this.editFlag;
      } else {
        this.cancelSaveProjectInfo("click");
        // this.editFlag = !this.editFlag;
      }
    },
    // 左边导航栏选项目
    pickProjectMenu(e) {
      console.log(e);
      if (e.length < 1) {
        return false;
      }
      this.projectId = this.projectData[e[0]].id;
      if (this.editFlag) {
        this.projectData = JSON.parse(JSON.stringify(this.backupsProjectData));
        this.projectInfo = JSON.parse(
          JSON.stringify(this.backupsProjectData[this.currentProjectIndex])
        );
        this.editFlag = false;
      }
      // 获取当前分包项目数据
      if (this.projectData[this.currentProjectIndex].sub_contracts) {
        this.projectSubcData = JSON.parse(
          JSON.stringify(
            this.projectData[this.currentProjectIndex].sub_contracts
          )
        );
      }
      this.activeName = "";
      if (e.length == 1) {
        if (e[0] == 0) {
          this.currentProjectName = this.projectData[e[0]].vprojectname;
          setTimeout(() => {
            this.activeName = "info" + ":" + 0;
          }, 100);
          this.currentContentBox = "info";
        } else if (e[0]) {
          this.currentProjectName = this.projectData[e[0]].vprojectname;
          this.activeName = "info" + ":" + e[0];
          // this.$nextTick(() => {
          //   this.$refs.menus.updateOpened();
          // });
          // this.openName = ['info']
          this.currentContentBox = "info";
        }
        let arr = [
          {
            name: "项目信息",
            in: "info",
            index: 1,
          },
        ];
        this.breadcrumbList = arr;
        this.currentProjectIndex = e[0];
        this.projectInfo = this.projectData[this.currentProjectIndex];
        this.updateArea();
        setTimeout(() => {
          this.$refs.my_count.getStatData(this.projectInfo.id);
        }, 100);
      }
      // 分包项目
      // // console.log(e)
      // if(e.length == 3){
      //   if (e[0].split(':')[1] == 0) {
      //     this.currentProjectName = this.projectSubcData[e[0].split(':')[1]].vprojectname;
      //     setTimeout(() => {
      //       this.activeName = "subc1" + ":" + 0;
      //     }, 100);
      //     this.currentContentBox = "info";
      //   }
      //   let arr = [
      //     {
      //       name: "项目信息",
      //       in: "info",
      //       index: 1,
      //     },
      //   ];
      //   this.breadcrumbList = arr;
      //   this.currentProjectSubcIndex = e[0].split(':')[1];
      //   this.projectInfo = this.projectSubcData[this.currentProjectSubcIndex];
      //   this.updateArea();
      //   setTimeout(() => {
      //     this.$refs.my_count.getStatData(this.projectInfo.id);
      //   }, 100);
      // }
      if (e.length == 2 && e[1].split(":")[0] == "subc") {
        if (e[1].split(":")[2] == 0) {
          this.currentProjectName =
            this.projectSubcData[e[1].split(":")[1]].vprojectname;
          setTimeout(() => {
            this.activeName = "subc1" + ":" + e[1].split(":")[1] + ":" + 0;
          }, 100);
          this.currentContentBox = "info";
        } else if (e[1].split(":")[2]) {
          this.currentProjectName =
            this.projectSubcData[e[1].split(":")[1]].vprojectname;
          this.activeName =
            "subc1" + ":" + e[1].split(":")[1] + ":" + e[1].split(":")[2];
          // this.$nextTick(() => {
          //   this.$refs.menus.updateOpened();
          // });
          // this.openName = ['info']
          this.currentContentBox = "info";
        }
        let arr = [
          {
            name: "项目信息",
            in: "info",
            index: 1,
          },
        ];
        this.breadcrumbList = arr;
        this.currentProjectSubcIndex = e[1].split(":")[2];
        this.projectInfo = this.projectSubcData[this.currentProjectSubcIndex];
        this.updateArea();
        setTimeout(() => {
          this.$refs.my_count.getStatData(this.projectInfo.id);
        }, 100);
      } else if (e.length == 2) {
        // // console.log("执行非分包项目", e);
        this.activeName = e[1].split(":")[0] + "1:" + e[0];
        this.currentContentBox = e[1].split(":")[0] + "1";
        console.log("==========================",this.currentContentBox);
        let btxt = "";
        switch (e[1].split(":")[0]) {
          case "prejob":
            btxt = "任务管理";
            this.tabDataNull = false;
            this.prejobTabFlag = "finish";
            this.handlePickPrejob();
            break;
          case "safe":
            btxt = "安全技术交底表管理";
            this.currentWorkTypeList = [];
            this.handlePickSafe();
            break;
          case "special":
            btxt = "专项签字表管理";
            this.handlePickSpecial();
            break;
          case "oncheck":
            btxt = "设备管理";
            // console.log(e)
            // this.handlePickOncheck();
            break;
          case "outside":
            btxt = "外部交换平台";
            this.handlePickOutside();
            break;
          case "insurance":
            btxt = "团体保险";
            break;
        }
        let arr = [
          {
            name: btxt,
            in: e[1].split(":")[0],
            index: 1,
          },
        ];
        this.breadcrumbList = arr;
      }
    },
    // 左边导航栏选子菜单
    pickMenu(e) {
      // // console.log("点击项目", e);
      let that = this;
      let cur = e.split(":")[0];
      // // console.log("点击项目", cur);

      if (cur == "subc1" || cur == "subc2" || cur == "subc3") {
        switch (cur) {
          case "subc1":
            this.currentContentBox = "info";
            break;
          case "subc2":
            this.currentContentBox = "team";
            break;
          case "subc3":
            this.currentContentBox = "check";
            break;
        }
      } else {
        this.currentContentBox = cur;
      }
      let arr = [];
      let text = "";
      switch (cur) {
        case "info":
          this.projectInfo = JSON.parse(
            JSON.stringify(this.backupsProjectData[this.currentProjectIndex])
          );
          setTimeout(() => {
            that.$refs.my_count.getStatData(that.projectInfo.id);
          }, 100);
          text = "项目信息";
          break;
        case "task":
          text = "培训任务";
          break;
        case "right":
          text = "项目授权";
          break;
        case "team":
          text = "班组管理";
          break;
        case "equipment":
          text = "设备管理";
          break;
        case "check":
          text = "考勤管理";
          break;
        case "rosterlist":
          text = "花名册管理";
          break;
        case "taskList":
          text = "内部培训管理";
          break;
        case "worktype":
          text = "工种管理";
          break;
        case "subcontract":
          text = "分包项目";
          break;
        case "subc1":
          this.projectInfo = JSON.parse(
            JSON.stringify(this.projectSubcData[this.currentProjectSubcIndex])
          );
          setTimeout(() => {
            that.$refs.my_count.getStatData(that.projectInfo.id);
          }, 100);
          text = "项目信息";
          break;
        case "subc2":
          text = "班组管理";
          break;
        case "subc3":
          text = "考勤管理";
          break;
        case "files":
          text = "档案管理";
          break;
        case "prejob1":
          text = "任务管理";
          break;
        case "prejob2":
          text = "档案管理";
          break;
        case "safe1":
          text = "安全技术交底表管理";
          break;
        case "safe2":
          text = "交底样板管理";
          break;
        case "safe3":
          text = "任务管理";
          break;
        case "safe4":
          text = "档案管理";
          break;
        case "safe5":
          text = "项目视频库";
          break;
        case "safe6":
          text = "安全技术交底";
          break;
        case "comsafe1":
          text = "安全技术交底表";
          break;
        case "special1":
          text = "专项签字表管理";
          break;
        case "special2":
          text = "模板管理";
          break;
        case "special3":
          text = "任务管理";
          break;
        case "special4":
          text = "档案管理";
          break;
        // case "oncheck1":
        //   text = "设备管理";
        //   break;
        // case "oncheck2":
        //   text = "考勤数据";
        //   break;
        case "oncheck1":
          text = "设备管理";
          break;
        case "oncheck2":
          text = "班组管理";
          break;
        case "oncheck3":
          text = "考勤数据";
          break;
        case "sign":
          text = "签字管理";
          break;
        case "irregularities":
          text = "违规记录";
          break;
        case "outside1":
          text = "实名制人员清单";
          break;
        case "outside2":
          text = "企业内部管理系统";
          break;
        case "outside3":
          text = "考勤数据";
          break;
        case "outside4":
          text = "设置工种薪资";
          break;
        case "outside5":
          text = "整改单管理";
          break;
        case "outside6":
          text = "整改分类设置";
          break;
        case "outside5":
          text = "罚单单管理";
          break;
        case "work":
          text = "项目工作台";
          break;
        case "insurance1":
          text = "团体保险";
          break;
        case "insurance2":
          text = "个人保险";
          break;
        case "insurance4":
          text = "社会保险";
          break;
        case "insurance4":
          text = "其他保险";
          break;
      }
      arr = [
        {
          name: text,
          in: cur,
          index: 1,
        },
      ];
      this.breadcrumbList = arr;
      if (cur == "info" || cur == "subc1") {
        this.updateArea();
      }
      if (cur == "right") {
        this.authTableLoading = true;
        this.getProjectsAuthList();
      }
      if (cur == "irregularities") {//获取违规记录列表
        // this.irregularitiesLoading = true;
        this.getirregularitiesList();
      }
      if (cur == "team" || cur == "subc2") {
        this.searchTeamTxt = "";
        this.teamListPage = 1;
        this.teamTableLoading = true;
        this.getProjectTeamList();
        this.getWorkType();
        this.addNewTeamFlag = false;
      }
      if (cur == "equipment") {
        this.checkInRelateTableLoading = true;
        this.getTeamCheckinRelateList();
        this.newEquipmentData = { eId: "", eName: "" };
      }
      if (cur == "check" || cur == "subc3") {
        this.attendanceDataTableLoading = true;
        this.attendanceTeamListLoading = true;
        this.getAttendanceData();
        this.getAttendanceTeamList();
        this.attendanceSearchData = {
          aName: "",
          aTeamList: "",
          aStatus: "",
        };
        this.attendanceDateDetail = {
          clockStartTime: "",
          clockEndTime: "",
        };
        this.attendanceDate = "";
        this.exportData.date = "";
      }
      if (cur == "subcontract") {
        this.subContractPage = 1;
        this.subContractLoading = true;
        this.getSubContractList();
      }
      if (cur == "rosterlist") {
        this.rosterListPage = 1;
        this.rosterListLoading = true;
        this.getRosterList();
      }
      if (cur == "taskList") {
        this.rosterListPage = 1;
        this.rosterListLoading = true;
        this.getRosterList();
      }
      if (cur == "worktype") {
      }
      if (cur == "files") {
        this.filesTeamList = [];
        this.prejobFilesDataList = [];
        this.currentCollapseName = "";
        this.getFilesDetailLoading = true;
        this.getFilesTeamListLoading = true;
        this.getFilesTeamList();
      }
      if (cur == "prejob1") {
        // // console.log("岗前培训任务-任务管理");
        this.prejobProjectSearchData = {
          name: "",
          type: "",
        };
        this.prejobTabFlag = "finish";
        this.getPrejobProjectList();
      }
      if (cur == "prejob2") {
        this.danganFlag = 13;
      }
      if (cur == "safe1") {
        this.safeSelectValue = [];
        this.currentSafeContent = "";
        this.currentWorkTypeList = [];
        this.getSafeList();
        this.getSafeYearList();
      }
      if (cur == "safe2") {
        // // console.log("安全技术交底表-自动模板管理");
        // this.options.query.projectId = this.projectInfo.id;
        this.prejobProjectSearchData = {
          name: "",
          type: "",
        };
        this.safeTemplateTababFlag = "safeVideo";
        this.editTemplateFlag = false;
        this.getSafeList();
        this.getCategories();
        this.getVideoTypeList();
        this.getSafeAutoList();
        this.getWorkTypeList();
      }
      if (cur == "safe3") {
        // // console.log("安全技术交底-任务管理");
        this.prejobProjectSearchData = {
          name: "",
          type: "",
        };
        this.getSafeList();
        this.safeAutoTabFlag = "finish";
        this.getSafeProjectList();
      }
      if (cur == "safe4") {
      }
      if (cur == "safe5") {
        this.options.query.projectId = this.projectInfo.id;
        this.searchSafeVideoData = "";
        this.addSafeVideoFlag = false;
        this.getVideoTypeList();
        this.getSafeVideoList();
      }
      if (cur == "special1") {
      }
      if (cur == "special2") {
      }
      if (cur == "special3") {
      }
      if (cur == "special4") {
        this.danganFlag = 15;
        this.filesTeamList = [];
        this.currentCollapseName = "";
        this.getFilesDetailLoading = true;
        this.getFilesTeamListLoading = true;
        this.getFilesTeamList();
      }
      if (cur == "sign") {
        this.signListPage = 1;
        this.signSearchData = {
          name: "",
        };
        this.getSignList();
        this.getProjectTeamList(1, 999, "");
        this.signTabFlag = "signpeople";
      }
      if (cur == "outside1") {
        this.searchWorkerInfoData = {
          name: "",
          phone: "",
          cardNo: "",
          intoDate: "",
          exitDate: "",
        };
        this.GMRealNameData = [];
        this.GMRealNamePage = 1;
        this.getWorkerInfoList();
      }
      if (cur == "outside2") {
      }
      if (cur == "outside3") {
        this.searchOSCheckData = {
          name: "",
          postId: "",
          typeId: "",
          yearsMonth: "",
        };
        this.GMCheckPage = 1;
        this.getGMCheckList();
      }
      if (cur == "outside4") {
        this.GMWorkTypePage = 1;
        this.GMWorkerPage = 1;
        this.getOSWorkTypeMoneyList();
        this.getOSWorkerMoneyList();
      }
      if (cur == "outside5") {
      }
      if (cur == "outside6") {
        this.OSRectifyTypePage = 1;
        this.OSFineTypeSize = 1;
        this.getOSRectifyTypeList();
        this.getOSFineTypeList();
      }
    },
    addAuth(type) {
      this.selectedRoleId = 6;
      if (type == "open") {
        this.addNewAuthFlag = true;
      }
      if (type == "close") {
        this.addNewAuthFlag = false;
      }
      this.tipsAddAuth = false;
      this.newAuthData = { name: "", phone: "" };
    },
    toHandleWorkType(e) {
      let plist = e || [];
      let nlist = [];
      let flist = this.abproWorkTypeList;
      this.newTeamData.workTypeValue = "";
      plist.forEach((val) => {
        if (flist.findIndex((o) => o.workTypeValue == val) >= 0) {
          nlist.push(flist[flist.findIndex((o) => o.workTypeValue == val)]);
        }
      });
      this.abproWorkTypeSonList = nlist;
    },
    getLabourCorpList() {
      let that = this;
      let data = {};
      this.$fetch(`/sapi/labourCorp/list?projectId=${this.projectInfo.id}`, {
        method: "get",
        data,
      })
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            if (res.data.length > 0) {
              this.labourCorpList = res.data;
            } else {
              this.$Message.info("暂无可选劳务公司");
            }
          } else {
            this.$Message.error("获取劳务公司失败");
          }
        })
        .catch((err) => {
         // this.$Message.error("获取劳务公司失败");
        });
    },
    // 获取项目关联当前工种
    getAboutProjectWorktypeList() {
      let that = this;
      let data = {};
      this.$fetch(
        `/sapi/workType/relate/getProjectRelates?projectId=${this.projectInfo.id}`,
        {
          method: "get",
          data,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            if (res.data.length > 0) {
              this.abproWorkTypeList = res.data;
              // let clist = res.data[0].childs
              // let ids = []
              // clist.forEach(val=>{
              //   ids.push(val.value)
              // })
              // this.addNewWorkType = ids
              // this.corpWorkTypeList = clist
            } else {
              this.$Message.info("该项目没有关联工种");
            }
          } else {
            this.$Message.error("获取工种失败");
          }
        })
        .catch((err) => {
         // this.$Message.error("获取工种失败");
        });
    },
    addTeam() {
      this.newTeamData = {
        phone: "",
        vteam_name: "",
        vresponsible_person_name: "",
        vresponsible_person_phone: "",
        corp_name: "",
        vproject_code: "",
        dentry_time: "",
        dexit_time: "",
        remark: "",
        leader_work_type: "",
        is_no_audit: "1",
        workTypeValues: [],
        workTypeValue: "",
        workTypeName: "",
        labour_corp_id: undefined,
      };
      this.enterTheFieldTime = undefined;
      this.abproWorkTypeList = [];
      this.getAboutProjectWorktypeList();
      this.getLabourCorpList();
      this.addNewTeamFlag = !this.addNewTeamFlag;
    },
    // 新增授权角色
    toAddAuth() {
      if (this.tipsAddAuth) {
        this.toAddAuthReal();
        this.addNewAuthFlag = false;
        return false;
      }
      if (this.newAuthData.name == "" || this.newAuthData.phone == "") {
        this.$Message.warning("请填写新增授权人员信息");
        this.addNewAuthNext = false;
        setTimeout(() => {
          this.addNewAuthNext = true;
        }, 100);
        return false;
      }
      this.toAddAuthReal();
    },
    toAddAuthReal() {
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      let data = {
        phone: this.newAuthData.phone,
        name: this.newAuthData.name,
        corpId: this.projectInfo.icorpid + "",
        projectId: this.projectInfo.id + "",
        roleId: this.selectedRoleId,
        // rectifyType: this.selectedRolePermissionId,
      };
      // this.$fetch("/sapi/mutliUser/setMutliUser", {
      //   method: "post",
      //   data: { multiUser: [data] },
      // })
      this.$axios({
        url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/mutli`,
        method: "post",
        data: { multiUserList: [data] },
        //  this.$axios({url:`/dgtech/api/mgmt/mutli`, method:"post",data:{multiUserList:[data]},
        headers: { accessToken: token.accessToken },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.$Message.success("新增成功");
          // 处理修改成功
          this.newAuthData = { name: "", phone: "" };
          this.tipsAddAuth = false;
          this.authTableLoading = true;
          this.addNewAuthFlag = false;
          this.selectedRolePermissionId = null;
          this.getProjectsAuthList();
        } else {
          this.addNewAuthNext = false;
          setTimeout(() => {
            this.addNewAuthNext = true;
          }, 100);
          this.$Message.error("新增失败");
        }
      });
    },
    // 新增班组
    toAddTeam() {},
    hiddenQR() {
      this.showQRFlag = !this.showQRFlag;
    },
    showQR(e) {
      if (!e) {
        return false;
      }
      this.showQRFlag = !this.showQRFlag;
      this.showImgSrc = e;
    },
    editTeamTasks() {
      if (!this.teamTasksDataChange) {
        this.teamTasksDataChange = !this.teamTasksDataChange;
      } else {
        let data = {
          id: this.teamTasksData.id + "",
          vteamName: this.teamTasksData.teamName,
          isRemoved: this.teamTasksData.status,
          isNoAudit: this.teamTasksData.isNoAudit,
        };
        this.$Modal.confirm({
          title: "提示",
          content: `<p>是否修改班组信息</p>`,
          onOk: () => {
            this.$fetch("/sapi/team/update", {
              method: "post",
              data,
            }).then((res) => {
              if (res.code == "200") {
                this.$Message.success("班组信息修改成功");
                // 处理修改成功
                this.currentTeamData = JSON.parse(
                  JSON.stringify(this.teamTasksData)
                );
                this.teamTasksDataChange = !this.teamTasksDataChange;
              } else {
                this.$Message.info(res.msg || "班组信息修改失败");
                this.teamTasksDataChange = !this.teamTasksDataChange;
              }
              // // console.log(res)
            });
          },
          onCancel: () => {
            this.teamTasksDataChange = !this.teamTasksDataChange;
          },
        });
      }
    },
    // 获取工种
    getWorkTypeList() {
      console.log(123);
      this.$fetch("/sapi/disclose/categories", {
        method: "get",
        params: {
          dataId: this.projectInfo.id,
          dataType: "PROJECT",
        },
      }).then((res) => {
        console.log(res, "工种1111111111111111");
        if (res && res.code === "200") {
          this.workTypeListData = res.data;
        } else {
          this.$Message.error("获取失败，请稍后再试");
        }
      });
    },
    getCategories(node, resolve) {
      this.$fetch("/sapi/disclose/categories", {
        method: "get",
        params: {
          dataId: this.projectInfo.id,
          dataType: "PROJECT",
        },
      }).then((res) => {
        console.log(res, "555555555555555");
        if (res.code == "200") {
          const companyData = res.data.map((value, i) => ({
            value: value.categoryId,
            label: value.categoryName,
            leaf: node.level >= 1,
          }));
          console.log(companyData);
          resolve(companyData);
        }
      });
    },
    getDiscloseContents(node, resolve) {
      console.log(node, resolve);
      this.$fetch("/sapi/project/disclose/contents", {
        method: "get",
        params: {
          projectId: this.projectInfo.id,
          useable: true,
          categoryId: node.value,
        },
      }).then((res) => {
        console.log(res, "66666666");
        if (res.code == "200") {
          const projectData = res.data.dataList.map((value, i) => ({
            value: value.contentId,
            label: value.contentName,
            children: undefined,
            leaf: node.level >= 0,
          }));
          node.loading = false;
          node.loaded = true;
          resolve(projectData);
        }
      });
    },
    onnewTemplateDataCategoryChange() {
      this.newTemplateData.projectContentId = "";
      this.projectContentData = [];
    },
    onProjectContentChange() {
      console.log(this.newTemplateDataCategoryId);
      if (
        this.newTemplateDataCategoryId == "" ||
        this.newTemplateDataCategoryId == undefined
      ) {
        this.projectContentData = [];
        this.$Message.error("请先选择项目安全技术交底表内容分类");
        return;
      }
      this.$fetch("/sapi/project/disclose/contents", {
        method: "get",
        params: {
          projectId: this.projectInfo.id,
          useable: true,
          categoryId: this.newTemplateDataCategoryId,
        },
      }).then((res) => {
        if (res.code == "200") {
          this.projectContentData = res.data.dataList;
          console.log(this.projectContentData, "66666666");
          // const projectData = res.data.dataList.map((value, i) => ({
          //     value: value.contentId,
          //     label: value.contentName,
          //     children: undefined,
          //     leaf: node.level >= 0
          //   }));
          //   node.loading=false;
          //   node.loaded=true;
          //   resolve(projectData)
        }
      });
    },
    // 监听
    projectChange(info) {
      console.log(info);
      if (info.length == 1) {
        this.newTemplateData.projectContentId = "";
        console.log(this.newTemplateData);
        return this.$Message.error("请选择工程");
      }
      this.newTemplateData.projectContentId = info[1];
    },
    lazyLoad(node, resolve) {
      setTimeout(() => {
        if (node.level == 0) {
          this.getCategories(node, resolve);
        } else if (node.level == 1) {
          this.getDiscloseContents(node, resolve);
          node.loading = false;
          node.loaded = true;
        } else {
          node.loading = false;
          node.loaded = true;
        }
      }, 1000);
    },
    pickTaskTab(e) {
      this.currentTasksTab = e;
      // // console.log(e)
      if (e == "7") {
        // teamManTasksDataList
        // teamManTasksDataLoading
        this.teamManTaskDataPage = 1;
        this.teamManTasksDataLoading = true;
        this.getTeamManTaskData();
      }
      if (e == "5") {
        this.getExcelTemplate();
      }
      if (e == "6") {
        this.safeSelectValue = [];
        this.currentSafeContent = "";
        this.getSafeList();
        this.getSafeYearList();
      }
    },
    getExcelTemplate() {
      this.$axios
        .get(`/sapi/excelTemplate/getExcelTemplate/${this.projectInfo.id}`)
        .then((res) => {
          if (res.data.code == "200") {
            this.handleExcelTemplate(
              res.data.data.province,
              res.data.data.template
            );
          } else {
            this.$Message.error("获取安全表格数据失败");
          }
        })
        .catch((err) => {
         // this.$Message.error(err.message);
        });
    },
    handleExcelTemplate(plist, tlist) {
      // // console.log(plist,tlist)
      let newPlist = [];
      for (let key in plist) {
        newPlist.push({
          value: key,
          label: plist[key],
        });
      }
      this.excelProvinceList = newPlist;
      this.excelDataList = tlist;
      let cpid = this.projectInfo.province;
      this.currentExcelProvince = cpid;
      this.currentExcelDataList = this.excelDataList[cpid];
    },
    handleExcelProvinceChange(e) {
      this.currentExcelDataList = this.excelDataList[e];
    },
    toTeamProjectPageChange(e) {
      this.teamManTasksDataLoading = true;
      this.teamManTaskDataPage = e;
      this.getTeamManTaskData();
    },
    // 获取班组管理-当前班组班组培训任务
    getTeamManTaskData() {
      // // console.log("获取班组培训")
      let data = {
        projectIds: [this.projectInfo.id],
        teamIds: [this.currentTeamData.id],
        pageCurrent: this.teamManTaskDataPage,
        pageSize: 500,
      };
      this.$fetch("/sapi/task/getTeamsTasksStatics", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          let list = res.data.data;
          let newList = [];
          if (list == null) {
            this.teamManTasksDataList = [];
          } else {
            if (list.length > 0) {
              list.forEach((val, index) => {
                if (val.taskMemberCount > 0) {
                  // list.splice(index, 1);
                  newList.push(val);
                }
              });
              this.teamManTasksDataList = newList;
            } else {
              this.teamManTasksDataList = [];
            }
          }
          this.teamManTaskDataTotal = res.data.total;
          this.teamManTasksDataLoading = false;
        } else {
          this.$Message.warning(res.msg || "班组信息修改失败");
          this.teamManTasksDataLoading = false;
        }
        // // console.log(res)
      });
    },
    //添加第三级面包屑
    addBreadcrumb(e, name) {
      let arr = this.breadcrumbList;
      let text = name || "未命名";
      arr.push({
        name: text,
        in: e,
        index: 2,
      });
    },
    // 班组管理点击退场
    toTeamExit(val) {
      // // console.log(val);
      // 班组下所有人员已完成下发任务
      if (val.totalNum == val.finishNum) {
        this.exitTeam(val.id);
      } else {
        this.$Modal.confirm({
          title: "提示",
          content: `<p>当前班组存在未完成任务，暂不允许班组退场！</p>`,
          okText: "查看详情",
          onOk: () => {
            this.getNoFinishUserList(val.id);
            this.toFinishTeamData = val;
            this.toFinishTeamId = val.id;
            this.showExitTeamFlag = true;
          },
          onCancel: () => {},
        });
      }
    },
    // 导出工种课程PDF
    toExportPDF(val) {
      this.teamTableLoading = true
      let token = new Storage().get(constants.EOVA_AUTH);
      this.$axios({
        url:`${this.apiDgtechUrlCros}/dgtech/api/mgmt/team/${val.id}/video/pdf/export`,
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accessToken: token.accessToken,
        },
        responseType: 'arraybuffer',
      }).then((res) => {
        if (res.status==200) {
          const url = window.URL.createObjectURL(
            new Blob([res.data], {
              type: "application/zip",
            })
          );
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          let name = new Date().getTime();
          link.setAttribute("download", name + ".zip");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.$Message.error("下载失败");
        }
      }).catch(err => {
        console.error(err)
        this.$message.warning("暂无数据");
      }).finally(() => {
        this.teamTableLoading = false
      })
    },
    // 完成任务下发
    toTeamFinish() {
      let data = {
        teamId: this.toFinishTeamId,
      };
      let that = this;
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否将班组未完成任务人员线下完成培训</p>`,
        onOk: () => {
          this.$fetch("/sapi/userTask/updateStatus", {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("班组任务未完成人员已在线下完成培训");
              this.searchTeamTxt = "";
              this.teamTableLoading = true;
              this.getProjectTeamList(1);
              this.showExitTeamFlag = false;
            } else {
              this.showExitTeamNext = false;
              setTimeout(() => {
                this.showExitTeamNext = true;
              }, 500);
              this.$Message.info(res.msg || "操作失败");
            }
            // // console.log(res)
          });
        },
        onCancel: () => {
          this.showExitTeamNext = false;
          setTimeout(() => {
            this.showExitTeamNext = true;
          }, 500);
        },
      });
    },
    toTeamFinishPageChange(e) {
      this.toFinishTeamPage = e;
      this.getNoFinishUserList(this.toFinishTeamId);
    },
    // 获取未完成任务用户列表
    getNoFinishUserList(tid) {
      this.finishTeamUserTableLoading = true;
      let data = {
        id: tid,
        pageSize: this.toFinishTeamSize,
        pageNumber: this.toFinishTeamPage,
      };
      this.$fetch("/sapi/team/findByNotFinishUserList", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          this.noFinishUserList = res.data;
          this.finishTeamUserTableLoading = false;
          this.toFinishTeamTotal = res.total;
        } else {
          this.$Message.info(res.msg || "获取失败");
          this.finishTeamUserTableLoading = false;
        }
        // // console.log(res)
      });
    },
    // 班组退场api
    exitTeam(tid) {
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否确定班组退场</p>`,
        onOk: () => {
          let data = {
            id: tid,
            isExit: 1,
          };
          this.$fetch("/sapi/team/exit", {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("操作成功");
              this.searchTeamTxt = "";
              this.teamTableLoading = true;
              this.getProjectTeamList(1);
            } else {
              this.$Message.info(res.msg || "操作失败");
            }
            // // console.log(res)
          });
        },
        onCancel: () => {
          this.teamTasksDataChange = !this.teamTasksDataChange;
        },
      });
    },
    // 班组管理点击编辑
    toTeamEdit(data) {
      // // console.log(data);
      this.currentContentBox = "teamEdit";
      this.teamUserPage = 1;
      this.currentTasksTab = 1;
      this.addBreadcrumb("teamEdit", data.teamName);
      // 获取该班组详情的信息
      this.currentTeamData = data;
      this.teamTasksData = JSON.parse(JSON.stringify(data));
      this.teamUserTableLoading = true;
      this.getProjectTeamUserList();
      // // console.log(data);
      this.teamUserTabFlag = "insured";
      this.curTeamUser = {};
    },
    toEquipmentEdit(val) {
      // // console.log(val);
      this.currentEquipmentData = val;
      this.equipmentTeamLoading = true;
      this.equipmentTeamDetailLoading = true;
      this.currentContentBox = "equipmentEdit";
      this.addBreadcrumb("equipmentEdit", val.name);
      this.equipmentListPage = 1;
      this.equipmentListSearch = "";
      this.getEquipmentTeamList(val.serialNo);
    },
    // 初始化选地区组件
    initializeArea() {
      let area = this.area;
      for (let i in area) {
        let obj = {
          value: area[i].id,
          label: area[i].name,
        };
        this.provinceList.push(obj);
        this.cityList.push(area[i].child);
      }
    },
    // 选择省份--项目信息
    changeProvince(data) {
      // // console.log("2-已知当前项目地址，获取省份列表", data);
      let that = this;
      that.cityList = [];
      that.city = "";

      that.province = data.value;
      that.projectInfo.province = data.value;
      that.provinceName = data.label;

      let area = that.area;
      for (let i in area) {
        if (data.value === area[i].id) {
          let city = area[i].child;
          for (let j in city) {
            let obj = {
              value: city[j].id,
              label: city[j].name,
            };
            that.cityList.push(obj);
          }
        }
      }
      // // console.log("3-通过传过来的id获取新区列表", that.cityList);
      let cid = that.projectData[that.currentProjectIndex].city || "";
      // // console.log("获取当前cid", cid);
      let cname = "";
      if (cid) {
        that.cityList.forEach((val) => {
          if (val.value == cid) {
            cname = val.label;
          }
        });
        // // console.log("获得当前cid的名字", { value: cid, label: cname });
        this.changeCity({ value: cid, label: cname });
      }
    },
    // 选择市区--项目信息
    changeCity(data) {
      if (data) {
        this.city = data.value;
        this.projectInfo.city = data.value;
        this.cityName = data.label;
      }
    },
    changeProvinceNew(data) {
      if (data) {
        let that = this;
        that.addNewProjectData.province = data.value;
        that.province = data.value;
        that.cityList = [];
        that.addNewProjectDataProvinceName = data.label;
        let area = that.area;
        for (let i in area) {
          if (data.value === area[i].id) {
            let city = area[i].child;
            for (let j in city) {
              let obj = {
                value: city[j].id,
                label: city[j].name,
              };
              that.cityList.push(obj);
            }
          }
        }
      }
    },
    changeCityNew(data) {
      if (data) {
        let that = this;
        that.addNewProjectData.city = data.value;
        that.addNewProjectDataCityName = data.label;
        that.city = data.value;
      }
    },
    // 培训任务--新增任务
    toAddProject() {
      this.addProjectFlag = !this.addProjectFlag;
    },
    handleTeamLeaderChange(val) {
      // // console.log(val)
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      if (val.isTeamLeader != "1" && this.teamLeaderFlag) {
        this.teamLeaderFlag = false;
        this.$Modal.confirm({
          title: "提示",
          content: `<p>是否将 ${val.realname} 设置为班组长</p>`,
          onOk: () => {
            let data = {
              id: val.id,
              is_team_leader: 1,
              phone: val.phone,
              team_id: val.teamId,
            };
            // this.$fetch("/rlapi/teamItem/update", {
            //   method: "post",
            //   data,})
            this.$axios({
              url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/team/item/${val.id}/leader`,
              method: "post",
              // this.$axios({url:`/dgtech/api/mgmt/team/item/${val.id}/leader`, method:"post",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                accessToken: token.accessToken,
              },
            }).then((res) => {
              if (res.data.resultStatus) {
                this.$Message.success("操作成功");
                // 处理修改成功
                this.teamUserPage = 1;
                this.teamUserTableLoading = true;
                this.getProjectTeamUserList();
                this.updateSwitch = false;
                setTimeout(() => {
                  this.updateSwitch = true;
                }, 500);
                setTimeout(() => {
                  this.teamLeaderFlag = true;
                }, 3000);
              } else {
                setTimeout(() => {
                  this.updateSwitch = true;
                }, 500);
                setTimeout(() => {
                  this.teamLeaderFlag = true;
                }, 3000);
              }
            });
          },
          onCancel: () => {
            setTimeout(() => {
              this.updateSwitch = true;
            }, 500);
            setTimeout(() => {
              this.teamLeaderFlag = true;
            }, 3000);
          },
        });
      } else {
        setTimeout(() => {
          this.teamLeaderFlag = true;
        }, 3000);
        this.$Message.error("请不要频繁操作，稍后再试");
        return false;
      }
    },
    removeAuth(e) {
      // // console.log(e);
      // this.removeAuthFlag = !this.removeAuthFlag;
      // if (this.currentUserInfo.dg_key == 1) {
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否将 ${e.name} 从授权列表中移除</p>`,
        onOk: () => {
          let data = { id: e.id }; //签字管理
          this.$fetch("/sapi/mutliUser/delMultiUsers", {
            method: "post",
            data,
          }).then((res) => {
            if (res && res.code == "200") {
              this.$Message.success("移除授权成功");
              // 处理修改成功
              this.authTableLoading = true;
              this.getProjectsAuthList();
            } else {
              // this.$Message.error("移除授权失败");
            }
          });
        },
        onCancel: () => {},
      });
      // } else {
      //   this.$Message.warning("无权限移除");
      // }
    },
    showModal(type, data) {
      if (type == "addEquipment") {
        this.modalFlag.addEquipment = !this.modalFlag.addEquipment;
      }
      if (type == "bindEquipment") {
        this.modalFlag.bindEquipment = !this.modalFlag.bindEquipment;
        this.getEquipmentTeamLoading = true;
        this.bindNewEquipmentData = {};
        if (data) {
          this.getBindEquipmentTeamList(data);
          this.bindNewEquipmentData = data;
        } else {
          this.getBindEquipmentTeamList();
          this.bindNewEquipmentData = JSON.parse(
            JSON.stringify(this.currentEquipmentData)
          );
        }
      }
      if (type == "removeEquipment") {
        this.modalFlag.removeEquipment = !this.modalFlag.removeEquipment;
      }
    },
    showTips(type, txt) {
      const title = "提示";
      const content = txt || "<p>Content of dialog</p><p>Content of dialog</p>";
      switch (type) {
        case "info":
          this.$Modal.info({
            title: title,
            content: content,
          });
          break;
        case "success":
          this.$Modal.success({
            title: title,
            content: content,
          });
          break;
        case "warning":
          this.$Modal.warning({
            title: title,
            content: content,
          });
          break;
        case "error":
          this.$Modal.error({
            title: title,
            content: content,
          });
          break;
      }
    },
    pickEquipmentTeam(eid) {
      let list = this.equipmentTeamList;
      this.equipmentTeamUserPage = 1;
      list.forEach((val, index) => {
        if (eid == index) {
          val.pickFlag = true;
        } else {
          val.pickFlag = false;
        }
      });
      this.currentEquipmentTeamData = {};
      this.equipmentTeamDataSearch = {
        eName: "",
        eStatus: "",
        eJob: "",
      };
      this.equipmentTeamDetailLoading = true;
      this.equipmentTeamUserPage = 1;
      this.getEquipmentTeamDetail();
    },
    // 获取项目信息
    async getProjects(pageNum, pageSize) {
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
      if (userInfo) {
        // let data = {
        //   pageNumber: pageNum,
        //   pageSize: pageSize,
        // };
        this.currentUserInfo = userInfo;
        // let res = await this.$fetch("/rlapi/project/list4Portal", {
        //   method: "post",
        //   data,
        // });
        let res = await this.$axios.get(
          `${this.apiDgtechUrlCros}/dgtech/api/mgmt/corp/${userInfo.icorpid}/projects?pageNo=${pageNum}&pageSize=${pageSize}`,
          // let res = await this.$axios.get(`/dgtech/api/mgmt/corp/${userInfo.icorpid}/projects?pageNo=${pageNum}&pageSize=${pageSize}`,
          { headers: { accessToken: token.accessToken } }
        );
        if (res) {
          if (res.data.resultData.list.length > 0) {
            res.data.resultData.list.forEach((item) => {
              item.record_code = item.recordCode;
              item.vresponsible_person_name = item.vresponsiblePersonName;
              item.vresponsible_person_phone = item.vresponsiblePersonPhone;
              item.vresponsible_person_id_number =
                item.vresponsiblePersonIdNumber;
              item.vfunction_num = item.vfunctionNum;
              item.vcontractor_corp_name = item.vcontractorCorpName;
              item.frame_type = item.frameType;
              item.is_qrcode = item.isQrcode;
              item.app_secret = item.appSecret;
              item.compete_inter_task_count = item.competeInterTaskCount;
              item.create_by = item.createBy;
              item.create_id = item.createId;
              item.create_time = item.createTime;
              item.dbuilding_area = item.dbuildingArea;
              item.dbuilding_length = item.dbuildingLength;
              item.education_officer = item.educationOfficer;
              item.form_filler = item.formFiller;
              item.full_location = item.fullLocation;
              item.has_check_in = item.hasCheckIn;
              item.has_pay_sign = item.hasPaySign;
              item.is_demo = item.isDemo;
              item.is_existed = item.isExisted;
              item.is_newbie = item.is_newbie;
              item.is_removed = item.isRemoved;
              item.is_removed_person_count = item.isRemovedPersonCount;
              item.logo_url = item.logoUrl;
              item.person_count = item.personCount;
              item.record_code = item.recordCode;
              item.role_id = item.roleId;
              item.role_name = item.roleName;
              item.sub_contract_flag = item.subContractFlag;
              item.team_count = item.teamCount;
              item.total_person_count = item.totalPersonCount;
              item.total_realname_count = item.totalRealnameCount;
              item.train_location = item.trainLocation;
              item.train_pproject_name = item.trainPprojectName;
              item.train_project_time = item.trainProjectTime;
              item.train_team_time = item.trainTeamTime;
              item.update_by = item.updateBy;
              item.updata_id = item.updataId;
              item.update_time = item.updateTime;
              item.vapproval_level_num = item.vapprovalLevelNum;
              item.vapprovel_num = item.vapprovelNum;
              item.varea_code = item.vareaCode;
              item.vbuild_corp_code = item.vbuildCorpCode;
              item.vbuild_corp_name = item.vbuildCorpName;
              item.vbuild_plan_num = item.vbuildPlanNum;
              item.vbuilder_licenses = item.vbuilderLicenses;
              item.vcontractor_corp_name = item.vcontractorCorpName;
              item.vcontractor_corp_code = item.vcontractorCorpCode;
              item.vfunction_num = item.vfunctionNum;
              item.vlink_man = item.vlinkMan;
              item.vlink_phone = item.vlinkPhone;
              item.vnation_num = item.vnationNum;
              item.vprj_num = item.vprjNum;
              item.vprj_size = item.vprjSize;
              item.vproperty_num = item.vpropertyNum;
            });
          }

          this.projectData = res.data.resultData.list;
          this.backupsProjectData = JSON.parse(
            JSON.stringify(res.data.resultData.list)
          );
          this.projectTotal = parseInt(res.data.resultData.totalNum);
          // this.pageSize = res.resData.pageSize;
          // // console.log(res.resData.list);
          this.handleProject();
          this.pickProjectMenu([0]);
        }
        this.getMultiUserOptions(userInfo.icorpid);
      } else {
        this.$router.push({ name: "login" });
      }
    },
    // 获取项目授权列表
    async getProjectsAuthList() {
      let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
      if (userInfo) {
        let res = await this.$fetch(
          "/sapi/mutliUser/getMutliUsers2?projectId=" +
            this.projectInfo.id +
            "&icorpId=" +
            userInfo.icorpid,
          {
            method: "get",
          }
        );
        if (res) {
          this.authTableLoading = false;
          this.authInfo = res.data || [];
        }
        this.handleAuth();
      } else {
        this.$router.push({ name: "login" });
      }
    },
     // 获取违规记录列表
    async getirregularitiesList() {
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
      if (userInfo) {


      let res = await this.$axios.get(
          `${this.apiDgtechUrlCros}/dgtech/api/mgmt/irregularities?projectId=${this.projectId}&pageNo=${this.irregularitieListPageNo}&pageSize=${this.irregularitieListPageSize}&keyword=${this.irregularitieListKeyword}&irregularLevel=${this.irregularitieListIrregularLevel}`,
          { headers: { accessToken: token.accessToken } }
        );
        if (res) {
          this.irregularitiesLoading = false;
          // this.irregularitiesDataList = res.data.resultData.dataList || [];
          this.irregularitiesDataList1 = res.data.resultData.dataList || [];


          this.irregularitiesDataList= JSON.parse(JSON.stringify(this.irregularitiesDataList1));//处理深拷贝  浅拷贝
            // 默认先脱敏
          this.irregularitiesDataList.forEach((e) => {

            e.teamItems.forEach((e1) => {
              e1.username = e1.username?nameConceal(e1.username.toString()):'';//姓名
             
              });
              e.showOrHide = "hide";//显示/隐藏
            
          });

          this.irregularitiesListTotal=res.data.resultData.totalNum;
        }
      } else {
        this.$router.push({ name: "login" });
      }
    },
    // 展示
    showIrregularitiesConceal(data){
       this.irregularitiesDataList1.forEach((item,index)=>{
        if(data.irregularId==item.irregularId){
          data.teamItems=item.teamItems;
          data.showOrHide = "show";//显示/隐藏
        
        }
      })
    },
    //隐藏
    hideIrregularitiesConceal(data){
      this.irregularitiesDataList1.forEach((item,index)=>{
        if(data.irregularId==item.irregularId){
          // data.teamItems.forEach((item1)=>{ 
          //   item1.username = item1.username?nameConceal(item1.username.toString()):'';//姓名
          // })
          data.teamItems=[];
          data.showOrHide = "hide";//显示/隐藏
         
        }
      })
      console.log(this.irregularitiesDataList);
    },
    // 获取班组管理列表
    async getProjectTeamList(pageNum, pageSize, searchName) {
      pageNum = this.teamListPage;
      pageSize = pageSize || 10;
      searchName = this.searchTeamTxt || "";
      let data = {
        pageNumber: pageNum,
        pageSize: pageSize,
        projectId: this.projectInfo.id,
        name: searchName,
      };
      let res = await this.$fetch("/sapi/team/getTeamsByProjectIdOrName", {
        method: "post",
        data,
      });
      if (res) {
        this.teamTableLoading = false;
        this.teamInfo = res.data || [];
        this.teamListTotal = res.total;
        this.handleTeam();
      }
    },
    handleSearchTeamItem(type) {
      if (type == "reset") {
        this.searchTeamItemData = {
          name: "",
          phone: "",
          idcard: "",
          status: "",
        };
        this.teamUserPage = 1;
      }
      this.getProjectTeamUserList();
    },
    // 获取班组管理-成员列表
    async getProjectTeamUserList() {
      this.teamUserTableLoading = true;
      let pageNum = this.teamUserPage;
      let pageSize = 5;
      let data = {
        pageNumber: pageNum,
        pageSize: pageSize,
        teamId: this.currentTeamData.id,
        isRemoved: this.searchTeamItemData.status,
        phone: this.searchTeamItemData.phone,
        vidcard: this.searchTeamItemData.idcard,
        realname: this.searchTeamItemData.name,
      };
      let res = await this.$fetch("/sapi/teamItem/getTeamItems", {
        method: "post",
        data,
      });
      if (res) {
        // // console.log(res);
        this.teamUserTableLoading = false;
        // // console.log("test", this.teamManUserDataList, res.data);
        this.teamUserTotal = res.total;
        // this.teamManUserDataList = res.data;
        this.teamManUserDataList1=res.data;  


        this.teamManUserDataList= JSON.parse(JSON.stringify(this.teamManUserDataList1));//处理深拷贝  浅拷贝
          // 默认先脱敏
        this.teamManUserDataList.forEach((e) => {
            e.vname = e.vname?nameConceal(e.vname.toString()):'';//姓名
            e.phone = e.phone?phoneConceal(e.phone.toString()):'';//手机号
            e.vidcard = e.vidcard?IdcardConceal(e.vidcard.toString()):'';//身份证号

            e.showOrHide = "hide";//显示/隐藏
        });

        // // console.log("test2", this.teamManUserDataList, res.data);
      }
    },
    // 展示
    showTeamUserConceal(data){
       this.teamManUserDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.vname = item.vname;//姓名
         data.phone = item.phone;//手机号
         data.vidcard = item.vidcard;//身份证号

         data.showOrHide = "show";//显示/隐藏
        }
      })
    },
    //隐藏
    hideTeamUserConceal(data){
      this.teamManUserDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.vname = item.vname?nameConceal(item.vname.toString()):'';//姓名
         data.phone= item.phone?phoneConceal(item.phone.toString()):'';//手机号
         data.vidcard= item.vidcard?phoneConceal(item.vidcard.toString()):'';//身份证号

         data.showOrHide = "hide";//显示/隐藏
        }
      })
    },
    // 获取设备管理-考勤机列表
    async getTeamCheckinRelateList() {
      let pageNum = this.checkInRelatePage;
      let pageSize = 10;
      let data = {
        pageNumber: pageNum,
        pageSize: pageSize,
        projectId: this.projectInfo.id,
      };
      let res = await this.$fetch("/sapi/teamCheckinRelate/getCheckInList", {
        method: "post",
        data,
      });
      if (res) {
        // // console.log(res);
        this.checkInRelateTableLoading = false;
        // this.teamUserTableLoading = false;
        // // console.log("test", this.teamManUserDataList, res.data);
        this.teamUserTotal = res.total;
        let list = res.data;
        list.forEach((val, index) => {
          if (val.name == null || val.name == "") {
            val.name = "未命名考勤机";
          }
        });
        this.equipmentDataList = list;
        // // console.log("test2", this.teamManUserDataList, res.data);
      }
    },
    // 处理项目信息
    handleProject() {
      if (this.projectData.length < 1) {
        // // console.log("无项目");
        this.currentProjectName = "暂无项目";
        this.noProjectFlag = true;
      } else {
        this.noProjectFlag = false;
        this.currentProjectName = this.projectData[0].vprojectname;
        this.projectInfo = this.projectData[0];
        this.updateArea();
      }
    },
    // 处理授权信息
    handleAuth() {
      this.authDataList = [];
      var a = [];
      Array.prototype.push.apply(a, this.authInfo);
      

     this.authDataList1=a;  

     this.authDataList= JSON.parse(JSON.stringify(this.authDataList1));//处理深拷贝  浅拷贝
      // 默认先脱敏
     this.authDataList.forEach((e) => {
        e.name = e.name?nameConceal(e.name.toString()):'';//姓名
        e.phone = e.phone?phoneConceal(e.phone.toString()):'';//手机号
        e.showOrHide = "hide";//显示/隐藏
     });

      // this.authDataList = a;
    },
    // 展示
    showAuthConceal(data){
       this.authDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.name = item.name;//姓名
         data.phone = item.phone;//手机号
         data.showOrHide = "show";//显示/隐藏
        }
      })
    },
    //隐藏
    hideAuthConceal(data){
      this.authDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.name = item.name?nameConceal(item.name.toString()):'';//姓名
         data.phone= item.phone?phoneConceal(item.phone.toString()):'';//手机号
         data.showOrHide = "hide";//显示/隐藏
        }
      })
    },
    // 处理班组信息
    handleTeam() {
      // this.teamDataList = [];
      // this.teamDataList = this.teamInfo;

      this.teamDataList1=this.teamInfo;  

      this.teamDataList= JSON.parse(JSON.stringify(this.teamDataList1));//处理深拷贝  浅拷贝
        // 默认先脱敏
      this.teamDataList.forEach((e) => {
          e.teamLeaderName = e.teamLeaderName?nameConceal(e.teamLeaderName.toString()):'';//姓名
          e.teamLeaderPhone = e.teamLeaderPhone?phoneConceal(e.teamLeaderPhone.toString()):'';//手机号
          e.showOrHide = "hide";//显示/隐藏
      });

    },
    // 展示
    showTeamConceal(data){
       this.teamDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.teamLeaderName = item.teamLeaderName;//姓名
         data.teamLeaderPhone = item.teamLeaderPhone;//手机号
         data.showOrHide = "show";//显示/隐藏
        }
      })
      this.teamHide=false
    },
    //隐藏
    hideTeamConceal(data){
      this.teamDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.teamLeaderName = item.teamLeaderName?nameConceal(item.teamLeaderName.toString()):'';//姓名
         data.teamLeaderPhone = item.teamLeaderPhone?phoneConceal(item.teamLeaderPhone.toString()):'';//手机号
         data.showOrHide = "hide";//显示/隐藏
        }
      })
      this.teamHide=true
    },
    // 更新当前地区信息
    updateArea() {
      // // console.log("area");
      // if有选择地区
      // changeProvince
      // changeCity
      let pid = this.projectData[this.currentProjectIndex].province || "";
      let cid = this.projectData[this.currentProjectIndex].city || "";
      // // console.log("1-获取当前项目的地址id", pid, cid);
      let plist = this.provinceList;
      let pname = "";
      if (pid) {
        plist.forEach((val) => {
          if (val.value == pid) {
            pname = val.label;
          }
        });
        // // console.log({ value: pid, label: pname });
        this.changeProvince({ value: pid, label: pname });
      } else {
        this.province = "";
        this.provinceName = "未选择";
        this.city = "";
        this.cityName = "未选择";
      }
    },
    // 修改项目信息培训二维码显示隐藏
    changeQRCodeStatus(e) {
      this.projectInfo.is_qrcode = e;
    },
    // 取消保存项目信息
    cancelSaveProjectInfo(type) {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>该操作不会保留当前修改数据</p>",
        onOk: () => {
          // this.$Message.info('Clicked ok');
          this.projectData = JSON.parse(
            JSON.stringify(this.backupsProjectData)
          );
          this.projectInfo = JSON.parse(
            JSON.stringify(this.backupsProjectData[this.currentProjectIndex])
          );
          this.editFlag = !this.editFlag;
          this.updateArea();
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel');
        },
      });
    },
    selectProjectInfo(e) {
      // // console.log(e);
    },
    selectProjectName(e) {
      console.log(e, "9999");
      let arr = this.projectNameInfo.filter((item) => item.vbillname == e);
      console.log(arr, "aee");
      this.addNewProjectData.vresponsible_person_name = arr[0].vdef8;
      this.addNewProjectData.vresponsible_person_phone = arr[0].vdef35;
      this.addNewProjectData.vprojectaddress = arr[0].vprojectaddress;
      if (arr[0].pk_projstatus == "筹备") {
        this.addNewProjectData.vprojectstatus = "001";
      } else if (arr[0].pk_projstatus == "在建") {
        this.addNewProjectData.vprojectstatus = "003";
      } else if (arr[0].pk_projstatus == "完工") {
        this.addNewProjectData.vprojectstatus = "004";
      } else if (arr[0].pk_projstatus == "停工") {
        this.addNewProjectData.vprojectstatus = "005";
      }

      this.addNewProjectData.vprojectcode = arr[0].vbillcode;

    },
    // 处理项目信息的四个下拉选择
    handleProjectSelect() {
      // // console.log(this.projectData);
    },
    // 保存项目信息
    toSaveProject() {
      console.log(this.projectInfo);
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      if (
        this.projectInfo.province == "" ||
        this.projectInfo.city == "" ||
        this.projectInfo.vprojectaddress == ""
      ) {
        this.$Message.warning("请填写工程地址");
        return false;
      }

      if (this.reviseFlag) {
        // let data = this.projectInfo;
        let data = {
          recordCode: this.projectInfo.record_code, //工程项目报建编码  recordCode
          vprojectname: this.projectInfo.vprojectname, //项目名称
          vresponsiblePersonName: this.projectInfo.vresponsible_person_name, //项目负责人      vresponsiblePersonName
          vresponsiblePersonPhone: this.projectInfo.vresponsible_person_phone, //项目负责人手机号   vresponsiblePersonPhone
          vresponsiblePersonIdNumber:
            this.projectInfo.vresponsible_person_id_number, //项目负责人身份证  vresponsiblePersonIdNumber
          vcategory: this.projectInfo.vcategory, //工程类别
          vfunctionNum: this.projectInfo.vfunction_num, //工程用途  vfunctionNum
          dinvest: this.projectInfo.dinvest, //工程造价
          vcontractorCorpName: this.projectInfo.vcontractor_corp_name, //施工单位  vcontractorCorpName
          dbuildingArea: this.projectInfo.dbuilding_area, //建筑面积  dbuildingArea
          vprojectstatus: this.projectInfo.vprojectstatus, //工程状态
          frameType: this.projectInfo.frame_type, //结构类型  frameType
          vprojectaddress: this.projectInfo.vprojectaddress, //工程地点
          province: this.projectInfo.province,
          city: this.projectInfo.city,
          fullLocation:
            this.projectInfo.province +
            this.projectInfo.city +
            this.projectInfo.vprojectaddress, //fullLocation
          isQrcode: this.projectInfo.is_qrcode || 0,
        };
        // this.$fetch("/rlapi/project/update", {
        //   method: "post",
        //   data,
        // })
        this.$axios({
          url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/projects/${this.projectId}`,
          method: "post",
          data,
          //  this.$axios({url:`/dgtech/api/mgmt/projects/${this.projectId}`, method:"put",data,
          headers: { accessToken: token.accessToken },
        }).then((res) => {
          if (res.data.resultStatus) {
            this.$Message.success("修改成功");
            // 处理修改成功
            this.backupsProjectData[this.currentProjectIndex] = JSON.parse(
              JSON.stringify(this.projectInfo)
            );
            this.projectData[this.currentProjectIndex] = JSON.parse(
              JSON.stringify(this.projectInfo)
            );
            this.editFlag = !this.editFlag;
          } else {
            this.$Message.success(res.data.errorMessage);
          }
        });
      } else {
        this.$Message.info("未作修改");
      }
    },
    // 搜索班组管理中的班组
    toSearchTeam() {
      let txt = this.searchTeamTxt;
      this.teamListPage = 1;
      this.teamTableLoading = true;
      this.getProjectTeamList();
    },
     // 搜索违规记录
    toSearchIrregularities() {
      this.irregularitieListPageNo = 1;
      this.irregularitiesLoading = true;
      this.irregularitieListIrregularLevel==undefined?this.irregularitieListIrregularLevel="":this.irregularitieListIrregularLevel;
      this.getirregularitiesList();
    },
      // 清除违规记录
    clearIrregularities() {
      
        this.irregularitieListPageNo = 1;
        this.irregularitieListKeyword = "";
        this.irregularitieListIrregularLevel = "";
        this.getirregularitiesList();
      
    },
    // 清除班组搜索管理关键词搜索
    clearSearchTeam() {
      if (this.searchTeamTxt != "") {
        this.teamListPage = 1;
        this.searchTeamTxt = "";
        this.teamTableLoading = true;
        this.getProjectTeamList();
      }
    },
    // 班组管理-点击分页处理
    toTeamPageChange(e) {
      this.teamListPage = e;
      this.teamTableLoading = true;
      this.getProjectTeamList();
    },
     // 违规记录列表管理-点击分页处理
    toIrregularitiesPageChange(e) {
      this.irregularitieListPageNo = e;
      this.irregularitiesLoading = true;
      this.getirregularitiesList();
    },
    // 班组管理-班组列表-中止、恢复、编辑、删除
    handleTeamItem(type, rid) {
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      if (type == "recovery") {
        // team/restoreTeam 恢复
        // this.$fetch("/rlapi/team/restoreTeam", {
        //   method: "post",
        //   data: { id: rid },
        // })
        this.$axios({
          url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/team/${rid}/removed`,
          method: "post",
          // this.$axios({url:`/dgtech/api/mgmt/team/${rid}/removed`, method:"put",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accessToken: token.accessToken,
          },
        }).then((res) => {
          if (res.data.resultStatus) {
            this.$Message.success("状态修改成功");
            // 处理修改成功
            this.teamTableLoading = true;
            this.getProjectTeamList();
          } else {
            this.$Message.error("状态修改失败");
          }
        });
      }
      if (type == "suspension") {
        // team/removeTeam 中止
        // this.$fetch("/rlapi/team/removeTeam", {
        //   method: "post",
        //   data: { id: rid },
        // })
        this.$axios({
          url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/team/${rid}/removed`,
          method: "post",
          // this.$axios({url:`/dgtech/api/mgmt/team/${rid}/removed`, method:"put",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accessToken: token.accessToken,
          },
        }).then((res) => {
          if (res.data.resultStatus) {
            this.$Message.success("状态修改成功");
            // 处理修改成功
            this.teamTableLoading = true;
            this.getProjectTeamList();
          } else {
            this.$Message.error("状态修改失败");
          }
        });
      }
      if (type == "delete") {
        // team/delTeam 删除
        this.$Modal.confirm({
          title: "提示",
          content: `<p>是否删除该班组</p>`,
          onOk: () => {
            this.$fetch("/rlapi/team/delTeam", {
              method: "post",
              data: { id: rid },
            }).then((res) => {
              if (res && res.success) {
                this.$Message.success("班组删除成功");
                // 处理修改成功
                this.teamTableLoading = true;
                this.getProjectTeamList();
              } else {
                this.$Message.error(res.msg || "班组删除失败");
              }
            });
          },
          onCancel: () => {},
        });
      }
    },
    changeTeamStatus(sid, stxt) {
      this.teamTasksData.status = sid;
      this.teamTasksData.statusStr = stxt;
    },
    changeTeamAuto(sid) {
      // // console.log(sid)
      this.teamTasksData.isNoAudit = sid;
      // this.teamTasksData.statusStr = stxt;
    },
    // 班组管理-用户列表分页修改
    toTeamUserPageChange(e) {
      this.teamUserPage = e;
      this.teamUserTableLoading = true;
      this.getProjectTeamUserList();
    },
    // 班组管理-用户列表班组成员状态修改
    changeTeamUserStatus(type, cur) {
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      console.log(cur);
      if (type == "remove") {
        this.$Modal.confirm({
          title: "提示",
          content: `<p>是否将 ${cur.realname} 从班组成员列表中移除</p>`,
          onOk: () => {
            // this.$fetch("/rlapi/teamItem/removeTeamItem", {
            //   method: "post",
            //   data: { id: cur.id },
            // })
            this.$axios({
              url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/team/item/${cur.id}/removed`,
              method: "post",
              // this.$axios({url:`/dgtech/api/mgmt/team/item/${cur.id}/removed`, method:"post",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                accessToken: token.accessToken,
              },
            }).then((res) => {
              console.log(res);
              if (res.data.resultStatus) {
                this.$Message.success("状态修改成功");
                // 处理修改成功
                this.teamUserTableLoading = true;
                this.getProjectTeamUserList();
              } else {
                this.$Message.error("状态修改失败");
              }
            });
          },
          onCancel: () => {},
        });
      }
      if (type == "restore") {
        this.$Modal.confirm({
          title: "提示",
          content: `<p>是否将 ${cur.realname} 从班组成员列表中返岗</p>`,
          onOk: () => {
            // this.$fetch("/rlapi/teamItem/restoreTeamItem", {
            //   method: "post",
            //   data: { id: cur.id },
            // })
            this.$axios({
              url: `${this.apiDgtechUrlCros}/dgtech/api/mgmt/team/item/${cur.id}/removed`,
              method: "post",
              // this.$axios({url:`/dgtech/api/mgmt/team/item/${cur.id}/removed`, method:"post",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                accessToken: token.accessToken,
              },
            }).then((res) => {
              if (res.data.resultStatus) {
                this.$Message.success("状态修改成功");
                // 处理修改成功
                this.teamUserTableLoading = true;
                this.getProjectTeamUserList();
              } else {
                this.$Message.error("状态修改失败");
              }
            });
          },
          onCancel: () => {},
        });
      }
    },
    handleLabour(e) {
      this.newTeamData.corp_name = e.label;
      this.newTeamData.labour_corp_id = e.value;
    },
    // 处理新增班组
    toAddNewTeam() {
      let data = this.newTeamData;
      data.phone = this.projectInfo.phone;
      data.vproject_code = this.projectInfo.vprojectcode;
      if (data.vteam_name == "") {
        this.$Message.warning("请填写班组名称");
        this.addNewTeamNext = false;
        setTimeout(() => {
          this.addNewTeamNext = true;
        }, 100);
        return false;
      }
      if (data.vresponsible_person_name == "") {
        this.$Message.warning("请填写负责人名字");
        this.addNewTeamNext = false;
        setTimeout(() => {
          this.addNewTeamNext = true;
        }, 100);
        return false;
      }
      if (data.vresponsible_person_phone == "") {
        this.$Message.warning("请填写负责人手机号码");
        this.addNewTeamNext = false;
        setTimeout(() => {
          this.addNewTeamNext = true;
        }, 100);
        return false;
      }
      // if (data.workTypeValues.length < 0) {
      //   this.$Message.warning("请选择班组工种");
      //   this.addNewTeamNext = false;
      //   setTimeout(() => {
      //     this.addNewTeamNext = true;
      //   }, 100);
      //   return false;
      // }
      if (data.workTypeValue == "") {
        this.$Message.warning("请选择班组负责人工种");
        this.addNewTeamNext = false;
        setTimeout(() => {
          this.addNewTeamNext = true;
        }, 100);
        return false;
      }
      let wlist = this.abproWorkTypeList;
      data.leader_work_type = data.workTypeValue;
      data.workTypeName =
        wlist[
          wlist.findIndex((o) => o.workTypeValue == data.workTypeValue)
        ].workTypeName;
      this.$fetch("/sapi/v3/team/save", {
        method: "post",
        data,
      }).then((res) => {
        // // console.log(res);
        if (res && res.success) {
          this.$Message.success("新增班组成功");
          // 处理修改成功
          this.addNewTeamNext = true;
          this.addNewTeamFlag = false;
          this.teamTableLoading = true;
          this.getProjectTeamList();
          this.newTeamData = {
            phone: "",
            vteam_name: "",
            vresponsible_person_name: "",
            vresponsible_person_phone: "",
            corp_name: "",
            vproject_code: "",
            dentry_time: "",
            dexit_time: "",
            remark: "",
            leader_work_type: "",
            is_no_audit: "1",
            labour_corp_id: undefined,
          };
          this.newTeamData.dentry_time = "";
        } else {
          this.$Message.error(res.msg || "新增班组失败");
          this.addNewTeamNext = true;
          this.addNewTeamFlag = false;
        }
      });
    },
    changeNewTeamDateEn(e) {
      this.newTeamData.dentry_time = e;
    },
    changeNewTeamDateEx(e) {
      this.newTeamData.dexit_time = e;
    },
    toDownForm(src) {
      window.open(src + this.currentTeamData.id, "_blank");
    },
    toDownRoster() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "9000",
        "rlapi",
        `/template/downloadProjectRosterTemplate`
      );
      window.open(downUrl, "_blank");
    },
    toAddEquipment(type) {
      if (type == "ok") {
        // newEquipmentData
        if (
          this.newEquipmentData.eId == "" ||
          this.newEquipmentData.eName == ""
        ) {
          this.$Message.info("请填写考勤机信息");
          this.addEquipmentNext = false;
          setTimeout(() => {
            this.addEquipmentNext = true;
          }, 100);
          return false;
        }
        let data = {
          serialNo: this.newEquipmentData.eId,
          name: this.newEquipmentData.eName,
          projectId: this.projectInfo.id,
        };
        this.$fetch("/sapi/teamCheckinRelate/updateCheckInBySerialNo", {
          method: "post",
          data,
        }).then((res) => {
          if (res) {
            this.$Message.success(res.msg || "新增考勤机成功");
            // 处理修改成功
            this.modalFlag.addEquipment = false;
            this.checkInRelateTableLoading = true;
            this.newEquipmentData = { eId: "", eName: "" };
            this.getTeamCheckinRelateList();
          } else {
            this.$Message.error("新增考勤机失败");
            this.modalFlag.addEquipment = false;
            this.checkInRelateTableLoading = true;
            this.newEquipmentData = { eId: "", eName: "" };
            this.getTeamCheckinRelateList();
          }
        });
      }
      if (type == "cancel") {
        this.modalFlag.addEquipment = false;
      }
    },
    toAddTemplate(type) {
      if (type == "ok") {
        // // newEquipmentData
        if (
          this.newTemplateData.name == "" ||
          this.newTemplateData.duration == "" ||
          this.newTemplateData.content == "" ||
          this.newTemplateData.remark == ""
        ) {
          this.$Message.info("请填写样板信息");
          this.addTemplateNext = false;
          setTimeout(() => {
            this.addTemplateNext = true;
          }, 100);
          return false;
        }
        if (
          this.newTemplateData.projectContentId == "" ||
          this.newTemplateData.projectContentId == null
        ) {
          this.$Message.info("请选择所属分部分项工程类别");
          this.addTemplateNext = false;
          setTimeout(() => {
            this.addTemplateNext = true;
          }, 100);
          return false;
        }
        let data = {};
        let url = "";
        if (!this.editTemplateFlag) {
          data = {
            name: this.newTemplateData.name,
            duration: this.newTemplateData.duration,
            content: this.newTemplateData.content,
            remark: this.newTemplateData.remark,
            projectContentId: this.newTemplateData.projectContentId,
            type: "17",
            icorpid: this.currentUserInfo.icorpid,
            irootcorpid: this.projectInfo.irootcorpid,
            vprojectCode: this.projectInfo.vprojectcode,
            iprojectid: this.projectInfo.id,
            createId: this.currentUserInfo.id,
            updateId: this.currentUserInfo.id,
            createBy: this.currentUserInfo.login_id,
            updateBy: this.currentUserInfo.login_id,
          };
          url = "/sapi/taskTemplate/save";
        } else {
          data = {
            id: this.editTemplateData.id,
            name: this.newTemplateData.name,
            duration: this.newTemplateData.duration,
            content: this.newTemplateData.content,
            remark: this.newTemplateData.remark,
            type: this.newTemplateData.type,
            icorpid: this.currentUserInfo.icorpid,
            irootcorpid: this.projectInfo.irootcorpid,
            vprojectCode: this.projectInfo.vprojectcode,
            iprojectid: this.projectInfo.id,
            projectContentId: this.newTemplateData.projectContentId,
            updateBy: this.currentUserInfo.login_id,
          };
          url = "/sapi/taskTemplate/update";
        }
        this.$fetch(url, {
          method: "post",
          data,
        }).then((res) => {
          if (res) {
            this.$Message.success(res.msg || "操作成功");
            // 处理修改成功
            this.modalFlag.addTemplate = false;
            this.editTemplateFlag = false;
            this.handleSafeAutoSearch("reset");
          } else {
            this.$Message.error("操作成功");
            this.modalFlag.addTemplate = false;
            this.handleSafeAutoSearch("reset");
          }
        });
      }
      if (type == "cancel") {
        this.newTemplateDataCategoryId = "";
        this.projectContentData = [];
        console.log(this.projectContentData);
        this.modalFlag.addTemplate = false;
        this.editTemplateFlag = false;
      }
    },
    // 获取设备管理-查看信息-班组列表
    getEquipmentTeamList(sid) {
      let data = {
        serialNo: sid,
        pageNumber: this.equipmentListPage,
        pageSize: "10",
        name: this.equipmentTeamSearch || "",
        projectId: this.projectInfo.id,
      };
      let res = this.$fetch(`/sapi/teamCheckinRelate/getRelateTeamInfos`, {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          let list = res.data;
          if (list.length > 0) {
            list.forEach((val) => {
              val.pickFlag = false;
            });
            list[0].pickFlag = true;
            this.equipmentTeamList = list;
            // this.equipmentTeamSearchList = JSON.parse(JSON.stringify(list));
            this.equipmentTeamLoading = false;
            this.equipmentListTotal = res.total;
            this.equipmentTeamUserPage = 1;
            this.getEquipmentTeamDetail();
          } else {
            this.equipmentTeamList = [];
            this.equipmentTeamLoading = false;
            this.equipmentListTotal = 0;
            this.equipmentTeamUserPage = 1;
            this.equipmentUserDataList = [];
            this.equipmentTeamDetailLoading = false;
            this.equipmentUserTableLoading = false;
            this.equipmentTeamUserTotal = 0;
            this.$Message.info("暂无绑定考勤机信息，请先添加班组绑定考勤机");
          }
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 获取档案班组列表
    getFilesTeamList() {
      let data = {
        pageSize: "1000",
        projectId: this.projectInfo.id,
      };
      let res = this.$fetch(`/sapi/team/getTeamsByProjectIdOrName`, {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          // this.handleFilesTeamList(res.data)
          let list = res.data;
          if (list.length > 0) {
            list.forEach((val) => {
              val.pickFlag = false;
            });
            list[0].pickFlag = true;
            this.filesTeamList = list;
            this.getFilesTeamListLoading = false;
            this.getFilesDetailLoading = true;
            this.currentFilesTeamData = list[0];
            this.currentFilesTeamId = list[0].id;
            this.filesDetail = [];
            this.prejobFilesDataList = [];
            this.getFilesListDetail(list[0].id);
            this.getFilesUserListDetail(list[0].id);
          } else {
            this.getFilesTeamListLoading = false;
            this.getFilesDetailLoading = false;
            this.filesDetail = [];
          }
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 获取当前班组档案内容
    getFilesListDetail(tid) {
      // // console.log(tid)
      this.getFilesDetailLoading = true;
      if (tid) {
        let data = {
          pageNumber: this.sdFilesPage,
          pageSize: this.sdFilesSize,
          teamId: tid,
          type: this.danganFlag,
        };
        let res = this.$fetch(`/sapi/trainingData/list`, {
          method: "post",
          data,
        }).then((res) => {
          if (res.code == "200") {
            // this.handleFilesTeamList(res.data)
            this.getFilesDetailLoading = false;
            console.log(res);
            let list = res.data;
            if (list != null) {
              if (list.length > 0) {
                list.forEach((val, index) => {
                  if (val.isWarn) {
                    if (val.childs) {
                      delete val.childs;
                    }
                  }
                });
              }
            }
            this.sdFilesTotal = res.total;
            this.filesDetail = list;
          } else {
            this.getFilesDetailLoading = false;
            this.$Message.info("获取详情失败，请稍后重试");
          }
        });
      } else {
        this.getFilesDetailLoading = false;
        this.filesDetail = [];
      }
    },
    // 获取当前班组成员内容
    getFilesUserListDetail(tid) {
      // teamItem/getTeamItems
      this.prejobFilesLoading = true;
      let data = {
        pageNumber: this.prejobFilesPage,
        pageSize: this.prejobFilesSize,
        teamId: tid,
        isRemoved: this.searchFilesUserData.status,
        phone: this.searchFilesUserData.phone,
        vidcard: this.searchFilesUserData.idcard,
        realname: this.searchFilesUserData.name,
      };
      this.$fetch(`/sapi/teamItem/getTeamItems`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.prejobFilesDataList = res.data;
          this.prejobFilesLoading = false;
        } else {
          this.prejobFilesLoading = false;
          this.$Message.error("获取班组成员列表失败");
        }
      });
    },
    handleFilesDetailListPageChange(e) {
      this.sdFilesPage = e;
      this.getFilesListDetail(this.currentFilesTeamId);
    },
    // 档案管理-点击班组
    pickFilesTeam(tid) {
      // // console.log(tid)
      let list = this.filesTeamList;
      this.currentFilesTeamData = list[tid];
      this.currentFilesTeamId = list[tid].id;
      list.forEach((val, index) => {
        if (tid == index) {
          val.pickFlag = true;
        } else {
          val.pickFlag = false;
        }
      });
      this.currentCollapseName = "";
      this.getFilesDetailLoading = true;
      this.getFilesListDetail(list[tid].id);
      this.getFilesUserListDetail(list[tid].id);
    },
    handlePrejobFilesPageChange(e) {
      this.prejobFilesPage = e;
      this.getFilesUserListDetail(this.currentFilesTeamId);
    },
    searchEquipmentTeam(e) {
      this.equipmentTeamSearch = e;
      this.equipmentTeamLoading = true;
      this.equipmentTeamDetailLoading = true;
      this.getEquipmentTeamList(this.currentEquipmentData.serialNo);
    },
    changePageEquipmentList(e) {
      this.equipmentListPage = e;
      this.equipmentTeamLoading = true;
      this.getEquipmentTeamList(this.currentEquipmentData.serialNo);
    },
    // 获取该班组下详细信息
    getEquipmentTeamDetail() {
      this.equipmentUserTableLoading = true;
      let tid = "";
      let list = this.equipmentTeamList;
      let cur = {};
      list.forEach((val, index) => {
        if (val.pickFlag) {
          tid = val.id;
          cur = val;
        }
      });
      this.currentEquipmentTeamData = cur;
      let data = {
        teamId: tid,
        pageNumber: this.equipmentTeamUserPage,
        pageSize: "10",
        name: this.equipmentTeamDataSearch.eName,
        isRemoved: this.equipmentTeamDataSearch.eJob,
        clockinStatus: this.equipmentTeamDataSearch.eStatus,
      };
      this.$fetch("/sapi/teamCheckinRelate/getRelateVPersons", {
        method: "post",
        data,
      }).then((res) => {
        if (res) {
          this.equipmentUserDataList = res.data;
          this.equipmentTeamDetailLoading = false;
          this.equipmentUserTableLoading = false;
          this.equipmentTeamUserTotal = res.total;
        } else {
          this.$Message.error("查询班组失败，请稍后重试");
        }
      });
    },
    // 设备管理 详情 条件搜索
    searchEquipmentTeamDetail() {
      // // console.log(this.equipmentTeamDataSearch);
      this.equipmentUserTableLoading = true;
      this.equipmentTeamUserPage = 1;
      this.getEquipmentTeamDetail();
    },
    // 设备管理 详情 修改页码
    changePageEquipmentTeam(e) {
      this.equipmentTeamUserPage = e;
      this.equipmentUserTableLoading = true;
      this.getEquipmentTeamDetail();
    },
    handelReset(type) {
      if (type == "list") {
        this.equipmentTeamSearch = "";
        this.equipmentTeamLoading = true;
        this.equipmentTeamDetailLoading = true;
        this.getEquipmentTeamList(this.currentEquipmentData.serialNo);
      }
      if (type == "detail") {
        this.equipmentTeamDataSearch = {
          eName: "",
          eStatus: "",
          eJob: "",
        };
        this.equipmentUserTableLoading = true;
        this.equipmentTeamUserPage = 1;
        this.getEquipmentTeamDetail();
      }
    },
    // 获取考勤数据表格
    getAttendanceData() {
      let data = {
        projectId: this.projectInfo.id,
        pageNumber: this.attendanceListPage,
        pageSize: "10",
        isRemoved: this.attendanceSearchData.aStatus,
        name: this.attendanceSearchData.aName,
        teamIds: this.attendanceSearchData.aTeamList.toString(),
        beginTime: this.attendanceDateDetail.clockStartTime,
        endTime: this.attendanceDateDetail.clockEndTime,
      };
      this.$fetch("/sapi/teamCheckinRelate/getClockInList", {
        method: "post",
        data,
      }).then((res) => {
        if (res) {
          // // console.log(res);
          this.checkDataList = res.data;
          // this.equipmentUserDataList = res.data
          // this.equipmentTeamDetailLoading = false
          // this.equipmentUserTableLoading = false
          this.attendanceListTotal = res.total;
          this.attendanceDataTableLoading = false;
          this.getAttendanceDate();
        } else {
          this.$Message.error("查询考勤数据失败，请稍后重试");
        }
      });
    },
    // 获取考勤数据表格后 获取天数
    getAttendanceDate() {
      // teamCheckinRelate/getClockInTimesByPersons
      let list = this.checkDataList;
      let dataList = [];
      list.forEach((val) => {
        dataList.push({
          vidcard: val.vidcard,
          clockStartTime: this.attendanceDateDetail.clockStartTime || "",
          clockEndTime: this.attendanceDateDetail.clockEndTime || "",
        });
      });
      // let data = {
      //   projectId: this.projectInfo.id,
      //   pageNumber: this.attendanceListPage,
      //   pageSize: "10",
      //   isRemoved: this.attendanceSearchData.aStatus,
      //   name: this.attendanceSearchData.aName,
      //   teamIds: this.attendanceSearchData.aTeamList.toString(),
      // };
      this.$fetch("/sapi/teamCheckinRelate/getClockInTimesByPersons", {
        method: "post",
        data: dataList,
      }).then((res) => {
        if (res) {
          let countList = res.data;
          this.handleCheckCount(countList);
        } else {
          this.$Message.error("查询考勤数据失败，请稍后重试");
        }
      });
    },
    // 处理出勤天数
    handleCheckCount(data) {
      let list = this.checkDataList;
      // // console.log(list, data);
      list.forEach((val, index) => {
        val.clockInTimes = data[index].clockTimes;
      });
      this.checkDataList = list;
    },
    // 获取考勤机已接入班组名单
    getAttendanceTeamList() {
      let data = {
        projectId: this.projectInfo.id,
      };
      this.$fetch("/sapi/team/getTeamsByProjectIdAndWithClokin", {
        method: "post",
        data,
      }).then((res) => {
        if (res) {
          // // console.log(res);
          this.checkTeamList = res.data;
          this.attendanceTeamListLoading = false;
        } else {
          this.$Message.error("查询班组列表失败，请稍后重试");
        }
      });
    },
    handelAttendanceDateChange(e) {
      var date1 = new Date(e[0]);
      var date2 = new Date(e[1]);
      this.attendanceDateDetail.clockStartTime = Date.parse(date1) / 1000;
      this.attendanceDateDetail.clockEndTime = Date.parse(date2) / 1000;
      // // console.log(this.attendanceDateDetail)
    },
    handelExportDateChange(e) {
      this.exportData.date = e;
    },
    handelAttendanceSearch(type) {
      if (type == "ok") {
        // // console.log(this.attendanceSearchData);
        this.attendanceDataTableLoading = true;
        this.attendanceListPage = 1;
        this.getAttendanceData();
      }
      if (type == "reset") {
        this.attendanceSearchData = {
          aName: "",
          aTeamList: "",
          aStatus: "",
        };
        this.attendanceDateDetail = {
          clockStartTime: "",
          clockEndTime: "",
        };
        this.attendanceDate = "";
        this.attendanceListPage = 1;
        this.attendanceDataTableLoading = true;
        this.getAttendanceData();
      }
    },
    handelEquipmentListPageChange(e) {
      this.checkInRelatePage = e;
      this.checkInRelateTableLoading = true;
      this.getTeamCheckinRelateList();
    },
    handelAttendanceListPageChange(e) {
      this.attendanceListPage = e;
      this.attendanceDataTableLoading = true;
      this.getAttendanceData();
    },
    // 下发考勤机
    issueSttendance(uid, ustatus) {
      let that = this;
      if (ustatus == "1") {
        this.$Message.info("该人员已绑定考勤机");
      } else {
        let data = {
          vteamCode: this.currentEquipmentTeamData.code,
          id: uid,
        };
        this.$fetch("/sapi/teamCheckinRelate/issuePerson", {
          method: "post",
          data: data,
        }).then((res) => {
          if (res) {
            // // console.log(res);
            this.$Message.success("下发考勤机成功");
            this.equipmentUserTableLoading = true;
            setTimeout(() => {
              that.getEquipmentTeamDetail();
            }, 3000);
          } else {
            // this.$Message.warning("下发考勤机失败，请稍后重试");
          }
        });
      }
    },
    exportTable() {
      if (this.exportData.date == "") {
        this.$Message.error("请选择导出考勤数据日期范围");
        return false;
      }
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/excel/project/exprotAccessRecord?projectId=${this.projectInfo.id}&month=${this.exportData.date}`
      );
      window.open(downUrl, "_blank");
    },
    updateCheck() {
      let data = {
        iprojectid: this.projectInfo.id,
      };
      this.$fetch("/sapi/teamCheckinRelate/getAllProjectPersons", {
        method: "post",
        data,
      })
        .then((res) => {
          // // console.log(res);
          if (res.code == "200") {
            this.$Message.success("更新成功");
          } else {
            this.$Message.success("操作失败，请稍后再试");
          }
        })
        .catch((err) => {
         // this.$Message.success("操作失败，请稍后再试");
        });
    },
    // 绑定考勤机
    toBindEquipment() {
      if (this.currentEquipmentTeamPick.vteamName == "") {
        this.$Message.info("请选择需绑定设备的班组");
        this.bindEquipmentLoading = false;
        setTimeout(() => {
          this.bindEquipmentLoading = true;
        }, 100);
        return false;
      }
      let data = {
        teamId: this.currentEquipmentTeamPick.id,
        serialNo: this.bindNewEquipmentData.serialNo,
        status: "1",
        projectId: this.projectInfo.id,
      };
      this.$fetch("/sapi/teamCheckinRelate/bindCheckIn", {
        method: "post",
        data,
      })
        .then((res) => {
          // // console.log(res);
          if (res.code == "200") {
            // // console.log(res);
            // this.getEquipmentTeamLoading = false
            // this.equipmentTeamDataList = res.data
            this.bindNewEquipmentData = {};
            this.currentEquipmentTeamPick = { vteamName: "" };
            this.modalFlag.bindEquipment = false;
            if (this.currentContentBox == "equipmentEdit") {
              this.searchEquipmentTeam();
            }
          } else {
            this.$Message.error("设备绑定新班组失败");
            this.bindEquipmentLoading = false;
            setTimeout(() => {
              this.bindEquipmentLoading = true;
            }, 100);
          }
        })
        .catch((err) => {
          // // console.log(err);
          this.bindEquipmentLoading = false;
          setTimeout(() => {
            this.bindEquipmentLoading = true;
          }, 100);
        });
    },
    // 取消绑定考勤机
    handleBindEquipmentCancel() {
      this.bindNewEquipmentData = {};
      this.currentEquipmentTeamPick = { vteamName: "" };
      this.modalFlag.bindEquipment = false;
    },
    // 取消线下完成班组培训
    handleTeamFinishCancel() {
      this.showExitTeamFlag = false;
    },
    // 获取排除已绑定考勤机班组
    getBindEquipmentTeamList(data) {
      let info = data || this.currentEquipmentData;
      // // console.log(info);
      let postData = {
        projectId: this.projectInfo.id,
        serialNo: info.serialNo,
      };
      this.$fetch("/sapi/team/getTeamsByProjectIdAndNotInSerialNo", {
        method: "post",
        data: postData,
      }).then((res) => {
        // // console.log(res);
        if (res) {
          // // console.log(res);
          this.getEquipmentTeamLoading = false;
          this.equipmentTeamDataList = res.data;
        } else {
          this.$Message.error("获取班组列表失败");
        }
      });
    },
    // 选择设备绑定新班组
    pickEquipmentNewTeam(val) {
      this.currentEquipmentTeamPick = val;
    },
    // 获取企业关联当前工种
    getProjectOfWorkType() {
      let that = this;
      let data = {};
      this.$fetch(
        `/sapi/workType/relate/getCorpRelate?corpId=${this.currentUserInfo.icorpid}`,
        {
          method: "get",
          data,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            if (res.data.length > 0) {
              let clist = res.data[0].childs;
              let ids = [];
              clist.forEach((val) => {
                ids.push(val.value);
              });
              this.addNewWorkType = ids;
              this.corpWorkTypeList = clist;
            } else {
              this.$Message.info("请先在控制台，设置企业工种。");
              setTimeout(() => {
                that.handleProject();
                that.pickProjectMenu([0]);
              }, 1000);
            }
          } else {
            this.$Message.error("获取工种失败");
          }
        })
        .catch((err) => {
         // this.$Message.error("获取工种失败");
        });
    },
    addNewProject() {
      this.getProjectName();
      this.getProjectOfWorkType();
      this.addNewProjectData = {
        vprojectcode:"",//项目编码 只有项目下拉选择的时候才有
        record_code: "", //工程项目报建编码
        vprojectname: "", //项目名称
        vresponsible_person_name: "", //项目负责人
        vresponsible_person_phone: "", //项目负责人手机号
        vresponsible_person_id_number: "", //项目负责人身份证
        phone: "", //当前用户手机号
        vcategory: "", //工程类别
        vfunction_num: "", //工程用途
        dinvest: null, //工程造价
        vcontractor_corp_name: "", //施工单位
        dbuilding_area: null, //建筑面积
        vprojectstatus: "", //工程状态
        frame_type: "", //结构类型
        vprojectaddress: "", //工程地点
        province: "",
        city: "",
        full_location: "",
      };
      this.addNewProjectDataProvinceName = "";
      this.addNewProjectDataCityName = "";
      this.cityList = [];
      this.breadcrumbList = arr;
      this.city = "";
      this.province = "";
      this.zoneData = "";
      this.currentContentBox = "newProject";
      let arr = [
        {
          name: "新增项目",
          in: "newProject",
          index: 1,
        },
      ];
    },
    toAddNewProject() {
      // // console.log(this.addNewProjectData);
      let data = this.addNewProjectData;
      if (
        data.vprojectname == "" ||
        data.vresponsible_person_name == "" ||
        data.vresponsible_person_phone == "" ||
        data.vcontractor_corp_name == "" ||
        data.province == "" ||
        data.city == ""
      ) {
        this.$Message.warning("请完整填写必填资料");
        return false;
      }
      // 当有企业片区功能时
      if (this.areaAuthFlag) {
        this.zoneData;
        if (!this.zoneData) {
          this.$Message.warning("请选择项目所属片区");
          return false;
        } else {
          data.zone_code = this.zoneData;
        }
      }
      this.addNewProjectDataLoading = true;
      data.icorpid = this.currentUserInfo.icorpid;
      data.full_location =
        this.addNewProjectDataProvinceName +
        this.addNewProjectDataCityName +
        data.vprojectaddress;
      // // console.log(data);
      data.phone = data.vresponsible_person_phone;
      data.workTypeValues = this.addNewWorkType;      
      this.$fetch("/sapi/v3/project/save", {
        method: "post",
        data,
      }).then((res) => {
        if (res && (res.code == "200" || res.code == 0 || res.code == "0")) {
          this.$Message.success("新增项目成功");
          this.getProjects(1, 99);
          this.addNewProjectDataLoading = false;
        } else {
          this.$Message.error("新增项目失败");
        }
      });
    },
    handleSafeSelectChange(value, selectedData) {
      // // console.log(value, selectedData);
      // this.safeSelectData
      this.safeSelectValue = selectedData;
      this.pickSafeListId = value;
      this.editSafeFlag = false;
      // 获取当前表内容
      if (value[1]) {
        this.getSafeContentLoading = true;
        this.getSafeContentProject(value[1]);
      } else {
        this.currentSafeContent = "";
      }
      // 空表时清除内容
    },
    handleSafeSelectChange2(value, selectedData) {
      console.log(value, selectedData);
      // this.safeSelectData
      // this.newTemplateData.projectContentId = selectedData;
      console.log(this.newTemplateData);
      // this.pickSafeListId = value;
      // 获取当前表内容
      // if (value[1]) {
      //   this.getSafeContentLoading = true;
      //   this.getSafeContentProject(value[1]);
      // } else {
      //   this.currentSafeContent = "";
      // }
      // 空表时清除内容
    },
    userRender(item) {
      return item.key + ":" + item.label;
    },
    handleSafeUserChange(newTargetKeys) {
      this.pickSafeUserArr = newTargetKeys;
    },
    toDownTask(val) {
      if (val.completeCount > 0) {
        let downUrl = "";
        downUrl = this.common.openUrl(
          "8082",
          "sapi",
          `/teamItem/getTeamItemExamPaper/${this.currentTeamData.id}/${val.id}`
        );
        window.open(downUrl, "_blank");
      } else {
        this.$Message.warning("该试卷暂无已完成人员");
      }
    },
    //下载身份证复印件
    downIDCardCopy() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/realName/downloadIdCards/${this.currentTeamData.id}`
      );
      // window.open(fileUrl, "_blank");
      this.handelDownIDCardCopy(downUrl);
    },
    //下载安全生产承诺书
    downSafePromise() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/common/download?fileName=安全生产承诺书.docx`
      );
      window.open(downUrl, "_blank");
      // this.handelDownIDCardCopy(fileUrl)
    },
    // 下载法律告知函
    downTeamLegal() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/teamItem/getTeamLegal/${this.currentTeamData.id}`
      );
      window.open(downUrl, "_blank");
    },
    // 下载施工人员信息卡
    downInfoCardCopy() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/teamItem/createTeamInfoCard/${this.currentTeamData.id}`
      );
      window.open(downUrl, "_blank");
    },
    // 下载奖罚制度
    downTeamRewards() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/teamItem/getTeamRewards/${this.currentTeamData.id}`
      );
      window.open(downUrl, "_blank");
    },
    // 下载生产常识
    downTeamKnowledge() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/teamItem/getTeamKnowledge/${this.currentTeamData.id}`
      );
      window.open(downUrl, "_blank");
    },
    // 下载安全交底
    downTeamGiveAccount() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/teamItem/getTeamGiveAccount/${this.currentTeamData.id}`
      );
      window.open(downUrl, "_blank");
    },
    downHealthy() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/teamItem/createTeamHealthyNotice/${this.currentTeamData.id}`
      );
      window.open(downUrl, "_blank");
      // this.handelDownIDCardCopy(fileUrl)
    },
    handelDownIDCardCopy(url) {
      let downUrl = url;
      let that = this;
      that.$axios
        .get(`/sapi/realName/checkIdCards/${that.currentTeamData.id}`)
        .then((res) => {
          if (res.data.code == "200") {
            if (res.data.data == "true" || res.data.data == true) {
              window.open(downUrl, "_blank");
            } else {
              that.$Message.error(res.data.msg);
            }
          } else {
            that.$Message.error("操作失败，请稍后再试");
          }
        })
        .catch((err) => {
          // that.$Message.error(err.message);
        });
    },
    handleBeforeChange() {
      return new Promise((resolve) => {
        this.$Modal.confirm({
          title: "切换确认",
          content: "您确认要切换开关状态吗？",
          onOk: () => {
            resolve();
          },
        });
      });
    },
    handelSignatureChange(e) {
      if (e == true) {
        this.showSignatureTips();
      } else {
        this.closeSign();
      }
    },
    showSignatureTips() {
      this.$Modal.confirm({
        title: "电子签服务开通须知",
        content:
          "<p>抖工提醒您：电子签章功能启用以后，工人完成线上安全培训需要进行线上签名，签名仅用于省统表《新工人入场三级安全教育登记表》，《公司（第一级）安全教育记录》,《项目部（第二级）安全教育记录》，《班组（第三级）安全教育记录》4张表格，该服务为付费服务，如需开通，请联系商务处理（400-640-6898）。</p>",
        onOk: () => {
          // this.$Message.info('开通成功');
          // this.signatureSwitch = false
          // this.projectInfo.has_pay_sign = 2
          let data = {
            id: this.projectInfo.id,
            hasPaySign: 2,
          };
          this.$fetch(`/sapi/project/applyPaySign`, {
            method: "post",
            data,
          }).then((res) => {
            if (res && res.code === "200") {
              this.$Message.success("申请成功");
              this.projectInfo.has_pay_sign = 2;
            } else {
              this.$Message.error("操作失败，请稍后再试");
              this.projectInfo.has_pay_sign = 0;
            }
            // // console.log(res)
          });
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel');
          // this.signatureSwitch = false
          this.projectInfo.has_pay_sign = 0;
        },
      });
    },
    closeSign() {
      this.$Modal.confirm({
        title: "是否关闭电子签服务",
        content: "<p>抖工提醒您：关闭该服务将无法使用电子签名功能。</p>",
        onOk: () => {
          // this.$Message.info('开通成功');
          // this.signatureSwitch = false
          // this.projectInfo.has_pay_sign = 2
          let data = {
            id: this.projectInfo.id,
            hasPaySign: 0,
          };
          this.$fetch(`/sapi/project/applyPaySign`, {
            method: "post",
            data,
          }).then((res) => {
            if (res && res.code === "200") {
              this.$Message.success("关闭成功");
              this.projectInfo.has_pay_sign = 0;
            } else {
              this.$Message.error("操作失败，请稍后再试");
              this.projectInfo.has_pay_sign = 1;
            }
            // // console.log(res)
          });
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel');
          // this.signatureSwitch = false
          this.projectInfo.has_pay_sign = 1;
        },
      });
    },
    getSafeList() {
      let data = {};
      this.$fetch(
        `/sapi/safeDisclose/getCategory?projectId=${this.projectInfo.id}`,
        {
          method: "post",
          data,
        }
      ).then((res) => {
        console.log(res);
        if (res && res.code === "200") {
          // this.$Message.success("新增项目成功");
          // //处理新增成功
          // this.getProjects(1, 99);
          // this.addNewProjectDataLoading = false;
          let datalist = res.data;
          let list = [];
          datalist.forEach((val) => {
            let arr = [];
            let clist = val.childs;
            // clist.forEach(vall=>{
            //   arr.push({
            //     value: vall.id,
            //     label: vall.name
            //   })
            // })
            for (let i = 0; i < clist.length; i++) {
              arr.push({
                value: clist[i].id + "",
                label: clist[i].name,
              });
            }
            list.push({
              value: val.id + "",
              label: val.name,
              children: arr,
            });
          });
          this.safeSelectData = list;
        } else {
          this.$Message.error("获取安全交底表格失败，请稍后再试");
        }
        // // console.log(res)
      });
    },
    getSafeYearList() {
      let data = {
        type: "corp",
        corpId: this.currentUserInfo.icorpid,
      };
      this.$fetch(`/sapi/safeDisclose/getYearList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.handleYearList(res.data);
        } else {
          this.$Message.error("获取安全交底表格失败，请稍后再试");
        }
        // // console.log(res)
      });
    },
    downSafeList() {
      let sid = this.pickSafeListId[1] || "";
      if (sid) {
        let downUrl = "";
        downUrl = this.common.openUrl(
          "8082",
          "sapi",
          `/safeDisclose/download?projectId=${this.projectInfo.id}&corpId=${this.currentUserInfo.icorpid}&cateId=${sid}`
        );
        window.open(downUrl, "_blank");
      } else {
        this.$Message.warning("请先选择下载的安全交底表格");
      }
    },
    downSafeList2(tid) {
      if (tid) {
        let downUrl = "";
        downUrl = this.common.openUrl(
          "8082",
          "sapi",
          `/safeDisclose/download?projectId=${this.projectInfo.id}&corpId=${this.currentUserInfo.icorpid}&cateId=${tid}`
        );
        window.open(downUrl, "_blank");
      } else {
        this.$Message.warning("请先选择下载的安全交底表格");
      }
    },
    downSafeList3(info) {
      let downUrl;
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/project/disclose/contents/${info.contentId}/file`
      );
      window.open(downUrl, "_blank");
    },
    showTeamFile() {},
    toUpdateCheck(tid) {
      // /teamCheckinRelate/getAllRecords
      // teamId
      let data = {
        teamId: this.currentEquipmentTeamData.id,
      };
      this.$fetch(`/sapi/teamCheckinRelate/getAllRecords`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.$Message.success("更新成功");
        } else {
          this.$Message.error("获取失败，请稍后再试");
        }
        // // console.log(res)
      });
    },
    toRemoveCheck() {
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否确认解绑考勤机</p>`,
        onOk: () => {
          let data = {
            teamId: this.currentEquipmentTeamData.id,
            projectId: this.projectInfo.id,
            serialNo: this.currentEquipmentData.serialNo,
            status: "0",
            id: this.currentEquipmentTeamData.tcrId,
          };
          this.$fetch(`/sapi/teamCheckinRelate/bindCheckIn`, {
            method: "post",
            data,
          }).then((res) => {
            if (res && res.code === "200") {
              this.$Message.success("解绑成功");
              this.pickMenu("equipment:" + this.currentProjectIndex);
            } else {
              this.$Message.error("获取失败，请稍后再试");
            }
            // // console.log(res)
          });
        },
        onCancel: () => {},
      });
    },
    getSubContractList() {
      let data = {
        phone: this.currentUserInfo.login_id,
        projectId: this.projectInfo.id,
        corpId: this.currentUserInfo.icorpid,
        pageNumber: this.subContractPage,
        pageSize: this.subContractSize,
      };
      this.$fetch(`/sapi/turnkey/getByPage`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          // // console.log(res);
          this.subContractTotal = res.total;
          this.subcDataList = res.resList;
          this.subContractLoading = false;
        } else {
          this.$Message.error("获取失败，请稍后再试");
        }
        // // console.log(res)
      });
    },
    changeSubcPage(e) {
      this.subContractPage = e;
      this.subContractLoading = true;
      this.getSubContractList();
    },
    getRosterList() {
      let data = {
        projectId: this.projectInfo.id,
        pageNumber: this.rosterListPage,
        pageSize: this.rosterListSize,
      };
      this.$fetch(`/sapi/projectRoster/findProjectRoster`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.rosterListTotal = res.total;
          this.rosterDataList = res.resList;
          this.rosterListLoading = false;
        } else {
          this.$Message.error("获取失败，请稍后再试");
        }
        // // console.log(res)
      });
    },
    onBefore(file) {
      this.file = file;
      this.senBlackList();
      return false;
    },
    senBlackList() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("projectId", this.projectInfo.id);
      // formData.append("phone", this.currentUserInfo.login_id);
      this.$fetch("/sapi/projectRoster/upload", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type":
            "multipart/form-data;boundary = " + new Date().getTime(),
        },
      })
        .then((res) => {
          if (res.code == "200") {
            this.file = null;
            this.$Message.success("导入成功");
            // this.handleBlacklistSearch("reset");
            this.rosterListPage = 1;
            this.rosterListLoading = true;
            this.getRosterList();
            // // console.log(res);
          } else {
            this.file = null;
            this.$Message.error("导入失败，稍后再试");
          }
        })
        .catch((err) => {
          //  this.$Message.error(err);
          this.file = null;
         
        });
    },
    changeRosterListPage(e) {
      this.rosterListPage = e;
      this.rosterListLoading = true;
      this.getRosterList();
    },
    toExitPeople(idc) {
      let data = {
        projectId: this.projectInfo.id,
        idcard: idc,
      };
      this.$fetch(`/sapi/projectRoster/removedProjectRoster`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.$Message.success("操作成功");
          this.rosterListPage = 1;
          this.rosterListLoading = true;
          this.getRosterList();
        } else {
          this.$Message.error("操作失败，请稍后再试");
        }
        // // console.log(res)
      });
    },
    rosterClassName(row, index) {
      if (row.isExistInProject == 1) {
        return "demo-table-info-row";
      }
      return "";
    },
    getWorkType() {
      let that = this;
      that.$axios
        .get(`${this.apiUrlCros}/sapi/v3/train/position/type`)
      // that.$fetch(`/sapi/v3/train/position/type`, {
      //   method: "get",
      // })
        .then((res) => {
          if (res.data.code == "0") {
            // if(res.data.data == "true" || res.data.data == true){
            //   window.open(downUrl, "_blank");
            // }else{
            //   that.$Message.error(res.data.msg);
            // }
            this.handleWorkTypeList(res.data.data);
            // // console.log(res)
          } else {
            that.$Message.error("获取工种失败，请稍后再试");
          }
        })
        .catch((err) => {
          // that.$Message.error(err.message);
        });
    },
    handleWorkTypeList(val) {
      let list = val;
      this.workTypeList = list;
    },
    // 获取安全表内容
    getSafeContentProject(tid) {
      let data = {
        type: "project",
        id: tid,
      };
      this.$fetch(`/sapi/safeDisclose/getSafeDisclose`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          // this.$Message.success("操作成功")
          // this.rosterListPage = 1
          // this.rosterListLoading = true
          // this.getRosterList()
          this.currentSafeContent = res.data.content;
          this.getSafeContentLoading = false;
          this.getSafeDiscloseWorkType(res.data.id);
        } else {
          this.$Message.error("获取安全交底表内容失败，请稍后再试");
        }
        // // console.log(res)
      });
    },
    // 获取交底表工种
    getSafeDiscloseWorkType(did) {
      this.$axios
        .get(
          `${this.apiUrlCros}/sapi/safeDisclose/getDiscloseWorkType?source=${"project"}&discloseId=${did}`
        )
        .then((res) => {
          if (res.data.code == "200") {
            // // console.log(res.data.data)
            this.currentWorkTypeList = res.data.data;
          } else {
            this.$Message.error("获取安全交底表工种失败，请稍后再试");
          }
        })
        .catch((err) => {
         // this.$Message.error(err.message);
        });
    },
    // 修改安全表内容
    toEditSafeContent() {
      if (this.pickSafeListId[1]) {
        this.editSafeFlag = true;
      } else {
        this.$Message.info("请先选择需要编辑的安全技术交底表");
      }
    },
    toChangeSafeContent(type) {
      if (type == "save") {
        this.$Modal.confirm({
          title: "提示",
          content: "<p>是否保存安全技术表格内容</p>",
          onOk: () => {
            let data = {
              id: this.pickSafeListId[1],
              type: "project",
              corpId: this.currentUserInfo.icorpid,
              projectId: this.projectInfo.id,
              content: this.currentSafeContent,
            };
            this.$fetch(`/sapi/safeDisclose/editContent`, {
              method: "post",
              data,
            }).then((res) => {
              if (res && res.code === "200") {
                this.$Message.success("修改成功");
                this.editSafeFlag = false;
                this.currentSafeContent = "";
                this.getSafeContentLoading = true;
                this.getSafeContentProject(this.pickSafeListId[1]);
              } else {
                this.$Message.error("操作失败，请稍后再试");
              }
            });
          },
          onCancel: () => {},
        });
      } else if (type == "cancel") {
        this.editSafeFlag = false;
        this.currentSafeContent = "";
        this.getSafeContentLoading = true;
        this.getSafeContentProject(this.pickSafeListId[1]);
      }
    },
    updateSafeDisclose() {
      this.currentVersion = "";
      this.copySafeDiscloseFlag = true;
    },
    handleYearList(list) {
      let ylist = [];
      if (list.length > 0) {
        list.forEach((val, index) => {
          ylist.push({
            value: val,
            label: val + "版",
          });
        });
        this.versionList = ylist;
      } else {
        this.versionList = [];
      }
    },
    // 根据版本号获取模板
    toPickVersion() {
      if (!this.currentVersion) {
        this.$Message.warning("请先选择版本");
        return false;
      }
      let data = {
        type: "project",
        from: "corp",
        createBy: this.currentUserInfo.id,
        corpId: this.currentUserInfo.icorpid,
        projectId: this.projectInfo.id,
        province: 440000,
        year: this.currentVersion,
      };
      this.$fetch(`/sapi/safeDisclose/copy`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.$Message.success("获取成功");
          this.editSafeFlag = false;
          this.currentSafeContent = "";
          this.safeSelectValue = "";
          this.pickSafeListId = [];
          this.safeSelectData = [];
          this.getSafeList();
        } else {
          // this.$Message.error("操作失败，请稍后再试");
        }
      });
    },
    toDownFile(src) {
      window.open(src, "_blank");
    },
    toViewFile(src) {
      window.open(src, "_blank");
    },
    handlePrejobTablePick(currentRow, oldCurrentRow) {
      // // console.log(currentRow, oldCurrentRow);
      // 处理岗前安全培训 任务管理 列表选择
      if (this.teamDataList.length < 1) {
        this.tabDataNull = true;
        // this.$Message.info("暂无数据");
        // return false;
      }
      this.finishProjectDataPage = 1;
      this.searchPrejobTaskData = {
        teamId: "",
        stauts: "",
        beginTime: "",
        finishTime: "",
      };
      this.prejobProjectPickFlag = true;
      this.currentPerjobTaskId = currentRow.id;
      this.getPrejobTaskFinishData(currentRow.id);
      this.getPrejobTaskVideoData(currentRow.id);
    },
    handleSignTablePick(currentRow, oldCurrentRow) {
      // // console.log(currentRow, oldCurrentRow);
      // 处理签字培训 列表选择
      this.signPickFlag = true;
      this.currentSignId = currentRow.id;
      this.searchSignPeopleData = {
        name: "",
        phone: "",
        isUpload: "",
      };
      this.searchSignFilesData = {
        fileType: "",
        fileName: "",
      };
      this.tagName = currentRow.tagName
      console.log("2222", currentRow, this.tagName)
      this.signDetailPage = 1;
      this.signFilesPage = 1;
      this.getSignPeopleList(currentRow.id);
      this.getSignFilesList(currentRow.id);
    },
    handleSearchPrejobFinish(type) {
      if (type == "reset") {
        this.finishProjectDataPage = 1;
        this.searchPrejobTaskData = {
          teamId: "",
          stauts: "",
          beginTime: "",
          finishTime: "",
        };
      }
      this.finishProjectDataPage = 1;
      this.getPrejobTaskFinishData(this.currentPerjobTaskId);
    },
    handleSearchPrejobFinishPageChange(e) {
      this.finishProjectDataPage = e;
      this.getPrejobTaskFinishData(this.currentPerjobTaskId);
    },
    handlePrejobVideoDataPageChange(e) {
      this.prejobVideoDataPage = e;
      this.getPrejobTaskVideoData(this.currentPerjobTaskId);
    },
    getPrejobTaskFinishData(tid) {
      this.prejobTaskFinishTableFlag = true;
      let data = {
        projectId: this.projectInfo.id,
        taskId: tid,
        teamId: this.searchPrejobTaskData.teamId,
        status: this.searchPrejobTaskData.status,
        pageNumber: this.finishProjectDataPage,
        pageSize: this.finishProjectDataSize,
        beginTime: this.searchPrejobTaskData.beginTime,
        finishTime: this.searchPrejobTaskData.finishTime,
      };
      this.$fetch(`/sapi/task/getProjectTaskDetail`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          // // console.log(res);
          let list = res.resList;
          list.forEach((val) => {
            if (val.taskTempo > 0) {
              val.taskTempo = val.taskTempo + "%";
            } else if (val.taskTempo == 0) {
              val.taskTempo = val.taskTempo + "%";
            }
          });
          // this.finishProjectDataList = list;
          this.finishProjectDataList1 = list;


          this.finishProjectDataList= JSON.parse(JSON.stringify(this.finishProjectDataList1));//处理深拷贝  浅拷贝
                // 默认先脱敏
              this.finishProjectDataList.forEach((e) => {
              e.name = e.name?nameConceal(e.name.toString()):'';//姓名
              e.phone = e.phone?phoneConceal(e.phone.toString()):'';//手机号

              e.showOrHide = "hide";//显示/隐藏
          });

          this.finishProjectDataTotal = res.total;
          // this.projectManTypeList = res.data;
          this.prejobTaskFinishTableFlag = false;
        } else {
          this.$Message.error("获取任务详情失败");
          this.prejobTaskFinishTableFlag = false;
        }
      });
    },
    // 展示
    showFinishProjectConceal(data){
       this.finishProjectDataList1.forEach((item,index)=>{
        if(data.taskId==item.taskId){
         data.name = item.name;//姓名
         data.phone = item.phone;//手机号
         data.showOrHide = "show";//显示/隐藏
        }
      })
    },
    //隐藏
    hideFinishProjectConceal(data){
      this.finishProjectDataList1.forEach((item,index)=>{
        if(data.taskId==item.taskId){
         data.name = item.name?nameConceal(item.name.toString()):'';//姓名
         data.phone= item.phone?phoneConceal(item.phone.toString()):'';//手机号
         data.showOrHide = "hide";//显示/隐藏
        }
      })
    },
    getPrejobTaskVideoData(tid) {
      this.prejobTaskVideoTableFlag = true;
      let data = {
        taskId: tid,
        pageNumber: this.prejobVideoDataPage,
        pageSize: this.prejobVideoDataSize,
      };
      this.$fetch(`/sapi/task/getTaskVideos`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          // // console.log(res);
          this.prejobVideoDataList = res.resList;
          this.prejobVideoDataTotal = res.total;
          this.prejobTaskVideoTableFlag = false;
        } else {
          this.$Message.error("获取任务视频列表失败");
          this.prejobTaskVideoTableFlag = false;
        }
      });
    },
    getPrejobTaskDiscloseData(tid) {
      this.sdDiscloseLoading = true;
      let data = {
        taskId: tid,
        projectId: this.projectInfo.id,
        pageNumber: this.sdDisclosePage,
        pageSize: this.sdDiscloseSize,
      };
      this.$fetch(`/sapi/task/taskDiscloseList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          // // console.log(res);
          let list = res.resList;
          list.forEach((val, index) => {
            if (val.workType) {
              let type = val.workType.split(",");
              if (type.length > 3) {
                val.wts = type[0] + "," + type[1] + "," + type[2] + "...";
              } else {
                val.wts = val.workType;
              }
            }
          });
          this.sdDiscloseData = list;
          this.sdDiscloseTotal = res.total;
          this.sdDiscloseLoading = false;
        } else {
          this.$Message.error("获取任务交底列表失败");
          this.sdDiscloseLoading = false;
        }
      });
    },
    // 安全技术交底-自动模板管理处理表格点击
    handleSafeTablePick(currentRow, oldCurrentRow) {
      // console.log(currentRow, oldCurrentRow);
      //
      // this.finishProjectDataPage = 1;
      // this.searchPrejobTaskData = {
      //   teamId: "",
      //   stauts: "",
      //   beginTime: "",
      //   finishTime: "",
      // };
      this.safeAutoPickFlag = true;
      this.currentSafeAutoVideoId = currentRow.id;
      this.handleSearchSafeAuto("reset");
      this.safeTemplateDisclosePage = 1;
      // this.getSafeTaskDiscloseList();
      this.safeTemplateTypePage = 1;
      this.getSafeTemplateTypeList();
      this.safeTemplateDiscloseData = [];
      // this.getPrejobTaskFinishData(currentRow.id);
      // this.getPrejobTaskVideoData(currentRow.id);
      // 查询项目任务模板安全技术交底表内容信息
      if (currentRow.projectContentId != null) {
        this.$axios({
          method: "get",
          url: `${this.apiUrlCros}/sapi/project/disclose/contents/${currentRow.projectContentId}`,
        }).then((res) => {
          if (res.data.code == "200") {
            this.safeTemplateDiscloseData = [res.data.data];
          }
        });
      }
    },
    handleClearSign() {
      this.$refs.signTable.clearCurrentRow();
      // 清除业务逻辑
      this.signPickFlag = false;
    },
    handleClearCurrentRow() {
      this.$refs.prejobProject.clearCurrentRow();
      // 清除业务逻辑
      this.prejobProjectPickFlag = false;
    },
    handleClearSafe() {
      this.$refs.safeAuto.clearCurrentRow();
      // 清除业务逻辑
      this.safeAutoPickFlag = false;
    },
    handleClearSafeProject() {
      this.$refs.safeProject.clearCurrentRow();
      // 清除业务逻辑
      this.safeProjectPickFlag = false;
    },
    handleClearTeamUser() {
      this.$refs.teamtable.clearCurrentRow();
      // 清除业务逻辑
      this.teamUserTabPickFlag = false;
    },
    pickPrejobTab(name) {
      // // console.log(name);
      // this.prejobTabFlag = name;
    },
    pickSafeAutoTab(name) {
      // // console.log(name);
      // this.safeAutoTabFlag = name;
    },
    pickTeamUserTab(name) {},
    pickSafeTemplateTab(name) {
      // // console.log(name);
      // this.safeTemplateTababFlag = name;
    },
    pickSignTab(name) {},
    handleSafeProjectTablePick(currentRow, oldCurrentRow) {
      // // console.log(currentRow, oldCurrentRow);
      // 处理岗前安全培训 任务管理 列表选择
      if (this.teamDataList.length < 1) {
        this.tabDataNull = true;
        this.$Message.info("暂无数据");
        return false;
      }
      this.finishProjectDataPage = 1;
      this.searchPrejobTaskData = {
        teamId: "",
        status: "",
        beginTime: "",
        finishTime: "",
      };
      this.safeProjectPickFlag = true;
      this.currentPerjobTaskId = currentRow.id;
      this.currentPerjobTaskTemId = currentRow.templateId;
      this.getPrejobTaskFinishData(currentRow.id);
      this.getPrejobTaskVideoData(currentRow.id);
      this.getPrejobTaskDiscloseData(currentRow.templateId);
    },
    handleSdDisclosePageChange(e) {
      this.sdDisclosePage = e;
      this.getPrejobTaskDiscloseData(this.currentPerjobTaskTemId);
    },
    // 获取任务管理分类列表
    getProjectManTypeList() {
      let data = {};
      // this.$fetch(`/sapi/common/getTaskTypes`, {
      //   method: "post",
      //   data,
      // })
      this.$fetch(`/sapi/common/getTaskTypes`,{
        method: "post",
        data,
      })
      .then((res) => {
        if (res && res.code === "200") {
          // // console.log(res);
          this.projectManTypeList = res.data;
        } else {
          this.$Message.error("获取任务管理分类失败");
        }
      });
    },
    getProjectWorkTypeList() {
      let data = {};
      this.$fetch(`/sapi/workType/list`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.projectWorkTypeList = res.data;
        } else {
          this.$Message.error("获取工种分类失败");
        }
      });
    },
    // 岗前培训任务-任务管理
    getPrejobProjectList() {
      this.prejobProjectLoading = true;
      this.prejobProjectPickFlag = false;
      let data = {
        projectId: this.projectInfo.id,
        isAuto: "1",
        type: "16",
        name: this.prejobProjectSearchData.name || "",
        pageNumber: this.prejobProjectPage,
        pageSize: this.prejobProjectSize,
      };
      this.$fetch(`/sapi/task/getAutoTaskList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          let list = res.resList;
          list.forEach((val, index) => {
            if (val.positionTypeStr) {
              let type = val.positionTypeStr.split(",");
              if (type.length > 3) {
                val.pts = type[0] + "," + type[1] + "," + type[2] + "...";
              } else {
                val.pts = val.positionTypeStr;
              }
            }
          });
          this.prejobProjectDataList = list;
          this.prejobProjectTotal = res.total;
          this.prejobProjectLoading = false;
        } else {
          this.$Message.error("操作失败，请稍后再试");
          this.prejobProjectLoading = false;
        }
      });
    },
    // 安全技术交底-任务管理
    getSafeProjectList() {
      this.safeProjectLoading = true;
      this.safeProjectPickFlag = false;
      let data = {
        projectId: this.projectInfo.id,
        isAuto: "1",
        type: "17",
        name: this.safeProjectSearchData.name || "",
        status: this.safeProjectSearchData.status || "",
        pageNumber: this.prejobProjectPage,
        pageSize: this.prejobProjectSize,
      };
      this.$fetch(`/sapi/task/getAutoTaskList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          let list = res.resList;
          list.forEach((val, index) => {
            if (val.positionTypeStr) {
              let type = val.positionTypeStr.split(",");
              if (type.length > 3) {
                val.pts = type[0] + "," + type[1] + "," + type[2] + "...";
              } else {
                val.pts = val.positionTypeStr;
              }
            }
          });
          // this.safeProjectDataList = list;

          this.safeProjectDataList1 = list;
          this.safeProjectDataList= JSON.parse(JSON.stringify(this.safeProjectDataList1));//处理深拷贝  浅拷贝
              // 默认先脱敏
          this.safeProjectDataList.forEach((e) => {
            e.updateBy = e.updateBy?phoneConceal(e.updateBy.toString()):'';//手机号
            e.showOrHide = "hide";//显示/隐藏
        });


          this.safeProjectTotal = res.total;
          this.safeProjectLoading = false;
        } else {
          this.$Message.error("操作失败，请稍后再试");
          this.safeProjectLoading = false;
        }
      });
    },
    // 展示
    showSafeProjectConceal(data){
       this.safeProjectDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.updateBy = item.updateBy;//手机号
         data.showOrHide = "show";//显示/隐藏
        }
      })
    },
    //隐藏
    hideSafeProjectConceal(data){
      this.safeProjectDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.updateBy= item.updateBy?phoneConceal(item.updateBy.toString()):'';//手机号
         data.showOrHide = "hide";//显示/隐藏
        }
      })
    },
    handleSearchPrejobTask(type) {
      if (type == "reset") {
        this.prejobProjectSearchData = {
          name: "",
          type: "",
        };
        this.prejobProjectPage = 1;
        this.getPrejobProjectList();
        return false;
      }
      this.getPrejobProjectList();
    },
    handleSearchSafeTask(type) {
      if (type == "reset") {
        this.safeProjectSearchData = {
          name: "",
          type: "",
          status: "",
        };
        this.safeProjectPage = 1;
        this.getSafeProjectList();
        return false;
      }
      this.getSafeProjectList();
    },
    signProjectPageChange(e) {
      this.signListPage = e;
    },
    prejobProjectPageChange(e) {
      this.prejobProjectPage = e;
      this.getPrejobProjectList();
    },
    safeProjectPageChange(e) {
      this.safeProjectPage = e;
      this.getSafeProjectList();
    },
    // 处理默认任务管理
    handlePickPrejob() {
      this.getProjectTeamList(1, 999, "");
      this.getPrejobProjectList();
    },
    // 处理默认安全技术交底表管理
    handlePickSafe() {
      this.getProjectTeamList(1, 999, "");
      this.safeSelectValue = [];
      this.currentSafeContent = "";
      this.getSafeList();
      this.getSafeYearList();
    },
    // 处理默认专项签字表管理
    handlePickSpecial() {},
    // 处理默认设备管理
    handlePickOncheck() {
      // this.checkInRelateTableLoading = true;
      // this.getTeamCheckinRelateList();
      // this.newEquipmentData = { eId: "", eName: "" };
    },
    // 处理默认外部交换平台
    handlePickOutside() {
      this.searchWorkerInfoData = {
        name: "",
        phone: "",
        cardNo: "",
        intoDate: "",
        exitDate: "",
      };
      this.GMRealNameData = [];
      this.GMRealNamePage = 1;
      this.getWorkerInfoList();
    },
    handleDate1(e) {
      this.searchPrejobTaskData.beginTime = e;
    },
    handleDate2(e) {
      this.searchPrejobTaskData.finishTime = e;
    },
    handleSafeAutoSearch(type) {
      if (type == "reset") {
        this.safeAutoSearchData = {
          name: "",
          pid: "",
        };
        this.safeAutoListPage = 1;
        this.getSafeAutoList();
        return false;
      }
      this.getSafeAutoList();
    },
    // 安全技术交底--自动模板管理
    getSafeAutoList() {
      this.safeAutoListLoading = true;
      this.safeAutoPickFlag = false;
      let data = {
        projectId: this.projectInfo.id,
        name: this.safeAutoSearchData.name || "",
        pageNumber: this.safeAutoListPage,
        pageSize: this.safeAutoListSize,
      };
      this.$fetch(`/sapi/taskTemplate/getTaskTemplateList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.safeAutoDataList = res.resList;
          this.safeAutoListTotal = res.total;
          this.safeAutoListLoading = false;
        } else {
          this.$Message.error("操作失败，请稍后再试");
          this.safeAutoListLoading = false;
        }
      });
    },
    handleSafeAutoPageChange(e) {
      this.safeAutoListPage = e;
      this.getSafeAutoList();
    },
    // 安全技术交底--自动模板管理--视频列表
    getSafeTemplateVideoList() {
      this.safeTemplateVideoLoading = true;
      let data = {
        templateId: this.currentSafeAutoVideoId,
        pageNumber: this.safeTemplateVideoPage,
        pageSize: this.safeTemplateVideoSize,
      };
      this.$fetch(`/sapi/taskTemplate/getTaskTemplateVideos`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.safeTemplateVideoData = res.resList;
          this.safeTemplateVideoTotal = res.total;
          this.safeTemplateVideoLoading = false;
        } else {
          this.$Message.error("操作失败，请稍后再试");
          this.safeTemplateVideoLoading = false;
        }
      });
    },
    // 安全技术交底--自动模板管理--交底表
    getSafeTaskDiscloseList() {
      this.safeTemplateDiscloseLoading = true;
      let data = {
        taskId: this.currentSafeAutoVideoId,
        projectId: this.projectInfo.id,
        pageNumber: this.safeTemplateVideoPage,
        pageSize: this.safeTemplateVideoSize,
      };
      this.$fetch(`/sapi/task/taskDiscloseList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          let list = res.resList;
          list.forEach((val, index) => {
            let type = val.workType.split(",");
            if (type.length > 3) {
              val.pts = type[0] + "," + type[1] + "," + type[2] + "...";
            } else {
              val.pts = val.workType;
            }
          });
          this.safeTemplateDiscloseData = list;
          this.safeTemplateDiscloseTotal = res.total;
          this.safeTemplateDiscloseLoading = false;
        } else {
          this.$Message.error("操作失败，请稍后再试");
          this.safeTemplateDiscloseLoading = false;
        }
      });
    },
    // 安全技术交底--自动模板管理--适用工种
    getSafeTemplateTypeList() {
      this.safeTemplateTypeLoading = true;
      let data = {
        taskId: this.currentSafeAutoVideoId,
        pageNumber: this.safeTemplateTypePage,
        pageSize: this.safeTemplateTypeSize,
      };
      this.$fetch(`/sapi/task/taskWorkTypeList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.safeTemplateTypeData = res.resList;
          this.safeTemplateTypeTotal = res.total;
          this.safeTemplateTypeLoading = false;
        } else {
          this.$Message.error("操作失败，请稍后再试");
          this.safeTemplateTypeLoading = false;
        }
      });
    },
    handleSafeTemplateTypePageChange(e) {
      this.safeTemplateTypePage = e;
      this.getSafeTemplateTypeList();
    },
    handleSafeTemplateDisclosePageChange(e) {
      this.safeTemplateDisclosePage = e;
      this.getSafeTaskDiscloseList();
    },
    handleSafeTemplateVideoPageChange(e) {
      this.safeTemplateVideoPage = e;
      this.getSafeTemplateVideoList();
    },
    handleSearchSafeAuto(type) {
      if (type == "reset") {
        this.safeTemplateVideoPage = 1;
        this.getSafeTemplateVideoList();
        return false;
      }
      this.safeTemplateVideoPage = 1;
      this.getSafeTemplateVideoList();
    },
    // 样板模板查看修改记录
    toEditRecord(val) {
      if (val) {
        this.editRecordTemplateId = val.id;
      }
      this.modalFlag.editRecordVisible = true;
      let data = {
        templateId: this.editRecordTemplateId,
        pageNumber: this.pageNumber,
        pageSize: this.editRecordDataSize,
      };
      this.editRecordDataTableLoading = true;
      // /sapi/taskTemplate/getTaskTemplateLog
      this.$fetch(`/sapi/taskTemplate/getTaskTemplateLog`, {
        method: "POST",
        data,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          // this.editRecordDataList = res.resList;


          this.editRecordDataList1 = res.resList;
          this.editRecordDataList= JSON.parse(JSON.stringify(this.editRecordDataList1));//处理深拷贝  浅拷贝
              // 默认先脱敏
            this.editRecordDataList.forEach((e) => {
            e.realname = e.realname?nameConceal(e.realname.toString()):'';//姓名
            e.updateBy = e.updateBy?phoneConceal(e.updateBy.toString()):'';//手机号

            e.showOrHide = "hide";//显示/隐藏
        });


          this.editRecordDataTableLoading = false;
          this.editRecordDataTotal = res.total;
        }
      });
    },
    // 展示
    showRecordConceal(data){
       this.editRecordDataList1.forEach((item,index)=>{
        if(data.logId==item.logId){
         data.realname = item.realname;//姓名
         data.updateBy = item.updateBy;//手机号
         data.showOrHide = "show";//显示/隐藏
        }
      })
    },
    //隐藏
    hideRecordConceal(data){
      this.editRecordDataList1.forEach((item,index)=>{
        if(data.logId==item.logId){
         data.realname = item.realname?nameConceal(item.realname.toString()):'';//姓名
         data.updateBy= item.updateBy?phoneConceal(item.updateBy.toString()):'';//手机号
         data.showOrHide = "hide";//显示/隐藏
        }
      })
    },
    handleeditRecordPageChange(val) {
      console.log(val);
      this.pageNumber = val;
      this.toEditRecord();
    },
    toeditRecordOk() {},
    addNewTemplate() {
      this.newTemplateData = {
        name: "",
        duration: "",
        content: "",
        remark: "",
        type: "",
        projectContentId: "",
      };
      this.getSafeList();
      this.modalFlag.addTemplate = true;
    },
    toEditTemplate(val) {
      console.log("aaaaaaaaa", val);
      // console.log(this.common.getStorage(this.CONSTANTS.USER_INFO).vname)
      // this.editTemplateFlag = true;
      // this.modalFlag.addTemplate = true;
      this.editTemplateData = val;
      this.newTemplateData = {
        name: val.name,
        duration: val.duration,
        content: val.content,
        remark: val.remark,
        type: val.type,
        // updateBy: val.updateBy==null?"":val.updateBy,
        // this.newTemplateData.projectContentId: val.projectContentId.toString()
      };
      console.log(this.newTemplateData);
      this.$fetch("/sapi/project/disclose/contents", {
        method: "get",
        params: {
          projectId: this.projectInfo.id,
          useable: true,
        },
      }).then((res) => {
        if (res.code == "200") {
          res.data.dataList.forEach((item) => {
            this.workTypeListData.forEach((item2) => {
              if (item.parentName == item2.categoryName) {
                this.newTemplateDataCategoryId = item2.categoryId;
                this.$fetch("/sapi/project/disclose/contents", {
                  method: "get",
                  params: {
                    projectId: this.projectInfo.id,
                    useable: true,
                    categoryId: this.newTemplateDataCategoryId,
                  },
                }).then((ress) => {
                  if (ress.code == "200") {
                    this.projectContentData = ress.data.dataList;
                    this.newTemplateData.projectContentId =
                      val.projectContentId;
                    this.editTemplateFlag = true;
                    this.modalFlag.addTemplate = true;
                  }
                });
              }
            });
          });
        }
      });
      // this.editTemplateFlag = true;
      // this.modalFlag.addTemplate = true;
      console.log(this.newTemplateData);
    },
    // 安全技术交底--自动模板管理--添加视频
    toAddVideo(type) {
      // this.$refs.selection.data = []
      this.choseVideoList = [];
      // // console.log(this.$refs.selection,this.choseVideoList)
      this.addNewVideoDataPage = 1;
      this.searchNewVideoData = {
        from: type,
        type: "",
        title: "",
      };
      this.getAddVideoList();
      this.addVideoFlag = true;
    },
    handleAddVideo(type) {
      if (type == "reset") {
        this.addVideoFlag = false;
        return false;
      }
      let data = [];
      let idlist = this.choseVideoList;
      // let data = {
      //   templateId: '',
      //   videoId: '',
      //   videoSource: '',
      // };
      idlist.forEach((val) => {
        data.push({
          templateId: this.currentSafeAutoVideoId,
          videoId: val,
          videoSource: this.searchNewVideoData.from,
        });
      });
      let updateBy = this.currentUserInfo.login_id;
      let data2 = { updateBy, data };
      this.$fetch(`/sapi/taskTemplate/saveTemplateVideo`, {
        method: "post",
        data: data2,
      }).then((res) => {
        if (res && res.code === "200") {
          this.$Message.success("操作成功");
          this.getSafeTemplateVideoList();
          this.addVideoFlag = false;
        } else {
          this.addVideoNext = false;
          setTimeout(() => {
            this.addVideoNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    getVideoTypeList() {
      let data = {};
      this.$fetch(`/sapi/corpVideo/getCorpVideoCate`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.videoTypeList = res.data;
          // this.safeTemplateVideoTotal = res.total;
          // this.safeTemplateVideoLoading = false;
        } else {
          this.$Message.error("获取视频分类列表失败");
        }
      });
    },
    handleVideoSelect(selection, row) {
      let idlist = this.choseVideoList;
      if (idlist.indexOf(row.videoId) == "-1") {
        idlist.push(row.videoId);
      }
      this.choseVideoList = idlist;
      // // console.log(idlist)
    },
    handleVideoSelectCancle(selection, row) {
      let idlist = this.choseVideoList;
      idlist = idlist.filter((item) => item != row.videoId);
      this.choseVideoList = idlist;
      // // console.log(idlist)
    },
    handleVideoSelectAll(selection) {
      // // console.log(selection)
      let idlist = this.choseVideoList;
      selection.forEach((val) => {
        if (idlist.indexOf(val.videoId) == "-1") {
          idlist.push(val.videoId);
        }
        // // console.log(idlist.indexOf(val.videoId),idlist,val.videoId)
      });
      this.choseVideoList = idlist;
      // // console.log(idlist)
    },
    handleVideoSelectAllCancle() {
      let data = this.$refs.selection.data;
      let idlist = this.choseVideoList;
      let choseIdList = [];
      let newIdList = [];
      data.forEach((val) => {
        choseIdList.push(val.videoId);
      });
      // idlist.forEach(val=>{
      //   if(choseIdList.indexOf(val) != '-1'){
      //     newIdList.push(val.videoId)
      //   }
      // })
      idlist.forEach((val) => {
        if (choseIdList.indexOf(val) == "-1") {
          newIdList.push(val);
        }
      });
      this.choseVideoList = newIdList;
      // // console.log(newIdList)
    },
    // 处理添加视频 搜索视频列表
    handleSearchVideoList(type) {
      if (type == "reset") {
        this.searchNewVideoData.type = "";
        this.searchNewVideoData.title = "";
        this.addNewVideoDataPage = 1;
      }
      this.addNewVideoDataPage = 1;
      this.getAddVideoList();
    },
    handleVideoPageChange(e) {
      this.addNewVideoDataPage = e;
      this.getAddVideoList();
    },
    // 获取添加视频列表
    getAddVideoList() {
      let _this = this;
      this.addNewVideoDataLoading = true;
      let data = {
        videoSource: this.searchNewVideoData.from,
        corpId: this.currentUserInfo.icorpid,
        pageNumber: this.addNewVideoDataPage,
        pageSize: this.addNewVideoDataSize,
        title: this.searchNewVideoData.title,
        cateId: this.searchNewVideoData.type,
      };
      this.$fetch(`/sapi/video/getVideos`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.addNewVideoDataList = res.resList;
          this.addNewVideoDataTotal = res.total;
          this.addNewVideoDataLoading = false;
          // 处理表格
          setTimeout(() => {
            _this.handleVideoTable();
          }, 100);
        } else {
          this.$Message.error("获取视频列表失败");
          this.addNewVideoDataLoading = false;
        }
      });
    },
    // 处理修改视频来源
    handleChangeVideoFrom(value) {
      // // console.log(value)
      if (value == "0") {
        this.searchNewVideoData.type = "";
      }
    },
    handleVideoTable() {
      //拿到当前table的所有行的引用，里面包含_isChecked属性
      let idlist = this.choseVideoList;
      let _that = this;
      let objData = _that.$refs.selection.$refs.tbody.objData;
      // // console.log(objData,_that.choseVideoList)
      for (let key in objData) {
        if (idlist.indexOf(objData[key].videoId) != "-1") {
          objData[key]._isChecked = true;
        } else {
          objData[key]._isChecked = false;
        }
      }
      // objData.forEach((val,index)=>{
      //   if(idlist.indexOf(val.videoId) != '-1'){
      //     val._isChecked = true;
      //   }
      // })
      // // console.log(objData)
    },
    // 设置工种
    toEditWorkType(flag) {
      this.currentWrokTypeFlag = flag;
      let list = JSON.parse(JSON.stringify(this.projectWorkTypeList));
      list.forEach((val) => {
        val.label = val.name;
        val.key = val.value;
        val.disabled = false;
      });
      this.transferData = list;
      if (flag == "disclose") {
        if (!this.pickSafeListId[1]) {
          this.$Message.info("请先选择需要编辑的安全技术交底表");
          return false;
        }
        this.transferTarget = this.currentWorkTypeList;
      }
      if (flag == "auto") {
        if (this.safeTemplateTypeData) {
          let nilist = this.safeTemplateTypeData;
          let newl = [];
          nilist.forEach((val) => {
            newl.push(parseInt(val.value));
          });
          // // console.log(newl)
          this.transferTarget = newl;
        } else {
          this.transferTarget = [];
        }
      }
      this.editWorkTypeFlag = true;
      // // console.log(list)
    },
    handleTransferChange(newTargetKeys) {
      this.transferTarget = newTargetKeys;
    },
    transferSearch(data, query) {
      // // console.log(data,query)
      return data.label.indexOf(query) > -1;
    },
    handleEditWorkType(type) {
      if (type == "cancel") {
        this.editWorkTypeFlag = false;
        return false;
      }
      let idList = this.transferTarget;
      let ids = idList.join(",");
      if (ids.length < 1) {
        this.$Message.info("工种选择列表不能为空");
        this.editWorkTypeFlag = false;
        return false;
      }
      if (this.currentWrokTypeFlag == "auto") {
        let data = {
          taskId: this.currentSafeAutoVideoId,
          workTypes: ids,
        };
        this.$fetch(`/sapi/task/setWorkType`, {
          method: "post",
          data,
        }).then((res) => {
          if (res && res.code === "200") {
            this.$Message.success("修改工种成功");
            this.getSafeTemplateTypeList();
            this.getSafeTaskDiscloseList();
            this.editWorkTypeFlag = false;
            // this.safeTemplateVideoTotal = res.total;
            // this.safeTemplateVideoLoading = false;
          } else {
            this.editWorkTypeNext = false;
            setTimeout(() => {
              this.editWorkTypeNext = true;
            }, 500);
            this.$Message.error("修改工种失败");
          }
        });
      }
      if (this.currentWrokTypeFlag == "disclose") {
        let data = {
          discloseId: this.pickSafeListId[1],
          source: "project",
          workTypeValues: idList,
          createBy: this.currentUserInfo.id,
          corpId: this.currentUserInfo.icorpid,
          projectId: this.projectInfo.id,
        };
        this.$fetch(`/sapi/safeDisclose/setWorkType`, {
          method: "post",
          data,
        }).then((res) => {
          if (res && res.code === "200") {
            this.$Message.success("修改工种成功");
            this.getSafeDiscloseWorkType(this.pickSafeListId[1]);
            this.editWorkTypeFlag = false;
            // this.safeTemplateVideoTotal = res.total;
            // this.safeTemplateVideoLoading = false;
          } else {
            this.editWorkTypeNext = false;
            setTimeout(() => {
              this.editWorkTypeNext = true;
            }, 500);
            this.$Message.error("修改工种失败");
          }
        });
      }
    },
    handleSearchFilesUser(type) {
      if (type == "reset") {
        this.prejobFilesPage = 1;
        this.searchFilesUserData = {
          name: "",
          phone: "",
          idcard: "",
          status: "",
        };
        this.getFilesUserListDetail(this.currentFilesTeamId);
        return false;
      }
      this.getFilesUserListDetail(this.currentFilesTeamId);
    },
    toAddProblemAnswer() {
      this.addProblemData = {
        name: "",
        answer: "",
      };
      this.addProblemFlag = true;
    },
    // 配置考题
    toAddProblem(pid) {
      // // console.log(pid)
      this.currentVideoId = pid;
      this.addProblemListFlag = true;
      this.problemListPage = 1;
      this.getAddProblemList();
    },
    toOpenProblem(vid) {
      this.currentVideoId = vid;
      this.problemListFlag = true;
      this.problemListPage = 1;
      this.getProblemList();
    },
    problemListPageChange(e) {
      this.problemListPage = e;
      this.getProblemList();
    },
    getAddProblemList() {
      this.problemListLoading = true;
      let data = {
        videoId: this.currentVideoId,
      };
      this.$fetch(`/sapi/corpVideoQuestion/getCorpVideoQuestions`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.problemDataList = res.data;
          this.problemListTotal = res.total;
          this.problemListLoading = false;
        } else {
          this.$Message.error("获问题列表失败");
          this.problemListLoading = false;
        }
      });
    },
    getProblemList() {
      this.problemListLoading = true;
      let data = {
        videoId: this.currentVideoId,
        pageNumber: this.problemListPage,
        pageSize: this.problemListSize,
      };
      this.$fetch(`/sapi/question/getQuestionsByVideo`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.problemDataList = res.resList;
          this.problemListTotal = res.total;
          this.problemListLoading = false;
        } else {
          this.$Message.error("获问题列表失败");
          this.problemListLoading = false;
        }
      });
    },
    handleAddProblemConfirm(type) {
      if (type == "reset") {
        this.addProblemData = {
          name: "",
          answer: "",
        };
        this.addProblemFlag = false;
        return false;
      }
      if (!this.addProblemData.name || !this.addProblemData.answer) {
        this.$Message.info("请填写完整信息");
        this.addPNext = false;
        setTimeout(() => {
          this.addPNext = true;
        }, 500);
        return false;
      }
      let data = {
        videoId: this.currentVideoId,
        title: this.addProblemData.name,
        isRight: this.addProblemData.answer == "1",
        corpId: this.currentUserInfo.icorpid,
      };
      this.$fetch(`/sapi/corpVideoQuestion/save`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.getAddProblemList();
          this.$Message.success("新增问题成功");
          this.addProblemFlag = false;
        } else {
          this.$Message.error("新增问题失败");
          this.addProblemFlag = false;
        }
      });
    },
    toDelProblem(pid) {
      let data = {
        id: pid,
      };
      this.$fetch(`/sapi/corpVideoQuestion/remove`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.getAddProblemList();
          this.$Message.success("删除问题成功");
          this.addProblemFlag = false;
        } else {
          this.$Message.error("删除问题失败");
          this.addProblemFlag = false;
        }
      });
    },
    delVideo(vid) {
      let data = {
        id: vid,
      };
      this.$fetch(`/sapi/taskTemplate/removeVideo`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.safeTemplateVideoPage = 1;
          this.getSafeTemplateVideoList();
          this.$Message.success("删除视频成功");
        } else {
          this.$Message.error("删除视频失败");
        }
      });
    },
    toRunTemplate(tid, status) {
      let data = {
        id: tid,
        status: status,
      };
      this.$fetch(`/sapi/taskTemplate/runTemplate`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.safeAutoListPage = 1;
          this.getSafeAutoList();
          this.$Message.success("修改状态成功");
        } else {
          // this.$Message.error("修改状态失败");
        }
      });
    },
    uploadSuccess(file, response) {
      if (response.code == "200") {
        this.uploadFile.name = response.resData.fileName;
        this.uploadFile.size = response.resData.size;
        this.uploadFile.size_show = (response.resData.size / 10000000).toFixed(
          2
        );
        this.uploadFile.ext = response.resData.ext;
        this.uploadFile.frame_show =
          this.CONSTANTS.FRAME_URL + response.resData.frame;
        this.uploadFile.filePath_show =
          this.CONSTANTS.VIDEO_URL + response.resData.fileName;
        this.uploadFile.frame = response.resData.fileName;
        this.uploadFile.filePath = response.resData.file_path;
        this.uploadFile.preview_frame_path =
          response.resData.preview_frame_path;
        this.uploadFile.upload_time = response.resData.upload_time;
        this.uploadFile.id = response.resData.id;
        this.$Message.success("上传成功");
        this.$router.push({
          name: "releaseUpload",
          params: {
            uploadFile: this.uploadFile,
          },
        });
      } else {
        this.$Message.error(response.message);
        this.reload();
      }
    },
    uploadError(file) {
      this.$Message.error("上传失败");
      this.videoLoading = false;
      this.reload();
    },
    onFileSuccess: function (rootFile, file, response, chunk) {
      // // console.log(rootFile, file, response, chunk)
      // // console.log(JSON.parse(response).model);
      if (response) {
        // // console.log("上传完成", JSON.parse(response));
        let rdata = JSON.parse(response);
        this.videoFileId = rdata.data;
        this.videoLoading = false;
      }
    },
    computeMD5(file) {
      // // console.log(file);
      // const loading = this.$loading({
      //   lock: true,
      //   text: '正在计算MD5',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      let fileReader = new FileReader();
      let time = new Date().getTime();
      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;
      let currentChunk = 0;
      const chunkSize = 10 * 1024 * 1024;
      let chunks = Math.ceil(file.size / chunkSize);
      let spark = new SparkMD5.ArrayBuffer();
      file.pause();

      loadNext();

      fileReader.onload = (e) => {
        spark.append(e.target.result);
        if (currentChunk < chunks) {
          currentChunk++;
          loadNext();
          this.$nextTick(() => {
            // // console.log(
            //   "校验MD5 " + ((currentChunk / chunks) * 100).toFixed(0) + "%"
            // );
          });
        } else {
          let md5 = spark.end();
          // loading.close();
          this.computeMD5Success(md5, file);
          // // console.log(
          //   `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
          //     file.size
          //   } 用时：${new Date().getTime() - time} ms`
          // );
        }
      };
      fileReader.onerror = function () {
        this.error(`文件${file.name}读取出错，请检查该文件`);
        // loading.close();
        file.cancel();
      };

      function loadNext() {
        let start = currentChunk * chunkSize;
        let end =
          start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end));
      }
    },
    computeMD5Success(md5, file) {
      file.uniqueIdentifier = md5; //把md5值作为文件的识别码
      file.resume(); //开始上传
    },
    filesAdded(file, event) {
      //大小判断
      this.videoLoading = true;
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        this.$Message.error(this.$t("error.error_upload_file_max"));
      } else {
        this.computeMD5(file);
      }
    },
    getSafeVideoList() {
      this.safeVideoDataListLoading = true;
      let data = {
        corpId: this.currentUserInfo.icorpid,
        projectId: this.projectInfo.id,
        pageSize: this.safeVideoDataListSize,
        pageNumber: this.safeVideoDataListPage,
        title: this.searchSafeVideoData,
      };
      this.$fetch(`/sapi/corpVideo/getCorpVideoList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.safeVideoDataList = res.resList;
          this.safeVideoDataListTotal = res.total;
          this.safeVideoDataListLoading = false;
        } else {
          this.$Message.error("获取视频列表失败");
          this.safeVideoDataListLoading = false;
        }
      });
    },
    removeVideo(val) {
      // // console.log(val.id)
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否移除该视频数据</p>`,
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$fetch("/sapi/corpVideo/remove", {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("移除成功");
              this.handleSafeVideoListSearch("reset");
            } else {
              this.$Message.info(res.msg || "操作失败");
            }
            // // console.log(res)
          });
        },
        onCancel: () => {},
      });
    },
    handleSafeVideoListSearch(type) {
      if (type == "reset") {
        this.searchSafeVideoData = "";
        this.safeVideoDataListPage = 1;
      }
      this.getSafeVideoList();
    },
    handleSafeVideoListPageChange(e) {
      this.safeVideoDataListPage = e;
      this.getSafeVideoList();
    },
    addSafeVideo() {
      this.pdfId = "";
      this.videoFileId = "";
      this.editVideoId = "";
      this.safeNewVideoData = {
        title: "",
        type: "",
        desc: "",
      };
      this.addSafeVideoFlag = true;
    },
    onBeforePdf(file) {
      this.pdfFile = file;
      this.senVideopdf();
      return false;
    },
    senVideopdf() {
      this.pdfId = "";
      let formData = new FormData();
      formData.append("file", this.pdfFile);
      formData.append("corpId", this.currentUserInfo.icorpid);
      formData.append("projectId", this.projectInfo.id);
      this.$fetch("/sapi/corpVideo/uploadPdf", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type":
            "multipart/form-data;boundary = " + new Date().getTime(),
        },
      })
        .then((res) => {
          if (res.code == "200") {
            this.pdfFile = null;
            this.$Message.success("上传成功");
            this.pdfId = res.data.id;
            // // console.log(this.pdfId);
            // 处理上传pdf
            // this.handleBlacklistSearch("reset");
          } else {
            this.pdfFile = null;
            this.$Message.error("上传失败，稍后再试");
          }
        })
        .catch((err) => {
          // this.$Message.error(err);
          this.pdfFile = null;
          
        });
    },
    toSaveSafeVideo(type) {
      if (type == "cancle") {
        this.safeNewVideoData = {
          title: "",
          type: "",
          desc: "",
        };
        this.pdfId = "";
        this.videoFileId = "";
        this.searchVideoData = "";
        this.videoListPage = 1;
        this.getSafeVideoList();
        this.addSafeVideoFlag = false;
        return false;
      }
      if (this.videoLoading) {
        this.$Message.error("视频正在上传，请稍后再试。");
        return false;
      }
      if (
        !this.safeNewVideoData.title ||
        !this.safeNewVideoData.type ||
        !this.safeNewVideoData.desc ||
        !this.videoFileId ||
        !this.pdfId
      ) {
        this.$Message.error("请完整填写内容");
        return false;
      }
      // // console.log(
      //   this.safeNewVideoData.title,
      //   this.safeNewVideoData.type,
      //   this.safeNewVideoData.desc,
      //   this.videoFileId,
      //   this.pdfId
      // );
      let data = {};
      let url = "";
      if (this.editVideoId) {
        data = {
          id: this.videoFileId,
          title: this.safeNewVideoData.title,
          desp: this.safeNewVideoData.desc,
          cateId: this.safeNewVideoData.type,
          pdfAttachmentId: this.pdfId,
          id: this.editVideoId,
          corpId: this.currentUserInfo.icorpid,
          projectId: this.projectInfo.id,
        };
        url = "/sapi/corpVideo/updateCorpVideo";
      } else {
        data = {
          id: this.videoFileId,
          title: this.safeNewVideoData.title,
          desp: this.safeNewVideoData.desc,
          cateId: this.safeNewVideoData.type,
          pdfAttachmentId: this.pdfId,
          corpId: this.currentUserInfo.icorpid,
          projectId: this.projectInfo.id,
        };
        url = "/sapi/corpVideo/updateCorpVideo";
      }

      this.$fetch(url, {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.$Message.success("保存成功");
            this.safeNewVideoData = {
              title: "",
              type: "",
              desc: "",
            };
            this.pdfId = "";
            this.videoFileId = "";
            this.searchVideoData = "";
            this.videoListPage = 1;
            this.getSafeVideoList();
            this.addSafeVideoFlag = false;
          } else {
            this.$Message.error("保存失败");
          }
        })
        .catch((err) => {
         // this.$Message.error("保存失败");
        });
    },
    editVideo(val) {
      this.editVideoId = val.id;
      this.safeNewVideoData = {
        title: val.title,
        type: val.cateId,
        desc: val.desp,
      };
      this.videoFileId = val.fileId;
      this.pdfId = val.pdfAttachmentId;
      this.addSafeVideoFlag = true;
    },
    // 判断有没有片区管理功能
    getAreaAuth() {
      this.$axios
        .get(`${this.apiUrlCros}/sapi/corp/getZoneAuth?corpId=${this.currentUserInfo.icorpid}`,)
        .then((res) => {
          if (res.data.code == "200") {
            this.areaAuthFlag = res.data.data;
          } else {
            this.areaAuthFlag = false;
            this.$Message.error("获取‘片区授权管理功能’权限失败");
          }
        })
        .catch((err) => {
         // this.$Message.error(err.message);
        });
    },
    getZoneList() {
      let data = {
        corpId: this.currentUserInfo.icorpid,
      };
      this.$fetch(`/sapi/zone/getZoneList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          let list = res.data;
          let zlist = [];
          list.forEach((val) => {
            zlist.push({
              value: val.code,
              label: val.name,
            });
          });
          this.zoneList = zlist;
        } else {
          this.$Message.error("获取片区列表失败");
        }
      });
    },
    // 签字管理
    getSignList() {
      this.signLoading = true;
      this.signPickFlag = false;
      let data = {
        projectId: this.projectInfo.id,
        name: this.signSearchData.name || "",
        pageNumber: this.signListPage,
        pageSize: this.signListSize,
      };
      this.$fetch(`/sapi/signManager/tagList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          let list = res.resList;
          this.signDataList = list;
          // tagName
          this.signListTotal = res.total;
          this.signLoading = false;
        } else {
          this.$Message.error("操作失败，请稍后再试");
          this.signLoading = false;
        }
      });
    },
    getSignPeopleList(id) {
      this.signDetailLoading = true;
      let data = {
        projectId: this.projectInfo.id,
        tagId: id,
        name: this.searchSignPeopleData.name || "",
        phone: this.searchSignPeopleData.phone || "",
        isUpload: this.searchSignPeopleData.isUpload || "",
        pageNumber: this.signDetailPage,
        pageSize: this.signDetailSize,
      };
      this.$fetch(`/sapi/signManager/peopleList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          let list = res.resList;
          list.forEach((val) => {
            if (val.isAuth != "") {
              if (val.isAuth == 1) {
                val.isAuthStr = "已授权";
              } else {
                val.isAuthStr = "已授权";
              }
            } else {
              val.isAuthStr = "";
            }
          });
          // this.signDetailList = list;
          this.signDetailList1 = list;

          this.signDetailList= JSON.parse(JSON.stringify(this.signDetailList1));//处理深拷贝  浅拷贝
            // 默认先脱敏
          this.signDetailList.forEach((e) => {
            e.name = e.name?nameConceal(e.name.toString()):'';//姓名
            e.phone = e.phone?phoneConceal(e.phone.toString()):'';//手机号

            e.showOrHide = "hide";//显示/隐藏
          });



          this.signDetailTotal = res.total;
          this.signDetailLoading = false;
        } else {
          this.$Message.error("获取签名人列表失败");
          this.signDetailLoading = false;
        }
      });
    },
    // 展示
    showSignDetailConceal(data){
       this.signDetailList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.name = item.name;//姓名
         data.phone = item.phone;//手机号
         data.showOrHide = "show";//显示/隐藏
        }
      })
    },
    //隐藏
    hideSignDetailConceal(data){
      this.signDetailList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.name = item.name?nameConceal(item.name.toString()):'';//姓名
         data.phone= item.phone?phoneConceal(item.phone.toString()):'';//手机号
         data.showOrHide = "hide";//显示/隐藏
        }
      })
    },
    getSignFilesList(id) {
      this.signFilesLoading = true;
      let data = {
        projectId: this.projectInfo.id,
        tagId: id,
        fileName: this.searchSignFilesData.fileName || "",
        fileType: this.searchSignFilesData.fileType || "",
        // status: "",
        pageNumber: this.signFilesPage,
        pageSize: this.signFilesSize,
      };
      this.$fetch(`/sapi/signManager/fileList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.signFilesList = res.resList;
          this.signFilesTotal = res.total;
          this.signFilesLoading = false;
        } else {
          this.$Message.error("获取签名人列表失败");
          this.signFilesLoading = false;
        }
      });
    },
    signPageChange(e) {
      this.signListPage = e;
      this.getSignList();
    },
    signDetailPageChange(e) {
      this.signDetailPage = e;
      this.getSignPeopleList(this.currentSignId);
    },
    toAddSignPeople() {
      this.newSignPeopleData = {
        name: "",
        phone: "",
        teamId: [],
      };
      this.addSignPeopleFlag = true;
    },
    handleAddSignPeople(type) {
      if (type == "reset") {
        this.addSignPeopleFlag = false;
        return false;
      }
      if (
        this.newSignPeopleData.name == "" ||
        this.newSignPeopleData.phone == ""
      ) {
        this.$Message.warning("请填写人员信息");
        this.addSignPeopleNext = false;
        setTimeout(() => {
          this.addSignPeopleNext = true;
        }, 500);
        return false;
      }
      let data = {
        projectId: this.projectInfo.id,
        tagId: this.currentSignId,
        name: this.newSignPeopleData.name,
        phone: this.newSignPeopleData.phone,
        teamIds: this.newSignPeopleData.teamId,
        createPhone: this.currentUserInfo.login_id,
        createName: this.currentUserInfo.vname || this.currentUserInfo.login_id,
      };
      this.$fetch(`/sapi/signManager/addPeople`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.$Message.success("添加成功");
          this.signDetailPage = 1;
          this.getSignPeopleList(this.currentSignId);
          // this.addVideoFlag = false
          this.addSignPeopleFlag = false;
        } else {
          this.addSignPeopleNext = false;
          setTimeout(() => {
            this.addSignPeopleNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    signFilesPageChange(e) {
      this.signFilesPage = e;
      this.getSignFilesList(this.currentSignId);
    },
    handleSearchSign(type) {
      if (type == "reset") {
        this.signListPage = 1;
        this.signSearchData = {
          name: "",
        };
        this.getSignList();
        return false;
      }
      this.getSignList();
    },
    handleSearchSignFile(type) {
      if (type == "reset") {
        this.finishProjectDataPage = 1;
        this.searchSignFilesData = {
          fileType: "",
          fileName: "",
        };
      }
      this.getSignFilesList(this.currentSignId);
    },
    toDeletePeople(uid) {
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否删除该人员</p>`,
        onOk: () => {
          this.$fetch("/sapi/signManager/deletePeople", {
            method: "post",
            data: { id: uid },
          }).then((res) => {
            if (res && res.code === "200") {
              this.$Message.success("删除成功");
              // 处理修改成功
              this.signDetailPage = 1;
              this.getSignPeopleList(this.currentSignId);
            } else {
              this.$Message.error(res.msg || "删除失败");
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 处理选中班组

    handleTeamTablePick(currentRow, oldCurrentRow) {
      // console.log(currentRow, oldCurrentRow);
      this.teamUserTabPickFlag = true;
      this.curTeamUser = currentRow;
      // 处理选择用户 获取四个文件
      this.teamUserQRCode = "";
      this.teamUserFileListPage1 = 1;
      this.teamUserFileListPage2 = 1;
      this.teamUserFileListPage3 = 1;
      this.teamUserFileListPage4 = 1;
      this.getFileOnTeam("1", currentRow.id);
      this.getFileOnTeam("2", currentRow.id);
      this.getFileOnTeam("3", currentRow.id);
      this.getFileOnTeam("4", currentRow.id);
      this.generateQrcode(currentRow);
      // 处理岗前安全培训 任务管理 列表选择
      // if (this.teamDataList.length < 1) {
      //   this.tabDataNull = true;
      //   this.$Message.info("暂无数据");
      //   return false;
      // }
      // this.finishProjectDataPage = 1;
      // this.searchPrejobTaskData = {
      //   teamId: "",
      //   stauts: "",
      //   beginTime: "",
      //   finishTime: "",
      // };
      // this.prejobProjectPickFlag = true;
      // this.currentPerjobTaskId = currentRow.id;
      // this.getPrejobTaskFinishData(currentRow.id);
      // this.getPrejobTaskVideoData(currentRow.id);
    },
    generateQrcode(val) {
      // console.log(val);
      let data = {
        phone: val.phone,
        project_code: this.projectInfo.vprojectcode,
      };
      this.$fetch("/gbApi/generateQrcode", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "0") {
          // this.$Message.success("上传成功");
          // this.getFileOnTeam(sdata.type,this.curTeamUser.id)
          // // console.log()
          this.teamUserQRCode = res.data.qr_code;
        } else {
          this.$Message.info("获取二维码失败");
        }
        // // console.log(res)
      });
    },

    toAddWorkType() {
      this.newAddWorkType = {
        type: "",
        money: "",
        id: null,
      };
      this.outsideWorkTypeFlag = true;
    },
    toAddWorker() {
      this.osUserList = [];
      this.newAddWorker = {
        team: "",
        name: "",
        money: "",
        id: null,
      };
      this.outsideWorkerFlag = true;
    },

    toAddRectifyType() {
      this.newRectifyTypeData = {
        name: "",
        id: null,
      };
      this.outsideRectifyTypeFlag = true;
    },
    toAddFineType() {
      this.newFineTypeData = {
        name: "",
        id: null,
      };
      this.outsideFineTypeFlag = true;
    },
    toEditRectifyTypeRow(e) {
      this.newRectifyTypeData = {
        name: e.name,
        id: e.id,
      };
      this.outsideRectifyTypeFlag = true;
    },
    toEditFineTypeRow(e) {
      this.newFineTypeData = {
        name: e.name,
        id: e.id,
      };
      this.outsideFineTypeFlag = true;
    },

    toEditWorkTypeMoney(e) {
      // // console.log(e)
      this.newAddWorkType = {
        type: e.typeId + "",
        money: e.perDiem,
        id: e.id,
      };
      this.outsideWorkTypeFlag = true;
    },
    toEditWorker(e) {
      // console.log(e);
      this.osUserList = [];
      this.getOSTeamUser(e.teamId);
      this.newAddWorker = {
        team: e.teamId,
        name: e.userId,
        money: e.perDiem,
        id: e.id,
      };
      this.outsideWorkerFlag = true;
    },
    getWorkerInfoList() {
      this.GMRealNameLoading = true;
      let data = {
        corpId: this.currentUserInfo.icorpid,
        projectId: this.projectInfo.id,
        name: this.searchWorkerInfoData.name || "",
        phone: this.searchWorkerInfoData.phone || "",
        cardNo: this.searchWorkerInfoData.cardNo || "",
        intoDate: this.searchWorkerInfoData.intoDate || "",
        exitDate: this.searchWorkerInfoData.exitDate || "",
        pageNum: this.GMRealNamePage,
        pageSize: this.GMRealNameSize,
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      // // console.log(formData);
      this.$fetch(`/osapi/worker/info/getWorkerInfoList`, {
        method: "post",
        data: formData,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          this.fromVal = "";
          this.fromVal = res.data;
          this.GMRealNameData = res.resList;
          // this.GMRealNameData1 = res.resList;

          // this.GMRealNameData= JSON.parse(JSON.stringify(this.GMRealNameData1));//处理深拷贝  浅拷贝
          // // 默认先脱敏
          // this.GMRealNameData.forEach((e) => {
          //   e.name = e.name?nameConceal(e.name.toString()):'';//姓名
          //   e.cardNo = e.cardNo?IdcardConceal(e.cardNo.toString()):'';//证件号码
          //   e.address = e.address?addressConceal(e.address.toString()):'';//住址
          //   e.phone = e.phone?phoneConceal(e.phone.toString()):'';//手机号
          //   e.bankCard = e.bankCard?bankCardConceal(e.bankCard.toString()):'';//银行卡号

          //   e.showOrHide = "hide";//显示/隐藏
          // });



          this.GMRealNameTotal = res.total;
          this.GMRealNameLoading = false;
        } else {
          this.$Message.error("获取人员信息失败");
          this.GMRealNameLoading = false;
        }
      });
    },
    // // 展示   暂时先不改外部交换平台里面的2022年9月21日16:42:58
    // showGMRealNameConceal(data){
    //    this.GMRealNameData1.forEach((item,index)=>{
    //     if(data.id==item.id){

    //     }
    //   })
    // },
    // //隐藏
    // hideGMRealNameConceal(data){
    //   this.GMRealNameData.forEach((item,index)=>{
    //     if(data.id==item.id){

    //     }
    //   })
    // },
    handleDateChange1(e) {
      this.searchWorkerInfoData.intoDate = e;
    },
    handleDateChange2(e) {
      this.searchWorkerInfoData.exitDate = e;
    },
    handleSearchWorkerInfo(type) {
      if (type == "reset") {
        this.searchWorkerInfoData = {
          name: "",
          phone: "",
          cardNo: "",
          intoDate: "",
          exitDate: "",
        };
      }
      this.GMRealNamePage = 1;
      this.getWorkerInfoList();
    },
    handleWorkerInfoPageChange(e) {
      this.GMRealNamePage = e;
      this.getWorkerInfoList();
    },
    pickOSCheckTab() {
      this.getGMCheckList();
    },
    getGMCheckList() {
      this.GMCheckLoading = true;
      let data = {
        corpId: this.currentUserInfo.icorpid,
        projectId: this.projectInfo.id,
        name: this.searchOSCheckData.name || "",
        postId: this.searchOSCheckData.postId || "",
        typeId: this.searchOSCheckData.typeId || "",
        yearsMonth: this.searchOSCheckData.yearsMonth || "",
        pageNum: this.GMCheckPage,
        pageSize: this.GMCheckSize,
        isManage: this.osCheckTab == "worker" ? "0" : "1",
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      // // console.log(formData);
      this.$fetch(`/osapi/worker/attendance/getWorkerAttendanceList`, {
        method: "post",
        data: formData,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          this.fromVal = "";
          this.fromVal = res.data;
          this.GMCheckData = res.resList;
          this.GMCheckTotal = res.total;
          this.GMCheckLoading = false;
          if (res.resList.length < 1) {
            this.contactFlag = true;
          } else {
            this.contactFlag = false;
          }
        } else {
          this.$Message.error("获取人员信息失败");
          this.GMCheckLoading = false;
        }
      });
    },
    getOSPostList() {
      let data = {};
      this.$fetch(`/osapi/common/getPostList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.osPostList = res.data;
        } else {
          this.$Message.error("获取外部交换平台工种列表失败");
        }
      });
    },
    getOSRectifyStatusList() {
      let data = {};
      this.$fetch(`/osapi/common/getRectifyStatusList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.osRectifyStatusList = res.data;
        } else {
          this.$Message.error("获取外部交换平台整改状态列表失败");
        }
      });
    },
    getOSTeamList() {
      let data = {
        corpId: this.currentUserInfo.icorpid,
        projectId: this.projectInfo.id,
      };
      this.$fetch(`/osapi/common/getTeamsList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.osTeamList = res.data;
        } else {
          this.$Message.error("获取外部交换平台班组列表失败");
        }
      });
    },
    getOSPostTypeList() {
      let data = {};
      this.$fetch(`/osapi/common/getPostTypeList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.osPostTypeList = res.data;
        } else {
          this.$Message.error("获取外部交换平台工种类别列表失败");
        }
      });
    },
    getOSYearsMonthList() {
      let data = {};
      this.$fetch(`/osapi/common/getYearsMonthList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.osYearsMonthList = [
            { dictCode: 48, dictLabel: "2021年01月", dictValue: "2021-01" },
            { dictCode: 49, dictLabel: "2021年02月", dictValue: "2021-02" },
            { dictCode: 50, dictLabel: "2021年03月", dictValue: "2021-03" },
            { dictCode: 51, dictLabel: "2021年04月", dictValue: "2021-04" },
            { dictCode: 52, dictLabel: "2021年05月", dictValue: "2021-05" },
          ];
          // this.osYearsMonthList = res.data;
        } else {
          this.$Message.error("获取外部交换平台信息日期列表失败");
        }
      });
    },
    handleMonthChange(e) {
      this.searchOSCheckData.yearsMonth = e;
    },
    searchOSCheck(type) {
      if (type == "reset") {
        this.searchOSCheckData = {
          name: "",
          postId: "",
          typeId: "",
          yearsMonth: "",
        };
      }
      this.GMCheckPage = 1;
      this.getGMCheckList();
    },
    handleOSCheckPageChange(e) {
      this.GMCheckPage = e;
      this.getGMCheckList();
    },
    handleOSRectifyPageChange(e) {
      this.GMRectifyPage = e;
      this.getRectifyList();
    },
    fnThrottle(method, delay, duration) {
      var that = this;
      var fTimer = this.fTimer;
      var begin = new Date().getTime();
      return function () {
        var context = that;
        var args = arguments;
        var current = new Date().getTime();
        clearTimeout(fTimer);
        if (current - begin >= duration) {
          method;
          begin = current;
        } else {
          that.fTimer = setTimeout(function () {
            method;
          }, delay);
        }
      };
    },
    // 上传档案功能
    toupLoadFile(ufile) {
      let that = this;
      let formData = new FormData();
      formData.append("file", ufile);
      this.$fetch("/gbApi/uploadFile", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type": "multipart/form-data;",
        },
      })
        .then((res) => {
          if (res.code == "0") {
            // this.$Message.success("上传成功");
            let sdata = {
              attachment_id: res.data.attachment_id,
              user_id: this.curTeamUser.id,
            };
            if (that.teamUserTabFlag == "insured") {
              sdata.type = "1";
              that.fileBindUser(sdata);
            }
            if (that.teamUserTabFlag == "qualifications") {
              sdata.type = "2";
              that.fileBindUser(sdata);
            }
            if (that.teamUserTabFlag == "medically") {
              sdata.type = "3";
              that.fileBindUser(sdata);
            }
            if (that.teamUserTabFlag == "labor") {
              sdata.type = "4";
              that.fileBindUser(sdata);
            }
          } else {
            that.$Message.error("上传失败，稍后再试");
          }
        })
        .catch((err) => {
          // this.$Message.error(err);
          this.pdfFile = null;
          
        });
    },
    handleTeamUserListPageChange1(e) {
      teamUserFileListPage1 = e;
      this.getFileOnTeam("1", this.curTeamUser.id);
    },
    handleTeamUserListPageChange2(e) {
      teamUserFileListPage2 = e;
      this.getFileOnTeam("2", this.curTeamUser.id);
    },
    handleTeamUserListPageChange3(e) {
      teamUserFileListPage3 = e;
      this.getFileOnTeam("3", this.curTeamUser.id);
    },
    handleTeamUserListPageChange4(e) {
      teamUserFileListPage4 = e;
      this.getFileOnTeam("4", this.curTeamUser.id);
    },
    getFileOnTeam(type, uid) {
      if (type == "1") {
        this.teamUserFileListLoading1 = true;
        this.$axios
          .get(`${this.apiUrlCros}/gbApi/attachmentsByUser/${uid}?type=1`)
          .then((res) => {
            if (res.data.code == "0") {
              this.teamUserFileList1 = res.data.data;
              this.teamUserFileListTotal1 = res.data.total;
              this.teamUserFileListLoading1 = false;
            } else {
              this.teamUserFileListLoading1 = false;
              this.$Message.error("获取文件数据失败");
            }
          })
          .catch((err) => {
            //this.$Message.error(err.message);
          });
      }
      if (type == "2") {
        this.teamUserFileListLoading2 = true;
        this.$axios
          .get(`${this.apiUrlCros}/gbApi/attachmentsByUser/${uid}?type=2`)
          .then((res) => {
            if (res.data.code == "0") {
              this.teamUserFileList2 = res.data.data;
              this.teamUserFileListTotal2 = res.data.total;
              this.teamUserFileListLoading2 = false;
            } else {
              this.teamUserFileListLoading2 = false;
              this.$Message.error("获取文件数据失败");
            }
          })
          .catch((err) => {
            //this.$Message.error(err.message);
          });
      }
      if (type == "3") {
        this.teamUserFileListLoading3 = true;
        this.$axios
          .get(`${this.apiUrlCros}/gbApi/attachmentsByUser/${uid}?type=3`)
          .then((res) => {
            if (res.data.code == "0") {
              this.teamUserFileList3 = res.data.data;
              this.teamUserFileListTotal3 = res.data.total;
              this.teamUserFileListLoading3 = false;
            } else {
              this.teamUserFileListLoading3 = false;
              this.$Message.error("获取文件数据失败");
            }
          })
          .catch((err) => {
            //this.$Message.error(err.message);
          });
      }
      if (type == "4") {
        this.teamUserFileListLoading4 = true;
        this.$axios
          .get(`${this.apiUrlCros}/gbApi/attachmentsByUser/${uid}?type=4`)
          .then((res) => {
            if (res.data.code == "0") {
              this.teamUserFileList4 = res.data.data;
              this.teamUserFileListTotal4 = res.data.total;
              this.teamUserFileListLoading4 = false;
            } else {
              this.teamUserFileListLoading4 = false;
              this.$Message.error("获取文件数据失败");
            }
          })
          .catch((err) => {
            //this.$Message.error(err.message);
          });
      }
    },
    onBeforeFile1(file) {
      this.toupLoadFile(file);
      return false;
    },
    fileBindUser(sdata) {
      let data = sdata;
      this.$fetch("/gbApi/attachment/relationUser", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "0") {
          this.$Message.success("上传成功");
          this.getFileOnTeam(sdata.type, this.curTeamUser.id);
        } else {
          this.$Message.info("上传失败");
        }
        // // console.log(res)
      });
    },
    // 设置日薪工种
    toEditTypeWork(type) {
      if (type == "reset") {
        this.outsideWorkTypeFlag = false;
        return false;
      }
      if (this.newAddWorkType.type == "") {
        this.$Message.info("请选择设置日薪工种");
        this.outsideWorkTypeNext = false;
        setTimeout(() => {
          this.outsideWorkTypeNext = true;
        }, 500);
        return false;
      }
      if (this.newAddWorkType.money == "") {
        this.$Message.info("请选择设置日薪");
        this.outsideWorkTypeNext = false;
        setTimeout(() => {
          this.outsideWorkTypeNext = true;
        }, 500);
        return false;
      }
      let data = {
        id: this.newAddWorkType.id,
        typeId: this.newAddWorkType.type,
        perDiem: this.newAddWorkType.money,
        remarks: "",
        corpId: this.currentUserInfo.icorpid,
        projectId: this.projectInfo.id,
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      // // console.log(formData)
      this.$fetch(`/osapi/common/addEditTypeWork`, {
        method: "post",
        data,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          // this.GMRealNameData = res.resList;
          // this.GMRealNameTotal = res.total;
          this.getOSWorkTypeMoneyList();
          this.$Message.success("操作成功");
          this.outsideWorkTypeFlag = false;
        } else {
          this.outsideWorkTypeNext = false;
          setTimeout(() => {
            this.outsideWorkTypeNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    handleOSWorkTypePageChange(e) {
      this.GMWorkTypePage = e;
      this.getOSWorkTypeMoneyList();
    },
    getOSWorkTypeMoneyList() {
      this.GMWorkTypeLoading = true;
      let data = {
        typeId: "",
        perDiem: "",
        pageNum: this.GMWorkTypePage,
        pageSize: this.GMWorkTypeSize,
        corpId: this.currentUserInfo.icorpid,
        projectId: this.projectInfo.id,
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      // // console.log(formData)
      this.$fetch(`/osapi/common/getTypeWorkAllList`, {
        method: "post",
        data: formData,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          this.GMWorkTypeData = res.data;
          this.GMWorkTypeTotal = res.total;
          this.GMWorkTypeLoading = false;
        } else {
          this.$Message.error("获取工种日薪信息失败");
          this.GMWorkTypeLoading = false;
        }
      });
    },
    handleOSWorkerPageChange(e) {
      this.GMWorkerPage = e;
      this.getOSWorkerMoneyList();
    },
    getOSWorkerMoneyList() {
      this.GMWorkerLoading = true;
      let data = {
        teamId: "",
        userId: "",
        perDiem: "",
        pageNum: this.GMWorkerPage,
        pageSize: this.GMWorkerSize,
        corpId: this.currentUserInfo.icorpid,
        projectId: this.projectInfo.id,
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      // // console.log(formData)
      this.$fetch(`/osapi/common/getConstructionList`, {
        method: "post",
        data: formData,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          this.GMWorkerData = res.data;
          this.GMWorkerTotal = res.total;
          this.GMWorkerLoading = false;
        } else {
          this.$Message.error("获取人员日薪信息失败");
          this.GMWorkerLoading = false;
        }
      });
    },
    handlePickOSTeam(e) {
      if (e) {
        this.getOSTeamUser(e);
      }
    },

    // 整改分类

    getOSRectifyTypeList() {
      this.OSRectifyTypeLoading = true;
      let data = {
        pageNum: this.OSRectifyTypePage,
        pageSize: this.OSRectifyTypeSize,
        projectId: this.projectInfo.id,
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      // // console.log(formData)
      this.$fetch(`/osapi/rectify/reform/getRectifyTypeList`, {
        method: "post",
        data: formData,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          this.OSRectifyTypeData = res.data;
          this.OSRectifyTypeTotal = res.total;
          this.OSRectifyTypeLoading = false;
        } else {
          this.$Message.error("获取整改分类信息失败");
          this.OSRectifyTypeLoading = false;
        }
      });
    },
    handleOSRectifyTypePageChange(e) {
      this.OSRectifyTypePage = e;
      this.getOSRectifyTypeList();
    },
    getOSFineTypeList() {
      this.OSFineTypeLoading = true;


      let formData = new FormData();
      formData.append("pageNum", this.OSFineTypePage);
      formData.append("pageSize", this.OSFineTypeSize);
      formData.append("projectId", this.projectInfo.id);


      // let data = {
      //   pageNum: this.OSFineTypePage,
      //   pageSize: this.OSFineTypeSize,
      //   projectId: this.projectInfo.id,
      // };
      // // console.log(formData)
      this.$fetch(`/osapi/fine/reform/getFineTypeList`, {
        method: "post",
        // data,
        data:formData,

      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          this.OSFineTypeData = res.data;
          this.OSFineTypeTotal = res.total;
          this.OSFineTypeLoading = false;
        } else {
          this.$Message.error("获取罚单分类信息失败");
          this.OSFineTypeLoading = false;
        }
      });
    },
    handleOSFineTypePageChange(e) {
      this.OSFineTypePage = e;
      this.getOSFineTypeList();
    },

    getOSTeamUser(tid) {
      let data = {
        teamId: tid,
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      this.$fetch(`/osapi/common/getConstructionListByTeamId`, {
        method: "post",
        data: formData,
      }).then((res) => {
        if (res && res.code === "200") {
          this.osUserList = res.data;
        } else {
          this.$Message.error("获取外部交换平台人员列表失败");
        }
      });
    },
    // 设置日薪工人
    toEditConstruction(type) {
      if (type == "reset") {
        this.outsideWorkerFlag = false;
        return false;
      }
      if (this.newAddWorker.team == "") {
        this.$Message.info("请选择班组");
        this.outsideWorkerNext = false;
        setTimeout(() => {
          this.outsideWorkerNext = true;
        }, 500);
        return false;
      }
      if (this.newAddWorker.name == "") {
        this.$Message.info("请选择人员");
        this.outsideWorkerNext = false;
        setTimeout(() => {
          this.outsideWorkerNext = true;
        }, 500);
        return false;
      }
      if (this.newAddWorker.money == "") {
        this.$Message.info("请选择设置日薪");
        this.outsideWorkerNext = false;
        setTimeout(() => {
          this.outsideWorkerNext = true;
        }, 500);
        return false;
      }
      let data = {
        id: this.newAddWorker.id,
        teamId: this.newAddWorker.team,
        userId: this.newAddWorker.name,
        perDiem: this.newAddWorker.money,
        remarks: "",
        corpId: this.currentUserInfo.icorpid,
        projectId: this.projectInfo.id,
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      // // console.log(formData)
      this.$fetch(`/osapi/common/addEditConstruction`, {
        method: "post",
        data,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          // this.GMRealNameData = res.resList;
          // this.GMRealNameTotal = res.total;
          this.getOSWorkerMoneyList();
          this.$Message.success("操作成功");
          this.outsideWorkerFlag = false;
        } else {
          this.outsideWorkerNext = false;
          setTimeout(() => {
            this.outsideWorkerNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    // 设置整改分类
    toEditRectifyType(type) {
      if (type == "reset") {
        this.outsideRectifyTypeFlag = false;
        return false;
      }
      if (this.newRectifyTypeData.name == "") {
        this.$Message.info("请填写分类名称");
        this.outsideRectifyTypeNext = false;
        setTimeout(() => {
          this.outsideRectifyTypeNext = true;
        }, 500);
        return false;
      }
      let data = {
        id: this.newRectifyTypeData.id,
        name: this.newRectifyTypeData.name,
        projectId: this.projectInfo.id,
      };
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      // // console.log(formData)
      this.$fetch(`/osapi/rectify/reform/addEditRectifyType`, {
        method: "post",
        data,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          // this.GMRealNameData = res.resList;
          // this.GMRealNameTotal = res.total;
          this.getOSRectifyTypeList();
          this.$Message.success("操作成功");
          this.outsideRectifyTypeFlag = false;
        } else {
          this.outsideRectifyTypeNext = false;
          setTimeout(() => {
            this.outsideRectifyTypeNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    // 设置罚单分类
    toEditFineType(type) {
      if (type == "reset") {
        this.outsideFineTypeFlag = false;
        return false;
      }
      if (this.newFineTypeData.name == "") {
        this.$Message.info("请填写分类名称");
        this.outsideFineTypeNext = false;
        setTimeout(() => {
          this.outsideFineTypeNext = true;
        }, 500);
        return false;
      }
      let data = {
        id: this.newFineTypeData.id,
        name: this.newFineTypeData.name,
        projectId: this.projectInfo.id,
      };
      this.$fetch(`/osapi/fine/reform/addEditFineType`, {
        method: "post",
        data,
      }).then((res) => {
        // console.log(res);
        if (res && res.code == "200") {
          // console.log(res);
          // this.GMRealNameData = res.resList;
          // this.GMRealNameTotal = res.total;
          this.getOSFineTypeList();
          this.$Message.success("操作成功");
          this.outsideFineTypeFlag = false;
        } else {
          this.outsideFineTypeNext = false;
          setTimeout(() => {
            this.outsideFineTypeNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    handleSearchPeopleList() {},
   
    // 跳转到登录页面
    toLogin(){
      this.$router.push({ name: 'login' });
    },
     // 跳转到重置密码
    toResetPassword(type){
      if(type=="close"){
        this.passwordOverTimeModal=false;

      }else{
      this.$router.push({ name: 'resetPassword' });

      }

    },


     // 关闭  没有修改过密码  的弹窗   只有正式的时候先用一下
    toResetPassword1(){
      this.passwordNeverChangeModal=false;
    },
    showBasicConceal(){
      this.basicHide=false;
    },
    hideBasicConceal(){
      this.basicHide=true;
    }
  },
};
</script>
<style scoped>
/* .test-project >>> .ivu-menu-submenu-title{
  background: #2d8cf0 !important;
}
.test-project >>> .ivu-menu-submenu-title:hover{
  background: #5cadff !important;
}
.test-project >>> .ivu-menu-opened .ivu-menu-submenu-title{
  background: #2b85e4 !important;
}
.test-project >>> .ivu-menu{
  background: #2d8cf0 !important;
} */
.test-project >>> .ivu-menu-item-group-title {
  color: #fff;
}
</style>
<style lang="less">
@import "./public/style.less";
.ivu-menu-vertical.ivu-menu-light:after {
  background: #fff;
}
.ivu-table .demo-table-info-row td {
  background-color: #fff9e6;
  color: #333;
}

</style>
<style>
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
.passwordTips{
  text-align:center;
}
.showBasicOrHideBasic{
  font-size:14px;
  margin-left:10px;
  color:#2d8cf0;
  cursor: pointer;
}
</style>
