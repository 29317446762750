export default{
    vcategoryList: [
        {
          value: '01',
          label: '房屋建筑工程'
        },
        {
          value: '02',
          label: '市政公用工程'
        },
        {
          value: '03',
          label: '机电安装工程'
        },
        {
          value: '04',
          label: '铁路工程'
        },
        {
          value: '05',
          label: '公路工程'
        },
        {
          value: '06',
          label: '港口与航道工程'
        },
        {
          value: '07',
          label: '水利水电工程'
        },
        {
          value: '08',
          label: '电力工程'
        },
        {
          value: '09',
          label: '矿山工程'
        },
        {
          value: '10',
          label: '冶炼工程'
        },
        {
          value: '11',
          label: '化工石油工程'
        },
        {
          value: '12',
          label: '通信工程'
        },
        {
          value: '99',
          label: '其他'
        },
      ],
      frame_typeList: [
        {
          value: '001',
          label: '框架结构'
        },
        {
          value: '003',
          label: '钢混结构'
        },
        {
          value: '004',
          label: '砖混结构'
        },
        {
          value: '005',
          label: '装配式钢筋混泥土结构'
        },
        {
          value: '006',
          label: '现浇剪力墙结构'
        },
      ],
      vprojectstatusList: [
        {
          value: "001",
          label: '筹备'
        },
        {
          value: "003",
          label: '在建'
        },
        {
          value: "004",
          label: '完工'
        },
        {
          value: "005",
          label: '停工'
        },
      ],
      vfunction_numList: [
        {
          value: '100',
          label: '居住建筑'
        },
        {
          value: '200',
          label: '居住建筑配套工程'
        },
        {
          value: '300',
          label: '公共建筑'
        },
        {
          value: '301',
          label: '办公建筑'
        },
        {
          value: '302',
          label: '商业建筑'
        },
        {
          value: '303',
          label: '旅游建筑'
        },
        {
          value: '304',
          label: '科教文卫建筑'
        },
        {
          value: '305',
          label: '交通运输类'
        },
        {
          value: '306',
          label: '通信建筑'
        },
        {
          value: '307',
          label: '公共建筑配套工程'
        },
        {
          value: '400',
          label: '商住楼'
        },
        {
          value: '500',
          label: '农业建筑'
        },
        {
          value: '600',
          label: '农业建筑配套工程'
        },
        {
          value: '700',
          label: '工业建筑'
        },
        {
          value: '800',
          label: '工业建筑配套工程'
        },
        {
          value: '999',
          label: '其他'
        },
        {
          value: '010',
          label: '给水'
        },
        {
          value: '011',
          label: '排水'
        },
      ],
}